<section class="py-1 small d-none d-lg-block sectionClass">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-lg-5 col-sm-5">
                <ul class="nav">
                    <!-- <li class="nav-item">
                        <a class="nav-link py-0 border-end active" aria-current="page" routerLinkActive="active"
                            routerLink="/home">Home</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/multimedia/feedback']"
                            routerLinkActive="active">Feedback</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" routerLinkActive="active" routerLink="/sitemap">Sitemap</a>
                    </li>
                    <li class="nav-item" *ngIf="!isLoggedIn">
                        <a class="nav-link py-0 navHover" routerLinkActive="active" routerLink="/login">Login</a>
                    </li>
                    <li class="nav-item" *ngIf="isLoggedIn">
                        <a class="nav-link py-0 navHover" routerLinkActive="active" [routerLink]="!userLevelName ? ['/admin/hrModule'] : ['/admin/content']" (click)="openAdminContent()">Admin Content</a>
                        <!-- <a class="nav-link py-0 navHover" *ngIf="userLevelName" routerLinkActive="active" routerLink="['/admin/hrModule']" (click)="openAdminContent()">Admin Content</a> -->
                        <!-- <a class="nav-link py-0 navHover" *ngIf="!userLevelName" routerLinkActive="active" routerLink="['/admin/content']" (click)="openAdminContent()">Admin Content</a> -->
                    </li>
                </ul>
            </div>
            <div class="col-md-7 col-lg-7 col-sm-7">
                <ul class="nav  justify-content-end">
                    <li class="nav-item">
                        <a href="#main-content" class="nav-link py-0 border-end navHover">Skip to main content</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link py-0 border-end navHover" [routerLink]="['/screen-reader-access']">Screen Reader
                            Access</a>
                    </li>
                    <li class="nav-item">
                        <ng-container *ngFor="let data of textSize">
                            <span (click)="setFontSize(data)" class="text-{{data}} textIcon circleHover" style="cursor: pointer;border: none;"
                                [ngClass]="currentTextSize == data ? 'active' : '' ">A</span>
                        </ng-container>
                    </li>
                    <li  class="nav-item">
                        <a href="https://www.facebook.com/aajeevika" target="_blank" class="nav-link py-0 circleHover">
                        <i class="fa fa-facebook" aria-hidden="true" style="margin-left: -5px;"></i>
                      </a>
                      </li>
                  
                      <li  class="nav-item">
                        <a href="https://twitter.com/DAY_NRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-twitter" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                      </li>
                      <!-- <li class="instagram">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                        <div class="slider">
                          <p>instagram</p>
                        </div>
                      </li> -->
                  
                      <li  class="nav-item">
                        <a href="http://www.youtube.com/user/aajeevikaNRLM" target="_blank" class="nav-link py-0 circleHover">
                          <i class="fa fa-youtube" aria-hidden="true" style="margin-left: -5px;"></i>
                        </a>
                  
                  
                      </li>
                </ul>
            </div>
            
        </div>
    </div>
</section>
<section class="py-3 bg-white shadow">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-3 col-6 text-right">
                <img src="/assets/images/NewMord.png" alt="Deendayal Antyodaya Yojana"
                    title="Deendayal Antyodaya Yojana" class="img-fluid" />
            </div>
            <div class="col-lg-6 col-6 d-flex" style="display: flex;justify-content: right;">
                <div class="fake-alert" (click)="showFakePdf()">
                    <strong>Alert!</strong> Regarding Fake Websites.
                  </div>
                <img src="/assets/images/AAJEEVIKA LOGO png.png" style="height: 58px;
                width: 128px;"
                    alt="Ministry of Rural Development (MoRD), Government of India"
                    title="Ministry of Rural Development (MoRD), Government of India" class="img-fluid" />
                    <!-- <p class="ms-3 mt-1"><b style="font-size: 15px;
                        font-weight: 600;">Deendayal Antyodaya Yojana <br> National Rural Livelihoods Mission</b></p> -->
            </div>
        </div>
    </div>
</section>
<nav class="navbar navbar-expand-lg navbar-dark bg-primary nav-primary" *ngIf="!hasRoute('admin')" style="display: none;">
    <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">

                <li class="nav-item" (click)="openHome()">
                    <a class="nav-link" aria-current="page" routerLinkActive="active" routerLink="/home">Home</a>
                </li>

                <li class="nav-item" (click)="openDashboard()">
                    <a class="nav-link" href="https://nrlm.gov.in/dashboardForOuter.do?methodName=dashboard"
                        target="_blank">Dashboard</a>
                </li>

                <li class="nav-item dropdown" routerLinkActive="active" (click)="openAboutDropdown()">
                    <a class="nav-link dropdown-toggle dropdown dropdown-6" id="aboutUs" role="button"
                        aria-expanded="false" [style.color]="aboutUsSubDropdown === true ? '#fff' : '#cba6a6'">
                        About Us
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6"
                        [style.display]="aboutUsDropdown === true ? 'block' : 'none'" aria-labelledby="aboutUs">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/introduction'" routerLink="/about/introduction"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active" aria-controls="navbarText"
                                aria-expanded="false" (click)="openAboutusSubDropdown()">Introduction</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/founding-philosophy'" routerLink="/about/founding-philosophy"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" (click)="openAboutusSubDropdown()">Founding
                                Philosophy</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/genesis'" routerLink="/about/genesis"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Genesis</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/goal'" routerLink="/about/goal"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Goal</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/guiding-principles'" routerLink="/about/guiding-principles"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Guiding Principles </a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/values'" routerLink="/about/values"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Values</a></li>
                        <!-- <li><a class="dropdown-item" routerLink="/about/leadership">Leadership</a></li> -->
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/about/team'" routerLink="/about/team"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openAboutusSubDropdown()">Team</a></li>
                    </ul>
                </li>

                <li class="nav-item dropdown" (click)="openWhatDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" routerLinkActive="active" id="whatWeDo"
                        role="button" aria-expanded="false"
                        [style.color]="whatSubDropdown === true ? '#fff' : '#cba6a6'">
                        What We Do
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="whatWeDo"
                        [style.display]="whatDropdown === true ? 'block' : 'none'">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/institutional-capacity-building'" routerLink="/what-we-do/institutional-capacity-building"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Institutional Building and Capacity Building</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/social-inclusion-and-social-development'" routerLink="/what-we-do/social-inclusion-and-social-development"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Social Inclusion and Social Development</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/universal-financial-inclusion'" routerLink="/what-we-do/universal-financial-inclusion"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Universal Financial Inclusion</a></li>
                        <li class="dropdown-ele" class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/livelihoods'" routerLink="/what-we-do/livelihoods"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Livelihoods</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/monitoring-system-and-mis'" routerLink="/what-we-do/monitoring-system-and-mis"><a class="dropdown-item" 
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">Programme Management</a></li>
                        <li routerLink="/what-we-do/national-rural-economic-transformation-project" class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/national-rural-economic-transformation-project'"><a class="dropdown-item"
                               
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWhatSubDropdown()">National Rural Economic Transformation Project</a></li>
                    </ul>
                </li>
                <!--  -->
                <li class="nav-item dropdown" (click)="openWorkStructureDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="workStructure" role="button"
                        routerLinkActive="active" aria-expanded="false"
                        [style.color]="workStructureSubDropdown === true ? '#fff' : '#cba6a6'">
                        Work Structure
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="workStructure"
                        [style.display]="workStructureDropdown === true ? 'block' : 'none'">
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/approach'" routerLink="/work-structure/approach"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Approach</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/support-units'" routerLink="/work-structure/support-units"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Support Units</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/resource-blocks'" routerLink="/work-structure/resource-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Resource Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/intensive-blocks'" routerLink="/work-structure/intensive-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Intensive Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/partnership-blocks'" routerLink="/work-structure/partnership-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Partnership Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/non-intensive-blocks'" routerLink="/work-structure/non-intensive-blocks"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Non-Intensive Blocks</a></li>
                        <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/exit-strategy'" routerLink="/work-structure/exit-strategy"><a class="dropdown-item"
                                data-bs-toggle="collapse" data-bs-target="#navbarText.show" routerLinkActive="active"
                                (click)="openWorkstructureSubDropdown()">Exit Strategy</a></li>
                    </ul>
                </li>
                <!--  -->
                <li class="nav-item dropdown" (click)="openImpactDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="ourImpact" role="button"
                        aria-expanded="false" [style.color]="ourImpactSubDropdown === true ? '#fff' : '#cba6a6'">
                        Our Impact
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="ourImpact"
                        [style.display]="impactDropdown === true ? 'block' : 'none'">
                        <li routerLink="/our-impact/overview" routerLinkActive="active" [class.selectedList]="router.url == '/our-impact/overview'" class="dropdown-ele"><a class="dropdown-item"  data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" 
                                (click)="openOurImpactSubDropdown()">Overview</a></li>
                        <li routerLinkActive="active" routerLink="/our-impact/success-stories" [class.selectedList]="router.url == '/our-impact/success-stories'" class="dropdown-ele"><a class="dropdown-item" data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show" 
                                (click)="openOurImpactSubDropdown()">Success Stories</a></li>
                        <!-- <li><a class="dropdown-item" routerLink="/our-impact/fact-sheet">Fact Sheets</a></li>
                        <li><a class="dropdown-item" routerLink="/our-impact/milestones">Milestones</a></li> -->
                    </ul>
                </li>

                <li class="nav-item dropdown" (click)="openResourceDropdown()">
                    <a class="nav-link dropdown-toggle  dropdown dropdown-6" id="resourceCentre" role="button"
                        aria-expanded="false" [style.color]="resourceSubDropdown === true ? '#fff' : '#cba6a6'">
                        Resource Centre
                    </a>
                    <ul class="dropdown_menu dropdown_menu--animated dropdown_menu-6" aria-labelledby="resourceCentre"
                        [style.display]="resourceDropdown === true ? 'block' : 'none'">
                        <li routerLinkActive="active" class="dropdown-ele" [class.selectedList]="activeButton === item?.subModuleName" (click)="getToResourceSubModule(item);openResourceSubDropdown()" style="cursor: pointer;" *ngFor="let item of resourceSubModules"><a class="dropdown-item"
                                 data-bs-toggle="collapse"
                                data-bs-target="#navbarText.show"
                                >{{item.subModuleName}}</a></li>

                    </ul>
                </li>

                <li class="nav-item" routerLink="/join-us" routerLinkActive="active">
                    <a class="nav-link"  data-bs-toggle="collapse"
                        data-bs-target="#navbarText.show" (click)="openCareers()">Career Data</a>
                </li>
                <li class="nav-item" routerLink="/join-us" routerLinkActive="active">
                    <a class="nav-link"  data-bs-toggle="collapse"
                        data-bs-target="#navbarText.show" (click)="openGrievances()">Grievances</a>
                </li>
            </ul>
            <ul class="nav">
                <li class="nav-item d-flex" *ngIf="!isLoginPage">
                    <form [formGroup]="searchForm" class="d-flex bg-white rounded searchForm"
                        (ngSubmit)="enterSubmit($event, searchForm.value)">
                        <input type="text" name="searchForm" id="searchFormInput" class="form-control border-0"
                            formControlName="searchValue" placeholder="search...">
                        <button class="btn btn-link"><i class="fa fa-search icon"></i></button>
                    </form>


                </li>
                <!-- <li class="nav-item">
                    <a   class="btn btn-login">Login</a>
                </li> -->
            </ul>
        </div>
    </div>
</nav>

<nav class="nav-ajvk bg-primary nav-primary">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-sm-9">
                <ul class="cust-navbar">
                    <li routerLinkActive="active" class="nav-ele">
                        <a routerLink="/home">Home</a>
                    </li>
                    <li class="nav-ele">
                        <a href="https://nrlm.gov.in/dashboardForOuter.do?methodName=dashboard" target="_blank">Dashboard</a>
                    </li>
                    <li class="nav-ele aboutDropdown" routerLinkActive="active">
                        <a href="#">About Us
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/introduction'" routerLinkActive="active" routerLink="/about/introduction">
                                <a>Introduction</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/founding-philosophy'" routerLinkActive="active" routerLink="/about/founding-philosophy">
                                <a>Founding Philosophy</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/genesis'" routerLinkActive="active" routerLink="/about/genesis">
                                <a>Genesis</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/goal'" routerLinkActive="active" routerLink="/about/goal">
                                <a>Goal</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/guiding-principles'" routerLinkActive="active" routerLink="/about/guiding-principles">
                                <a>Guiding Principles</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/values'" routerLinkActive="active" routerLink="/about/values">
                                <a>Values</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/about/team'" routerLinkActive="active" routerLink="/about/team">
                                <a>Team</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-ele whatWeDoDropdown" routerLinkActive="active">
                        <a href="#">What We Do
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/institutional-capacity-building'" routerLinkActive="active" routerLink="/what-we-do/institutional-capacity-building">
                                <a>Institutional Building and Capacity Building</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/social-inclusion-and-social-development'" routerLinkActive="active" routerLink="/what-we-do/social-inclusion-and-social-development">
                                <a>Social Inclusion and Social Development</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/universal-financial-inclusion'" routerLinkActive="active" routerLink="/what-we-do/universal-financial-inclusion">
                                <a>Universal Financial Inclusion</a></li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/what-we-do/livelihoods'" routerLinkActive="active" routerLink="/what-we-do/livelihoods">
                                <a>Livelihoods</a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/monitoring-system-and-mis">
                                    Monitoring System and MIS
                                </a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/knowledge-management">
                                    Knowledge Management and Communication
                                </a>
                            </li>
                            <li class="dropdown-ele">
                                <a routerLinkActive="active" routerLink="/what-we-do/national-rural-economic-transformation-project">National Rural Economic Transformation Project</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-ele implementationStructure" routerLinkActive="active">
                        <a >Implementation Structure
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/approach'"  routerLinkActive="active" routerLink="/work-structure/approach">
                                <a>Approach</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/support-units'" routerLinkActive="active" routerLink="/work-structure/support-units">
                                <a>Support Units</a></li>
                            <!-- <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/resource-blocks'" routerLinkActive="active" routerLink="/work-structure/resource-blocks">
                                <a>Resource Blocks</a></li> -->
                            <!-- <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/intensive-blocks'" routerLinkActive="active" routerLink="/work-structure/intensive-blocks">
                                <a>Intensive Blocks</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/partnership-blocks'" routerLinkActive="active" routerLink="/work-structure/partnership-blocks">
                                <a>Partnership Blocks</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/non-intensive-blocks'" routerLinkActive="active" routerLink="/work-structure/non-intensive-blocks">
                                <a>Non-Intensive Blocks</a>
                            </li> -->
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/community-institutional'" routerLinkActive="active" routerLink="/work-structure/community-institutional">
                                <a>Community Institutional Architecture</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/work-structure/exit-strategy'" routerLinkActive="active" routerLink="/work-structure/exit-strategy">
                                <a>Exit Strategy</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-ele ourImpactStructure" routerLinkActive="active">
                        <a href="#">Our Impact
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/our-impact/overview'" routerLinkActive="active" routerLink="/our-impact/overview">
                                <a>Overview</a>
                            </li>
                            <li class="dropdown-ele" [class.selectedList]="router.url == '/our-impact/success-stories'" routerLinkActive="active" routerLink="/our-impact/success-stories">
                                <a>Success Stories</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-ele resourceCenter" routerLinkActive="active">
                        <a href="#">Resource Centre
                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                        <ul class="dropdown">
                            <li class="dropdown-ele" *ngFor="let item of resourceSubModules" (click)="getToResourceSubModule(item);openResourceSubDropdown()"
                            [class.selectedList]="activeButton === item?.subModuleName">
                                <a routerLinkActive="active">
                                    {{item.subModuleName}}
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li routerLinkActive="active" class="nav-ele" routerLink="/join-us" (click)="openCareersPage()">
                        <a>Careers</a>
                    </li>
                    <li routerLinkActive="active" class="nav-ele" routerLink="/grievances">
                        <a>Grievances</a>
                    </li>
                  
                </ul>
               
            </div>
            <div class="col-md-3 col-sm-3">
                <ul>
                  <li class="nav-ele hideBorder" *ngIf="!isLoginPage">
                    <form [formGroup]="searchForm" class="d-flex searchForm"
                    (ngSubmit)="enterSubmit($event, searchForm.value)">
                        <input class="input-transparent borderRadius" type="text" placeholder="Search" name="searchForm" formControlName="searchValue">             
                    </form>
            </li></ul></div>
            
    
            
        </div>
    </div>
</nav>
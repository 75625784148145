import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ApproachComponent } from './approach/approach.component';
import { SupportUnitsComponent } from './support-units/support-units.component';
import { ResourceBlocksComponent } from './resource-blocks/resource-blocks.component';
import { IntensiveBlocksComponent } from './intensive-blocks/intensive-blocks.component';
import { PartnershipBlocksComponent } from './partnership-blocks/partnership-blocks.component';
import { NonIntensiveBlocksComponent } from './non-intensive-blocks/non-intensive-blocks.component';
import { ExitStrategyComponent } from './exit-strategy/exit-strategy.component';
import { CommunityInstitutionalComponent } from './community-institutional/community-institutional.component';

const routes: Routes = [
    {
		path: "",
		redirectTo: 'approach', 
		pathMatch: 'full',
	},
	{
		path: "approach",
        component: ApproachComponent	,
	},
	{
		path: "support-units",
        component: SupportUnitsComponent	,
	},
	{
		path: "resource-blocks",
        component: ResourceBlocksComponent	,
	},
	{
		path: "intensive-blocks",
        component: IntensiveBlocksComponent	,
	},
	{
		path: "partnership-blocks",
        component: PartnershipBlocksComponent	,
	},
	{
		path: "non-intensive-blocks",
        component: NonIntensiveBlocksComponent	,
	},
	{
		path: "community-institutional",
        component: 	CommunityInstitutionalComponent,
	},
	{
		path: "exit-strategy",
        component: ExitStrategyComponent ,
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkStructureRoutingModule { }
<div class="bg-white rounded-3 shadow p-3 my-3">
    <ng-container *ngIf="!isKms">
        <div class="d-flex">
            <div>
                <a class="btn btn-link" (click)="goBack()">
                    <img src="assets/icons/arrow_back_black_24dp.svg" alt="back icon">
                </a>
            </div>
            <div>
                <h3 class="text-black m-0">{{title}}</h3>
            </div>
        </div>
        <hr class="under-line" />
    </ng-container>
    <div *ngIf="userLevelName === 'hrModule' && dynamicForm?.uploadDate">
        <app-instruction-editor [htmlContent]="htmlContent" (backToPreviousPage)="comeToHrModuleFromInstruction($event)"></app-instruction-editor>
    </div>
    <form [formGroup]="entryForm" (ngSubmit)="onSubmit()" class="form-group" autocomplete="off">
        <div class="row">
            <!-- Enter Position -->
            <div class="col-sm-3 mb-3 position-relative" *ngIf="dynamicForm?.positionName && positionMaster">
                <label for="">Position <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Position"
                    name="positionName" formControlName="positionName" required autocomplete="off">
                <p *ngIf="positionMasterFormValidation && !entryForm?.get('positionName')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Select Under Position -->
            <div class="col-sm-3 mb-3 position-relative" *ngIf="dynamicForm?.underPosition" style="display: flex; flex-direction: column;">
                <label for="underPosition">Select Division<span class="text-danger">*</span></label>
                <select [ngClass]="{'disabled': isViewMode}" name="underPosition" id="underPosition" formControlName="underPosition" required autocomplete="off" class="select-style">
                    <option value="">Select</option>
                    <option value="RL">RL</option>
                    <option value="DDUGKY">DDUGKY</option>
                </select>
                <p *ngIf="positionMasterFormValidation && !entryForm?.get('underPosition')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Max Age -->
            <div class="col-sm-3 mb-3 position-relative" *ngIf="dynamicForm?.maxAge">
                <label for="">Max age </label>
                <input class="form-control" type="text" inputmode="numeric" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Age" name="maxAge" formControlName="maxAge"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    autocomplete="off"
                    maxlength="2">
                <p *ngIf="positionMasterFormValidation && !entryForm?.get('maxAge')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Min Years of Exp -->
            <div class="col-sm-3 mb-3 position-relative" *ngIf="dynamicForm?.minExp">
                <label for="">Min years of Experience<span class="text-danger">*</span></label>
                <input class="form-control" type="text" inputmode="numeric" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Experience" name="minExp" formControlName="minExp" required
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="2"
                    autocomplete="off">
                <p *ngIf="positionMasterFormValidation && !entryForm?.get('minExp')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
                </div>
            <!-- Select Date Range  -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.periodicApplication">
                <label for="">Period of application<span class="text-danger">*</span></label>
                <mat-form-field [ngClass]="{'disabled': isViewMode}">
                    <mat-date-range-input [rangePicker]="picker" placeholder="Select a Period">
                        <input matStartDate matInput placeholder="Start date" formControlName="startDatePeriodicApplication" />
                        <input matEndDate matInput placeholder="End date" formControlName="endDatePeriodicApplication"/>
                  </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
            </div>
            <!-- Position Master Code -->
            <div class="col-sm-3 mb-3 position-relative" *ngIf="dynamicForm?.code && positionMaster">
                <label for="">Code<span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': true}"
                    placeholder="NRLPS/xxxxx" name="code" formControlName="code" required
                    autocomplete="off">
                <p *ngIf="positionMasterFormValidation && !entryForm?.get('code')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!--  -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.title && !addMoreButton">
                <label for="">Title <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Title"
                    name="title" formControlName="title" required autocomplete="off">
            </div>
            <!-- Job listing title -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.title && addMoreButton">
                <label for="">Title <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Title"
                    name="title" formControlName="title" required autocomplete="off">
                <p *ngIf="jobNotificationCode && !entryForm?.get('title')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.publishedDate">
                <label for="">Published Date <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Date"
                    name="publishedDate" formControlName="publishedDate" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.publishedBy">
                <label for="">Published By <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Published By" name="publishedBy" formControlName="publishedBy" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.author">
                <label for="">Author <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Author" name="author" formControlName="author" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.source">
                <label for="">Source <span class="text-danger">*</span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Source" name="source" formControlName="source" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.link">
                <label for="">Link <span class="text-danger"
                        *ngIf="moduleData.moduleId=='28'?false:true">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Link"
                    name="link" formControlName="link" [required]="moduleData.moduleId=='28'?false:true"
                    pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?"
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.corrigendum">
                <label for="">Corrigendum <span class="text-danger">*</span></label>
                <!-- <input class="form-check" type="checkbox" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Corrigendum" name="corrigendum" formControlName="corrigendum"> -->
                <div class="">
                    <input type="radio" name="corrigendum" [ngClass]="{'disabled': isViewMode}"
                        formControlName="corrigendum" [value]="1" required> Yes
                    <input type="radio" name="corrigendum" [ngClass]="{'disabled': isViewMode}"
                        formControlName="corrigendum" [value]="2" required> No
                </div>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.fileNumber">
                <label for="">File Number <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter File Number" name="fileNumber" formControlName="fileNumber" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedNo">
                <label for="">Issued Number <span class="text-danger">*</span></label>
                <input class="form-control" type="number" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued Number" name="issuedNo" formControlName="issuedNo" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedNumber">
                <label for="">Issued No. <span class="text-danger">*</span></label>
                <input class="form-control" type="number" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued No." name="issuedNumber" formControlName="issuedNumber" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.location">
                <label for="">Location <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Location" name="location" formControlName="location" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.rtiQuery">
                <label for="">RTI Query <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" class="form-control" type="text"
                    [ngClass]="{'disabled': isViewMode}" placeholder="Enter RTI Query" name="rtiQuery"
                    formControlName="rtiQuery" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedBy">
                <label for="">Issued By <span class="text-danger"></span></label>
                <input appAlphabetOnly class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued By" name="issuedBy" formControlName="issuedBy" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.referenceNo">
                <label for="">Reference Number<span class="text-danger"></span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" placeholder="Referenece Number" name="referenceNo"
                    formControlName="referenceNo" autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.autoExpiry">
                <label for="">Expiry Date <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Expiry Date" name="autoExpiry" formControlName="autoExpiry" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.dueDateExtended">
                <label for="">Due Date Extended <span class="text-danger">*</span></label>
                <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Due Date Extended" name="dueDateExtended" formControlName="dueDateExtended"> -->
                <div class="">
                    <input type="radio" name="dueDateExtended" [ngClass]="{'disabled': isViewMode}"
                        formControlName="dueDateExtended" [value]="1" required> Yes
                    <input type="radio" name="dueDateExtended" [ngClass]="{'disabled': isViewMode}"
                        formControlName="dueDateExtended" [value]="2" required> No
                </div>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.lastDateSubmission">
                <label for="">Last Date Of Submission <span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Last Date Of Submission" name="lastDateSubmission"
                    formControlName="lastDateSubmission" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.vacancyUploadedDate">
                <label for="">Vacancy Uploaded Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Vacancy Uploaded Date" name="vacancyUploadedDate"
                    formControlName="vacancyUploadedDate" required autocomplete="off">
            </div>
            <!-- Job Posting Vacancy Uploaded Date -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.uploadDate">
                <label for="">Vacancy Uploaded Dated<span class="text-danger">*</span></label>
                <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    [min]="currentDate"
                    placeholder="Select Date" name="uploadDate" (change)="getJobPostingVacancyUploadedDate($event)"
                    formControlName="uploadDate" required autocomplete="off"> -->
                    <div class="date-picker">
                        <input required matInput [matDatepicker]="uploadDate" placeholder="Select Upload Date" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode}" [min]="currentDate" [max]="currentDate" (dateChange)="getJobPostingVacancyUploadedDate($event)" formControlName="uploadDate">
                        <mat-datepicker-toggle matSuffix [for]="uploadDate" class="date-picker-toggle"></mat-datepicker-toggle>
                        <mat-datepicker #uploadDate></mat-datepicker>
                    </div>
                    <p *ngIf="jobNotificationCode && entryForm.controls.uploadDate.errors?.required" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Application Opening Date -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.openingDate">
                <label for="">Application Opening Date<span class="text-danger">*</span></label>
                <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" (change)="getApplicationUploadDate($event)"
                    placeholder="Select Date" name="openingDate" [min]="uploadDate"
                    formControlName="openingDate" required autocomplete="off"> -->
                
                <div class="date-picker">
                    <input required matInput [matDatepicker]="openingDate" placeholder="Select Application Opening Date" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode}" [min]="currentDate" (dateChange)="getApplicationUploadDate($event)" formControlName="openingDate">
                    <mat-datepicker-toggle matSuffix [for]="openingDate" class="date-picker-toggle"></mat-datepicker-toggle>
                    <mat-datepicker #openingDate></mat-datepicker>
                </div>
                    <p *ngIf="jobNotificationCode && entryForm.controls.openingDate.errors?.required" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Job Posting Last Date Submission -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.lastDate">
                <label for="">Application Closing Date<span class="text-danger">*</span></label>
                <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Select Date" name="lastDate" [min]="openingDate"
                    formControlName="lastDate" required autocomplete="off"> -->
                    <div class="date-picker">
                        <input required matInput [matDatepicker]="lastDate" placeholder="Select Application Closing Date" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode}" [min]="vacancyLastDate" (dateChange)="getApplicationLastDate($event)" formControlName="lastDate">
                        <mat-datepicker-toggle matSuffix [for]="lastDate" class="date-picker-toggle"></mat-datepicker-toggle>
                        <mat-datepicker #lastDate></mat-datepicker>
                    </div>
                    <p *ngIf="jobNotificationCode && entryForm.controls.lastDate.errors?.required" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <!-- Job notification code -->
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.jobNotificationCode">
                <label for="">Code<span class="text-danger">*</span></label>
                <input class="form-control" type="text"
                    [ngClass]="{'disabled': true}"
                    placeholder="NRLPS/xxxxx" name="jobNotificationCode" formControlName="jobNotificationCode" required
                    autocomplete="off">
                    <p *ngIf="jobNotificationCode && !entryForm?.get('jobNotificationCode')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.issuedDate">
                <label for="">Issued Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Issued Date" name="issuedDate" formControlName="issuedDate" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.startDate">
                <label for="">Start Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Start Date" name="startDate" formControlName="startDate" required
                    autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.endDate">
                <label for="">End Date<span class="text-danger">*</span></label>
                <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter End Date" name="endDate" formControlName="endDate" required autocomplete="off">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.ranking">
                <label for="">Ranking <span class="text-danger">*</span></label>
                <input appAlphabetOnly="[1,2,3,4,5,6,7,8,9,0]" onkeyup="this.value = this.value.replace(/[\[\]]/g, '')"
                    class="form-control" type="number" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Ranking"
                    name="ranking" formControlName="ranking" required autocomplete="off" min="0">
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.description">
                <label for=""> {{labelKeys.description?labelKeys.description : 'Description' }}</label>
                <textarea style="resize: none;" class="form-control" cols="20" rows="2"
                    [ngClass]="{'disabled': isViewMode}"
                    placeholder="{{labelKeys.description?labelKeys.description : 'Description'}}" name="description"
                    formControlName="description"></textarea>
            </div>
            <div class="col-sm-4 " *ngIf="dynamicForm?.fileList && addMoreButton">
                <label for="">File <span class="text-danger" *ngIf="moduleData.moduleId !='9'">*</span>
                </label>
                <input *ngIf="submitButton=='Submit' || submitButton == 'Save & Submit' && !isKms" class="form-control" type="file"
                    [ngClass]="{'disabled': isViewMode}" id="fileInput" #fileInput (change)="onFileChange($event)"
                    placeholder="Enter File" accept="application/pdf" multiple>
                <input *ngIf="!displayUploadNew " class="form-control" type="file" [ngClass]="{'disabled': isViewMode}"
                    id="fileInput" #fileInput (change)="onFileChange($event)" placeholder="Enter File" 
                    accept="application/pdf" multiple>
                    <p *ngIf="jobNotificationCode && !fileInput" class="error-message reset-paragraph-margin">Please Enter this field.</p>
                    <ng-container *ngIf="this.entryForm.get('fileList')?.value">
                    <span class="m-1"><button type="button" class="btn btn-sm"
                            [ngClass]="{'btn-success m-2':displayUploadNew,'btn-danger mt-2':!displayUploadNew,'disabled d-none': isViewMode}"
                            (click)="uploadNewImage()">{{displayUploadNew?'Upload
                            New':'Cancel'}}</button></span>
                    <div *ngIf="displayUploadNew && userLevelName !== 'HR Level'" class="m-1 text-truncate"> 
                        <div style="cursor:pointer" (click)="openDialog(this.entryForm.get('fileList')?.value)" [matTooltip]="getFileName(this.entryForm.get('fileList')?.value)">
                            <i class="fa fas fa-paperclip orange p-2"></i>
                            {{getFileName(this.entryForm.get('fileList')?.value)}}
                        </div>
                    </div>
                    <div *ngIf="displayUploadNew && userLevelName == 'HR Level'" class="m-1 text-truncate"> 
                        <div style="cursor:pointer" (click)="viewFile(this.entryForm.get('fileList')?.value)"  [matTooltip]="getHrModuleFileName(this.entryForm.get('fileList')?.value)">
                            <i class="fa fas fa-paperclip orange p-2"></i>
                            {{getHrModuleFileName(this.entryForm.get('fileList')?.value)}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Job notification file -->
            <div class="col-sm-4 " *ngIf="dynamicForm?.fileList && !addMoreButton">
                <label for="">File <span class="text-danger" *ngIf="moduleData.moduleId !='9'">*</span>
                </label>
                <input *ngIf="submitButton=='Submit' || submitButton == 'Save & Submit' && !isKms" class="form-control" type="file"
                    [ngClass]="{'disabled': isViewMode}" id="fileInput" #fileInput (change)="onFileChange($event)"
                    placeholder="Enter File" [accept]="fileAcceptType" multiple>
                <input *ngIf="!displayUploadNew " class="form-control" type="file" [ngClass]="{'disabled': isViewMode}"
                    id="fileInput" #fileInput (change)="onFileChange($event)" placeholder="Enter File" 
                    [accept]="fileAcceptType" multiple>
                <ng-container *ngIf="this.entryForm.get('fileList')?.value">
                    <span class="m-1"><button type="button" class="btn btn-sm"
                            [ngClass]="{'btn-success m-2':displayUploadNew,'btn-danger mt-2':!displayUploadNew,'disabled d-none': isViewMode}"
                            (click)="displayUploadNew=!displayUploadNew">{{displayUploadNew?'Upload
                            New':'Cancel'}}</button></span>
                    <div *ngIf="displayUploadNew && userLevelName !== 'HR Level'" class="m-1 text-truncate"> 
                        <div style="cursor:pointer" (click)="openDialog(this.entryForm.get('fileList')?.value)" [matTooltip]="getFileName(this.entryForm.get('fileList')?.value)">
                            <i class="fa fas fa-paperclip orange p-2"></i>
                            {{getFileName(this.entryForm.get('fileList')?.value)}}
                        </div>
                    </div>
                    <div *ngIf="displayUploadNew && userLevelName == 'HR Level'" class="m-1 text-truncate"> 
                        <div style="cursor:pointer" (click)="viewFile(this.entryForm.get('fileList')?.value)"  [matTooltip]="getHrModuleFileName(this.entryForm.get('fileList')?.value)">
                            <i class="fa fas fa-paperclip orange p-2"></i>
                            {{getHrModuleFileName(this.entryForm.get('fileList')?.value)}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-sm-4 mb-3 position-relative" *ngIf="dynamicForm?.keyword">
                <label for="">Keyword <span class="text-danger">*</span></label>
                <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}"
                    placeholder="Enter Keyword" name="keyword" formControlName="keyword" required autocomplete="off">
            </div>
        </div>

        <!-- Add More Job -->
        <div *ngIf="addMoreButton && viewJobListing.length == 0 && editjobListing.length == 0">
            <div class="d-flex add-more-job-button button-job-div align-items-center justify-content-center mt-3" (click)="addJob()">
                <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
                <p class="button-job-text">{{saveJobListing.length > 0 ? 'Add More Post' : 'Add Post'}}</p>
            </div>
        </div>

        <div *ngIf="addMoreButton && editjobListing.length > 0">
            <div class="d-flex add-more-job-button button-job-div align-items-center justify-content-center mt-3" (click)="addJob()">
                <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
                <p class="button-job-text">Add More Post</p>
            </div>
        </div>

        

        <div *ngIf="numberOfJob > 0 && entryForm?.get('jobListing')">
            <div formGroupName="jobListing" class="mt-4">
                <div *ngIf="previousNumberOfJob !== numberOfJob" class="row">
                    <!-- Select Position -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="selectPosition"> Select Position <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': isViewMode}" name="positionMasterId" id="positionMasterId" formControlName="positionMasterId" required autocomplete="off" class="select-style" (change)="selectPositionListData($event)">
                            <option value="">Select Position</option>
                            <option *ngFor="let positionName of positionMasterListData" [value]="positionName.id">
                                {{positionName.positionName}}
                            </option>
                        </select>
                        <p *ngIf="jobListingValidationForm && !entryForm?.get('jobListing')?.get('positionMasterId')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p>
                    </div>
                    <!-- Under Position -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="underPosition"> Select Division <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': true}" name="under" id="under" formControlName="under" required autocomplete="off" class="select-style">
                            <option value="">Select Under which division</option>
                            <option value="RL">RL</option>
                            <option value="DDUGKY">DDUGKY</option>
                        </select>
                        <!-- <p *ngIf="jobListingValidationForm && !entryForm?.get('jobListing')?.get('under')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p> -->
                    </div>
                    <!-- Max Age -->
                    <div class="col-sm-3 mb-3 position-relative">
                        <label for="">Max age</label>
                        <input class="form-control" type="text" inputmode="numeric" [ngClass]="{'disabled': true}"
                            placeholder="Enter Age" name="maxAge" formControlName="maxAge"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            autocomplete="off"
                            maxlength="2">
                    </div>
                    <!-- Min Years of Experience -->
                    <div class="col-sm-3 mb-3 position-relative">
                        <label for="">Min years of Experience<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" inputmode="numeric" [ngClass]="{'disabled': true}"
                            placeholder="Enter Experience" name="minExp" formControlName="minExp" required
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            maxlength="2"
                            autocomplete="off">
                        <!-- <p *ngIf="jobListingValidationForm && !entryForm?.get('jobListing')?.get('minExp')?.valid" class="error-message reset-paragraph-margin">Please Enter this field.</p> -->
                    </div>
                </div>
                <div *ngIf="previousNumberOfJob !== numberOfJob" class="d-flex">
                    <!-- Save Job button -->
                    <div *ngIf="updateDetailsText === 'save'" (click)="saveJob()" class="save-job d-flex justify-content-center enable-button enable-cursor" >Save Post</div>
                    <div *ngIf="updateDetailsText === 'edit'" (click)="updatedJob()" class="save-job d-flex justify-content-center enable-button enable-cursor">Update Post</div>
                    <div (click)="closeJob()" class="close-job d-flex justify-content-center">Close</div>
                </div>
            </div>
        </div>

        
        <div class="mt-3" *ngIf="currentPath === 'hrModule'">
            <app-common-table [tableHeader]="jobListingTableHead" [tableData]="jobListingTableData" (particularRowData)="getParticularRowData($event)"></app-common-table>
        </div>

        <!-- <div *ngIf="viewJobListing.length > 0" class="mt-4">
            <app-common-table [tableHeader]="jobListingViewHeaders" [tableData]="viewJobListing"></app-common-table>
        </div>

        <div *ngIf="editjobListing.length > 0" class="mt-4">
            <app-common-table [tableHeader]="jobListingEditHeaders" [tableData]="editjobListing" (particularRowData)="getUpdateJobRowData($event)"></app-common-table>
        </div> -->

        <div class="row mt-2" *ngIf="!addMoreButton && !positionMaster">
            <div class="col">
                <button class="btn btn-bg" type="submit" *ngIf="!isViewMode"
                    [disabled]="!entryForm.valid || buttonDisable">{{submitButton}}</button>
            </div>
        </div>
        <div class="row mt-2" *ngIf="positionMaster">
            <div class="col">
                <button class="btn btn-bg" type="submit" *ngIf="!isViewMode">{{submitButton}}</button>
            </div>
        </div>
        <div class="row mt-2" *ngIf="saveJobListing.length > 0 && editjobListing.length == 0 && addMoreButton">
            <div class="col">
                <button class="btn btn-bg" type="submit" *ngIf="!isViewMode">{{submitButton}}</button>
            </div>
        </div>
        <div class="row mt-2" *ngIf="editjobListing.length > 0">
            <div class="col">
                <button class="btn btn-bg" type="submit" *ngIf="!isViewMode">Update Job Notification</button>
            </div>
        </div>
    </form>
</div>
import { newArray } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {FormControl, FormGroup} from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.scss']
})
export class JoinUsComponent implements OnInit {

  filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
  });
  careers:any;
  page=1;
  totalCount =0;
  lastDate:any=[];
  lDate:any=[];
  splitedArray:any=[];
  obj:any={}
  modifiedArray:any=[]
  resultProductData:any=[]
  jobForApply: any = 'false'
  applicationJobList: any = []
  currentDate: any = ''
  constructor(private contentService:ContentService,private dialog:MatDialog,private router:Router, private datePipe: DatePipe) {
    
    
   }

  ngOnInit(): void {
    this.getCareers()
    this.getCurrentDate();
    this.jobForApply = sessionStorage.getItem('jobForApply') ? sessionStorage.getItem('jobForApply') : 'false'
  }

  getCurrentDate() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var day = currentDate.getDate().toString().padStart(2, '0');
    var formattedDate = year + '-' + month + '-' + day;
    console.log("Formatted Date ", formattedDate)
    this.currentDate = formattedDate
  }
  getCareers(from = '', to = ''){
    // this.contentService.getContent("list", `moduleId=12&page=${this.page}`).subscribe((res:any)=>{
    //   if(res){
    //     console.log('career response-->', res);
    //     this.careers = res && res['wrapperList'].length && res['wrapperList'];      
    //     this.totalCount = res.totalcount;
    //     // this.careers[2].title = 'Job advertisements for 22 posts of Tripura Rural Livelihood Mission (TRLM). Uploaded on: 13 April,2022'
       
    //     this.careers.forEach((ele:any)=>{
    //       console.log(ele);
          
    //     this.lDate = ele.title.split('.')
    //     this.splitedArray.push(this.lDate[0])
    //     this.lastDate.push(this.lDate[1]);
    //     for(let i=0;i<this.splitedArray.length;i++){
    //       ele.textDesc = this.splitedArray[i]
    //     }
    //     })
    //     this.modifiedArray = this.careers
    //      for(let i =0; i< this.lastDate.length;i++){
    //         if(this.lastDate[i]){
    //         let str = this.lastDate[i].split(':')
    //         this.lastDate[i] = str[1]
    //         }
    //       }
    //       console.log(this.modifiedArray);
    //       this.resultProductData = this.modifiedArray
    //       console.log('this.resultProductData', this.resultProductData);
          
    //   }
    // },error=>{
    //   console.log(error)
    // })
    this.contentService.getJobPostingData(this.page, from, to).subscribe((res:any) => {
      if(res?.responseCode === '200') {
         this.applicationJobList = this.updatedApplicationList(res?.wrapperList)
         this.totalCount = res?.totalcount
         console.log("Application Job List ", this.applicationJobList)

      }
    })
  }

  checkApplicationOpeningDate(openingDate:any, lastDate:any) {
    console.log("Opening Date ", new Date(lastDate), new Date())
    const lastYear = new Date(lastDate).getFullYear()
    const lastMonth = new Date(lastDate).getMonth()
    const lastDateOfDate = new Date(lastDate).getDate()
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const currentDateDateOfDate = new Date().getDate()
    let applicationopen = false
    if(new Date() >= new Date(openingDate) && new Date() < new Date(lastDate)) {
       applicationopen = true
    } if(lastYear === currentYear && lastMonth === currentMonth && lastDateOfDate === currentDateDateOfDate)  {
      applicationopen = true
    }

    console.log("Application Open ", applicationopen)
    return applicationopen
  }

  updatedApplicationList(data:any) {
    data.forEach((item:any) => {
      item['applicationClose'] = this.getUpdatedDate(item.lastDate)
      item['applicationOpen'] = this.checkApplicationOpeningDate(item.openingDate, item.lastDate)
    })

    return data
  }

  getUpdatedDate(lastDate:any) {
    let current = new Date()
    let last = new Date(lastDate)
    let applicationStatus = false
    // console.log("Date ", current, last)
    if(current > last) {
      applicationStatus = true
    } 

    return applicationStatus
  }

  formatDate(inputDate:any) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);
  
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Get the day, month, and year components from the Date object
    const day = date.getUTCDate();
    const monthIndex = date.getUTCMonth();
    const year = date.getUTCFullYear();
  
    // Generate the formatted date string
    const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
  
    return formattedDate;
  }

  applyFilter() {
    this.page = 1
    let formDate = this.reformatDate(this.filterForm.get('fromDate')?.value)
    let toDate = this.reformatDate(this.filterForm.get('toDate')?.value)

    console.log("From Date ", formDate, toDate)
    
    this.getCareers(formDate, toDate)
    // this.resultProductData = this.modifiedArray.filter(
    //   (ele:any)=>
    //   {
    //       return new Date(ele.vacancyUploadedDate) > this.filterForm.get('fromDate')?.value && new Date(ele.vacancyUploadedDate) < this.filterForm.get('toDate')?.value
    //   });
  }

  resetFilter() {
    this.page = 1
    this.filterForm.reset()
    this.getCareers()
  }

  reformatDate(dateStringData:any) {
    // Given date string
    const dateString = dateStringData;

    // Convert the string to a Date object
    const date = new Date(dateString);

    // Get year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Format the date as 'yyyy-mm-d'
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate
  }
  
  getLink(fileList:any){
    let link = fileList[0]? fileList[0].fileUrl : ''
    return link;
  }
  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getCareers();
  }

  applyJobs(data:any) {
    console.log("get particular data ", data)
    this.jobForApply = 'true'
    sessionStorage.setItem('jobForApply', this.jobForApply)
    sessionStorage.setItem('particularPositionDetails', JSON.stringify(data))
  }

  backToCareerPage(event:any) {
    this.jobForApply = 'false'
    sessionStorage.removeItem('jobForApply')
  }

  getFromOpeningDate(event:any) {
    // console.log("Filter Form ", this.filterForm)
    this.filterForm.get('toDate')?.reset()
  }

}

<div
  class="bg-white rounded-3 shadow p-5 my-3"
  *ngIf="!isEntry; else submitEntry"
  style="min-height: calc(100vh - 371px);"
>
  <div class="row">
    <div class="col">
      <h3 class="text-black">KMS Request</h3>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-9"></div>
  </div>
  <div class="row align-items-center">
    <div class="col-auto">
      <app-paginator
        [totalCount]="totalCount"
        [page]="page"
        (nextPage)="pageEvent($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead class="bg-blue">
        <tr>
          <!-- <th class="border-0"></th> -->
          <th class="text-white border-0"><span class="ps-2">S.No.</span></th>
          <!-- <th class="text-white border-0"><span class="">Status</span></th> -->
          <th class="text-white border-0" *ngFor="let heading of tableHeader">
            {{ heading?.label }}
          </th>
          <th class="text-white border-0">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of masterData; let sno = index" >
          <!-- this is for blinking row--- [ngClass]="{'blink' : record?.approverName}" -->
          <!-- <td class="">
            <input
              type="checkbox"
              class="form-check-input"
              id="{{ 'exampleCheck' + sno }}"
            />
          </td> -->
          <td width="10">{{ (sno + 1) + (currentPage * pageSize )}}</td>
          <!-- <td>
            <ng-container *ngIf="record?.approvedRejectedStatus == 1 || record?.approverName"
              >Approved</ng-container
            >
            <ng-container *ngIf="record?.approvedRejectedStatus == 2"
              >Rejected</ng-container
            >
            <ng-container
              *ngIf="
                (record.approvedRejectedStatus == 0 ||
                !record.approvedRejectedStatus) && !record?.approverName
              "
            >
              Approval Pending</ng-container
            >
          </td> -->
          <td *ngFor="let headKey of tableHeader" class="text-truncate">
            <ng-container
              *ngIf="
                headKey.key.includes('dueDateExtended') ||
                  headKey.key.includes('corrigendum');
                else noCorrigendum
              "
            >
              {{ record[headKey.key] == 1 ? "Yes" : "No" }}</ng-container
            >
            <ng-template #noCorrigendum>
              <ng-container
                *ngIf="
                  (!headKey.key.includes('dueDateExtended') &&
                    headKey.key.includes('Date')) ||
                    headKey.key.includes('Expiry');
                  else default
                "
              >
                {{ record[headKey.key]  }}
                </ng-container
              >
              <ng-template #default>
                <ng-container
                  *ngIf="headKey.key == 'file'; else subDefault"
                >
                  <a
                  style="cursor: pointer;"
                    (click)="openDialog(record[headKey.key])"
                    ><i class="fa fas fa-paperclip orange"></i></a
                ></ng-container>
                <ng-template #subDefault>{{ record[headKey.key] }}</ng-template>
              </ng-template>
            </ng-template>
          </td>
          <td class="text-nowrap text-ali" width="90" style="cursor: pointer">
            <img *ngIf="record?.approverName"
              src="../../../assets/visibility_black_24dp(2).png"
              alt="view icon"
              (click)="addCore(record)"
            />
            <img
              src="..//assets/icons/edit_black_24dp.svg"
              alt="edit icon"
              (click)="addCore(record)" *ngIf="!record?.approverName"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<ng-template #submitEntry>
<app-kms-core-add [kmsId]="kmsId" (previousPath)="resetFileType()" ></app-kms-core-add>
</ng-template>

// User Level
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,ViewChild,ViewChildren
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';

interface dynamicFormInterface {
  name: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  userLevel: (string | ((control: AbstractControl) => ValidationErrors | null))[];
}

interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
  id?: string
}

interface permissionInterface {
  title: string,
  permission: {},
  key: string
}

@Component({
  selector: 'app-user-policy-create',
  templateUrl: './user-policy-create.component.html',
  styleUrls: ['./user-policy-create.component.scss'],
})
export class UserPolicyCreateComponent implements OnInit {
  @Output() previousPath: any = new EventEmitter();
  userLevelForm: FormGroup;
  @Input() rowData: userListInterface[] | any = [];
  @Input() submitButton: string = 'Submit';
  @Output() submitEntry: any = new EventEmitter();
  @Input() isViewMode: boolean = false;
  @Input() buttonDisable: boolean = false;
  @Input() title: string = 'Create User Level';
  @Input() dynamicForm: dynamicFormInterface = { name: ['', Validators.required], userLevel: ['', Validators.required] };
  fileInput: any;
  permissionValue: permissionInterface | any = {};
  disabledButton:any = true;
  disableInput: boolean = false
  @ViewChildren("checkboxes") checkboxes: any;

  constructor(
    private formBuilder: FormBuilder,
    private contentService: ContentService,
    private dialog: MatDialog
  ) {
    this.userLevelForm = this.formBuilder.group({
      name: ['', Validators.required],
      userLevel: ['', Validators.required],
    });
  }

  positionMasterContent : any = {
    "title": "Position Master",
    "permission": [
      {
        "endpoint": "/aajeevika/positionMasterContent/save",
        "value": true,
        "label": "Add"
      },
      {
        "endpoint": "/aajeevika/positionMasterContent/update",
        "value": true,
        "label": "Update"
      },
      {
        "endpoint": "/aajeevika/positionMasterContent/delete",
        "value": true,
        "label": "Delete"
      },
      {
        "endpoint": "/aajeevika/positionMasterContent/list",
        "value": true,
        "label": "View"
      },
      {
        "endpoint": "/aajeevika/positionMasterContent/changeApprovedStatus",
        "value": true,
        "label": "Approve/Reject"
      }
    ],
    // "label": ["view", "add", "update", "delete", "approve", "reject"],
    "key": "positionMasterContent"
  }

  instructionComponent : any = {
    "title": "Instructions",
    "permission": [
      {
        "endpoint": "/aajeevika/instructionContent/save",
        "value": true,
        "label": "Add"
      },
      {
        "endpoint": "/aajeevika/instructionContent/update",
        "value": true,
        "label": "Update"
      },
      {
        "endpoint": "/aajeevika/instructionContent/delete",
        "value": true,
        "label": "Delete"
      },
      {
        "endpoint": "/aajeevika/instructionContent/list",
        "value": true,
        "label": "View"
      },
      {
        "endpoint": "/aajeevika/instructionContent/changeApprovedStatus",
        "value": true,
        "label": "Approve/Reject"
      }
    ],
    // "label": ["view", "add", "update", "delete", "approve", "reject"],
    "key": "instructionContent"
  }

  applicationRecievedComponent : any = {
    "title": "Application Recieved",
    "permission": [
      {
        "endpoint": "/aajeevika/applicationRecievedContent/save",
        "value": true,
        "label": "Add"
      },
      {
        "endpoint": "/aajeevika/applicationRecievedContent/update",
        "value": true,
        "label": "Update"
      },
      {
        "endpoint": "/aajeevika/applicationRecievedContent/delete",
        "value": true,
        "label": "Delete"
      },
      {
        "endpoint": "/aajeevika/applicationRecievedContent/list",
        "value": true,
        "label": "View"
      },
      {
        "endpoint": "/aajeevika/applicationRecievedContent/changeApprovedStatus",
        "value": true,
        "label": "Approve/Reject"
      }
    ],
    // "label": ["view", "add", "update", "delete", "approve", "reject"],
    "key": "applicationRecievedContent"
  }

  permissionModules = [
    {
      "title": "Content",
      "permission": [
        {
          "endpoint": "/aajeevika/admincontent/save",
          "value": false,
          "label": "Add"
        },
        {
          "endpoint": "/aajeevika/admincontent/update",
          "value": false,
          "label": "Update"
        },
        {
          "endpoint": "/aajeevika/admincontent/delete",
          "value": false,
          "label": "Delete"
        },
        {
          "endpoint": "/aajeevika/admincontent/list",
          "value": false,
          "label": "View"
        },
        {
          "endpoint": "/aajeevika/content/changeApprovedStatus",
          "value": false,
          "label": "Approve/Reject"
        }
      ],
      // "label": ["view", "add", "update", "delete", "approve", "reject"],
      "key": "content"
    },
    {
      "title": "FAQ",
      "permission": [
        {
          "endpoint": "/aajeevika/faq/save",
          "value": false,
          "label": "Add"
        },
        {
          "endpoint": "/aajeevika/faq/faqlist",
          "value": false,
          "label": "View"
        },
        {
          "endpoint": "/aajeevika/faq/update",
          "value": false,
          "label": "Update"
        },
        {
          "endpoint": "/aajeevika/faq/delete",
          "value": false,
          "label": "Delete"
        }
      ],
      // "label": ["add", "view", "update", "delete"],
      "key": "faq"
    },
    {
      "title": "Website Editor",
      "permission": [
        {
          "endpoint": "/aajeevika/extracontent/save",
          "value": false,
          "label": "Add"
        },
        {
          "endpoint": "/aajeevika/content/extralist",
          "value": false,
          "label": "View"
        },
        {
          "endpoint": "/aajeevika/extracontent/update",
          "value": false,
          "label": "Update"
        },
        {
          "endpoint": "/aajeevika/extracontent/delete",
          "value": false,
          "label": "Delete"
        }
      ],
      // "label": ["add", "view", "update", "delete"],
      "key": "editor"
    }
  ];
  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
    if (changes.rowData && changes.rowData.currentValue) {
      this.rowData = changes.rowData.currentValue;
      for (let key in this.userLevelForm.value) {
        this.userLevelForm.patchValue({
          [key]: this.rowData[key],
        });
      }
      this.prefilledForm();
    }
    if (changes.isView && changes.isView.hasOwnProperty('currentValue')) {
      this.isViewMode = changes.isView.currentValue;
    }

    if (changes.dynamicForm && changes.dynamicForm.currentValue) {
      this.dynamicForm = changes.dynamicForm.currentValue;
      this.userLevelForm = this.formBuilder.group(
        changes.dynamicForm.currentValue
      );
      if (this.rowData.hasOwnProperty('id')) this.prefilledForm();
    }
  }

  prefilledForm() {
    for (let key in this.userLevelForm.value) {
      this.userLevelForm.patchValue({
        [key]: this.rowData[key],
      });
    }

    this.permissionModules.forEach((item) => {
      for(let i =0; i < item?.permission?.length; i++){
          let isExist = this.rowData?.permissionModules?.findIndex((perm:any) => perm?.endPoint == item?.permission[i].endpoint)
          if(isExist > -1)
              item.permission[i].value = true
      }
          
      })
    console.log("userlevel", this.userLevelForm, this.rowData, this.permissionModules)
  }

  onSubmit() {
    console.log("On Submit function call", this.permissionModules)

    let addLogoutPermission = this.addLogoutPermission(this.permissionModules)
    // let checkPermission = this.checkHrPermission(this.permissionModules)

    // if(checkPermission) {
    //    this.permissionModules.push(this.positionMasterContent)
    //    this.permissionModules.push(this.instructionComponent)
    //    this.permissionModules.push(this.applicationRecievedComponent)
    // }
    this.submitEntry.emit({
      form: this.userLevelForm.value,
      id: this.rowData?.id,
      permissionModules: addLogoutPermission
    });

    // console.log("Submit Entry", this.permissionModules, this.submitEntry)
  }

  addLogoutPermission(permissionModules:any) {
    let obj = {
      title: 'Logout',
      key: 'logout',
      permission: [
        {endpoint: '/auth/logout', value: true, label: 'Logout'}
      ]
    }

    permissionModules.push(obj)
    return permissionModules
  }

  checkHrPermission(permissionModules:any) {
    let checkHrPermission = false
    for(let i = 0; i < permissionModules.length; i++) {
        if(permissionModules[i].key == 'hrModule') {
           for(let j = 0; j <  permissionModules[i].permission.length; j++) {
               if(permissionModules[i].permission[j].value) {
                  checkHrPermission = true;
                  break
               }
           }
        }
    }

    console.log("Check Hr Permission ", checkHrPermission)
    return checkHrPermission
  }

  onSelectionChange(event: any) { }
  onPolicyChange(event: any) { }
  // permissionModules = [
  //   {
  //     title: 'Content',
  //     permission: {
  //       view: false,
  //       add: false,
  //       update: false,
  //       delete: false,
  //       approve: false,
  //       reject: false,
  //     },
  //     key: 'content',
  //   },
  //   {
  //     title: 'FAQ',
  //     permission: { add: false, view: false, update: false, delete: false },
  //     key: 'faq',
  //   },
  //   {
  //     title: 'Website Editor',
  //     permission: { add: false, view: false, update: false, delete: false },
  //     key: 'editor',
  //   },
  // ];

  detectChange(event:any){
   console.log(event);
   (event?.data && event?.target?.value != '')  ? this.disableInput = true : this.disableInput = false 
  //  this.rowData.name.length == event?.target?.value.length ? this.disableInput = false : this.disableInput = true 
   console.log(this.disableInput);
   this.checkButton();
 }

 checkButton(){
    let flag= true;
    this.checkboxes.forEach((element:any) => { 
    if(element.nativeElement.checked){
      flag=false 
    }
    });
    this.disabledButton = flag;
  }
  onCheckUncheck(event: any, permissionsModule: any) {
    this.checkButton() 
    this.permissionModules.forEach((item) => {
      if (item.key == permissionsModule.key) {
        permissionsModule.permission[event.key] = event.value;
      }
    });
    this.permissionValue = permissionsModule

  }
  
  onKey(event:any){
   console.log(event); 
  }

  goBack() {
    this.previousPath.emit();
  }
}

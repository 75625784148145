<div class="bg-white rounded-3 shadow p-5 my-3" *ngIf="!isEntry; else submitEntry"
  style="min-height: calc(100vh - 372px);">
  <div class="row">
    <div class="col">
      <h3 class="text-black">{{ entryTitle }}</h3>
    </div>
    <div class="col text-end">
      <a class="btn btn-bg" (click)="
        addCore()
      ">
        <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
        Add
      </a>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-9"></div>
  </div>
  <div class="row align-items-center">
    <div class="col">
      <!-- <app-paginator
        [totalCount]="totalCount"
        [page]="page"
        (nextPage)="pageEvent($event)"
      ></app-paginator> -->
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead class="bg-blue">
        <tr>
          <!-- <th class="border-0"></th> -->
          <th class="text-white border-0"><span class="ps-2">S.No.</span></th>
          <th class="text-white border-0" *ngFor="let heading of tableHeader">
            {{ heading?.label }}
          </th>
          <th class="text-white border-0">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of masterData; let sno = index">
          <td class="" width="10">{{ sno + 1 }}</td>
          <td class="text-truncate">
            {{ record?.name }}
          </td>
          <!-- <td class="text-nowrap text-ali" width="90" style="cursor: pointer">
            <img src="..//assets/icons/visibility_black_24dp.svg" alt="view icon" (click)="addCore(record)" />
          </td> -->
          <td class="text-nowrap" width="90" style="cursor: pointer">
            <img src="../../../assets/images/view-icon.png" alt="view icon" (click)="editData(record, false)" />
            <img src="..//assets/icons/edit_black_24dp.svg" *ngIf="record.name !== 'hrModule'" alt="edit icon" (click)="editData(record)" />
            <img src="..//assets/icons/delete_black_24dp.svg"  *ngIf="record.name !== 'hrModule'"  (click)="openDeleteDialog(record)" alt="delete icon" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
<ng-template #submitEntry>
  <!-- HELLO USER ADDER -->
  <app-user-policy-create [title]="entryTitle" [dynamicForm]="dynamicForm" [submitButton]="submitButton"
    [buttonDisable]="buttonDisable" (previousPath)="goBack()" [isViewMode]="isViewMode" [rowData]="rowData"
    (submitEntry)="submitData($event)"></app-user-policy-create>
</ng-template>
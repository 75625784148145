import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { MatDatepicker } from '@angular/material/datepicker';
import { event } from 'jquery';
import Swal from 'sweetalert2';
const swal: SweetAlert = require("sweetalert");
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

interface tableHeaders {
  key: string;
  label: string;
}

@Component({
  selector: 'app-core-document-add',
  templateUrl: './core-document-add.component.html',
  styleUrls: ['./core-document-add.component.scss'],
})
export class CoreDocumentAddComponent implements OnInit {
  entryForm: FormGroup;
  fileInput: any;
  picker: any = ''
  @Input() reservePositionMasterCode: string = ''
  @Input() rowData: any = [];
  @Input() submitButton: any = 'Submit';
  @Input() dynamicForm: any = {};
  @Input() moduleData: any = {};
  @Input() fileAcceptType:any='';
  @Input() buttonDisable:boolean=false
  displayUploadNew = true;
  @Input() isKms = false;
  uploadDate: any
  numberOfJob: any = 0
  previousNumberOfJob: any = 0;
  positionMasterListData: any;
  saveJobListing: any = [];
  originalUnderPosition: any = [
    {
      id: 'rl',
      name: 'RL'
    },
    {
      id: 'ddugky',
      name: 'DDUGKY'
    }
  ];
  originalPositionNameList:any =[]
  updateDetailsText: string = ''
  editParticularDetailsId: any = ''
  userLevelName: string = ''
  viewJobListing: any = []
  jobListHeader: any;
  editjobListing: any = []
  currentSaveJobListingLength: any;
  previousSaveJobListinglength: any;
  jobListingTableHead: tableHeaders[] = []
  jobListingTableData: any = []
  openingDate: any;
  jobListingValidationForm: boolean = false
  jobNotificationCode: boolean = false
  positionMasterFormValidation: boolean = false
  currentDate: string = ''
  currentPath: string = ''
  vacancyLastDate: string = ''
  htmlContent: any;
  constructor(private formBuilder: FormBuilder,private contentService:ContentService, private dialog: MatDialog, private route: ActivatedRoute) {
    this.entryForm = this.formBuilder.group({
      title: ['', Validators.required],
      publishedDate: ['', Validators.required],
      publishedBy: ['', Validators.required],
      author: ['', Validators.required],
      fileList: ['', Validators.required],
      source: ['', Validators.required],
      autoExpiry: ['', Validators.required],
      ranking: ['', Validators.required],
      jobListing: [''],
      keyword: [''],
    });
  }
  @Input() title: string = 'NRLM Document';
  @Output() previousPath: any = new EventEmitter();
  @Output() submitEntry: any = new EventEmitter();
  @Input() isViewMode: boolean = false;
  @Input() labelKeys:any = {};
  @Input() addMoreButton: boolean = false;
  @Input() positionMaster: boolean = false;
  fileValidations='default';
  newPositionMasterCode:any = `NRLPS/${Math.random()}`
  jobListingHeaders: tableHeaders[] = [
    {
      key: 'positionMasterId',
      label: 'Position Name'
    },
    {
      key: 'under',
      label: 'Division'
    },
    {
      key: 'maxAge',
      label: 'Max Age'
    },
    {
      key: 'minExp',
      label: 'Min Years of Experience'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]

  jobListingViewHeaders: tableHeaders[] = [
    {
      key: 'positionMasterId',
      label: 'Position Name'
    },
    {
      key: 'under',
      label: 'Division'
    },
    {
      key: 'maxAge',
      label: 'Max Age'
    },
    {
      key: 'minExp',
      label: 'Min Years of Experience'
    },
  ]

  jobListingEditHeaders: tableHeaders[] = [
    {
      key: 'positionMasterId',
      label: 'Position Name'
    },
    {
      key: 'under',
      label: 'Division'
    },
    {
      key: 'maxAge',
      label: 'Max Age'
    },
    {
      key: 'minExp',
      label: 'Min Years of Experience'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]

  uploadNewImage() {
    this.displayUploadNew = !this.displayUploadNew
    if(this.displayUploadNew) {
       this.fileInput = this.entryForm
    }
  }
  ngOnInit(): void {
    // this.getTheCurrentRouteUrl()
    // console.log("Current Path ", this.currentPath)
    console.log("Ng OnInit Call")
    this.getTheCurrentRouteUrl()
    this.reservePositionMasterCode = sessionStorage.getItem('positionMasterCode') || ''
    this.userLevelName = sessionStorage.getItem('userLevelName') || ''
    console.log("User Level Name ", this.userLevelName)
    this.numberOfJob = sessionStorage.getItem('numberOfJob') || 0
    this.numberOfJob = Number(this.numberOfJob)
    this.previousNumberOfJob = sessionStorage.getItem('previousNumberOfJob') || 0
    this.previousNumberOfJob = Number(this.previousNumberOfJob)
    this.previousNumberOfJob = this.numberOfJob
    console.log("Current Path ", this.currentPath)
    console.log('Submit Button ', this.submitButton, this.currentPath)
    if(this.submitButton === 'Update' && this.currentPath === '/admin/positionMaster') {
       this.getPositionList()
    }
    if((this.submitButton === 'Save & Submit' || this.submitButton === 'Update') && this.currentPath === 'hrModule') {
      this.getPositionList()
    }
    if(this.currentPath === 'hrModule' && this.submitButton === 'Save & Submit') {
       this.getJobNotificationCode()
    }
    if(this.currentPath === 'positionMaster' && this.submitButton === 'Save & Submit') {
       this.getPositionMasterCode()
    }
    this.positionMasterListData = sessionStorage.getItem('positionMasterListData') ? sessionStorage.getItem('positionMasterListData') : []
    this.positionMasterListData = this.positionMasterListData.length > 0 ? JSON.parse(this.positionMasterListData) : []
    this.originalPositionNameList = sessionStorage.getItem('originalPositionNameList') ? sessionStorage.getItem('originalPositionNameList') : []
    this.originalPositionNameList = this.originalPositionNameList.length > 0 ? JSON.parse(this.originalPositionNameList) : []
    console.log("Original Name List ", this.originalPositionNameList)
    this.saveJobListing = sessionStorage.getItem('saveJobListing') ? sessionStorage.getItem('saveJobListing') : []
    this.saveJobListing = this.saveJobListing.length > 0 ? JSON.parse(this.saveJobListing) : []
    this.jobListingTableHead = this.jobListingHeaders;

    // Edit Job Listing
    this.editjobListing = sessionStorage.getItem('editjobListing') ? sessionStorage.getItem('editjobListing') : []
    this.editjobListing = this.editjobListing.length > 0 ? JSON.parse(this.editjobListing) : []

    console.log("listing ", this.saveJobListing, this.editjobListing)

    if(this.saveJobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
      this.jobListingTableData = this.saveJobListing
    } 
    if(this.editjobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
      this.jobListingTableData = this.editjobListing
    } 

    // if(this.reservePositionMasterCode) {
    //   this.entryForm.patchValue({
    //     code: `NRLPS${this.reservePositionMasterCode}`
    //   })
    // }

    console.log('On Init ', this.entryForm)
    this.getCurrentDate()
  }

  getPositionMasterCode() {
    // this.entryForm.patchValue({
    //   code: 'NRLPS/2023/001'
    // })

    // console.log("Entry form ", this.entryForm)
    this.contentService.getPositionMasterCode().subscribe((res:any) => {
      if(res?.responseCode == '200 ok') {
         this.entryForm.patchValue({
          code: res.wrapperList[0].code
         })
      }
    },(err) => {
      console.log("get error during the fetching ", err)
    })
  }

  getTheCurrentRouteUrl() {
    this.route.url.subscribe((event) => {
      // console.log("Route Event ", event, event[0].path)
      this.currentPath = event[0].path
    })
  }

  // Get Job Notification Code
  getJobNotificationCode() {
    // this.entryForm.patchValue({
    //   jobNotificationCode: 'NRLPS/001/002'
    // })
    // When Apis are ready
    this.contentService.getJobNotificationCode().subscribe((res:any) => {
      if(res?.responseCode == '200 ok') {
         this.entryForm.patchValue({
          jobNotificationCode: res.wrapperList[0].code
         })
      }
    },(err) => {
      console.log("Job Notification err ", err)
    })
  }

  getCurrentDate() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var day = currentDate.getDate().toString().padStart(2, '0');
    var formattedDate = year + '-' + month + '-' + day;
    console.log("Formatted Date ", formattedDate)
    this.currentDate = formattedDate
  }

  goBack() {
    this.previousPath.emit();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log('chanes', changes);
    this.checkValidationType(this.moduleData.moduleId, this.moduleData.subModuleId);
    if (changes.rowData && changes.rowData.currentValue) {
      this.rowData = changes.rowData.currentValue;
      console.log("Row Data ", this.rowData)
      this.labelKeys = changes.labelKeys && changes.labelKeys.currentValue
      // for(let key in this.entryForm.value){
      //   if(!key.includes('DateExtended'))
      //   if((key.includes('Date') || key.includes('Expiry')) && this.rowData[key])
      //   this.rowData[key] =  this.rowData[key].split('T')[0]
      //   this.entryForm.patchValue({

      //     [key] : this.rowData[key]
      //   })
      // }
      this.prefilledForm();
    }

    if(changes.positionMaster && changes.positionMaster.currentValue) {
       this.positionMaster = changes.positionMaster.currentValue
    }

    if(changes.addMoreButton && changes.addMoreButton.currentValue) {
      this.addMoreButton = changes.addMoreButton.currentValue
    }
    if (changes.isView && changes.isView.hasOwnProperty('currentValue')) {
      this.isViewMode = changes.isView.currentValue;
    }
    if (changes.dynamicForm && changes.dynamicForm.currentValue) {
      this.dynamicForm = changes.dynamicForm.currentValue;
      console.log("Dynamic Form ", this.dynamicForm)
      this.entryForm = this.formBuilder.group(changes.dynamicForm.currentValue);
      console.log("Entry Form ", this.entryForm)
      // if(changes.addMoreButton && changes.addMoreButton.currentValue) {
      //   if(this.addMoreButton) {
      //     let jobListingForm = this.formBuilder.group({
      //       positionMasterId: [''],
      //       under: [''],
      //       maxAge: [''],
      //       minExp: ['']
      //     })
      //     this.entryForm.setControl('jobListing', jobListingForm)
      //   }
      // }
      console.log("Entry Form ", this.entryForm, this.dynamicForm)
      // if(this.dynamicForm?.code) {
        
      //   this.entryForm.patchValue({
      //     code: `NRLPS${this.dynamicForm?.code}`
      //   })
        
      //   console.log("Entry Form ", this.entryForm)
      // }
      console.log("Row Data ", this.rowData)
      if (this.rowData && this.rowData.hasOwnProperty('id')) {
        this.prefilledForm();
      } 
    }
    
    console.log("Entry Form ", this.entryForm)
    if(this.isKms){
      
      let obj:any = {title:'fileName', fileType:'knowledgeMaterialVertical',language:'language',source:'source',file_url:'file'};
      for(let key in obj){
        this.rowData = this.entryForm.value[obj[key]];
      }
      
      this.entryForm.patchValue({
        ...this.rowData
      })
    }
  }

  reFormatTheCriteriaData(rowData:any) {
    let criteriaData = rowData.criteria
    let updatedCriteriaData:any = []
    criteriaData.forEach((item:any) => {
      let obj:any = {}
      obj['id'] = item.positionMasterId
      obj['positionMasterId'] = item.positionName;
      obj['under'] = item.under;
      obj['maxAge'] = item.maxAge;
      obj['minExp'] = item.minExp;
      if(!this.isViewMode) {
        obj['action']  = {view: false, edit: false, delete: true}
      }
      updatedCriteriaData.push(obj)
    })

    return this.getTheOriginalNameUnderPosition(updatedCriteriaData)
  }

  getTheOriginalNameUnderPosition(updateData:any) {
    this.originalUnderPosition.forEach((item:any) => {
      updateData.forEach((item1:any) => {
        if(item.id === item1.under) {
           item1['under'] = item.name
        }
      })
    })

    return updateData
  }
checkValidationType(moduleId='',subModuleId=''){
  console.log('ran')
  if(moduleId && subModuleId && moduleId=='11' && subModuleId=='15'){
    this.fileValidations='image'
  }else{
    this.fileValidations = 'default'
  }
}
  onSubmit() {
console.log("row", this.rowData, this.dynamicForm, this.entryForm, this.entryForm.valid)
    console.log("Validation ", this.entryForm, !this.entryForm.valid, this.addMoreButton)
    if(this.dynamicForm.hasOwnProperty('fileList') && !this.dynamicForm.fileList.length && !this.fileInput && !this.rowData?.fileList?.length && this.moduleData.moduleId !='9'){
      swal("Error", `Please Add File`, "error");
      return
    }
    console.log("Submit Entry ", this.entryForm, this.entryForm.value)
    let jobListing;
    if(this.saveJobListing.length > 0) {
      this.saveJobListing = this.getReframetheJobListingData(this.saveJobListing)
    } else if(this.editjobListing.length > 0) {
      this.editjobListing = this.getReframetheJobListingData(this.editjobListing)
    }

    console.log("Edit Job listing ", this.editjobListing, this.entryForm.value, this.htmlContent)
    if ((!this.entryForm.valid || !this.fileInput) && this.addMoreButton) {
      this.jobNotificationCode = true
    } else if(this.entryForm.valid && this.addMoreButton) {
      this.jobNotificationCode = false
      if(this.saveJobListing.length > 0) {
        this.submitEntry.emit({
          form: this.entryForm.value,
          file: this.fileInput?.files,
          instructions: this.htmlContent,
          contentId: this.rowData?.id,
          submitButtonText: this.submitButton,
          saveJobListing: this.saveJobListing
        });
      } else if(this.editjobListing.length > 0) {
        this.submitEntry.emit({
          form: this.entryForm.value,
          file: this.fileInput?.files || this.entryForm.get('fileList')?.value,
          contentId: this.rowData?.id,
          instructions: this.htmlContent,
          submitButtonText: this.submitButton,
          editjobListing: this.editjobListing
        });
      }
    } else if(this.entryForm.valid && this.positionMaster) {
      this.positionMasterFormValidation = false
      this.submitEntry.emit({
        form: this.entryForm.value,
        file: this.fileInput?.files,
        instructions: this.htmlContent,
        contentId: this.rowData?.id,
        submitButtonText: this.submitButton,
      });
    } else if(!this.entryForm.valid && this.positionMaster) {
      this.positionMasterFormValidation = true
    } else {
      this.submitEntry.emit({
        form: this.entryForm.value,
        file: this.fileInput?.files,
        instructions: this.htmlContent,
        contentId: this.rowData?.id,
        submitButtonText: this.submitButton,
      });
    }
    // this.submitEntry.subscribe((data:any) => console.log("Save Job Listing", data))
    console.log("Save Job Listing ", this.htmlContent, this.saveJobListing, this.entryForm)
  }

  getReframetheJobListingData(saveJobListing:any) {
    this.originalPositionNameList.forEach((item:any) => {
      saveJobListing.forEach((item1:any) => {
        if(item.positionName === item1.positionMasterId) {
           item1['positionMasterId'] = item.id 
        }
      })
    })

    this.originalUnderPosition.forEach((item:any) => {
      saveJobListing.forEach((item1:any) => {
        if(item.name === item1.under) {
           item1['under'] = item.id
        }
      })
    })

    return saveJobListing
  }

  prefilledForm() {
    console.log('Entry Form ', this.entryForm, this.rowData)
    this.rowData['fileList'] = this.rowData['file'];
    this.htmlContent = this.rowData['instructions'];
    for (let key in this.entryForm.value) {
      if (!key.includes('DateExtended'))

        if (
          (key.includes('Date') || key.includes('Expiry')) &&
          this.rowData[key]
        )
        this.rowData[key] = this.rowData[key].split('T')[0];
        this.entryForm.patchValue({
          [key]: this.rowData[key],
        });
    }
    console.log('ddd', this.entryForm.value, this.entryForm);
    console.log("Row Data ", this.rowData)
    console.log("Row Data ", this.rowData.hasOwnProperty('criteria'))
    if(this.rowData.hasOwnProperty('openingDate') && this.rowData.hasOwnProperty('lastDate')) {
       this.uploadDate = this.rowData.uploadDate
       this.openingDate = this.rowData.openingDate;
       this.vacancyLastDate = this.rowData.lastDate
       console.log("Upload Date ", this.uploadDate, this.openingDate, this.vacancyLastDate)
    }
    if(this.rowData.hasOwnProperty('file')) {
       this.fileInput = this.rowData.file
    }
    if(this.rowData.hasOwnProperty('criteria')) {
      let getUpdatedData = this.reFormatTheCriteriaData(this.rowData)
      console.log("Get Updated Criteria data ", getUpdatedData)
      console.log("View Mode ", this.isViewMode)
      if(!this.isViewMode) {
        
        this.jobListingTableData = getUpdatedData
        this.editjobListing = getUpdatedData
        this.jobListingTableHead = this.jobListingEditHeaders
        // this.numberOfJob = this.editjobListing.length
        // sessionStorage.setItem("numberOfJob", JSON.stringify(this.numberOfJob))
      } else {
        
        this.jobListingTableData = getUpdatedData
        this.jobListingTableHead = this.jobListingViewHeaders
        this.viewJobListing = getUpdatedData
      }
    }

  }

  // fileName: string = '';
  // getFileNameFromURL(fileUrl: any) {
  //   console.log(fileUrl)
  //   this.fileName = fileUrl.split(environment.url)[1];
  //   console.log('fileName', this.fileName)
  // }
  imgExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/raw', 'image/image'];
  async onFileChange(event:any) {
    console.log("File Change Event ", event)
    let validation = await this.contentService.checkValidFile(event);
    if(this.fileValidations=='image' && !this.imgExtensions.includes(validation)){
      swal("","Only image file is allowed","error");
      event.target.value = '';
      return;
    }
    if(validation.includes('Unknown')){
      swal("","Invalid file Type","error");
      event.target.value = '';
      return;
    }if(event.target.files[0].size > 2000000) {
      swal("Error", `File size exceeds the limit (2MB)`, "error");
      let fileInputs:any = document.getElementById('fileInput')
      fileInputs.value = ''
      this.fileInput = null
      // event.target.value = '';
      return
    } if(event.target.files[0].type !== 'application/pdf') {
      swal("Error", `Only Pdf File is accepted`, "error");
      let fileInputs:any = document.getElementById('fileInput')
      fileInputs.value = ''
      this.fileInput = null
    }
    this.fileInput = (<HTMLInputElement>document.getElementById("fileInput"));
    console.log("File Input ", this.fileInput)
  }
  openDialog(fileList:any) {

   if(!Array.isArray(fileList)){
    let type  =  fileList.fileUrl.split('.');
    fileList['fileType'] =type[type.length-1];
     fileList = [fileList];
  //   {
  //     "fileList": [
  //         {
  //             "id": 1218,
  //             "contentId": 1172,
  //             "fileUrl": "https://stgaajeevikaimg.dhwaniris.in/service-standards-for-mksp_1653991300230.pdf",
  //             "fileSize": "28.0",
  //             "fileType": "pdf",
  //             "downloadCount": 0,
  //             "viewCount": 0,
  //             "thumbnail": "https://stgaajeevikaimg.dhwaniris.in/service-standards-for-mksp_1653991300230_thumbnail.png"
  //         }
  //     ]
  // }
   }

  //  Open Hr Dialog



    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  getFileName(fileList:any){
    console.log( "File List ", fileList, Array.isArray(fileList))
    if(fileList.length && Array.isArray(fileList)){
      // debugger
      let fileArray = fileList[0].fileUrl.split('http://stgaajeevikaimg.dhwaniris.in/')
      let fileName = fileArray[fileArray.length-1];
      return fileName
    }else if(!Array.isArray(fileList)){
      return fileList
      // debugger
      // console.log(fileList)
      //   let fileName =  fileList.fileUrl.split('http://stgaajeevikaimg.dhwaniris.in/');
      // return fileName[fileName.length-1]
    }
    else{
      return 'No file provided'
    }

  }

  getHrModuleFileName(fileList:any) {
    console.log( "File List ", fileList)
    if(fileList.length){
      let fileArray = fileList.split('http://stgaajeevikaimg.dhwaniris.in/')
      let fileName = fileArray[fileArray.length-1];
      return fileName
    }else if(!Array.isArray(fileList)){
      console.log(fileList)
        let fileName =  fileList.split('http://stgaajeevikaimg.dhwaniris.in/');
      return fileName[fileName.length-1]
    }
    else{
      return 'No file provided'
    }
  }

  getJobPostingVacancyUploadedDate(event:any) {
    console.log("Job Posting Date ", event)
    this.uploadDate = this.reformatDate(event.target.value)
    console.log("Upload Date ", this.uploadDate)
    this.entryForm.get('uploadDate')?.patchValue(this.uploadDate)
  }

  reformatDate(originalDate:any) {
    const dateObject = new Date(originalDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');

    let convertedDate = `${year}-${month}-${day}`;
    return convertedDate
  }

  getApplicationUploadDate(event:any) {
    let givenDate = new Date(event.target.value)
    givenDate.setDate(givenDate.getDate() + 7)
    this.vacancyLastDate = this.reformatDate(givenDate)
    console.log("Opening Date ", this.openingDate)
    this.entryForm.get('openingDate')?.patchValue(this.reformatDate(new Date(event.target.value)))
    this.entryForm.get('lastDate')?.setValue('')
  }

  getApplicationLastDate(event:any) {
    let givenDate = new Date(event.target.value)
    // this.vacancyLastDate = this.reformatDate(givenDate)
    this.entryForm.get('lastDate')?.patchValue(this.reformatDate(givenDate))
  }

  addJob() {
    console.log("Listing Data ", this.saveJobListing, this.editjobListing)
    this.jobListingValidationForm = false
    this.initialiseJobPostingForm()
    this.setValidatorsJobListingForm()
    console.log("Entry Job ", this.entryForm.controls)
    console.log("Entry Form ", this.entryForm.get('jobListing'))
    this.updateDetailsText = 'save'
    console.log('Entry Form ', this.entryForm, this.saveJobListing)
    // if(this.numberOfJob == 0) {
    //   this.getPositionList()
    // }
    this.numberOfJob = this.numberOfJob + 1
    if(this.saveJobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
    } 
    else if(this.editjobListing.length > 0) {
      let positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
      this.positionMasterListData = positionMasterListData
      console.log("Position Master List data ", this.positionMasterListData)
    }
    sessionStorage.setItem("numberOfJob", JSON.stringify(this.numberOfJob))
    // if(this.saveJobListing.length > 0 || this.editjobListing.length > 0) {
    //   let jobListing;
    //   if(this.saveJobListing.length > 0) {
    //      jobListing = this.saveJobListing
    //   } else if(this.editjobListing.length > 0) {
    //     jobListing = this.editjobListing
    //   }
    //   this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, jobListing)
    //   console.log("Position Master List Data ", this.positionMasterListData)
    // }
  }

  updatePositionListData() {
    if(this.saveJobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
    } 
    else if(this.editjobListing.length > 0) {
     this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
    }
  }

  initialiseJobPostingForm() {
    let jobListingForm = this.formBuilder.group({
      positionMasterId: [''],
      under: [''],
      maxAge: [''],
      minExp: ['']
    })
    this.entryForm.setControl('jobListing', jobListingForm)
  }

  addActionsStatus(saveJobListing:any) {
    saveJobListing.forEach((item:any, index:any) => {
      item['action'] = {view: false, edit: false, delete: true}
    })

    return saveJobListing
  }

  filterJobPositionData(positionMasterListDetails:any, appliedPositionListing:any) {
    console.log("Position Master List Details ", positionMasterListDetails, appliedPositionListing)
    appliedPositionListing.forEach((item:any) => {
      positionMasterListDetails.forEach((item1:any, index:any) => {
        if(item.id === item1.id) {
           positionMasterListDetails.splice(index, 1)
        }
      })
    })
    console.log('Original Position Master Details ', this.originalPositionNameList)
    return positionMasterListDetails
  }

  getPositionList() {
    // debugger
    this.contentService.getPositionMasterData().subscribe((res:any) => {
      if(res?.responseCode == '200 ok') {
         this.positionMasterListData = res?.wrapperList
        //  this.updatePositionListData()
         this.originalPositionNameList = Array.apply(null, res?.wrapperList)
         console.log("Original Position Name List ", this.originalPositionNameList)
         sessionStorage.setItem('positionMasterListData', JSON.stringify(this.positionMasterListData))
         sessionStorage.setItem('originalPositionNameList', JSON.stringify(this.originalPositionNameList))
      }
    },(err) => {
      if(err.status !== 403) {
        swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
      }
      //  swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
    })
  }

  // Save Job
  saveJob() {
    // console.log("valid status ", this.entryForm.get('jobListing'))
    if(this.entryForm.get('jobListing')?.status === 'INVALID') {
       this.jobListingValidationForm = true
    } else {
      console.log("Save Job ", this.saveJobListing, this.editjobListing)
      if(this.editjobListing.length > 0) {
        this.jobListingValidationForm = false
        this.editjobListing = [...this.editjobListing]
        // 
        this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
        let editJobPostValue = this.entryForm.controls.jobListing.value
        let updateSaveJobRowValue = this.getUpdateSaveJobRowValue(editJobPostValue)
        editJobPostValue['id'] = updateSaveJobRowValue.id
        editJobPostValue['particularPositionId'] = updateSaveJobRowValue.id
        editJobPostValue['positionMasterId'] = updateSaveJobRowValue.positionName
        console.log("Updated Save Job Post ", editJobPostValue, updateSaveJobRowValue)
        // 
        this.editjobListing.push(editJobPostValue)
        this.editjobListing = this.addActionsStatus(this.editjobListing)
        this.jobListingTableHead = this.jobListingHeaders
        this.jobListingTableData = this.editjobListing
        this.previousNumberOfJob = this.numberOfJob
        sessionStorage.setItem('previousNumberOfJob', JSON.stringify(this.previousNumberOfJob))
        sessionStorage.setItem('editjobListing', JSON.stringify(this.editjobListing))
      } else if(this.saveJobListing.length >= 0) {
        this.saveJobListing = [...this.saveJobListing]
        // console.log("Update Save Job Row Value ", updateSaveJobRowValue)
        // this.entryForm.controls.jobListing.value['positionMasterId'] = updateSaveJobRowValue.positionName
        this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
        let saveJobPostValue = this.entryForm.controls.jobListing.value
        let updateSaveJobRowValue = this.getUpdateSaveJobRowValue(saveJobPostValue)
        saveJobPostValue['id'] = updateSaveJobRowValue.id
        saveJobPostValue['particularPositionId'] = updateSaveJobRowValue.id
        saveJobPostValue['positionMasterId'] = updateSaveJobRowValue.positionName
        console.log("Updated Save Job Post ", saveJobPostValue, updateSaveJobRowValue)

        this.saveJobListing.push(saveJobPostValue)
        this.saveJobListing = this.addActionsStatus(this.saveJobListing)
        console.log("Save Job listing ", this.saveJobListing)
        this.jobListingTableHead = this.jobListingHeaders
        this.jobListingTableData = this.saveJobListing
        this.previousNumberOfJob = this.numberOfJob
        sessionStorage.setItem('previousNumberOfJob', JSON.stringify(this.previousNumberOfJob))
        sessionStorage.setItem('saveJobListing', JSON.stringify(this.saveJobListing))
        console.log('Save Job Listing ', this.saveJobListing)
        console.log("Job Listing table data ", this.jobListingTableData)
      } 
      this.jobListingValidationForm = false
      this.removeValidatorsJobListingForm()
    }
  }

  getUpdateSaveJobRowValue(value:any) {
    console.log("Value ", value)
    let getParticularPosition = this.positionMasterListData.filter((item:any) => item.id === Number(value.positionMasterId))[0]
    console.log("Get Particular Position ", getParticularPosition)
    return getParticularPosition
  }

  // Get Particular row data
  getParticularRowData(event:any) {
    console.log("Get Particular row data ", event, this.editjobListing)
    this.initialiseJobPostingForm()
    if(this.saveJobListing.length > 0) {
      if(event.status == 'edit') {
        this.updateDetailsText = 'edit'
        this.editParticularSaveJobPostData(event.rowData, this.saveJobListing)
      } else if(event.status == 'delete') {
        this.deleteSaveJobPostData(event.rowData, this.saveJobListing)
      }
    } else if(this.editjobListing.length > 0) {
      if(event.status == 'edit') {
        this.updateDetailsText = 'edit'
        this.editParticularSaveJobPostData(event.rowData, this.editjobListing)
      } else if(event.status == 'delete') {
        this.deleteSaveJobPostData(event.rowData, this.editjobListing)
      }
    }
  }

  // Add Options filter to the position master
  addOptionOnPositionMaster(originalPositionMasterList:any, rowData:any) {
    console.log("Position List ", this.originalPositionNameList, rowData)
  }

  // Delete Save Job Post Data
  deleteSaveJobPostData(rowData:any, jobListing:any) {
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this job ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        this.getPositionList()
        jobListing.forEach((item:any, index:any) => {
          if(item.id == rowData?.id) {
             jobListing.splice(index, 1)
            //  this.addOptionOnPositionMaster(this.originalPositionNameList, rowData)
             this.addOptionOnFilterOption(this.originalPositionNameList, rowData)
             swal({
              title: "Success",
              text: "Data Delete successfully",
              icon: "success",
            })
          }
        })
        this.numberOfJob = this.numberOfJob - 1
        this.previousNumberOfJob = this.numberOfJob
        sessionStorage.setItem("numberOfJob", JSON.stringify(this.numberOfJob))
        sessionStorage.setItem('previousNumberOfJob', JSON.stringify(this.previousNumberOfJob))
        console.log('Save Job Listing ', this.saveJobListing, this.editjobListing)
        let jobListingData = [...jobListing]
        console.log("Job Listing Data ", jobListingData)
          this.jobListingTableData = jobListingData
          this.jobListingTableData = jobListingData
          // jobListingData.forEach((item:any) => {
          //   this.jobListingTableData.push(item)
          // })
          console.log("Job Listing Table Data ", this.jobListingTableData)
          // this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.jobListingTableData)
        this.jobListingTableData = jobListingData
          // jobListingData.forEach((item:any) => {
          //   this.jobListingTableData.push(item)
          // })
          console.log("Job Listing Table Data ", this.jobListingTableData)
          // this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.jobListingTableData)
        this.removeValidatorsJobListingForm()
        sessionStorage.setItem('saveJobListing', JSON.stringify(jobListingData))

        // if(this.saveJobListing.length > 0) {
        //   let jobListingData = [...jobListing]
        //   console.log("Job Listing Data ", jobListingData)
        //   this.jobListingTableData = jobListingData
        //   // jobListingData.forEach((item:any) => {
        //   //   this.jobListingTableData.push(item)
        //   // })
        //   console.log("Job Listing Table Data ", this.jobListingTableData)
        //   // this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.jobListingTableData)
        //   this.removeValidatorsJobListingForm()
        //   sessionStorage.setItem('saveJobListing', JSON.stringify(jobListingData))
        // }
        
        // if(this.editjobListing.length > 0) {
        //   let jobListingData = [...jobListing]
        //   this.jobListingTableData = []
        //   jobListingData.forEach((item:any) => {
        //     this.jobListingTableData.push(item)
        //   })
        //   // this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.jobListingTableData)
        //   this.removeValidatorsJobListingForm()
        //   sessionStorage.setItem('saveJobListing', JSON.stringify(jobListingData))
        // }
        // if(this.editjobListing.length > 0) {
        //    this.editjobListing = jobListing
        //    this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
        //    sessionStorage.setItem('editJobListing', JSON.stringify(this.editjobListing))
        //    return this.editjobListing
        // } else if(this.saveJobListing.length > 0) {
        //   this.saveJobListing = jobListing;
        //   this.previousSaveJobListinglength = jobListing.length
        //   this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
        //   sessionStorage.setItem('saveJobListing', JSON.stringify(this.saveJobListing))
        //   return this.saveJobListing
        // }
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })
  }

  // Edit Particular Save Job Post Data
  editParticularSaveJobPostData(rowData:any, saveJobListing:any) {
    console.log("Edit Details ", rowData, )
    if(this.saveJobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
      this.jobListingTableData = this.saveJobListing
    } 
    if(this.editjobListing.length > 0) {
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
      this.jobListingTableData = this.editjobListing
    }
    this.addOptionOnFilterOption(this.originalPositionNameList, rowData)
    this.numberOfJob = saveJobListing.length
    this.previousNumberOfJob = this.previousNumberOfJob - 1
    let rowObject = Object.assign({}, rowData)
    console.log("Row Data ", rowObject)
    this.editParticularDetailsId = rowObject.id
    rowData.positionMasterId = rowData.particularPositionId
    delete rowData.particularPositionId
    delete rowData.id
    delete rowData.action
    // debugger
    console.log('Row Data ', rowData)
    this.entryForm.controls.jobListing.setValue(rowData)
    console.log('Entry Form ', this.entryForm)
  }

  setValidatorsJobListingForm() {
    this.entryForm.get('jobListing')?.get('positionMasterId')?.setValidators(Validators.required)
    this.entryForm.get('jobListing')?.get('positionMasterId')?.updateValueAndValidity()
    this.entryForm.get('jobListing')?.get('under')?.setValidators(Validators.required)
    this.entryForm.get('jobListing')?.get('under')?.updateValueAndValidity()
    this.entryForm.get('jobListing')?.get('minExp')?.setValidators(Validators.required)
    this.entryForm.get('jobListing')?.get('minExp')?.updateValueAndValidity()
  }

  removeValidatorsJobListingForm() {
    this.entryForm.controls.jobListing.reset({
      positionMasterId: [''],
      under: [''],
      maxAge: [''],
      minExp: ['']
    })
    // this.entryForm.controls.jobListing.reset({
    //   positionMasterId: [''],
    //   under: [''],
    //   maxAge: [''],
    //   minExp: ['']
    // })
    this.entryForm.get('jobListing')?.get('positionMasterId')?.clearValidators()
    this.entryForm.get('jobListing')?.get('positionMasterId')?.updateValueAndValidity()
    this.entryForm.get('jobListing')?.get('under')?.clearValidators()
    this.entryForm.get('jobListing')?.get('under')?.updateValueAndValidity()
    this.entryForm.get('jobListing')?.get('minExp')?.clearValidators()
    this.entryForm.get('jobListing')?.get('minExp')?.updateValueAndValidity()
    console.log("Entry Form ", this.entryForm.get('jobListing'), this.entryForm)
  }

  getSaveJobRowData(event:any) {
    console.log("Row Data ", event, this.saveJobListing)
    if(event.status === 'delete') {
       this.saveJobListing = this.deleteSaveJobPostData(event.rowData, this.saveJobListing)
    } else if(event.status === 'edit') {
      this.updateDetailsText = 'edit'
      this.editParticularSaveJobPostData(event.rowData, this.saveJobListing)
    }
  }

  getUpdateJobRowData(event:any) {
    console.log("Event ", event)
    if(event.status === 'delete') {
      this.editjobListing = this.deleteSaveJobPostData(event.rowData, this.editjobListing)
      console.log('Edit Job Listing ', this.editjobListing)
    }
  }

  addOptionOnFilterOption(originalNameList:any, rowData:any) {
    this.originalPositionNameList = JSON.parse(sessionStorage.getItem('originalPositionNameList') || '')
    console.log("Original Position List ", this.originalPositionNameList)
    // console.log('Original Name List ', originalNameList, rowData, this.positionMasterListData)
    // this.originalPositionNameList.forEach((item:any) => {
    //   if(item.id === rowData.id) {
    //      this.positionMasterListData.push(item)
    //   }
    // })

    // console.log("Position Master Data ", this.positionMasterListData)
  }

  closeJob() {
    this.jobListingValidationForm = false
    console.log("Close Job ")
    this.removeValidatorsJobListingForm()
    if(this.numberOfJob > 0) {
       this.previousNumberOfJob = this.numberOfJob;
       sessionStorage.setItem('previousNumberOfJob', JSON.stringify(this.previousNumberOfJob))
    } else {
      this.numberOfJob = 0;
      this.previousNumberOfJob = 0;
      sessionStorage.setItem('previousNumberOfJob', JSON.stringify(this.previousNumberOfJob))
    }
  }

  // Update Job
  updatedJob() {
    if(this.entryForm.get('jobListing')?.status === 'INVALID') {
      this.jobListingValidationForm = true
    } else {
      let updatedRowData = this.entryForm.controls.jobListing.value
      let updatedRowJobValue = this.getUpdateSaveJobRowValue(updatedRowData)
      console.log("Updated Row Job Value ", updatedRowJobValue, updatedRowData)
      updatedRowData['id'] = this.editParticularDetailsId
      updatedRowData['action'] = {view: false, edit: true, delete: true}
      updatedRowData['positionMasterId'] = updatedRowJobValue.positionName
      this.previousNumberOfJob = this.numberOfJob
      this.saveJobListing.length > 0 ? this.updateSaveJobListData(updatedRowData, this.saveJobListing) : this.updateSaveJobListData(updatedRowData, this.editjobListing)

      console.log("Listing ", this.saveJobListing, this.editjobListing)

      if(this.saveJobListing.length > 0) {
         this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.saveJobListing)
      } else if(this.editjobListing.length > 0) {
        this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.editjobListing)
      }
    }
  }

  updateSaveJobListData(updatedRowData:any, alreadySaveJob:any) {
    for(let i = 0; i < alreadySaveJob.length; i++) {
        if(alreadySaveJob[i].id === updatedRowData.id) {
           alreadySaveJob[i] = Object.assign({}, updatedRowData)
           break;
        }
    }

    console.log("Already Save Job ", alreadySaveJob)
    // if(this.saveJobListing.length > 0) {
      let jobListingData = [...alreadySaveJob]
      this.jobListingTableData = []
      jobListingData.forEach((item:any) => {
        this.jobListingTableData.push(item)
      })
      this.positionMasterListData = this.filterJobPositionData(this.positionMasterListData, this.jobListingTableData)
      this.removeValidatorsJobListingForm()
      if(this.saveJobListing.length > 0) {
        sessionStorage.setItem('saveJobListing', JSON.stringify(alreadySaveJob))
      } else if(this.editjobListing.length > 0) {
        sessionStorage.setItem('editjobListing', JSON.stringify(alreadySaveJob))
      }

  }

  viewFile(link:any) {
    // console.log("View Link ", link)
    window.open(link)
  }

  selectPositionListData(event:any) {
    console.log("Select Position ", event, event.target.value, typeof(event.target.value))
    let selectedPosition = event.target.value
    this.filterJobPositionDetails(selectedPosition)
  }

  filterJobPositionDetails(selectedPosition:any) {
    let getSelectedPosition = this.positionMasterListData.filter((el:any) => el.id === Number(selectedPosition))[0]
    console.log('Get Selected Position ', getSelectedPosition, getSelectedPosition)
    this.entryForm.get('jobListing')?.patchValue({
      under: getSelectedPosition.underPosition,
      maxAge: getSelectedPosition.maxAge,
      minExp: getSelectedPosition.minExp
    })
    // console.log("get Selected Position ", getSelectedPosition)
  }

  comeToHrModuleFromInstruction(event:any){
    console.log("Edit Job listing", event)
    this.htmlContent = event;
  }



}


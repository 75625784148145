import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import Swal from 'sweetalert2';
const swal: SweetAlert = require("sweetalert");


interface tableHeaders {
  key: string;
  label: string
}

interface positionMasterDynamicForm {
  positionName: string[];
  underPosition: string[];
  maxAge: string[];
  minExp: string[];
  code: string[]
}


@Component({
  selector: 'app-position-master',
  templateUrl: './position-master.component.html',
  styleUrls: ['./position-master.component.scss']
})
export class PositionMasterComponent implements OnInit {
  positionMasterFormStatus: string = 'false'
  positionMasterRowDetails: any;
  positionMasterFormViewMode: boolean = false
  positionMasterSubmitButton: string = 'Save & Submit'
  positionMasterCode: any = '';
  reserveCode: any;
  page: number = 1
  currentPage: number = 0;
  totalCount: number = 0;
  @Output() previousPath: any = new EventEmitter();

  constructor(private contentService: ContentService) { }

  positionMasterTableHeader:tableHeaders[] = [
    {
      key: 'positionName',
      label: 'Position'
    },
    {
      key: 'underPosition',
      label: 'Division'
    },
    {
      key: 'maxAge',
      label: 'Max Age'
    },
    {
      key: 'minExp',
      label: 'Min Years of Experience'
    },
    {
      key: 'code',
      label: 'Code'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]

  positionMasterTableData: Object[] = []

  commonTableData:Object[] = []
  commonTableHeader:Object[] = [];
  approvedRejectActions:boolean = true;
  positionMasterDynamicForm:positionMasterDynamicForm ={
    positionName: [''],
    underPosition: [''],
    maxAge: [''],
    minExp: [''],
    code: ['']
  }

  positionMaster: any;
  positionMasterTitle: string = 'Position Master'

  dummyTableData: any =  
  {
    "wrapperList": 
    [
    
      {
        "id": 2,
        "positionName": "front DEVELOPER",
        "underPosition": 'RL',
        "maxAge": '25',
        "minExp": '3',
        "code": "NRLPS/2023/001",
        "status": 1,
        "createdAt": "2023-07-26T07:23:40.401+00:00",
        "updatedAt": "2023-07-26T07:23:40.401+00:00",
        "deletedAt": null,
        "createdBy": 1,
        "updatedBy": 1,
        "deletedBy": null
      }
    ],
    "errorMap": null,
    "errorMsg": null,
    "responseCode": "200 ok",
    "responseDesc": null,
    "totalcount": 1
  }

  ngOnInit(): void {
    this.getPositionMasterData()
    this.positionMaster = sessionStorage.getItem('positionMaster')
    this.positionMasterFormStatus = sessionStorage.getItem('positionMasterFormStatus') || 'false'
    console.log("Position Master ", this.positionMaster, typeof(this.positionMaster))
  }


  backButton() {
    sessionStorage.removeItem('positionMaster');
    this.previousPath.emit();
  }

  openPositionMasterForm() {
    // this.getPositionMasterCode()
    this.positionMasterFormStatus = 'true'
    this.positionMasterRowDetails = [];
    this.positionMasterFormViewMode = false
    sessionStorage.setItem('positionMasterFormStatus', this.positionMasterFormStatus)
    this.positionMasterSubmitButton = 'Save & Submit'
  }

  getPositionMasterCode() {
    // when apis are ready
    this.contentService.getPositionMasterCode().subscribe((res:any) => {
      console.log("Response ", res)
      if(res?.responseCode === '200 ok') {
        this.positionMasterCode = res.wrapperList[0].code.toString()
        // this.positionMasterDynamicForm['code'] = [this.positionMasterCode]
        this.positionMasterFormStatus = 'true'
        this.positionMasterRowDetails = [];
        this.positionMasterFormViewMode = false
        sessionStorage.setItem('positionMasterFormStatus', this.positionMasterFormStatus)
        sessionStorage.setItem('positionMasterCode', this.positionMasterCode)
      }
    },(err) => {
      if(err.status !== 403) {
        swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
     }
      // swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
    })
  }

  backPositionMasterForm() {
    this.positionMasterFormStatus = 'false'
    sessionStorage.removeItem('positionMasterFormStatus')
    sessionStorage.removeItem('positionMasterCode')
  }

  submitPositionData(event:any) {
    event.submitButtonText === 'Save & Submit' ? this.finalSubmitPositionMasterData(event) : this.updatePositionMasterData(event)
  }

  finalSubmitPositionMasterData(event:any) {
    console.log("Final Submit Form ", event)
    let positionFormData = event.form
    // When Apis are ready
    this.contentService.savePositionMasterData(positionFormData).subscribe((res:any) => {
      if(res.responseCode == '200 OK') {
        swal({
          title: "Success",
          text: "Data submitted successfully",
          icon: "success",
        })
        this.positionMasterFormStatus = 'false';
        this.currentPage = 0;
        this.page = 1
        sessionStorage.removeItem('positionMasterFormStatus')
        sessionStorage.removeItem('positionMasterCode')
        this.getPositionMasterData()
      }
    },(err) => {
       swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  // Update Position Master Data
  updatePositionMasterData(event:any) {
    console.log("Update Position Data ", event)
    // When Apis are ready 
    let positionFormData = event.form
    this.contentService.updatePositionMasterData(positionFormData, event.contentId).subscribe((res:any) => {
      console.log("Respone ", res)
      if(res.responseCode == '200 OK') {
        swal({
          title: "Success",
          text: "Data Updated successfully",
          icon: "success",
        })
        this.positionMasterFormStatus = 'false'
        sessionStorage.removeItem('positionMasterFormStatus')
        this.getPositionMasterData()
      }
    },(err) => {
      swal("", `${err?.error?.errorMsg}`, "error");
    })

  }

  getPositionMasterData() {
    // this.commonTableHeader = this.positionMasterTableHeader;
    // this.commonTableData = this.addActionColumnWithEachRow(this.dummyTableData.wrapperList)
    // When Apis are ready
    this.contentService.getPositionMasterData(this.page).subscribe((res:any) => {
        // console.log("Response ", res)
        if(res?.responseCode == '200 ok') {
          this.totalCount = res?.totalcount
          this.commonTableHeader = this.positionMasterTableHeader;
          this.commonTableData =  this.addActionColumnWithEachRow(res?.wrapperList)
          sessionStorage.setItem('positionTableData', JSON.stringify(res?.wrapperList))
          console.log("Common Table Data ", this.commonTableData)
        }
    },(err) => {
      if(err.status !== 403) {
        swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
      }
      //  swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  addActionColumnWithEachRow(data:any) {
    return data.map((item:any) => ({
      ...item,
      action : {view: true, edit: true, delete: true},
    }))
  }

  positionMasterRowData(event:any) {
    console.log("Event ", event)
    if(event.status === 'view' || event.status === 'edit') {
       this.positionMasterRowDetails = event.rowData;
       console.log("Position Master Row Details ", this.positionMaster)
       this.positionMasterFormStatus = 'true';
       this.positionMasterFormViewMode = event.status == 'view';
       this.positionMasterSubmitButton = event.status == 'view' ? 'view' : 'Update'
    } else if(event.status === 'delete') {
      this.positionMasterFormStatus = 'false';
      this.deletePositionMasterData(event.rowData)
    }
    console.log("Position Master Row Data ", event)
  }

  deletePositionMasterData(rowData:any) {
    console.log("Row Data ", rowData)
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this position?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        let getPositionData = JSON.parse(sessionStorage.getItem('positionTableData') || '')
        let findTheRowId = this.getFindTheRowID(rowData, getPositionData)
        console.log("Row Id ", findTheRowId)
        this.contentService.deletePositionMasterData(findTheRowId).subscribe((res:any) => {
          if(res.responseCode == '200 OK') {
            swal({
              title: "Success",
              text: "Data Deleted successfully",
              icon: "success",
            })
            this.getPositionMasterData()
          }
        },(err) => {
          if(err.status !== 403) {
            swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
         }
          // swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
        })
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })
  }

  getFindTheRowID(rowData:any, getPositionData:any) {
    console.log("Position Table Data ", getPositionData)
    let particularDetails = getPositionData.filter((item:any) => item.code == rowData.code)
    return particularDetails[0].id
  }

  pageEvent(event:any) {
    this.page = event
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getPositionMasterData()
  }



}

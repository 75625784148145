import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  sessionId:string = ''
  constructor(private formBuilder: FormBuilder, private contentService: ContentService) {
    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.email
      ]],
      feedback:['', Validators.required],
      captcha: ['', Validators.required],
      sessionId: [this.sessionId]
    });
    this.initialMechanism();
  }

  ngOnInit(): void {
  }

  initialMechanism(){
    this.deleteChild();
    this.sessionId = this.generateUUID();
    this.getCaptcha();
    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      feedback:['', Validators.required],
      captcha: ['', Validators.required],
      sessionId: [this.sessionId]
    });
  }

  deleteChild() {
    var e = <HTMLElement>document.getElementById('captchaIdFeedback');
    var first = e?.firstElementChild;
    while (first) {
        first.remove();
        first = e?.firstElementChild;
    }
}

  getCaptcha(){
    this.contentService.getCaptcha(this.sessionId).subscribe((result:any) => {
      console.log("results", result);
      this.contentService.getBase64ToImage(result['captchaan'], 'captchaIdFeedback');
    })
  }

  onSubmit(){
    console.log("formValue", this.feedbackForm.value);
    this.contentService.feedbackSubmit(this.feedbackForm.value).subscribe((result:any) => {
      console.log("results", result);
      swal("Success", `Feedback Submitted Successfully`, "success");
      this.initialMechanism();
    },
    (error) => {
      console.log("edit cutoff error", error);

      swal("Error", `${error?.error?.errorMsg}`, "error");
      this.refreshLogin()
    })
  }

  generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
    r = (d + r) % 16 | 0;
    d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
    r = (d2 + r) % 16 | 0;
    d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    }
    refreshLogin(){
      this.deleteChild();
      this.sessionId = this.generateUUID();
      this.feedbackForm.patchValue({
        sessionId:this.sessionId
      })
      this.getCaptcha()
    }
}

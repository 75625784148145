import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map, switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { param } from 'jquery';
import { EmbeddedTemplateAst } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  loaderSubscriber =  new BehaviorSubject<any>(false)
  validFileExtensions = [
    '.doc',
    '.docm',
    '.docx',
    '.pdf',
    '.txt',
    '.csv',
    '.xls',
    '.xlsb',
    '.xlsx',
    '.pptm',
    '.pptx',
    '.application/pdf',
    '.application/vnd.ms-excel',
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.raw',
    '.image',
    '.mp4',
    '.mov',
    '.wmv',
    '.avi',
    '.avchd',
    '.flv',
    '.f4v',
    '.swf',
    '.mkv',
    '.webm',
    // '.html5',
    '.mpeg-2',
  ];
  image = ['png', 'jpeg', 'JPG'];
  video = ['youtube.com', 'mp4', '3gp'];
  pdf = ['pdf'];
  docExtensions = [
    'doc',
    'docm',
    'docx',
    'pdf',
    'txt',
    'csv',
    'xls',
    'xlsb',
    'xlsx',
    'pptm',
    'pptx',
    'application/pdf',
    'application/vnd.ms-excel',
  ];
  imgExtensions = ['jpeg', 'jpg', 'png', 'gif', 'raw', 'image'];
  videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    // 'html5',
    'mpeg-2',
    'youtube.com',
  ];
  constructor(private httpClient: HttpClient, public sanitizer: DomSanitizer) {}
  
  kmsRouter : BehaviorSubject<any> = new BehaviorSubject(false);
  kmsCounter : BehaviorSubject<any> = new BehaviorSubject('');

  getContent(type: string, parameters: string = '') {
    const url =
      environment.url + 'aajeevika/content/' + type + '?' + parameters;
    return this.httpClient.get(url);

    // return url;
  }

  getApplicationJob() {
    const url = environment.url + `aajeevika/job/application/list`
    return this.httpClient.get(url)
  }

  getToken() {
    let token = sessionStorage.getItem('token');
    return token;
  }

  getInstructionData() {
    const url = environment.url + `aajeevika/instruction/list`
    return this.httpClient.get(url)
  }

  updateInstructionData(string:any) {
    let obj = {title: string}
    const url = environment.url + `aajeevika/instruction/save`
    return this.httpClient.post(url, obj)
  }

  saveJobPostingData(obj:any) {
    const url = environment.url + 'aajeevika/jobpost/save'
    return this.httpClient.post(url, obj)
  }

  getJobPostingData(pageNumber:any = 1, from = '', to = '') {
    const url = `${environment.url}aajeevika/jobpost/list?page=${pageNumber}&to=${to}&from=${from}`
    return this.httpClient.get(url)
  }

  getApplicationRecievedData(pageNumber:any = 1, particularPositionValue:any = 0) {
    const url = particularPositionValue > 0 ? environment.url + `aajeevika/job/application/list?page=${pageNumber}&position_id=${particularPositionValue}` : environment.url + `aajeevika/job/application/list?page=${pageNumber}`
    return this.httpClient.get(url)
  }

  // Download Data
  getDownloadData(particularPositionValue:any = 0) {
    let positionValue = particularPositionValue == 0 ? '' : particularPositionValue
    console.log("Position Value ", positionValue)
    const url = environment.url + `aajeevika/job/application/download?position_id=${positionValue}`
    return this.httpClient.get(url, {responseType: 'text'})
  }

  savePositionMasterData(obj:any) {
    const url = environment.url + 'aajeevika/position/save'
    return this.httpClient.post(url, obj)
  }

  saveJobPortalUserForm(obj:any) {
    const url = environment.url + 'aajeevika/job/application/save'
    return this.httpClient.post(url, obj)
  }

  getPositionMasterData(pageNumber:any = 1) {
    const url = environment.url + `aajeevika/position/list?page=${pageNumber}`
    return this.httpClient.get(url)
  }

  // get Position Master Code
  getPositionMasterCode() {
    const url = environment.url + 'aajeevika/position/code?flag=1'
    return this.httpClient.get(url)
  }

  // Get Job Notification Code
  getJobNotificationCode() {
    const url = environment.url + `aajeevika/position/code?flag=2`
    return this.httpClient.get(url)
  }

  deletePositionMasterData(id:number) {
    const url = environment.url + `aajeevika/position/delete?id=${id}`
    return this.httpClient.post(url, {})
  }

  updatePositionMasterData(obj:any, id:number) {
    const url = environment.url + `aajeevika/position/save?id=${id}`
    return this.httpClient.post(url, obj)
  }

  

  updateJobPostingData(obj:any, id:number) {
    const url = environment.url + `aajeevika/jobpost/save?id=${id}`
    return this.httpClient.post(url, obj)
  }

  deleteJobPostingData(id:number) {
    const url = environment.url + `aajeevika/jobpost/delete?id=${id}`
    return this.httpClient.post(url, {})
  }

  getParticularApplicationRowData(id:number) {
    const url = environment.url + `aajeevika/job/application/list?id=${id}`
    return this.httpClient.get(url)
  }

  getParticularJobPostingRowData(id:number) {
    const url = environment.url + `aajeevika/jobpost/list?id=${id}`
    return this.httpClient.get(url)
  }
  

  saveContent(obj: any, type: string, id: number = 0) {
    // let myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${this.getToken()}`);
    // let requestOptions:any = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: obj,
    //     redirect: 'follow'
    // };

    // fetch("http://stgaajeevika-api.dhwaniris.in/aajeevika/admincontent/save", requestOptions)
    //     .then(response => response.text())
    //     .then(result => console.log(result))
    //     .catch(error => console.log('error', error));
    const url = id
      ? environment.url + 'aajeevika/admincontent/' + type + '?id=' + id
      : environment.url + 'aajeevika/admincontent/' + type;
    return this.httpClient.post(url, obj);

    // return url;
  }

  getAdminContent(type: string, parameters: string = '') {
    const url =
      environment.url + 'aajeevika/admincontent/' + type + '?' + parameters;
    return this.httpClient.get(url);

    // return url;
  }
  getKmsContent(type: string, parameters: string = ''){
    const url =
    environment.url + 'aajeevika/kmsContent/' + type + '?' + parameters;
  return this.httpClient.get(url);
  }
  getKmsCount(type: string, parameters: string = ''){
    const url =
    environment.url + 'aajeevika/kmsContent/' + type + '?' + parameters;
  return this.httpClient.get(url);
  }
  deleteAdminContent(type: string, parameters: string = '') {
    const url = environment.url + 'aajeevika/' + type + '/delete?' + parameters;
    return this.httpClient.post(url, {});
  }

  loginSubmit(obj: any) {
    const url = environment.url + 'auth/login';
    return this.httpClient.post(url, obj);

    // return url;
  }

  changeAprrove(flag: any, obj: any) {
    const url =
      environment.url + 'aajeevika/content/changeApprovedStatus?flag=' + flag;
    return this.httpClient.post(url, obj);
  }

  faqSubmit(type: any, obj: any, id: any = 0) {
    const url = id
      ? environment.url + 'aajeevika/faq/' + type + '?id=' + id
      : environment.url + 'aajeevika/faq/' + type;
    return this.httpClient.post(url, obj);
  }

  getFaqList() {
    const url = environment.url + 'aajeevika/faq/faqlist';
    return this.httpClient.get(url);

    // return url;
  }
  moduleSubmit(type: any, obj: any, id: any = '') {
    const url =
      environment.url +
      (id
        ? `aajeevika/modules/updatemodule?id=${id}`
        : 'aajeevika/modules/savemodule');
    return this.httpClient.post(url, obj);
  }

  subModuleSubmit(type: any, obj: any, id: any = '') {
    const url =
      environment.url +
      (id
        ? `aajeevika/modules/updatesubmodule?id=${id}`
        : 'aajeevika/modules/savesubmodule');
    return this.httpClient.post(url, obj);
  }

  saveTicker(obj: any, type: string, id: any = 0) {
    const url = id
      ? environment.url + 'aajeevika/extracontent/' + type + '?id=' + id
      : environment.url + 'aajeevika/extracontent/' + type;
    return this.httpClient.post(url, obj);
  }

  getTicker(type: string) {
    const url =
      environment.url + 'aajeevika/content/extralist?contentType=' + type;
    return this.httpClient.get(url);
  }

  countApi(docId: any = '', requestType: any = '') {
    // requestId= 1 (Download)
    // requestId =2 (View)
    let payload = {
      requestId: requestType,
      id: docId,
    };

    const url =
      environment.url +
      `aajeevika/content/downloadviewcountfile?id=${payload.id}&requestId=${payload.requestId}`;
    return this.httpClient.post(url, payload);
  }
  addVisitor() {
    const url = environment.url + 'aajeevika/content/visitorsland';
    return this.httpClient.post(url, {});
  }
  getCaptcha(sessionId: any) {
    const url = environment.url + 'nrlm/captcha/get?sessionId=' + sessionId;
    return this.httpClient.get(url);
  }

  getBase64Img(captcha: any) {
    return `data:image/png;base64,${captcha}`;
  }

  getBase64ToImage(captcha: any, captchaId: string) {
    var base64img = this.getBase64Img(captcha);
    console.log('base64img', base64img);
    var image = new Image();
    image.src = base64img;
    var element = <HTMLElement>document.getElementById(captchaId);
    if (element) {
      element.appendChild(image);
    }
  }

  feedbackSubmit(obj: any) {
    const url = environment.url + 'aajeevika/feedback/save';
    return this.httpClient.post(url, obj);
  }
  logout() {
    const url = environment.url + 'auth/logout';
    return this.httpClient.get(url);
  }

  checkValidFile(event: any) {
    console.log(event.target.files);
    let uploads: any = [];
    var file = event.target.files[0];
    let getTypeS;
    var filereader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      filereader.onload = (evt: any) => {
        if (evt.target.readyState === FileReader.DONE) {
          console.log(evt.target.result);
          var uint = new Uint8Array(<ArrayBuffer>evt.target.result).subarray(
            0,
            4
          );
          console.log('>>>', uint);
          let bytes: any = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          });
          let hexCode = '';
          const hex = bytes.join('').toUpperCase();
          // console.log('hexhex', hex)
          hexCode = bytes.join('').toUpperCase();
          let fileHexType = this.getMimetype(hex);

          if (fileHexType.split('/') && file.name.split('.').length<3)
            uploads.push({
              filename: file.name,
              filetype: file.type ? file.type : 'Unknown/Extension missing',
              binaryFileType: fileHexType,
              hex: hex,
            });
          getTypeS = fileHexType;
          if (uploads.length) {
            resolve(getTypeS);
          } else {
            reject('Unknown');
          }
        }
      };
      filereader.readAsArrayBuffer(file);
    }).catch(error=>{
      return 'Unknown';
    });
  }
  getMimetype(signature: any) {
    console.log(signature);
    switch (signature) {
      case '89504E47':
        return 'image/png';

      case '47494638':
        return 'image/gif';

      case '25504446':
        return 'application/pdf';

      case 'FFD8FFDB':
      case 'FFD8FFE0':
      case 'FFD8FFE1':
        return 'image/jpeg';

      case '5F69642C':
      case 'D0CF11E0':
      case 'EFBBBF23':
      case 'EFBBBF44':
      case '4E474F20':
      case '22437573':
      case '546F6B65':
      case '2247616D':
      case 'A557365':
      case 'DA':
        return 'application/vnd.ms-excel';

      case '504B34':
        return 'application/document';

      case '00020':
        return 'video/mp4';

      case '1A45DFA3':
        return 'video/x-matroska';

      case '3026B275':
        return 'video/x-ms-wmv';

      default:
        return 'Unknown filetype';
    }
  }

  getYouTubeFrame(link: any = '') {
    let frameImg = '/assets/images/videDefault.png';
    if (link) {
      let videoId = link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
      frameImg = '//img.youtube.com/vi/' + videoId + '/0.jpg';
    }
    // console.log("frameImg", frameImg)
    return frameImg;
  }
  getImageThumbnail(item: any) {
    let imageUrl =
      item.fileList && item.fileList.length && item.fileList[0].fileUrl
        ? item.fileList[0]?.fileUrl
        : '/assets/images/women.png';
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
  geturl(url: string) {
    let newUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      url + '#toolbar=0'
    );
    return newUrl;
  }
  getDocUrl(url: string) {
    let prefixUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
    let suffixUrl = url;
    let fullUrl = prefixUrl + suffixUrl;
    return this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
  }
  getThumbnail(fileUrl: any) {
    console.log(fileUrl);
    let fileArraySplit = fileUrl.split('.');
    let fileType = fileArraySplit[fileArraySplit.length - 1].toLowerCase();
    let finalUrl: any;
    if (this.imgExtensions.includes(fileType)) {
      finalUrl = fileUrl ? fileUrl : '/assets/images/women.png';
    } else if (this.videoExtensions.includes(fileType)) {
      // finalUrl =  fileUrl?fileUrl:'/assets/images/.png';
    } else if (fileType == 'pdf') {
      finalUrl = this.geturl(fileUrl);
    } else if (this.docExtensions.includes(fileType)) {
      finalUrl = this.getDocUrl(fileUrl);
    } else if (fileUrl.includes('youtube.com')) {
      finalUrl = this.getYouTubeFrame(fileUrl);
    }
    return finalUrl;
  }
  getUser(type: string, parameters: string = '') {
    const url =
      environment.url + 'aajeevika/user/' + type + '?' + parameters;
    return this.httpClient.get(url);
  }

  getUserLevel(parameters: string = '') {
    const url = environment.url + 'aajeevika/userLevel/get' + '?' + parameters
    return this.httpClient.get(url)
  }

  saveUser(obj: any , id: any = 0) {
    const url = id
      ? environment.url + 'aajeevika/user/update' + '?id=' + id
      : environment.url + 'aajeevika/user/create';
      console.log(obj)

    return this.httpClient.post(url, obj);
  }

  saveUserLevel(obj:any, id: any = 0) {
    const url = id
      ? environment.url + 'aajeevika/userLevel/update' + '?id=' + id
      : environment.url + 'aajeevika/userLevel/create';

    return this.httpClient.post(url, obj);
  }
  deleteUserContent(parameters: string = '') {
    const url = environment.url + 'aajeevika/user' + '/delete?' + parameters;
    return this.httpClient.post(url, {});
  }

  deleteUserLevelContent(parameters: string = '') {
    const url = environment.url + 'aajeevika/userLevel' + '/delete?' + parameters;
    return this.httpClient.post(url, {});
  }

  // Download a file
  downloadFile(url: string): Observable<Blob> {
    // Set the responseType to 'blob' to get the file content as a Blob.
    return this.httpClient.get(url, { responseType: 'blob' });
  }

  // Loading the image 
  loadImage(url:any):Observable<Blob> {
    return this.httpClient.get(url, {
    responseType: "blob"
  });
}
}

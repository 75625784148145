import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-content-search',
  templateUrl: './content-search.component.html',
  styleUrls: ['./content-search.component.scss']
})
export class ContentSearchComponent implements OnInit {
searchString:string=''
  constructor(private activeRoute:ActivatedRoute,private router:Router ,private contentService:ContentService) {
    this.activeRoute.params.subscribe((res:any) => {
      this.searchString = res.id;
      this.searchContent(res.id);
    });
  }
  contentData:any;
  search = {
    "wrapperList": [
        {
            "id": 9,
            "moduleId": 1,
            "subModuleId": 1,
            "title": "title",
            "documentSource": null,
            "author": null,
            "uploadedOn": null,
            "publishedBy": null,
            "publishedDate": null,
            "issuedNo": null,
            "issuedDate": null,
            "issuedBy": null,
            "corrigendum": null,
            "dueDateExtended": null,
            "autoExpiry": null,
            "lastDateSubmission": null,
            "location": null,
            "link": null,
            "fileNumber": null,
            "source": null,
            "vacancyUploadedDate": null,
            "rtiQuery": null,
            "description": null,
            "isUpdate": null,
            "isHighlight": null,
            "approvedRejectedStatus": 1,
            "approvedRejectedBy": null,
            "fileList": [],
            "path": "Content/9",
            "submodulename": "prashant"
        }
    ],
    "errorMap": null,
    "errorMsg": null,
    "responseCode": "200",
    "responseDesc": "OK",
    "totalcount": 1
}
totalItems = 0;
page = 1;
currentId=''

  ngOnInit(): void {
  }
  searchContent(id:any){
    this.currentId=id;
    this.contentService.getContent('search',`globalSearch=${id}&page=${this.page}`).subscribe((res:any)=>{
      if(res){
        this.contentData =res && res['wrapperList'];
        this.totalItems = res.totalcount;
      }
    },error=>{
      console.log(error)
    })

  }
  searchModule(data:any){
    this.router.navigate([data.path]);

  }
  pageEvent(event: any) {
    this.page = event;
    this.searchContent(this.currentId);
  }


}

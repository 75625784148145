<div class="d-flex">
  <div style="display: block; width: 600px; height: 500px;" >
    <canvas id="chartComponent"></canvas>
  </div>
  <div style="display: block; width: 600px; height: 500px;" >
    <canvas id="chartComponentTwo"></canvas>
  </div>
</div>
<!-- <div style="display: block; width: 400px">
  <canvas id="canvas" height="320"></canvas>
</div> -->
<div class="container">
  <div class="row my-3">
    <div class="col px-0">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
          <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->

            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/multimedia" class="nav-link ">Multimedia</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Photo Gallery</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3 bg-white shadow p-3">
      <h5>Photo Gallery</h5>
      <div class="row">
        <div class="row mx-0">
          <app-common-filters
            [filterConfig]="filterConfig"
            [moduleId]="moduleId"
            (searchParams)="searchParams($event)"
          ></app-common-filters>
        </div>
        <!-- <div class="col-1 me-2"><button type="button" class="btn btn-primary">Apply</button></div>
           <div class="col-1"><button type="button" class="btn btn-outline-primary">Reset</button></div> -->
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <p class="m-2" style="padding: 10px;">Showing {{totalItem}} Results</p>
        <app-paginator [totalCount]="totalItem" [page]="page" (nextPage)="pageEvent($event)" ></app-paginator>
       </div>
      <div class="row mb-4">
        <div class="col-sm-4 mb-4" *ngFor="let item of photoGallery">
          <div class="card mx-2" (click)="openDialog(item.fileList)">
            <img src="{{getImageThumbnail(item)}}" class="card-img-top img-cust" [alt]="item.title" [title]="item.title">
            <div class="card-body">
              <a style="cursor: pointer;">{{ item.title }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative pe-0">
      <app-footer-sub-menu></app-footer-sub-menu>
    </div>
  </div>
</div>

<div class="mt-4 mb-4 shadow-lg hrModuleDiv" *ngIf="shouldShowDiv()">
    <div class="d-flex justify-content-between">
        <div>
            <p class="moduleHeading">{{currentPath == 'hrModule' ? 'Job Post' : 'Application Recieved'}}</p>
        </div>
        <div>
            <div class="div-border add-button d-flex justify-content-center align-items-center" *ngIf="currentPath === 'hrModule'" (click)="jonPostingAddForm()">
                <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
                <p style="margin: 0;">Add</p>
            </div>
            <div class="div-border download-button-icon" *ngIf="currentPath == 'applicationRecieved'" (click)="downloadData()">Download</div>
        </div>
    </div>
    <hr class="reset-margin" *ngIf="currentPath !== 'hrModule'" />
    <div class="m-2 application-recieved-div" style="margin: 10px;" *ngIf="currentPath === 'applicationRecieved'">
        <div class="mb-3" style="margin-left: 6px;">
            <label for="positionApplied"> Position Applied <span class="text-danger">*</span></label>
            <div class="d-flex align-items-center">
                <select name="positionApplied" id="positionApplied" class="select-style" (change)="getPositionDetails($event)" [(ngModel)]="particularPositionValue">
                    <option value="">Select Position</option>
                    <option *ngFor="let particularPositionDetails of positionListData" [value]="particularPositionDetails.id">{{particularPositionDetails.positionName}}</option>
                </select>
                <div class="div-border download-button-icon search-button" (click)="filterApplicationRecievedData()">Search</div>
                <div class="div-border download-button-icon search-button reset-button" (click)="resetFilterApplicationRecievedData()">Reset</div>
            </div>
        </div>
    </div>
    <div *ngIf="commonTableData.length > 0" style="margin: 10px;" id="tabelComponentData">
        <app-common-table [tableHeader]="commonTableHeader" [tableData]="commonTableData" (particularRowData)="jobPostingRowData($event)" [currentPage]="currentPage"></app-common-table>
    </div>
    <div class="row">
        <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
    </div>
    <div *ngIf="commonTableData.length === 0" class="d-flex justify-content-center align-items-center no-data-div">
        <img src="../../../../assets/images/No data-amico (1).jpg" alt="no-data image" />
    </div>
</div>

<div *ngIf="positionMaster == 'true'">
    <app-position-master (previousPath)="backJobPostingForm();resetPage()"></app-position-master>
</div>

<div *ngIf="jobPostingAddFormStatus === 'true' && currentPath === 'hrModule'">
    
    <app-core-document-add
        [addMoreButton]="true"
        [title]="jobPostingTitle"
        [dynamicForm]="jobPostingDynamicForm"
        [submitButton]="jobPostingSubmitButton"
        [rowData]="jobPostingRowDetails"
        [isViewMode]="jobPostingFormViewMode"
        (previousPath)="jobPostingRowDetails.length > 0 ? backJobPostingForm() : backJobPostingForm()"
        (submitEntry)="submitJobPostingData($event)"
    ></app-core-document-add>
</div>
<div *ngIf="applicationActive && currentPath === 'applicationRecieved'" style="padding: 15px;">
    <app-job-portal-form
    [jobPostingRowDetails]="jobPostingRowDetails"
    [applicationActive]="applicationActive"
    [viewApplicationForm]="true"
    (backToPrevious)="backToHrModule()"
    ></app-job-portal-form>
</div>

<div *ngIf="instructionEditor === 'true'">
    <app-instruction-editor (backToPreviousPage)="comeToHrModuleFromInstruction($event);backJobPostingForm();resetPage()"></app-instruction-editor>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-tenders',
  templateUrl: './tenders.component.html',
  styleUrls: ['./tenders.component.scss']
})
export class TendersComponent implements OnInit {

  constructor(private contentService:ContentService,private dialog:MatDialog,private router:Router) { }
  tenders:any
  ngOnInit(): void {
    this.getTenders();
  }
  page=1;
  totalCount=0
  getTenders(){
    this.contentService.getContent("list", `moduleId=5&page=${this.page}`).subscribe((res:any)=>{
      if(res){
        this.tenders = res && res['wrapperList'].length && res['wrapperList'];
        this.totalCount= res.totalcount;
      }
    },error=>{
      console.log(error)
    })
  }

  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getTenders();
  }
}

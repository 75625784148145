<div class="container">
  <div class="row mt-3">
      <div class="col-sm-8 mb-3">
          <div class="bg-white p-4">
            <h3 class="mb-2">Other Useful Links</h3>
            <div class="row mb-1 mb-md-3">
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex justify-content-center align-items-center">
                    <a href="http://pmrdfs.tiss.edu/" target="_blank" title="Prime Minister's Rural Development Fellows Scheme, External website that opens in a new window">
                    <img class="img-fluid" src="/assets/images/pmrdf.png" alt="">
                  </a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a class="Logos" href="http://mygov.in/" target="_blank" title="Mygov.in, External website that opens in a new window">
                    <img class="img-fluid" src="/assets/images/mygov-logo.png" alt="">
                  </a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a href="http://swachhbharat.mygov.in/" target="_blank" title="Swachh Bharat,External website that opens in a new window">
                    <img class="img-fluid" src="/assets/images/swachh-bharat.jpg" alt="">
                  </a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a class="fw-bold text-decoration-none" href="http://rti.gov.in/" target="_blank" title="RTI Portal,External website that opens in a new window ">RTI PORTAL</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 mb-md-3">
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex justify-content-center align-items-center">
                    <a class="fw-bold text-decoration-none text-center text-uppercase" href="http://pgportal.gov.in/" target="_blank" title="Public Grievances Portal,External website that opens in a new window">Public Grievances Portal</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a alt="" href="http://rudsetacademy.org/" target="_blank" title="National Academy of RUDSETI, External website that opens in a new window">
                      <img class="img-fluid" src="/assets/images/rsetis.jpg" alt="">
                    </a>  
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a class="fw-bold text-decoration-none text-center text-uppercase" href="http://www.ruralinnovations.in/" target="_blank" title="Bank of Ideas &amp; Innovations,External website that opens in a new window">Bank of Ideas &amp; Innovations</a>
                  </div>
                </div>
              </div>
              <div class="col-md-3 gx-3 mb-2 mb-md-0">
                <div class="card box">
                  <div class="card-body d-flex align-items-center justify-content-center">
                    <a class="fw-bold text-decoration-none text-center text-uppercase" href="http://www.himayat.org/" target="_blank" title="Himayat,External website that opens in a new window">Himayat</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-12 gx-3">
                <div class="card box">
                  <div class="card-body d-flex justify-content-center align-items-center">
                    <a class="fw-bold text-decoration-none text-center text-uppercase" href="http://himayat.jkedi.org/" target="_blank" title="JKEDI">JKEDI</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
      </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import * as Chart from "chart.js";


@Component({
  selector: 'app-sharedChart',
  templateUrl: './sharedChart.component.html',
  styleUrls: ['./sharedChart.component.scss']
})
export class SharedChartComponent implements OnInit {
  public chart: Chart = new Chart ("canvas",{type: "bar",data:{labels:[],datasets:[]}});
  ctx:any;
  canvas:any;
  constructor() { }

  ngOnInit(): void {
    this.canvas = document.getElementById("chartComponent");
    this.ctx = this.canvas.getContext("2d");
    this.chart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: ["NRLM", ["Institution Building", "and Capacity"," Building"], ["Financial","Inclusion"], "Livelihoods", ["Universal Social","Mobilization and","Inclusion"], "Case","Studies"],
        datasets: [
          {
            label: "Total Counts",
            data: [12, 19, 3, 5, 14, 3],
            backgroundColor: [
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07"
            ],
            borderColor: [
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07",
              "#F27B07"
            ],
            borderWidth: 1
          }
        ]
      },
      options: {​​
          legend: {​​
            display: true,
            labels: {​
              ​usePointStyle: true,
            }​​,
          }​​,
          responsive: true,
          maintainAspectRatio: false,
          scales: {​
            ​xAxes: [{
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0
              },
              ​​display: true,
              scaleLabel: {​
                ​display: true,
                labelString: "value",
                
                
              }​​,
              gridLines: {​
                ​display: true,
              }​​,
            }​​,],
            yAxes: [{​​
              ticks: {
                beginAtZero: true
              },
              display: true,
              scaleLabel: {
                ​​display: true,
                labelString: "Total Documents",
              }​​,
              gridLines: {​​
                display: true,
              }​​,
            }​​,],
          }​​,
        }​​,
    });
    this.canvas = document.getElementById("chartComponentTwo");
    this.ctx = this.canvas.getContext("2d");
    this.chart = new Chart(this.ctx, {
      type: "bar",
      data: {
        labels: ["NRLM", ["Institution Building", "and Capacity","Building"], ["Financial","Inclusion"], "Livelihoods", ["Universal Social","Mobilization and","Inclusion"], "Case","Studies"],
        datasets: [
          {
            label: "Total Counts",
            data: [12, 19, 3, 5, 14, 3],
            backgroundColor: [
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984"
            ],
            borderColor: [
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984",
              "#8C3984"
            ],
            borderWidth: 1
          }
        ]
      },
      options: {​​
          legend: {​​
            display: true,
            labels: {​
              ​usePointStyle: true,
            }​​,
          }​​,
          responsive: true,
          maintainAspectRatio: false,
          scales: {​
            ​xAxes: [{
              ticks: {
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0
              },
              ​​display: true,
              scaleLabel: {​
                ​display: true,
                labelString: "value",
                
                
              }​​,
              gridLines: {​
                ​display: true,
              }​​,
            }​​,],
            yAxes: [{​​
              ticks: {
                beginAtZero: true
              },
              display: true,
              scaleLabel: {
                ​​display: true,
                labelString: "Total Videos",
              }​​,
              gridLines: {​​
                display: true,
              }​​,
            }​​,],
          }​​,
        }​​,
    });
  }

}

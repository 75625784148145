<div class="header-image">
    <img src="/assets/images/intensive-blocks.jpg" alt="Introduction" title="Introduction"
        class="img-fluid" />
    <div class="page-title">Intensive Blocks</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link ">Work Structure</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Intensive Blocks</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>SRLMs directly implement in intensive blocks where they enter with their own staff at block level
                        and take support of the internal resources from the resource blocks. CRPs are chosen from the
                        resource blocks to accelerate implementation in these blocks. The protocols of implementation
                        followed in the resource blocks would be adopted in these blocks as well.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3">
            <app-work-structure-menu></app-work-structure-menu>
        </div>
    </div>
</div>
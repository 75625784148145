import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqList:any;
  page = 1;
  masterData: any;
  flag:any=0;
  
  constructor(private contentService :ContentService) { }
 
  ngOnInit(): void {
    this.getFaqList()
    let userLevelId:any = sessionStorage.getItem('levelId')
    if(Number(userLevelId) === 1) {
      this.getkmsCount();
    }
  }
  getFaqList(){
    this.contentService.getContent('faqlistfe').subscribe((res:any)=>{
      if(res){
        this.faqList = res && res['wrapperList'].length && res['wrapperList'];
        this.faqList.reverse();
        console.log(this.faqList)
      }
    },error=>{
      console.log(error)
    })
  }
  
  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }

}

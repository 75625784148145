import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { AngularEditorConfig } from '@kolkov/angular-editor';
const swal: SweetAlert = require("sweetalert");

@Component({
  selector: 'app-instruction-editor',
  templateUrl: './instruction-editor.component.html',
  styleUrls: ['./instruction-editor.component.scss']
})
export class InstructionEditorComponent implements OnInit {

  @Output() backToPreviousPage = new EventEmitter();
  @Input() htmlContent:any = '';
  instructionList: any = []
  instructionEditor: boolean = false
  copyInstructionList: any[] = []
  writeInstruction: boolean = false
  stringInstructions: any;
  newInstructionList: any = []
  numberOfInstruction: any;
  buttonKey: string = ''
  string: any = '';
  // htmlContent: any = ''
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  finalUpdatedInstrucion: any = ''

  constructor(
    private contentService: ContentService
  ) { }

  @ViewChild('textareaElement') textareaElement!: ElementRef;

  ngOnInit(): void {
    // this.getInstructionsData()
    if(this.htmlContent){
      console.log("changesHTMLContentInit", this.htmlContent)
    this.getParsedHtml()
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("changesHTMLContent", changes)
    if(changes.htmlContent){
      console.log("changesHTMLContentInside", changes)
      this.htmlContent = changes.htmlContent.currentValue ? changes.htmlContent.currentValue : 'Click on the edit instruction button to enter notification instructions';
      this.getParsedHtml()
    }
  }

  getParsedHtml(){
    console.log("Instrction title", this.htmlContent)
    this.stringInstructions = this.htmlContent
    this.textareaContent = this.htmlContent
    this.getTheFormattedInstructionData(this.htmlContent.split('\n'))
  }

  getInstructionsData() {
    this.contentService.getInstructionData().subscribe((res:any) => {
      // console.log("Instruction Response ", res)
      if(res?.responseCode == '200') {
         this.stringInstructions = res?.wrapperList[0].title
         this.textareaContent = res?.wrapperList[0].title
         this.getTheFormattedInstructionData(res?.wrapperList[0].title.split('\n'))
      }
    },(err) => {
      swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  getTheFormattedInstructionData(title:any) {
    console.log("Instrction title ", title)
    this.instructionList = title
    this.lines = title
    this.copyInstructionList = [...this.instructionList]
    this.numberOfInstruction = this.copyInstructionList.length
  }

  backButton() {
    this.backToPreviousPage.emit('false')
  }

  editInstruction() {
    this.htmlContent = this.instructionList
    this.instructionEditor = true
    this.writeInstruction = true
  }

  updateInstruction() {
    // this.finalUpdatedInstrucion = this.htmlContent
    this.instructionEditor = false
    this.writeInstruction = false;
    console.log("htmlCOnet", this.htmlContent)
    this.backToPreviousPage.emit(this.htmlContent);
    // this.stringInstructions = this.lines.join('\n')
    // this.contentService.updateInstructionData(this.htmlContent).subscribe((res:any) => {
    //   if(res?.responseCode == '200') {
    //     this.instructionEditor = false
    //     this.writeInstruction = false
    //     swal({
    //       title: "Success",
    //       text: "Instruction Updated Successfully",
    //       icon: "success",
    //     })
    //     // window.location.reload()
    //     this.getInstructionsData()
    //   }
    // },(err) => {
    //   this.instructionEditor = false
    //   this.writeInstruction = false
    //   if(err.status !== 403) {
    //     swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
    //   }
    //   // swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
    // })
  }

  textareaContent = '';
  lines: any[] = [];

  updateLines() {
    this.lines = this.textareaContent.split('\n');
  }

  closeInstructionEditor() {
    this.instructionEditor = false
    this.writeInstruction = false
  }

}

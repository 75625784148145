import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-non-intensive-blocks',
  templateUrl: './non-intensive-blocks.component.html',
  styleUrls: ['./non-intensive-blocks.component.scss']
})
export class NonIntensiveBlocksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss']
})
export class ContentDetailComponent implements OnInit {
  @Input() contentId: any = '';
  searchString:string='';
  excludeArray = ['id','subModuleId', 'path', 'approvedRejectedBy', 'approvedRejectedStatus', 'isHighlight', 'isUpdate','submodulename','ranking'];
  // item:any = {};
  item: BehaviorSubject<any> = new BehaviorSubject({});
  item$:any = this.item.asObservable();

  constructor(private route: ActivatedRoute,private contentService:ContentService,private dialog:MatDialog,  private location: Location,) {
    this.route.params.subscribe((res:any) => {
      console.log(res.id, this.contentId);
if(res.id)
      this.getContent(res.id)
    });
  }
  contentData:any;
  dateFlag = ['StartDate','EndDate','AutoExpiry','PublishedDate','Duedate','IssuedDate','LastDateSubmission'];
  getContent(id:any){
    this.contentService.getContent('list',`id=${id}`).subscribe((res:any)=>{
      if(res){
        let obj:any={};
        let resItem = res['wrapperList'][0];
        console.log(resItem);
        for(let key in resItem){
          if(!this.excludeArray.includes(key) && resItem[key]){
           let upperCasedKey = this.checkDataTypes(key);
            obj[upperCasedKey] = resItem[key];
          }
        }
        // this.item = {...obj};
        this.item.next({...obj})
        this.searchString = obj.Title

        // this.searchString = this.searchString.substr(0,80).concat('...');
      }
    },error=>{
      console.log(error)
    })
  }
  ngOnInit(): void {
    if(this.contentId)
    this.getContent(this.contentId)
  }
  checkDataTypes(data:any){
    if(typeof(data)=='string'){
      return data[0].toUpperCase()+ data.slice(1);
    }
    return data
   }
   typeOf(data:any){
     return(typeof data);
   }
   downloadCount(data:any){
     console.log(data);
     this.contentService.countApi(data.id,1).subscribe(res=>{
       console.log(res);
     })

   }
   openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
    }
    getTotalDownload(fileList:any){
      let total = 0;
      for (var i in fileList) {
        total += fileList[i].downloadCount;
      }
      return total

    }
    getDate(data:any,key:any){
   
      if(this.dateFlag.includes(key)){
        return new Date(data).toDateString()
      }else{
        return data
      }
    }

    goBack(){
      this.location.back()
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-units',
  templateUrl: './support-units.component.html',
  styleUrls: ['./support-units.component.scss']
})
export class SupportUnitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

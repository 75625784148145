<div class="header-image">
    <img src="/assets/images/ajjevika-banner-Approach.jpg" alt="Approach" title="Approach" class="img-fluid" />
    <div class="page-title">Approach</div>
</div>
<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <a href="/about" class="nav-link ">Work Structure</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Approach</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-9">
          <div class="card border-0 rounded-2 shadow mb-3">
              <div class="card-body">
                 
                <p>
                    The cornerstone of the Mission is its ‘community-driven’ approach which has provided a huge platform in the form of community institutions for women’s empowerment. Rural women are at the core of the program which is extensively focused on their socio-economic empowerment by way of building their capacities, providing financial support and training in order to enable them to undertake livelihoods activities and become self-independent.
                 <br>
                 Dedicated support structures build and strengthen the institutional platforms of the poor. These platforms, with the support of their built-up human and social capital, offer a variety of livelihoods services to their members across the value-chains of key products and services of the poor. These services include financial and capital services, production and productivity enhancement services, technology, knowledge, skills and inputs, market linkages etc. and offer space for convergence and partnerships with a variety of stakeholders, by building an enabling environment for poor to access their rights and entitlements, public services, and innovations. The aggregation of the poor, through their institutions, reduces transaction costs to the individual members, makes their livelihoods more viable and accelerates their journey out of poverty.
                </p>
              </div>
          </div>
      </div>
      <div class="col-sm-3 mb-3">
        <app-work-structure-menu></app-work-structure-menu>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { ManualsComponent } from './manuals/manuals.component';
import { GuidelinesComponent } from './guidelines/guidelines.component';
import { PressReleasesComponent } from './press-releases/press-releases.component';
import { PublicationsComponent } from './publications/publications.component';
import { AnnualReportsComponent } from './annual-reports/annual-reports.component';
import { ResourcesComponent } from './resources/resources.component';

const routes: Routes = [
  {
		path: "",
    component:ResourcesComponent
  },
    {
		path: "",
		redirectTo: 'case-studies',
		pathMatch: 'full',
	},
	{
		path: "case-studies",
        component: CaseStudiesComponent,
	},
	{
		path: "manuals",
        component: ManualsComponent,
	},
	{
		path: "guidelines",
        component: GuidelinesComponent,
	},
	{
		path: "press-releases",
        component: PressReleasesComponent,
	},
	{
		path: "publications",
        component: PublicationsComponent,
	},
	{
		path: "annual-reports",
        component: AnnualReportsComponent,
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResourcesRoutingModule { }

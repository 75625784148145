<div class="header-image">
    <img src="/assets/images/approach.png" alt="Introduction" title="Introduction" class="img-fluid" />
    <div class="page-title">Letters/Circulars</div>
</div>
<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <a href="/about" class="nav-link ">Whats New?</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Letter/Circulars</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-8 mb-3">
        <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
        <div class="card border-0 rounded-2 shadow mb-3">
            <ul class="list-group list-group-flush">
              <ng-container *ngFor="let item of letters">
                <li style="cursor: pointer;" class="list-group-item"(click)="navigateTo(item)">{{item?.title}}, Issued Date: {{item?.issuedDate | date : 'mediumDate'}}</li>
              </ng-container>
            </ul>
          </div>
      </div>
      <div class="col-sm-4 mb-3 position-relative">
          <app-whats-new-menu></app-whats-new-menu>
      </div>
  </div>



</div>

<div class="header-image">
    <img src="/assets/images/ajjevika-banner-Non-Intensive-Blocks.jpg" alt="Non-Intensive Blocks" title="Non-Intensive Blocks" class="img-fluid" />
    <div class="page-title">Non-Intensive Blocks</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link ">Work Structure</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Non-Intensive Blocks</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>The remaining blocks in a state (other than resource, intensive and partnership blocks) would be
                        classified as non-intensive blocks. DAY-NRLM strengthens existing SHGs in these blocks with some capacity
                        building and limited financial assistance. No new mobilization would be done in these blocks. Over time,
                        as DAY-NRLM implementation progresses, these blocks also would come into the intensive fold. Some SRLMs have
                        adopted 2-3 villages in the non-intensive blocks to implement DAY-NRLM in a full-fledged manner.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3">
            <app-work-structure-menu></app-work-structure-menu>
        </div>
    </div>
</div>
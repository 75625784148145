<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Terms & Conditions</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow">
          <h3>Terms & Conditions</h3>
        <p>This Portal is hosted by National Informatics Centre (External website that opens in a new window), Government of India for National Rural Livelihoods Mission (NRLM).</p>
            <p>
          Though all efforts have been made to ensure the accuracy and currency of the content on this Portal, the same should not be construed as a statement of law or used for any legal purposes. NRLM/NIC accepts no responsibility in relation to the accuracy, completeness, usefulness or otherwise, of the contents. Users are advised to verify/check any information with the relevant State Rural Livelihoods Mission/Government department(s) and/or other source(s), and to obtain any appropriate professional advice before acting on the information provided in the Portal.</p>
          <p>
          In no event will the Government or NRLM/NIC be liable for any expense, loss or damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this Portal.</p>
          <p>
          Links to other websites that have been included on this Portal are provided for public convenience only. NRLM/ NIC is not responsible for the contents or reliability of linked websites and does not necessarily endorse the view expressed within them. We cannot guarantee the availability of such linked pages at all times.</p>
          <p>
          These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</p>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>
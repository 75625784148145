import { Directive, ElementRef, OnInit} from "@angular/core";

@Directive({
    selector: "[camelCaseToSpace]"
})

export class camelCaseToSpaceDirective{
    constructor(
        private elem: ElementRef
        ) {
          // need access to inner text before setting new text
          // elem.nativeElement.outerHTML, innerHTML, outerText, innerText are all empty at this point
    
    }

    ngAfterContentInit() {
        this.elem.nativeElement.innerHTML = this.splitCase(this.elem.nativeElement.innerHTML);
    }

    splitCase = (s:string) => !s || s.indexOf(' ') >= 0 ? s :
    (s.charAt(0).toUpperCase() + s.substring(1))
        .split(/(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/g)
        .map(x => x.replace(/([0-9]+)/g,'$1 '))
        .join(' ')
}
<!-- <div class="header-image">
  <img src="/assets/images/case.png" alt="Introduction" title="Introduction" class="img-fluid" />
  <div class="page-title">{{currentModule}}</div>
</div> -->
<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/about" class="nav-link ">Resource Center</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">{{currentModule}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9 mb-3">
      <div class="card border-0 rounded-2 shadow mb-3">
        <div class="card-body">
          <p id="staticText">Inspiring stories of rural women who have risen out of abject poverty with the help of their institutions.
            These inspirational stories of unvanquished spirit and of powerful transformation will inspire poor women
            all
            over to join such networks and become change agents in ushering an inclusive society that recognizes and
            values
            the poor in their quest for empowerment.</p>
          <div class="row ">
            <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId"
              (searchParams)="searchParams($event)">
            </app-common-filters>

          </div>
          <ng-container>
            <div class="card rounded shadow-sm border-0 mt-3" *ngFor="let item of dataList" id="caseStudiesDocument">
              <div class="card-body ">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class=" mb-2" [matTooltip]="item.title">{{item?.title}}</h6>
                    <div class="text-muted small pipes">
                      <span class="" *ngIf="item?.publishedDate">
                        {{item?.publishedDate | date: 'mediumDate'}}
                      </span>
                      <span class="" *ngIf="item?.source">
                        Source: {{item?.source}}
                      </span>
                      <span class="" *ngIf="item?.author">
                        Author: {{item?.author}}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-auto" *ngIf="item?.fileList">
                    {{getTotalDownload(item.fileList)}} Downloads
                    <button (click)="openDialog(item.fileList)" class="btn btn-link"><img
                        src="/assets/images/download.svg"></button>
                  </div>
                </div>

              </div>
            </div>
          </ng-container>
          <div class="row">
            <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3 mb-3 subModuleClass">
      <div class="bg-light rounded-3 shadow sticky-top">
        <nav class="nav flex-column justify-content-between px-2 menu-cust">
          <li class="nav-item" *ngFor="let item of resourceSubModules" ><a class="nav-link"
              (click)="getToResourceSubModule(item)" [ngClass]="{'active' : currentModule==item.subModuleName}">{{item.subModuleName}}</a></li>
        </nav>
      </div>
      <!-- <app-resource-menu></app-resource-menu> -->
    </div>
  </div>

<div class="header-image">
    <img src="/assets/images/ajjevika-banner-Resource-Blocks.jpg" alt="Introduction" title="Introduction"
        class="img-fluid" />
    <div class="page-title">Resource Blocks</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link ">Work Structure</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Resource Blocks</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>It was envisaged that about 5-6% of the blocks in a State would be taken up as resource blocks. These
                        blocks would be model blocks where all the key strategies of DAY-NRLM would be piloted. These blocks would
                        create a ‘proof of concept’ for other blocks to adopt and replicate. The social capital expected to be
                        generated in the resource blocks would support the intensive blocks in implementation.
        
                        A few State Missions that had prior knowledge and experience of social mobilisation and institution
                        building through their state programmes were designated as National Resource Organisations (NRO) by
                        MoRD. The NROs supported the other states in the implementation of project activities in resource and
                        intensive blocks. The NRO would depute a State Anchor Person to SRLM to anchor implementation. A Project
                        Resource Person (PRP) would also be seconded to each cluster in the resource block to facilitate
                        implementation on the ground. External Community Resource Persons in teams of 5 each from NRO take-up
                        mobilisation, formation of institutions of the poor and train the institutions in the block. NRO also
                        nurtures social capital – active women, internal CRPs, bookkeepers and other community activists to
                        support the institutions in the resource block as well as other blocks.</p>
                    <p>
                        Community Resource Persons (CRPs) are members of the community who have graduated out of poverty with
                        the help of their SHGs. CRP rounds are the cornerstone of the Resource Block strategy. This strategy
                        essentially rests on the premise that the community learns from the community better. CRPs are employed
                        by the Community Based Organizations (CBOs) at various levels and in various verticals. There are some
                        CRPs who specialize in certain areas, such as training, gender, social action, farm livelihoods,
                        non-farm livelihoods, financial inclusion, FNHW, convergence etc. These CRPs provide services within the
                        state and different states in teams to inspire women from other communities to form and strengthen SHGs
                        and their federations, producer groups and producer companies and various thematic interventions.
                    </p>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3">
            <app-work-structure-menu></app-work-structure-menu>
        </div>
    </div>
</div>
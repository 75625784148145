<div [ngClass]="{'main-form-div':!applicationActive}" >
    <div class="print-section">
        <!-- Heading -->
        <div class="d-flex align-items-center">
            <i class="fa fa-arrow-left back-icon enable-cursor-pointer" aria-hidden="true" (click)="viewApplicationForm ? backToApplication() : backToInstruction()"></i>
            <p class="main-form-text">{{ viewApplicationForm ? 'View Application Form' : 'Application Form' }}</p>
        </div>
        <hr />
        <!-- Basic Information -->
        <div *ngIf="!viewApplicationForm">
            <p class="form-heading remove-margin">Basic Information <span class="text-danger">*</span></p>
            <p *ngIf="selectPosition && particularPositionDetails[0].maxAge" class="info-para">(Maximum age for this position is {{particularPositionDetails[0].maxAge}})</p>
            <form [formGroup]="basicInformationForm" class="form-control" autocomplete="off" id="basicInformationFormDiv">
                <div class="row">
                    <!-- Positon Applied For -->
                    <div class="col-sm-4 mb-3 position-relative dropdown-style">
                        <label for="underPosition"> Position Applied For <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': isViewMode}" name="appliedPosition" id="appliedPosition" formControlName="appliedPosition" required autocomplete="off" class="select-style" (change)="getParticularPositionDetails($event)">
                            <ng-container *ngIf="applicationActive; else defaultPosition"><option [value]="jobPostingRowDetails?.position_id" >{{jobPostingRowDetails?.position_name}}</option></ng-container>
                            <ng-template #defaultPosition>
                            <option value="" >Select Position</option>
                            <!-- <option value="testPosition">Test Position</option> -->
                            <option *ngFor="let particularPositionDetails of getTheFilterOutPositionDetails" [value]="particularPositionDetails.id">{{particularPositionDetails.name}}</option></ng-template>
                        </select>
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.appliedPosition.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- Name -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Name <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Name"
                            name="name" formControlName="name" required autocomplete="off">
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.name.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- Fathers Name -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Father's Name  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Father's Name"
                            name="fatherName" formControlName="fatherName" required autocomplete="off">
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.fatherName.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!--Genders Dropdown-->
                    <div class="col-sm-4 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="gender"> Gender <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': isViewMode}" name="gender" id="gender" formControlName="gender" required autocomplete="off" class="select-style">
                            <option value="">Select Gender</option>
                            <option *ngFor="let gender of genderDropdown" [value]="gender.value">{{gender.name}}</option>
                        </select>
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.gender.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- Date of Birth -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Date of Birth <span class="text-danger">*</span></label>
                        <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Select DOB"
                            name="dob" formControlName="dob" required autocomplete="off" (change)="getDateOfBirth($event)"> -->
                        <div class="date-picker-border">
                            <input matInput [matDatepicker]="dob" placeholder="Select Date Of Birth" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode}" (dateChange)="getDateOfBirth($event)" formControlName="dob" [max]="currentDate">
                            <mat-datepicker-toggle matSuffix [for]="dob" class="date-picker-toggle"></mat-datepicker-toggle>
                            <mat-datepicker #dob></mat-datepicker>
                        </div>
                        <!-- Eligible validation -->
                        <p *ngIf="eligibleValidation" class="validationError">You are not eligible for this position</p>
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.dob.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- Permanent Address -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Permanent Address  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Permanent Address"
                            name="permanentAddress" formControlName="permanentAddress" required autocomplete="off">
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.permanentAddress.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- Same as permanentaddress -->
                    <div>
                        <label for="sameCorrespondenceAddress">Same as Permanent Address</label>
                        <input type="checkbox" id="sameCorrespondenceAddress" name="sameCorrespondenceAddress" style="margin-left: 9px;" (change)="getPermanentAddress($event)">
                    </div>
                    <!-- Address for correspondence -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Address For Correspondence  <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode || permanentAddressField}" placeholder="Enter Corresspondence Address"
                            name="correspondenceAddress" formControlName="correspondenceAddress" required autocomplete="off">
                        <p *ngIf="basicInformationFormValidation && !basicInformationForm.controls.correspondenceAddress.valid" class="validationError">This field is mandatory</p>
                    </div>
                    <!-- E-mail -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">E-mail<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter E-mail"
                            name="email" formControlName="email" required autocomplete="off">
                        <p *ngIf="basicInformationFormValidation && basicInformationForm.controls.email.errors?.required" class="validationError">This field is mandatory</p>
                        <p *ngIf="basicInformationFormValidation && basicInformationForm.controls.email.errors?.email" class="validationError">Enter a valid mail ID</p>
                    </div>
                    <!-- Enter Mobile No -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Tel/Mobile No <span class="text-danger">*</span></label>
                        <input class="form-control" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Tel/Mobile No"
                            name="mobileNumber" formControlName="mobileNumber" required autocomplete="off" (keypress)="keyPress($event)" type="text"
                            autocomplete="off"
                            maxlength="10">
                        <p *ngIf="basicInformationFormValidation && basicInformationForm.controls.mobileNumber.errors?.required" class="validationError">This field is mandatory</p>

                    </div>
                </div>
            </form>
        </div>
        <!-- Basic Information View -->
        <div *ngIf="viewApplicationForm">
            <p class="main-heading">Basic Information</p>
            <div class="d-flex main-basic-information-div">
                <div class="basic-information-div">
                    <p>Position Applied For :  <span>{{basicInformationFormData?.positionName}}</span></p>
                    <hr />
                    <p>Father's Name : <span>{{basicInformationFormData?.fatherName}}</span></p>
                    <hr />
                    <p>Date Of Birth : <span>{{basicInformationFormData?.dob}}</span> </p>
                    <hr />
                    <p>Address For Correspondence : <span>{{basicInformationFormData?.correspondenceAddress}}</span></p>
                    <hr />
                    <p>Tel/Mobile No : <span>{{basicInformationFormData?.mobileNumber}}</span></p>
                    <hr />
                </div>
                <div class="basic-information-div">
                    <p>Name : <span>{{basicInformationFormData?.name}}</span></p>
                    <hr />
                    <p>Gender :  <span>{{basicInformationFormData?.gender}}</span></p>
                    <hr />
                    <p>Permanent Address :  <span>{{basicInformationFormData?.permanentAddress}}</span></p>
                    <hr />
                    <p>Email :  <span>{{basicInformationFormData?.email}}</span></p>
                    <hr />
                </div>
            </div>
        </div>
        <hr />
        <!-- Education Qualification -->
        <div  [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'">
            <p class="form-heading">Educational Qualification  <span class="text-danger">*</span></p>
            <div *ngIf="!viewApplicationForm">
            <form [formGroup]="educationQualificationForm" class="form-control" autocomplete="off" id="eductaionQualificationDiv">
                <div class="row">
                    <!-- Degree option -->
                    <div class="col-sm-4 mb-3 position-relative dropdown-style">
                        <label for="language"> Degree <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': educationQualificationFormViewMode}" name="degree" id="degree" formControlName="degree" required autocomplete="off" class="select-style">
                            <option value="">Select Degree</option>
                            <ng-container *ngFor="let degree of educationOptions">
                                <option [value]="degree.value" *ngIf="!appliedGrade.includes(degree.value)">{{degree.name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <!-- Year of Passing -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Year of Passing <span class="text-danger">*</span></label>
                        <input class="form-control" type="month" [ngClass]="{'disabled': educationQualificationFormViewMode}" placeholder="Select Year of Passing"
                            name="passingYear" formControlName="passingYear" required autocomplete="off">
                    </div>
                    <!-- Institute Name -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Institute <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': educationQualificationFormViewMode}" placeholder="Enter Institute"
                            name="institute" formControlName="institute" required autocomplete="off">
                    </div>
                    <!-- Board/University -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Board/University<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': educationQualificationFormViewMode}" placeholder="Enter Board/University"
                            name="boardUniversity" formControlName="boardUniversity" required autocomplete="off">
                    </div>
                    <!-- Specialization -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Specialization<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': educationQualificationFormViewMode}" placeholder="Enter Specialization"
                            name="specialization" formControlName="specialization" required autocomplete="off">
                    </div>
                    <!-- Percentage of Marks/Grading -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Percentage/Grades <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': educationQualificationFormViewMode}" placeholder="Enter Percentage"
                            name="percentageGrading" formControlName="percentageGrading" required autocomplete="off">
                    </div>
                    <!-- Upload Certificate for each degree -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Certificate<span class="text-danger">*</span></label>
                        <input class="form-control" type="file" [ngClass]="{'disabled': educationQualificationFormViewMode}" id="degeeFileName" #educationCertificate (change)="getCertificateDegree($event)"
                        placeholder="Enter File" accept="application/pdf">
                    </div>
                </div>
            </form>
            <!-- Education Qualification Button -->
            <div *ngIf="!viewModeForm" class="education-qualification-button" 
                [ngClass]="{ 'valid-button': educationQualificationForm.valid, 'not-valid-button': !educationQualificationForm.valid }" 
                (click)="educationQualificationButtonName === 'save' ? getEducationQualification() : updateEducationQualificationData()">
                {{ educationQualificationButtonName === 'save' ? 'Add Educational Qualification' : 'Update Educational Qualification' }}
            </div>
            <p *ngIf="commonTableDataFormValidation" class="validationError">Please Add a Education before submitting the form</p>
            </div>
        </div>
        <!-- Tabular Form Data -->
        <div [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'">
            <app-common-table [tableHeader]="educationQualificationTableHeader" [tableData]="commonTableData" (particularRowData)="getParticularEducationData($event)"></app-common-table>
        </div>
        <hr />
        <!-- Experience -->
        <div  [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'">
            <p class="form-heading remove-margin">Experience  <span class="text-danger">*</span></p>
            <p *ngIf="!viewApplicationForm" class="info-para">(Please add a experience from current / last Job to previous Job)</p>
            <p *ngIf="selectPosition && !viewApplicationForm" class="info-para" style="margin: 0;">(Minimum experience for this position is {{particularPositionDetails[0].minExp}} years)</p>
            <div *ngIf="!viewApplicationForm">
            <form [formGroup]="experienceForm" class="form-control" autocomplete="off" id="experienceFormDiv">
                <div class="row">
                    <!-- Name Of Organization -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Name Of Organization <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Name"
                            name="organizationName" formControlName="organizationName" required autocomplete="off">
                    </div>
                    <!-- Designation -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Designation<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Name"
                            name="designation" formControlName="designation" required autocomplete="off">
                    </div>
                    <!-- Start Date -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Start Date<span class="text-danger">*</span></label>
                        <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Select Start Date"
                            name="startDate" formControlName="startDate" required autocomplete="off"> -->
                        <div class="date-picker-border"> 
                            <input matInput [matDatepicker]="startDate" placeholder="Select Date" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode}" (dateChange)="getExperienceStartDate($event)" formControlName="startDate" [min]="editJobStartDate" [max]="lastJobStartDate || editJobEndDate">
                            <mat-datepicker-toggle matSuffix [for]="startDate" class="date-picker-toggle"></mat-datepicker-toggle>
                            <mat-datepicker #startDate></mat-datepicker>
                        </div>
                    </div>
                    <!-- Same as permanentaddress -->
                    <div style="display: flex;">
                        <label for="presentJob" id="presentJobLabel">Present Job</label>
                        <input type="checkbox" id="presentJob" name="presentJob" style="margin-left: 9px;" [checked]="presentJobStatus" (change)="getPresentJobStatus($event)">
                    </div>
                    <!-- End Date/As on -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">End Date / As on<span class="text-danger">*</span></label>
                        <!-- <input class="form-control" type="date" [ngClass]="{'disabled': isViewMode}" placeholder="Select End Date/As on"
                            name="endDate" formControlName="endDate" [min]="experienceForm.value.startDate" [max]="currentDate" required autocomplete="off" (change)="getEndDateOfJob($event)"> -->
                        <div class="date-picker-border">
                            <input matInput [matDatepicker]="endDate" placeholder="Select Date" class="form-control date-picker-input" [ngClass]="{'disabled': isViewMode || presentJobStatus}" (dateChange)="getEndDateOfJob($event)" formControlName="endDate" [min]="currentJobStartDate || editJobStartDate || lastJobEditStartDate" [max]="currentJobEndDate || editJobEndDate">
                            <mat-datepicker-toggle matSuffix [for]="endDate" class="date-picker-toggle" [ngClass]="{'disabled': isViewMode || presentJobStatus}"></mat-datepicker-toggle>
                            <mat-datepicker #endDate></mat-datepicker>
                        </div>
                    </div>
                    <!-- Total Experinece Number -->
                    <div class="col-sm-4 mb-3 position-relative" hidden="true">
                        <label for="">In Months <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" inputmode="numeric" [ngClass]="{'disabled': isViewMode}"
                            placeholder="Enter In Months" name="experienceInMonth" formControlName="experienceInMonth" required
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            autocomplete="off"
                            maxlength="2">
                    </div>
                    <!-- Responsibility/Assignment -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Responsibility <span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Responsibility"
                            name="responsibilityAssignment" formControlName="responsibilityAssignment" required autocomplete="off">
                    </div>
                    <!-- Achievemnets -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <label for="">Achievements</label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Achievements"
                            name="achievements" formControlName="achievements" autocomplete="off">
                    </div>
                </div>
            </form>
            <!-- Add Experinece Button -->
            <div *ngIf="experienceFormButtonName == 'save' && basicInformationForm.value.appliedPosition && !viewModeForm" class="education-qualification-button" [ngClass]="experienceForm.valid  ? 'valid-language-button' : 'not-valid-button'" (click)="getExperienceData()">Add Experience</div>
            <div *ngIf="experienceFormButtonName == 'edit' && basicInformationForm.value.appliedPosition && !viewModeForm" class="education-qualification-button"  [ngClass]="experienceForm.valid  ? 'valid-language-button' : 'not-valid-button'"  (click)="updateExpeienceData()">Update Experience</div>
            </div>
        </div>
        <div [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'">
            <app-common-table [tableHeader]="experienceFormHeader" [tableData]="experienceFormData" (particularRowData)="getExperienceFormData($event)"></app-common-table>
        </div>
        <p *ngIf="basicInformationForm.value.appliedPosition == ''" class="validationError" style="margin-top: 10px;">Please select position before adding the experience</p>
        <p *ngIf="!checkEligibleBasedOnExp && experienceFormData.length > 0 && particularPositionDetails.length > 0" class="validationError">Your work experience is less than the minimum experience requirement.</p>
        <p *ngIf="experienceFormDataValidation" class="validationError">Please add a experience before submitting the form</p>
        <div style="width: 80%;" *ngIf="experienceFormData.length > 0">
            <form [formGroup]="experienceFormSalary" autocomplete="off" *ngIf="!viewApplicationForm">
                <label for="">Please Enter Current/Last Salary Per Month<span class="text-danger">*</span></label>
                <input class="form-control" type="number" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Current/last Salary"
                    name="lastSalary" formControlName="last_salary" required autocomplete="off" style="width: 31%;" (input)="getCurrentSalary($event)">
                <p *ngIf="experienceFormValidation && !experienceFormSalary.valid && experienceFormData.length > 0" class="validationError">This field is mandatory</p>
                <p *ngIf="currentSalaryValidation" class="validationError">Salary should not be more than this 100000000</p>
            </form>
            <div *ngIf="viewApplicationForm">
                <p class="main-heading">Current/last Salary</p>
                <p class="lastSalaryBox">{{experienceFormSalary.value.last_salary}}</p>
            </div>
        </div>
        <hr />
        <!-- Language -->
        <div>
            <p class="form-heading">Language  <span class="text-danger">*</span></p>
            <div *ngIf="!viewApplicationForm">
            <form [formGroup]="languageForm" class="form-control" autocomplete="off" id="languageFormDiv">
                <div class="row">
                    <!-- Language Dropdown -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;" *ngIf="!othersLanguageStatus">
                        <label for="language"> Language <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': languageFormViewMode}" name="language" id="language" formControlName="language" required autocomplete="off" class="select-style" (change)="getParticularLanguage($event)">
                            <option value="">Select Language</option>
                            <option [value]="language.value" *ngFor="let language of languageOptions">{{language.name}}</option>
                        </select>
                    </div>
                    <!-- Others Language text box -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;" *ngIf="othersLanguageStatus">
                        <label for="language">Others (Please Specify the others language)<span class="text-danger">*</span></label>
                        <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Please Specify Others Language"
                            name="language" formControlName="language" required autocomplete="off">
                    </div>
                    <!-- Select Read -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="read"> Read <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': languageFormViewMode}" name="read" id="read" formControlName="read" required autocomplete="off" class="select-style">
                            <option value="">Select Read</option>
                            <option *ngFor="let proficient of proficiencyDropdown" [value]="proficient.value">{{proficient.name}}</option>
                        </select>
                    </div>
                    <!-- Select Write -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="write"> Write <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': languageFormViewMode}" name="write" id="write" formControlName="write" required autocomplete="off" class="select-style">
                            <option value="">Select Write</option>
                            <option *ngFor="let proficient of proficiencyDropdown" [value]="proficient.value">{{proficient.name}}</option>
                        </select>
                    </div>
                    <!-- Select Understand -->
                    <div class="col-sm-3 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <label for="underStand"> Understand <span class="text-danger">*</span></label>
                        <select [ngClass]="{'disabled': languageFormViewMode}" name="underStand" id="understand" formControlName="understand" required autocomplete="off" class="select-style">
                            <option value="">Select Understand</option>
                            <option *ngFor="let proficient of proficiencyDropdown" [value]="proficient.value">{{proficient.name}}</option>
                        </select>
                    </div>
                </div>
            </form>
            <!-- Add Language Button -->
            <div *ngIf="!viewModeForm" class="education-qualification-button"
                [ngClass]="{ 'valid-button': languageForm.valid, 'not-valid-button': !languageForm.valid }" 
                (click)="languageFormButtonName === 'save' ? getLanguageData() : updateLanguageData()">
                {{ languageFormButtonName === 'save' ? 'Add Language' : 'Update Language' }}
            </div>
            <p *ngIf="languageDataValidation" class="validationError">Please Add a language before submitting the form</p>
            </div>
        </div>
        <div [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'">
            <app-common-table [tableHeader]="languageFormHeader" [tableData]="languageData" (particularRowData)="getParticularLanguageData($event)"></app-common-table>
        </div>
        <hr />
        <!-- Computer Proficiency -->
        <div *ngIf="!viewApplicationForm">
            <p class="form-heading">Computer Proficiency <span class="text-danger">*</span></p>
            <form [formGroup]="computerProficiencyForm" class="form-control" autocomplete="off" id="computerProficiencyFormDiv">
                <div class="row">
                    <!-- Language Dropdown -->
                    <div class="col-sm-4 mb-3 position-relative" style="display: flex; flex-direction: column;">
                        <!-- <label for="computerProficiency"> Computer Proficiency <span class="text-danger">*</span></label> -->
                        <select [ngClass]="{'disabled': isViewMode}" name="computerProficiency" id="computerProficiency" formControlName="computerProficiency" required autocomplete="off" class="select-style">
                            <option value="">Select Computer Proficiency</option>
                            <option *ngFor="let computerProficiency of computerProficiencyDropdown" [value]="computerProficiency.value">{{computerProficiency.name}}</option>
                        </select>
                    </div>
                </div>
            </form>
            <p *ngIf="computerProficiencyFormValidation && !computerProficiencyForm.valid" class="validationError">This field is mandatory</p>
        </div>
        <hr />
        <div *ngIf="!viewApplicationForm">
            <p class="form-heading">Justify your candidature for the position  <span class="text-danger">*</span></p>
            <form [formGroup]="candidatureForm" class="form-control" autocomplete="off" id="justifyCandidatureForm">
                <div class="row">
                    <!-- Justify your candidature for the position -->
                    <div class="col-sm-4 mb-3 position-relative">
                        <!-- <label for="">Justify your candidature for the position</label> -->
                        <!-- <input class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Justification"
                            name="justificationCandidature" formControlName="justificationCandidature" maxlength="10" required autocomplete="off"> -->
                        <!-- Text Area -->
                        <textarea class="form-control" type="text" [ngClass]="{'disabled': isViewMode}" placeholder="Enter Justification" name="justificationCandidature" formControlName="justificationCandidature" maxlength="500" required autocomplete="off" rows="4" cols="50"></textarea>
                    </div>
                </div>
            </form>
            <p *ngIf="candidatureFormValidation && !candidatureForm.valid" class="validationError">This field is mandatory</p>
        </div>
        <!-- Computer Proficiency and Justify candidature -->
        <div *ngIf="viewApplicationForm">
            <div>
                <p class="main-heading">Computer Proficiency</p>
                <div class="basic-information-div">
                    <p>{{computerProficiencyFormData?.computerProficiency}}</p>
                </div>
            </div>
            <hr />
            <div class="main-basic-information-div" *ngIf="candidatureFormData?.justificationCandidature">
                <p class="main-heading">Justify your Candidature for the position</p>
                <div class="basic-information-div">
                    <p class="break-word">{{candidatureFormData?.justificationCandidature}}</p>
                </div>
            </div>
        </div>
        <hr />
        <!-- Refrences -->
        <div  [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'" *ngIf="!viewApplicationForm" >
            <p class="form-heading">References</p>
            <div>
                <form [formGroup]="refrenceForm" class="form-control" autocomplete="off" id="refrenceFormDiv">
                    <div class="row">
                        <!-- Name -->
                        <div class="col-sm-4 mb-3 position-relative">
                            <label for="">Name <span class="text-danger">*</span></label>
                            <input class="form-control" type="text" [ngClass]="{'disabled': refrenceFormViewMode}" placeholder="Enter Name"
                                name="referenceName" formControlName="referenceName" autocomplete="off">
                        </div>
                        <!-- Relation -->
                        <div class="col-sm-4 mb-3 position-relative" style="display: flex; flex-direction: column;">
                            <label for="referenceRelation"> Relation <span class="text-danger">*</span> </label>
                            <select [ngClass]="{'disabled': refrenceFormViewMode}" name="referenceRelation" id="referenceRelation" formControlName="referenceRelation"  autocomplete="off" class="select-style">
                                <option value="">Select Relation</option>
                                <option *ngFor="let relation of relationDropdown" [value]="relation.value">{{relation.name}}</option>
                            </select>
                        </div>
                        <!-- Address -->
                        <div class="col-sm-4 mb-3 position-relative" style="display: flex; flex-direction: column;">
                            <label for="referenceAddress"> Address <span class="text-danger">*</span> </label>
                            <input class="form-control" type="text" [ngClass]="{'disabled': refrenceFormViewMode}" placeholder="Enter Address"
                                name="referenceAddress" formControlName="referenceAddress" autocomplete="off">
                        </div>
                        <!-- Enter Mobile No -->
                        <div class="col-sm-4 mb-3 position-relative">
                            <label for="referenceMobile">Tel/Mobile No <span class="text-danger">*</span> </label>
                            <input class="form-control" [ngClass]="{'disabled': refrenceFormViewMode}" placeholder="Enter Tel/Mobile No"
                                name="referenceMobile" formControlName="referenceMobile" autocomplete="off" (keypress)="keyPress($event)" type="text"
                                maxlength="10"
                                >
                        </div>
                        <!-- Email Id  -->
                        <div class="col-sm-4 mb-3 position-relative">
                            <label for="referenceEmail">E-mail Address: <span class="text-danger">*</span> </label>
                            <input class="form-control" type="text" [ngClass]="{'disabled': refrenceFormViewMode}" placeholder="Enter E-Mail"
                                name="referenceEmail" formControlName="referenceEmail" autocomplete="off">
                        </div>
                    </div>
                </form>
                <!-- Add Refrences -->
                <div *ngIf="!viewModeForm" class="education-qualification-button"
                        [ngClass]="{
                        'valid-button': refrenceForm.valid,
                        'not-valid-button': !refrenceForm.valid
                        }"
                        (click)="refrenceFormButtonName === 'save' ? getRefrencesData() : updateRefrenceData()">
                    {{ refrenceFormButtonName === 'save' ? 'Add References' : 'Update References' }}
                </div>
            </div>
        </div>
        <p class="form-heading" *ngIf="viewApplicationForm && refrenceData.length > 0">References</p>
        <div [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'" *ngIf="refrenceData.length > 0">
            <app-common-table [tableHeader]="refrenceFormHeader" [tableData]="refrenceData"  (particularRowData)="getParticularRefrenceData($event)"></app-common-table>
        </div>
        <hr />
        <div *ngIf="applicationActive" >
            <p class="resume-heading">Resume:</p>
            <i  class="fa fa-paperclip orange" style="cursor: pointer;" (click)="downloadResume(viewResume)"></i>
            <!-- <p (click)="downloadResume(viewResume)">{{viewResume}}</p> -->
        </div>
        <div *ngIf="!applicationActive"  [ngClass]="viewModeForm ? 'disabled-form' : 'enable-form'" id="uploadedCV">
            <p class="form-heading">Upload latest CV(in PDF only) <span class="text-danger">*</span></p>
            <form class="form-control" autocomplete="off">
                <div class="row">
                    <!-- Language Dropdown -->
                    <div class="col-sm-4 mb-3 position-relative dropdown-style">
                        <input class="form-control" type="file"
                        [ngClass]="{'disabled': isViewMode}" id="fileInput" #fileInput (change)="getUserCV($event)"
                        placeholder="Enter File" accept="application/pdf">
                        <p *ngIf="userCvValidation && !userCv" class="validationError">This field is mandatory</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr class="underline-style" />
    <p *ngIf="!viewApplicationForm" class="declaration-div">Declaration: All information and document attached are correct to the best of my knowledge. If any information/document found incorrect or invalid my candidature will be rejected at any point of time.</p>
    <div class="d-flex button-div" *ngIf="!applicationActive">
        <button class="save-submit-button d-flex align-items-center valid-button" (click)="finalJobPortal()" *ngIf="viewModeForm">Submit</button>
        <button class="save-submit-button d-flex align-items-center valid-button"
                (click)="viewModeForm ? editJobPortalForm() : saveReview()"
                [style.margin-left.px]="viewModeForm ? 15 : 0"
                [style.padding.px]="!viewModeForm ? 7 : 10">
            {{ viewModeForm ? 'Edit' : 'Save & Review' }}
        </button>
        <button class="print-icon d-flex align-items-center" *ngIf="viewModeForm" (click)="printPreview()">
            <i class="fa fa-print" aria-hidden="true"></i>
            <p style="margin: 0; padding-left: 10px;">Print</p>
        </button>
    </div>
</div>

import { Component, SimpleChanges, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
// import { Appointment } from '../appointment.type';

export type Appointment = {
  id?: string;
  start?: Date;
  end?: Date;
  title?: string;
  allDay?: boolean;
  contentId?: number;
}

@Component({
  selector: 'app-appointment-detail',
  styleUrls: ['./appointment-detail.component.scss'],
  templateUrl: './appointment-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppointmentDetailComponent implements OnChanges {
  @Input() appointment: Appointment = {};
  @Input() isNew: any = null;
  @Input() contentId:any = ''
  @Output() close = new EventEmitter();
  @Output() add = new EventEmitter<Appointment>();
  @Output() update = new EventEmitter<Appointment>();
  form = this.formBuilder.group({
    title: [null, Validators.required],
    allDay: [null],
    start: [],
    end: []
  })
  constructor(private formBuilder: FormBuilder) { }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.appointment && simpleChanges.appointment.currentValue) {
      this.contentId = simpleChanges.appointment.currentValue.contentId;
      this.form.patchValue({ ...this.appointment });
    }
  }

  onAdd(): void {
    const { end, start, title, allDay } = this.form.value;
    this.add.emit({ end: end && new Date(end), start: start && new Date(start), title, allDay });
  }

  onUpdate(): void {
    const { end, start, title, allDay, id } = this.form.value;
    this.update.emit({ id: this.appointment.id, end: end && new Date(end), start: start && new Date(start), title, allDay });
  }
  closeContainer():void {
    this.close.emit()
  }
}
<div class="header-image">
  <img src="/assets/images/ajjevika-banner-Support-Units.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
  <div class="page-title">Support Units</div>
</div>
<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/about" class="nav-link ">Work Structure</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Support Units</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9">
      <div class="card border-0 rounded-2 shadow mb-3">
        <div class="card-body">
          <p appReadMore>To facilitate intensive implementation, State Rural Livelihoods Missions (SRLMs) have been established as
            special purpose vehicles. Dedicated implementation support units have been set up at the state, district and
            block levels known as State Mission Management Unit (SMMU), District Mission Management Unit (DMMU) and Block
            Mission Management Unit (BMMU). Multi-disciplinary professional teams are engaged at each level to spearhead the
            Mission activities under different components. A National Rural Livelihoods Promotion Society (NRLPS) has been
            established under the MoRD to provide technical support to the SRLMs. The NRLPS has set up a National Mission
            Management Unit (NMMU), a multi-specialty team of experts to support the State Missions.
            <br>
            <br>
          </p>
          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  NATIONAL LEVEL SUPPORT UNITS
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body" appReadMore>
                  NRLM Advisory Committee (NRLM-AC), chaired by the Union Minster of Rural Development, comprises eminent personalities representing CSOs, financial institutions, industry associations and academia, development and livelihoods experts, representatives of state governments etc. The committee is a policymaking body, setting the NRLM overall vision, direction and priorities and reviewing the overall progress.
                  <br>
                  NRLM Coordination Committee (NRLM-CC), chaired by the Secretary, Rural Development, MoRD, ensures that the objectives are achieved in time. NRLM Empowered Committee (NRLM-EC) reviews and approves the State Perspective and Implementation Plans and Annual Action Plans and releases funds to SRLMs.
                  <br>
                  The Joint Secretary/Additional Secretary, NRLM, MoRD leads DAY-NRLM as Mission Director and head of its National Mission Management Unit (NMMU), comprising multidisciplinary team of professionals from open market on contract, and requisite support staff. There are thematic sub-groups in Social Inclusion, Financial Inclusion and Livelihoods, and a Technical Support Cell (TSC) within NMMU to coordinate technical support and multidisciplinary appraisal missions with the states. TSC builds and maintains a pool of national resource persons, advisers and experts in all relevant disciplines, drawn from NMMU, state missions and the development sector. 
                  <br>
                  <br>
                  At national level, the broad responsibilities are:
                  <ul>
                    <li>
                      Technical Support to States/UTs
                    </li>
                    <li>
                      Knowledge Management and Capacity Building
                    </li>
                    <li>
                      Developing Partnerships for banking and market linkages
                    </li>
                    <li>
                      Supporting States and UTs in roll-out
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  STATE LEVEL SUPPORT UNITS
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body" appReadMore>
                  <h6>STATE RURAL LIVELIHOODS MISSION (SRLM)</h6>
                  Constituted by and incorporated as autonomous bodies under the State Governments, SRLMs oversee the
                    implementation of all DAY-NRLM-related activities in the state. In states, where the State Governments use existing
                    societies/entities as SRLMs, their articles of association/bye-laws are amended to incorporate DAY-NRLM objectives
                    and broaden the membership in the Governing Body.
                    <br>
                    <br>

                  <h6>STATE MISSION MANAGEMENT UNIT (SMMU)</h6>
                  The DAY-NRLM activities are implemented in the states through SMMUs, headed by a full-time State Mission Director
                    (SMD). The major responsibilities of the SMMU include:
                  <ul>
                    <li>Leading all DAY-NRLM activities in the state</li>
                    <li>Drafting policies and implementation guidelines of the mission at the state level</li>
                    <li>Handholding support to district and sub-district implementation/support structures</li>
                    <li>Ensuring quality implementation of different components/thematic interventions</li>
                    <li>Ensuring proper linkages with the DRDAs</li>
                    <li>Managing convergence and partnerships</li>
                    <li>And any other function, as required</li>
                  </ul>
                  Apart from the SMD, the multidisciplinary SMMU team comprises of experts in Social Inclusion, Financial
                    Inclusion, Livelihoods, Programme Management, Programme Support etc., and support staff. These experts, with
                    adequate experience in the relevant thematic area, are drawn either from the market on contractual basis or from
                    the government organizations on deputation basis, with specific terms of reference. Based on the need, a senior
                    development professional is taken from the open market as a second in command (Chief Operating Officer).
                    Further, SMMU engages thematic experts and empanels reputed resource agencies to support field implementation
                    teams.
                    <br>
                    <br>

                  <h6>STATES WITH MULTIPLE AGENCIES IMPLEMENTING LIVELIHOODS PROGRAMMES</h6>
                  In some states, there are multiple agencies/departments engaged in activities aligned with DAY-NRLM objectives.
                    These include externally aided projects, or the programmes of departments of Women & Child Development, Tribal
                    Affairs, Social Justice and Social Welfare, etc. DAY-NRLM presents an opportunity to converge all such initiatives.
            
                    The guiding principles for convergence
                  <ul>
                    <li>Leveraging strengths of existing partners</li>
                    <li>No loss of existing social and human capital</li>
                    <li>No compromise on DAY-NRLM non-negotiable principles</li>
                    <li>No duplication of efforts</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  DISTRICT LEVEL SUPPORT UNITS
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body" appReadMore>
                  <h6>DISTRICT MISSION MANAGEMENT UNIT (DMMU)</h6>
                    Responsible for meeting objectives and implementing activities in the districts, DMMUs are linked suitably with
                    DRDA and act as facilitating and support unit for field structures. They interface and forge convergence with
                    District Administration and line departments, banks, NGOs and corporate agencies. A broad-based District
                    Advisory Group/Review and Coordination Committee, chaired by District Collector, including representatives of
                    the institutions of the poor and NGOs that work with them in a significant way, reviews DAY-NRLM activities and
                    provides inputs for improving and developing subsequent plans.<br />
                    <br>
                    A multidisciplinary DMMU, led by District Mission Manager (DMM), hired from open market on contract or on
                    deputation from Government, includes functional specialists in Social Inclusion, Financial Inclusion,
                    Livelihoods, Capacity Building, Programme Management, Programme Support etc., and support staff, as required. A
                    Capacity Building cell (CB cell) within DMMU, is responsible for imparting training to the field implementation
                    units, and nurturing the community resource persons and developing them as trainers.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  BLOCK LEVEL SUPPORT UNITS
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                data-bs-parent="#accordionExample">
                <div class="accordion-body" appReadMore>
                  <h6>BLOCK MISSION MANAGEMENT UNIT (DMMU)</h6>
                  <p>INTENSIVE IMPLEMENTATION STRATEGY</p>


                  The BMMU comprises multi-disciplinary professional team headed by a Block Mission Manager (BMM)/ Block Project Manager (BPM) and consisting of 3-5 domain specialists for different thematic works. The intensive implementation strategy ensures that all key strategies of the program are implemented in the blocks.<br />
                    <br>
                    The principal responsibilities of BMMU include- mobilizing all poor households into SHG fold; strengthening existing and new SHGs; building SHG federations and other institutions of the poor at various levels; and building capacities of the poor, their institutions, CRPs and other social capital. These institutions, over a period of 2-3 years, would assume the responsibility for sustaining all interventions. The action plan for the block would be based on a systematic diagnosis of the ground situation. The action plan would strengthen the work on ground and plan to cover the uncovered areas. As capacities increase, gradually, institutions of  the poor – SHG Federations – interface and converge with the Block Administration on their own. 
                     <br>
                     DAY-NRLM intends to work in a block for a period of ten years till the community federations take responsibility of implementation. A typical block having about 13,500-15,000 (90% of total poor) poor households to be mobilized, spread over 100 -120 villages, is divided into 4 clusters of nearly 30 villages each. The initial years are spent in building the organizations of the poor by mobilizing them into SHGs and Federations at Village and Cluster level, ensuring their financial inclusion and credit linkage and support for diversifying their livelihoods base. Other interventions such as health, nutrition, inclusion of vulnerable communities like Persons with Disability (PwD), elderly, single women, etc. are introduced through these institutions. Funds flow to the community institutions over the first 4-5 years are also planned at regular intervals. The concluding 4 years are expected to be essentially a maintenance and withdrawal phase where the community institutions graduate to self-reliance and self-sustainability.
                  </div>
              </div>
            </div>
          </div>
          <!-- <h5>
            NATIONAL LEVEL SUPPORT UNITS
          </h5>
          <p>
            NRLM Advisory Committee (NRLM-AC), chaired by the Union Minster of Rural Development, comprises of eminent
            personalities representing CSOs, financial institutions, industry associations and academia, development and
            livelihoods experts, representatives of state governments etc. The committee is a
    
            policymaking body, setting the NRLM overall vision, direction and priorities and reviewing the overall progress.
    
            NRLM Coordination Committee (NRLM-CC), chaired by the Secretary, Rural Development, MoRD, ensures that the
            objectives are achieved in time. NRLM Empowered Committee (NRLM-EC) reviews and approves the State Perspective
            and Implementation Plans and Annual Action Plans and releases funds to SRLMs.
    
            The Joint Secretary/Additional Secretary, NRLM, MoRD leads NRLM as Mission Director and head of its National
            Mission Management Unit (NMMU), comprising multidisciplinary team of professionals from open market on contract,
            and requisite support staff. There are thematic sub-groups in Social Inclusion, Financial Inclusion and
            Livelihoods, and a Technical Support Cell (TSC) within NMMU to coordinate technical support and
            multidisciplinary appraisal missions with the states. TSC builds and maintains a pool of national resource
            persons, advisers and experts in all relevant disciplines, drawn from NMMU, state missions and the development
            sector. Exclusive cells in NMMU are responsible for Placement-linked Skill Development and Innovative
            Livelihoods Projects.
          </p>
          <p>At national level, the broad responsibilities are:</p>
          <ul>
            <li>
              Technical Support to States/UTs
            </li>
            <li>
              Knowledge Management and Capacity Building
            </li>
            <li>
              Developing Partnerships for banking and market linkages
            </li>
            <li>
              Supporting States and UTs in roll-out
            </li>
          </ul>
          <h5>STATE LEVEL SUPPORT UNITS</h5>
          <h6>STATE RURAL LIVELIHOODS MISSION (SRLM)</h6>
          <p>Constituted by and incorporated as autonomous bodies under the State Governments, SRLMs oversee the
            implementation of all NRLM-related activities in the state. In states, where the State Governments use existing
            societies/entities as SRLMs, their articles of association/bye-laws are amended to incorporate NRLM objectives
            and broaden the membership in the Governing Body.</p>
          <h6>STATE MISSION MANAGEMENT UNIT (SMMU)</h6>
          <p>The NRLM activities are implemented in the states through SMMUs, headed by a full-time State Mission Director
            (SMD). The major responsibilities of the SMMU include:</p>
          <ul>
            <li>Leading all NRLM activities in the state</li>
            <li>Drafting policies and implementation guidelines of the mission at the state level</li>
            <li>Handholding support to district and sub-district implementation/support structures</li>
            <li>Ensuring quality implementation of different components/thematic interventions</li>
            <li>Ensuring proper linkages with the DRDAs</li>
            <li>Managing convergence and partnerships</li>
            <li>And any other function, as required</li>
          </ul>
          <p>Apart from the SMD, the multidisciplinary SMMU team comprises of experts in Social Inclusion, Financial
            Inclusion, Livelihoods, Programme Management, Programme Support etc., and support staff. These experts, with
            adequate experience in the relevant thematic area, are drawn either from the market on contractual basis or from
            the government organizations on deputation basis, with specific terms of reference. Based on the need, a senior
            development professional is taken from the open market as a second in command (Chief Operating Officer).
            Further, SMMU engages thematic experts and empanels reputed resource agencies to support field implementation
            teams</p>
          <h6>STATES WITH MULTIPLE AGENCIES IMPLEMENTING LIVELIHOODS PROGRAMMES</h6>
          <p>In some states, there are multiple agencies/departments engaged in activities aligned with NRLM objectives.
            These include externally aided projects, or the programmes of departments of Women & Child Development, Tribal
            Affairs, Social Justice and Social Welfare, etc. NRLM presents an opportunity to converge all such initiatives.
    
            The guiding principles for convergence</p>
          <ul>
            <li>Leveraging strengths of existing partners</li>
            <li>No loss of existing social and human capital</li>
            <li>No compromise on NRLM non-negotiable principles</li>
            <li>No duplication of efforts</li>
          </ul>
          <h5>DISTRICT LEVEL SUPPORT UNITS</h5>
          <h6>DISTRICT MISSION MANAGEMENT UNIT (DMMU)</h6>
          <p>
            Responsible for meeting objectives and implementing activities in the districts, DMMUs are linked suitably with
            DRDA and act as facilitating and support unit for field structures. They interface and forge convergence with
            District Administration and line departments, banks, NGOs and corporate agencies. A broad-based District
            Advisory Group/Review and Coordination Committee, chaired by District Collector, including representatives of
            the institutions of the poor and NGOs that work with them in a significant way, reviews NRLM activities and
            provides inputs for improving and developing subsequent plans.<br />
    
            A multidisciplinary DMMU, led by District Mission Manager (DMM), hired from open market on contract or on
            deputation from Government, includes functional specialists in Social Inclusion, Financial Inclusion,
            Livelihoods, Capacity Building, Programme Management, Programme Support etc., and support staff, as required. A
            Capacity Building cell (CB cell) within DMMU, is responsible for imparting training to the field implementation
            units, and nurturing the community resource persons and developing them as trainers.
          </p> -->
        </div>
      </div>
    </div>
    <div class="col-sm-3 mb-3">
      <app-work-structure-menu></app-work-structure-menu>
    </div>
  </div>
</div>
<div class="bg-white rounded-3 shadow p-4 my-3">
  <h3 class="title text-black mt-2 px-3 pt-3">
    <a class="btn btn-link" (click)="goBack()">
        <img src="../../../../assets/icons/arrow_back_black_24dp.svg" alt="back icon">
    </a>
    {{'User Level'}}
    <span>
        <hr style="border:1px solid #04040433;">
    </span>
</h3>

  <hr />
    <div class="row">
      <form [formGroup]="userLevelForm" class="form-group" autocomplete="off">
        <div class="my-3">
          <div class="col-sm-3" >
            <div class="d-flex flex-column">
              <label for="contentType">Name<span class="text-danger">*</span></label>
              <input (input)="detectChange($event)" class=" form-control rounded" style="border: 1px solid #0000001A;" type="text" id="name" [ngClass]="{'disabled': isViewMode}" placeholder="Enter User Level" name="name" formControlName="name">
            </div>
          </div>
        </div>
      </form>
      <div class="col-sm-12">
        <table>
          <tr >
            <th>S.NO</th>
            <th>Module</th>
            <th >Actions</th>
          </tr>
          <tr *ngFor="let item of permissionModules;let sno = index">
            <td>{{sno + 1}}</td>
            <td>{{item.title}}</td>
            <td>
              <ng-container *ngFor="let key of item.permission">
                <div class="form-check">
                  <input #checkboxes class="form-check-input" type="checkbox" [(ngModel)]="key.value" (change)="onCheckUncheck(key,item)" [ngClass]="{'disabled': isViewMode}"  id="flexCheckDefault">
                  <label class="form-check-label text-capitalize" for="flexCheckDefault">
                    {{key.label}}
                  </label>
                </div>
              </ng-container>
            </td>
          </tr>
        </table>
  
      </div>
      <div class=" mt-3 mx-1">
        <div class="col-sm-12">
         <button class="btn btn-bg" type="submit" *ngIf="!isViewMode" (click)="onSubmit()" [disabled]="disabledButton">{{submitButton}}</button>
        </div>
      </div>
    </div>
</div>

<div class="shadow-lg mt-4 mb-4">
    <div class="d-flex justify-content-between align-items-center instruction-heading-box">
        <div class="d-flex">
            <p class="moduleHeading">Instructions</p>
        </div>
        <div class="div-border add-button d-flex justify-content-center align-items-center" (click)="editInstruction()">
            <i class="fa fa-pencil" aria-hidden="true"></i>
            <p style="margin: 0;margin-left: 4px;">Edit Instructions</p>
        </div>
    </div>
    <!-- <hr class="reset-margin" style="margin: 10px;" /> -->
    <div class="instruction-editor-content">
        <div class="instruction-editor-box disable-instruction-box" [ngClass]="instructionEditor ? 'enable-instruction-box' : 'disable-instruction-box'">
            <div *ngIf="!instructionEditor">
                <!-- <div *ngFor="let instruction of lines; let i = index">
                    <p style="margin: 0; margin-bottom: 4px;">{{i + 1}}. {{instruction}}</p>
                </div> -->
                <div [innerHtml]="instructionList"></div>
            </div>
            <div *ngIf="instructionEditor">
                <!-- <div class="line-number-textarea">
                    <div class="line-numbers">
                      <ng-container *ngFor="let line of lines; let i = index">
                        {{ i + 1 }}.)
                      </ng-container>
                    </div>
                    <textarea
                      #textareaElement
                      class="text-area"
                      [(ngModel)]="textareaContent"
                      (input)="updateLines()"
                    ></textarea>
                </div>                   -->
                <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
            </div>
            <!-- <div *ngIf="writeInstruction">
                <p style="margin: 0; margin-bottom: 4px;">{{copyInstructionList.length + 1}}. <span><input type="text" class="newInstructionBox" id="newInstructionInputBox" (keydown)="getLatestInstruction($event)" /></span></p>
            </div> -->
        </div>
    </div>
    <div *ngIf="instructionEditor" class="d-flex justify-content-end">
        <button class="btn btn-bg update-button-instruction" type="submit" (click)="updateInstruction()">Update Instructions</button>
        <button class="btn btn-bg close-button-instruction" type="submit" (click)="closeInstructionEditor()">Close Instruction Editor</button>    
    </div>
</div>
<div class="container-fluid bg">
    <div
      class="
        d-flex
        flex-md-row flex-column
        justify-content-center justify-content-md-between
        justify-content-lg-evenly
      "
    >
      <div
        class="
          card-left
          d-flex
          flex-column
          justify-content-center
          align-items-center align-items-md-start
        "
      >
        <h2 class="text-white text-start wel-text">WELCOME</h2>
        <p class="text-white text-start wel-cont">Please login to access your account.</p>
      </div>
      <div class="d-flex justify-content-center card p-5 m-5">
        <form [formGroup]="loginForm"  autocomplete="new-form" (ngSubmit)="submitLogin()" class="d-flex flex-column justify-content-center">
          <div class="form-group">
            <label class=" my-1" for="password my-1">Username</label>
            <input
              type="name"
              class="form-control"
              aria-describedby="userHelp"
              placeholder="Username"
              formControlName="loginId"
              autocomplete="new-name"
            />
          </div>
          <div class="form-group mt-2">
              <label class=" my-1" for="password">Password</label>
            <input
            autocomplete="new-password"
              type="password"
              class="form-control"
              placeholder="*********"
              formControlName="password"
            />
          </div>
            <!-- <div class="d-flex justify-content-end forgot-password">
              <a routerLink="/forgot-password">Forgot Password</a>
            </div> -->
            <div class="security-captcha d-flex justify-content-between">
                <div class="security-cont">
                  <!-- placeholder="Enter Captcha"
                          formControlName="captcha" -->
                    <div class="form-group">
                        <label class=" my-1" for="captcha my-1">Security Code</label>
                        <input
                        autocomplete="off"
                          type="captcha"
                          class="form-control"
                          id="captcha"
                          aria-describedby="userHelp"
                          formControlName="captcha"
                        />
                    </div>
                </div>
                <div class="captcha-cont d-flex justify-content-center pt-4">
                    <div id="captchaId" class="mt-2">
                    </div>
                  <i class="fa fa-refresh m-2" aria-hidden="true" (click)="refreshLogin()"></i>

                </div>
            </div>
          <button type="submit" [disabled]="isDisabled" class="btn btn-bg my-3">LOGIN</button>
        </form>
      </div>
    </div>
  </div>

<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <a href="/multimedia" class="nav-link ">Multimedia</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Forums</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row my-3">
        <div class="col ">
          <div class="card border-0 rounded-2 shadow mb-3 p-5">
            <div>
              <h1 class="text-center">Coming Soon</h1>
            </div>
            <div><img src="/assets/images/coming-soon.png" alt="" class="w-100 h-100">
          </div>
          </div>
        </div>
  </div>
</div>
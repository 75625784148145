<div class="container">
  <div class="row my-3">
    <div class="col-sm-8">
      <div class="bg-white p-4 shadow">
        <h5>FAQ</h5>
        <div class="accordion" id="accordionExample">
        <ng-container *ngFor="let item of faqList; let i = index ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#a' + i" aria-expanded="true" aria-controls="collapseOne">
               {{item?.question}}
              </button>
            </h2>
            <div [id]="'a' + i" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>{{item?.answer}}</p>
              </div>
            </div>
          </div>
        </ng-container>
        </div>
    </div>
    </div>
    <div class="col-sm-4">
      <app-footer-sub-menu></app-footer-sub-menu>
    </div>
  </div>
</div>

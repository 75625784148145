import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationComponent } from 'src/app/shared/component/pagination/pagination.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReactiveFormsModule } from '@angular/forms';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AlphabetOnlyDirective } from './directive/alphabet-only.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ReadmoreDirective } from './readmore.directive';
import { camelCaseToSpaceDirective } from './directive/camelCaseToSpace.directive';
import { CommonTableComponent } from './common-table/common-table.component';
import { DisplayablePipe } from './Pipe/displayable.pipe';
import { FilterArrayPipe } from './Pipe/filter-array.pipe';
import { CustomDatePipe } from './Pipe/custom-date.pipe';
// import { MatDatepickerModule } from '@angular/material/datepicker';
@NgModule({
    declarations: [
        PaginationComponent,
        PaginatorComponent,
        DeleteDialogComponent,
        AlphabetOnlyDirective,
        ReadmoreDirective,
        camelCaseToSpaceDirective,
        CommonTableComponent,
        DisplayablePipe,
        FilterArrayPipe,
        CustomDatePipe,
    ],
    imports: [
        CommonModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        MatTooltipModule,
        // MatDatepickerModule


    ],
    exports: [
        CommonTableComponent,
        PaginationComponent,
        PaginatorComponent,
        CommonModule,
        DeleteDialogComponent,
        AlphabetOnlyDirective,
        MatTooltipModule,
        ReadmoreDirective,
        camelCaseToSpaceDirective,
    ]
})
export class SharedModule { }

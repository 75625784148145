import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-media-news',
  templateUrl: './media-news.component.html',
  styleUrls: ['./media-news.component.scss']
})
export class MediaNewsComponent implements OnInit {
page=1;
totalCount=0
  constructor(private contentService:ContentService,private router:Router) { }
  media:any
  ngOnInit(): void {
    this.getMedia()
  }
  getMedia(){
    this.contentService.getContent("list", `moduleId=7&page${this.page}`).subscribe((res:any)=>{
      if(res){
        this.media = res && res['wrapperList'].length && res['wrapperList'];
        this.totalCount = res.totalcount;
      }
    },error=>{
      console.log(error)
    })
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getMedia();
  }
}

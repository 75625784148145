<div class="header-image">
    <img src="/assets/images/aajevika-banner-exit.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
    <div class="page-title">Exit Strategy</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/about" class="nav-link ">Work Structure</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Exit Strategy</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>The Mission has a clear exit strategy. It is expected that after 10 years in a block, women SHG
                        federations can manage their development agenda on their own and external support structure in
                        the form of Block Mission Management Unit (BMMU) would be taken over by the internal support
                        structure of Cluster level federations supported by community professionals.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3">
            <app-work-structure-menu></app-work-structure-menu>
        </div>
    </div>
</div>
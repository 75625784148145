import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayable'
})
export class DisplayablePipe implements PipeTransform {

  transform(subData: any): boolean {
    const keysToExclude = ['action', 'file', 'certificateUrl', 'experienceInMonth'];
    return !keysToExclude.includes(subData.key) || (!subData.key && subData.key !== 'action')
  }

}

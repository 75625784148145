<div class="bg-light rounded-3 shadow sticky-top">
  <nav class="nav flex-column justify-content-between px-2 menu-cust">
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/whats-new-section/updates']" [routerLinkActive]="['active']">
        Updates
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/whats-new-section/letters-circulars']" [routerLinkActive]="['active']">
        Letters/Circulars
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/whats-new-section/tenders']" [routerLinkActive]="['active']">
        Procurement
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/whats-new-section/events']" [routerLinkActive]="['active']">
        Events
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [routerLink]="['/whats-new-section/media-news']" [routerLinkActive]="['active']">
        Media/News
      </a>
    </li>
  </nav>
</div>
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.scss']
})
export class CommonTableComponent implements OnInit {

  @Input() tableData: any = []
  @Input() tableHeader: any = [];
  @Input() approvedRejectActions: boolean = false;
  @Output() particularRowData = new EventEmitter()
  selectedRow: any = [];
  tableHeaderKey: any;
  oldTableData: any[] = [];
  keysToExclude:any = ['status', 'createdAt', 'createdBy', 'deletedAt', 'deletedBy', 'updatedAt', 'updatedBy', 'experienceInMonth', 'id', 'educationQualificationCertificate']
  @Input() currentPage: any;
  pageSize: number = 20

  constructor(
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
      // get Table header key
      this.tableHeaderKey = this.getTableHeaderKey(this.tableHeader);
      let updatedTableData = this.updateTableData(this.tableData);
      console.log("Table Header ", this.tableHeader, updatedTableData)
      this.oldTableData = this.tableData;
      this.tableData = updatedTableData;
      console.log("Table Data ", this.tableData)
  }

  getTableHeaderKey(tableHeader:any) {
    let keyHeaderList:any = []
    tableHeader.forEach((item:any) => {
      keyHeaderList.push(item.key)
    })
    return keyHeaderList
  }


  // UpdateTable Data 
  updateTableData(data:any) {
    console.log("Data ", data, this.tableHeaderKey)
    let details:any = []
    data?.forEach((item:any) => {
      let obj:any = {}
      for(let key in item) {
        if(this.tableHeaderKey.includes(key)) {
           obj[key] = item[key]
        }
      }
      if(item.hasOwnProperty('educationQualificationCertificate')) {
         obj['educationQualificationCertificate'] = item['educationQualificationCertificate']
      }
      obj['id'] = item['id'];
      details.push(obj)
    })

    console.log("Details ", details)
    return details
  }

  ngOnChanges(changes:SimpleChanges) {
    console.log("Changes ", changes)
    if(changes.approvedRejectActions && changes.approvedRejectActions.currentValue) {
       this.approvedRejectActions = changes.approvedRejectActions.currentValue
    }

    if(changes.currentPage && changes.currentPage.currentValue) {
       this.currentPage = changes.currentPage.currentValue
    }

    if(changes.tableData && changes.tableData.currentValue) {
      this.tableHeaderKey = this.getTableHeaderKey(this.tableHeader)
      let updatedTableData = this.updateTableData(this.tableData)
      this.oldTableData = this.tableData
      this.tableData = updatedTableData;
      console.log("Table Data ", this.tableData, this.tableHeader)
    }
  }

  rejectRowData() {
    this.tableData.forEach((item:any) => {
      if(this.selectedRow.includes(item.id)) {
         item['approvedRejectStatus'] = 2
      }
    })
  }

  keepOriginalOrder = (a:any, b:any) => b.key - a.key;

  viewData(data:any, status:any, index:any) {
    let completeRowData = {...this.oldTableData[index], ...data}
    this.particularRowData.emit({rowData: completeRowData, status: 'view'})
  }

  editData(data:any, status:any, index:any) {
    let completeRowData = {...this.oldTableData[index], ...data}
    this.particularRowData.emit({rowData: completeRowData, status: 'edit'})
  }

  deleteData(data:any, status:any, index:any) {
    this.particularRowData.emit({rowData: data, status: 'delete'})
  }

  downloadFile(link:any) {
    const fileUrl = link;
    this.contentService.downloadFile(fileUrl).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'filename.extension'; // Set the desired filename
      link.click();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    });
  }

  openFile(file:any) {
    window.open(file)
  }

  downloadUrl(value:any) {
    console.log("Download Url ", value)
    window.open(value)
  }

}

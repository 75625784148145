import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approach',
  templateUrl: './approach.component.html',
  styleUrls: ['./approach.component.scss']
})
export class ApproachComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { CommonFiltersComponent } from 'src/app/shared/common-filters/common-filters.component';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-nrlm-documents',
  templateUrl: './nrlm-documents.component.html',
  styleUrls: ['./nrlm-documents.component.scss'],
})
export class NrlmDocumentsComponent implements OnInit {
  [x: string]: any;
  // @ViewChild('Paginator') paginator: MatPaginator | undefined;
  @ViewChild(CommonFiltersComponent) filters:CommonFiltersComponent | undefined;
  selectedType = "card";
  subModules:any;
  totalCount = 0;
  subModuleName = '';
  subModuleData:any;
  page=1;
  moduleId = '1'
  subModuleId = 1
  currentSubModuleId = '';
   filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }
  constructor(private contentService:ContentService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getNrlmDocs()
    this.getSubModules();

  }
  viewChange(type: any){
    this.selectedType = type;
    }

    getNrlmDocs(params=''){
      if(this.page==1){
        if(this.paginator){
          this.paginator.pageIndex = 0;
        }
      }
      this.contentService.getContent("list", `moduleId=1&page=${this.page}&${params}`).subscribe((res:any)=>{
        if(res){
          this.isSubModulesOpen =  false;
          this.subModuleName = '';
          this.cardData = res && res['wrapperList'] ;
          this.cardData = this.cardData.reverse();
          this.totalCount = res.totalcount;
        }
      },error=>{
        console.log(error)
      })
    }
    getSubModules(){
      this.contentService.getContent("submodulelist", `moduleId=1`).subscribe((res:any)=>{
        if(res){
          this.subModules = res && res['wrapperList']
          // this.subModules = this.subModules.filter((item:any)=>item.moduleId==1);
          this.subModules = this.subModules.reverse()
        }
      },error=>{
        console.log(error)
      })
    }

    changeModule(item:any){
      this.page = 1;
      this.subModuleName = item.subModuleName;
      this.subModuleId = item.id
      this.getSubModuleData (item.id,1,'');
      this.filters?.clearSearchFilters(true);


    }
    getSubModuleData(id:any,page=1,params=''){
      if(this.page==1){
        if(this.paginator){
          this.paginator.pageIndex = 0;
        }
      }
      this.currentSubModuleId = id;
      this.contentService.getContent("list", `moduleId=1&subModuleId=${id}&page=${this.page}&${params}`).subscribe((res:any)=>{
        if(res){
          this.totalCount = res.totalcount;
          this.cardData = res && res['wrapperList'];
          this.cardData.forEach((item:any) => {
          item['totalDownloads'] = this.getTotalDownload(item.fileList)
        });
        }
      },error=>{
        console.log(error)
      })

    }
    getLink(fileList:any){
      let link = fileList[0]? fileList[0].fileUrl : ''
      return link;
    }
    pageEvent(event:any){
      this.page = event;
      if(!this.subModuleName){
        this.getNrlmDocs();
      }else{
        this.getSubModuleData(this.currentSubModuleId);
      }

    }
    searchParams(event: any){
      let searchParams  = new URLSearchParams(event).toString();
      this.subModuleName.length?this.getSubModuleData(this.subModuleId,this.page,searchParams):this.getNrlmDocs(searchParams);
    }
    getFileTypeImage(fileList:any){
      console.log(fileList)
      let type = fileList[0]?.fileType;
      if(type=='csv'){
        return 'assets/images/pdfDefaullt.png'
      }
      if(type=='image'){
        return '/assets/images/imageDefault.png'
      }
      if(type=='video'){
        return '/assets/images/videoDefault.png'
      }
      return '/assets/images/imageDefault.png'
    }
    downloadCount(id:any,requestType:any){
      this.contentService.countApi(id,requestType).subscribe(res=>{

      },error=>{
        console.log(error)
      })
    }
    openDialog(fileList:any) {
      const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
        data:{
          fileList:fileList,
        },
        width: '800px',
        height: 'auto',
      });
      dialogRef.afterClosed().subscribe((result:any) => {
        console.log(`Dialog result: ${result}`);
      });
    }
    getTotalDownload(fileList:any){
      let total = 0;
      for (var i in fileList) {
        total += fileList[i].downloadCount;
      }
      return total

    }


    checkFileType(data:any,videos:boolean){
      let iframe:any;
       iframe = this.contentService.getThumbnail(data[0].fileUrl)
       return iframe
     }
     geturl(item:any){
     let type=  item.fileList[0]?.fileType;
      if(!item.fileList[0].thumbnail.includes('null')){
        return item.fileList[0].thumbnail;
      }
      if(item.fileType)
      if (type == 'image') {
        return '/assets/images/imageDefault.png';
      }
      if (type == 'video') {
        return '/assets/images/videoDefault.png';
      }
      if (type == 'pdf') {
        return '/assets/images/pdfDefaullt.png';
      }
      if (type == 'ppt' || type == 'pptx' ) {
        return '/assets/images/icons8-microsoft-powerpoint-240.png';
      }
      if (type == 'excel' || type == 'xlsx' ||  type == 'xls' ||  type == 'csv') {
        return '/assets/images/icons8-microsoft-excel-240.png';
      }
      return '/assets/images/imageDefault.png';

  }



}

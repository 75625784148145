<div class="bg-light rounded-3 shadow sticky-top index">
  <nav class="nav flex-column justify-content-between px-2 menu-cust">
    <li class="nav-item"><a class="nav-link" data-bs-toggle="collapse" href="#collapsrti">RTI Act</a></li>
    <div class="collapse show collapse-cust" id="collapsrti">
      <a class="nav-link" [routerLink]="['/multimedia/rti-act']" [routerLinkActive]="['active']">RTI received and
        responses given</a>
      <a class="nav-link" [routerLink]="['/multimedia/rti-compliance']" [routerLinkActive]="['active']">Compliance under
        Section 4, RTI Act</a>
    </div>
    <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/parliament-qa']">Parliament Q&A</a></li>
    <!-- <li class="nav-item "><a class="nav-link" [routerLink]="['/multimedia/forums']">Forums</a></li> -->
    <li class="nav-item"><a class="nav-link" [routerLink]="['/faq']">FAQ</a></li>
    <li class="nav-item"><a class="nav-link" data-bs-toggle="collapse" href="#collapseExample">Multimedia</a></li>
    <div class="collapse show collapse-cust" id="collapseExample">
      <a class="nav-link" [routerLink]="['/multimedia/photo-gallery']" [routerLinkActive]="['active']">Photo Gallery</a>
      <a class="nav-link" [routerLink]="['/multimedia/video-gallery']" [routerLinkActive]="['active']">Video Gallery</a>
    </div>
    <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/resources']">Resources</a></li> -->
  </nav>
</div>
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-rti-compliance',
  templateUrl: './rti-compliance.component.html',
  styleUrls: ['./rti-compliance.component.scss'],
})
export class RtiComplianceComponent implements OnInit {
  constructor(
    private contentService: ContentService,
    private dialog: MatDialog,
    private router: Router
  ) {}
  totalCount = 0;
  subModuleData: any;
  cardData: any;
  page = 1;
  filterConfig = {
    globalSearch: true,
    search: true,
    author: false,
    source: false,
    from: true,
    to: true,
    fileType: false,
  };
  moduleId = 9;
  ngOnInit(): void {
    this.getRTI();
  }

  getRTI(page = 1, params = '') {
    this.contentService
      .getContent(
        'list',
        `moduleId=9&page=${this.page}&${params}&subModuleId=13`
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.cardData =
              res && res['wrapperList'].length && res['wrapperList'];
            this.totalCount = res.totalcount;
            console.log(this.cardData);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getLink(fileList: any) {
    let link = fileList[0] ? fileList[0].fileUrl : '';
    return link;
  }
  pageEvent(event: any) {
    this.page = event;
    this.getRTI(this.page);
  }

  searchParams(event: any) {
    let searchParams = new URLSearchParams(event).toString();
    this.getRTI(this.page, searchParams);
  }
  openDialog(fileList: any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent, {
      data: {
        fileList: fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  getTotalDownload(fileList: any) {
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total;
  }

  navigateTo(item: any) {
    this.router.navigate(['content-details/' + item.id]);
  }
}

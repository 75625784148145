<div class="container-fluid careerImage" *ngIf="jobForApply == 'false'"> 
  <div class="centered">Careers</div>
</div>  
<div class="container" *ngIf="jobForApply == 'false'" id="jobListing">
  <!-- hided as per requirement -->
  <!-- <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Careers</div>
                  </li>
              </ul>
          </div>
      </div>
  </div> -->
    <div class="card border-0 rounded-2 shadow mb-3 mt-5">
      <div class="d-flex align-items-center justify-content-between">
        <div>
            <h4 class="ms-4 mt-3 textColor">Careers</h4>
        </div>
        <div class="filter-container">
          <form class="date-block row" [formGroup]="filterForm">
            <div class="d-flex">
              <div>
                <div style="margin-top: 15px;">
                  <span class="filter">Filter Opening Date:</span>
               </div>
              </div>
              <div style="margin-right: 20px;">
                <div>
                  <mat-form-field class="border-none">
                    <input matInput 
                      [matDatepicker]="fromDate" 
                      placeholder="From Date"
                      (dateChange)="getFromOpeningDate($event)"
                      formControlName="fromDate">
                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <div>
                  <mat-form-field class="border-none">
                    <input matInput 
                      [matDatepicker]="toDate" 
                      placeholder="To Date" 
                      [min]="filterForm.value.fromDate"
                      formControlName="toDate">
                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <div>
                <button mat-button color="primary" class="primary search-button" (click)="applyFilter()">Search</button>
                <button mat-button color="primary" class="primary reset-button"  (click)="resetFilter()">Reset</button>
              </div>
            </div>
            
            

            
              
          </form>
        </div>
      </div>
      <!-- <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator> -->
      <ng-container *ngIf="applicationJobList?.length;else noData">
      <ul class="list-group list-group-flush">
        <ng-container *ngFor="let item of applicationJobList">
          <div class="dropdown">
            <li style="cursor: pointer;
                border-radius: 5px;
    border: 1px solid lightgoldenrodyellow;" class="list-group-item dropbtn m-3 shadow-sm"><p class="job-text">{{item.title}}</p>
              <span class="d-flex">
                <span class="upload">Opening Date: </span>{{formatDate(item.openingDate)}}&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="lastDate">Last Submission Date: </span>{{formatDate(item.lastDate)}}
                <span>
                  <!-- Download File -->
                  <div class="dropdown-content d-button">
                    <a class="downloadButton" [href]="item?.file" download="file.pdf" target="_blank">
                      Download File
                    </a>
                  </div>
                </span>
                <span *ngIf="item.applicationOpen">
                  <!-- Apply Jobs -->
                  <div class="dropdown-content d-button" style="cursor: pointer;" (click)="applyJobs(item)">
                    <a class="applyButton">
                      Apply
                    </a>
                  </div>
                </span>
              </span>

            </li>
          </div>
          
         
        </ng-container>
      </ul>
      </ng-container>
      <div class="row">
        <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
      </div>
      <ng-template #noData>
        <div class="row mb-4 mt-4">
          <div style="text-align: center">
            <h1>No Data Found</h1>
          </div>
        </div>
      </ng-template>
    </div>
</div>

<div *ngIf="jobForApply === 'true'">
  <app-job-portal (backToCareerPage)="backToCareerPage($event)"></app-job-portal>
</div>

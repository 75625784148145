<footer class="footerBg">
    <div class="container-fluid p-0" style="position:relative">
  
        <div class="row mt-5 bgCustom">
            <div class="col-sm-12 mb-3 col-lg ms-5">
                <div class=" h-100  align-items-stretch d-block">
                    <div class="">
                        <img src="/assets/images/india-gov-in.jpg" alt="The National Portal of India"
                            title="The National Portal of India" />
                    </div>
                    <div class="mt-2">
                      <div class="visitors">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="12" viewBox="0 0 24 12">
                              <g transform="translate(0 -6)">
                                  <path
                                      d="M12,12.75a10.611,10.611,0,0,1,4.24.9A2.984,2.984,0,0,1,18,16.38V18H6V16.39a2.963,2.963,0,0,1,1.76-2.73A10.44,10.44,0,0,1,12,12.75ZM4,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,4,13Zm1.13,1.1A6.983,6.983,0,0,0,4,14a6.95,6.95,0,0,0-2.78.58A2.011,2.011,0,0,0,0,16.43V18H4.5V16.39A4.5,4.5,0,0,1,5.13,14.1ZM20,13a2,2,0,1,0-2-2A2.006,2.006,0,0,0,20,13Zm4,3.43a2.011,2.011,0,0,0-1.22-1.85,6.8,6.8,0,0,0-3.91-.48,4.5,4.5,0,0,1,.63,2.29V18H24ZM12,6A3,3,0,1,1,9,9,3,3,0,0,1,12,6Z"
                                      fill="#fff" />
                              </g>
                          </svg>
                          Visitors: <strong>{{totalVisitor}}</strong>
                      </div>
                  </div>
                  <div class="col-sm-12 mb-3 col-lg-auto align-self-end mt-3">
                    <h6>Connect with us on social media</h6>
                    <ul class="nav mt-3">
                        <li class="nav-item">
                            <a href="https://www.facebook.com/aajeevika" class="sm circle" title="facebook">
                                <svg id="facebook-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3674" data-name="Path 3674" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3675" data-name="Path 3675" d="M14,13.5h2.5l1-4H14v-2c0-1.03,0-2,2-2h1.5V2.14A28.166,28.166,0,0,0,14.643,2,4.336,4.336,0,0,0,10,6.7V9.5H7v4h3V22h4Z"/>
                                  </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="http://www.youtube.com/user/aajeevikaNRLM" class="sm circle" title="youtube">
                                <svg id="youtube-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3676" data-name="Path 3676" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3677" data-name="Path 3677" d="M21.543,6.5A31.058,31.058,0,0,1,22,12a31.058,31.058,0,0,1-.457,5.5A2.811,2.811,0,0,1,19.6,19.524C17.9,20,12,20,12,20s-5.893,0-7.605-.476A2.811,2.811,0,0,1,2.457,17.5,31.058,31.058,0,0,1,2,12a31.058,31.058,0,0,1,.457-5.5A2.811,2.811,0,0,1,4.395,4.476C6.107,4,12,4,12,4s5.9,0,7.6.476A2.811,2.811,0,0,1,21.543,6.5ZM10,15.5,16,12,10,8.5Z"/>
                                </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="https://twitter.com/DAY_NRLM" class="sm-3 circle" title="twitter">
                                <svg id="twitter-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path id="Path_3678" data-name="Path 3678" d="M0,0H24V24H0Z" fill="none"/>
                                    <path id="Path_3679" data-name="Path 3679" d="M22.162,5.656a8.384,8.384,0,0,1-2.4.658A4.2,4.2,0,0,0,21.6,4a8.358,8.358,0,0,1-2.656,1.015,4.182,4.182,0,0,0-7.126,3.814A11.874,11.874,0,0,1,3.2,4.459a4.188,4.188,0,0,0,1.294,5.584A4.168,4.168,0,0,1,2.6,9.52v.052a4.185,4.185,0,0,0,3.354,4.1,4.21,4.21,0,0,1-1.889.072A4.185,4.185,0,0,0,7.97,16.65a8.394,8.394,0,0,1-6.191,1.732,11.83,11.83,0,0,0,6.41,1.88,11.819,11.819,0,0,0,11.9-11.9c0-.18,0-.362-.013-.54a8.5,8.5,0,0,0,2.087-2.165Z"/>
                                  </svg>
                            </a>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
            <div class="col-sm-12 mb-3 col-lg ms-5">
                <h6>Public Information</h6>
                <ul class="nav flex-column mt-3">
                    
                    
                    <li class="nav-item">
                        <a  routerLink="/multimedia/parliament-qa">Parliament Q & A</a>
                    </li>
                    <!-- <li class="text-white">/</li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link text-white" routerLink="/multimedia/forums">Forums</a>
                    </li> -->
                    
                    <li class="nav-item">
                        <a  routerLink="/faq">FAQ</a>
                    </li>
                    <li class="nav-item">
                        <a  routerLink="/multimedia/rti-act">RTI Act</a>
                    </li>
                    <li class="nav-item">
                        <a  routerLink="/multimedia/photo-gallery">Multimedia</a>
                    </li>
                    <!-- <li class="text-white">/</li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link text-white" routerLink="/multimedia/resources">Resources</a>
                    </li> -->
        
                </ul>
            </div>
            <div class="col-sm-12 mb-3 col-lg ms-5">
                <h6>Help & Support</h6>
                <ul class="nav flex-column mt-3">
                    <li class="nav-item"><a href="/terms-conditions" title="Terms &amp; Conditions">Terms &amp; Conditions</a></li>
                    <li class="nav-item"><a href="/privacy-policy" title="Privacy Policy">Privacy Policy</a></li>
                    <li class="nav-item"><a href="/copyright-policy" title="Copyright Policy">Copyright Policy</a></li>
                    <li class="nav-item"><a href="/hyperlink-policy" title="Hyperlink Policy">Hyperlink Policy</a></li>
                    <li class="nav-item"><a href="/disclaimer" title="Disclaimer">Disclaimer</a></li>
                    <li class="nav-item"><a href="/accessibility-statement" title="Accessibility Statement">Accessibility Statement</a></li>
                    <li class="nav-item"><a href="/contact-ceo" title="Contact CEO">Contact CEO</a></li>
                </ul>
            </div>

            <div class="col-sm-12 mb-3 ms-5 col-lg-auto text-nowrap">
                <h6>Contact Us</h6>
                <div class="mt-3">Deendayal Antyodaya Yojana -&nbsp;</div>
                <div>National Rural Livelihoods Mission (DAY-NRLM)</div>
                <div>Ministry of Rural Development - Govt. of India</div>
                <div>7th Floor, NDCC Building -II, Jai Singh Road</div>
                <div>New Delhi - 110001</div>
                <div>Phone: 011 - 23461708</div>
            </div>
            
        </div>
    </div>
</footer>
<div class="back-to-top" (click)="scrollToTop()">
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/><path d="M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20 M12,22c5.52,0,10-4.48,10-10c0-5.52-4.48-10-10-10 C6.48,2,2,6.48,2,12C2,17.52,6.48,22,12,22L12,22z M11,12l0,4h2l0-4h3l-4-4l-4,4H11z"/></g></svg>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  textSize = ['sm','rg','lg'];
  currentTextSize: any;
  aboutUsDropdown: boolean = false;
  whatDropdown: boolean = false;
  workStructureDropdown: boolean = false;
  impactDropdown: boolean = false;
  resourceDropdown: boolean = false;
  whatSubDropdown: boolean = false
  workStructureSubDropdown: boolean = false
  aboutUsSubDropdown: boolean = false
  ourImpactSubDropdown:boolean = false
  resourceSubDropdown:boolean = false
  activeButton:any;
  fakePdfUrl: string = 'https://aajeevikaimg.dhwaniris.in/Fake_website_1676033493375.pdf';


  resourceSubModules:{subModuleName: string;_id: Number,link?:string;}[] = [
    {
      subModuleName:'Case-studies',
      _id:31
    },
    {
      subModuleName:'Manuals',
      _id:32
    },
    {
      subModuleName:'Guidelines',
      _id:36
    },
    // {
    //   subModuleName:'Press Releases',
    //   _id:33
    // },
    // {
    //   subModuleName:'Publications',
    //   _id:34
    // },
    {
      subModuleName:'Annual Reports',
      _id:35
    },
    {
      subModuleName:'Evaluation and Study Reports',
      _id:37
    },
    {
      subModuleName:'FNHW Digital Toolkit',
      _id:37898,
      link: 'https://fnhw-aajeevika.lokos.in/'
    }
  ];
  searchForm = new FormGroup({
    searchValue: new FormControl(''),
  });
  isLoginPage:boolean=false;
  isLoggedIn:boolean= false;
  userLevelName: boolean = false
  constructor(
    public router: Router,
    private location: Location,
    private authService:AuthGuardService
  ) {
    router.events.subscribe(val => {
      if (this.location.path()== "/login") {
        this.isLoginPage = true;
      } else {
        this.isLoginPage = false;
      }
    });
    this.authService.isLoggedIn();
    this.authService.isLogIn.subscribe((res:any)=>{
      this.isLoggedIn = res
    })
    // this.isLoggedIn =this.authService.isLoggedIn()

  }

  enterSubmit(event:Event,value:any){
    
    console.log(this.searchForm.value)
    if(value.searchValue.trim()==''){
      this.router.navigate(['home'])
      return
    }
    this.searchForm.reset();
    this.router.navigate(['search',value.searchValue])
  }
  ngOnInit(): void {


    const textSizeJson = sessionStorage.getItem('nrlmTextSize');
    const getTextSize = textSizeJson !== null ? JSON.parse(textSizeJson) : "";
    if(getTextSize){
      this.setFontSize(getTextSize.currentTextSize)
    }

    sessionStorage.getItem("whatSubDropdown") === 'true' ? this.whatSubDropdown = true : this.whatSubDropdown = false 
    sessionStorage.getItem("workStructureSubDropdown") === 'true' ? this.workStructureSubDropdown = true : this.workStructureSubDropdown = false
    sessionStorage.getItem("aboutUsSubDropdown") === 'true' ? this.aboutUsSubDropdown = true : this.aboutUsSubDropdown = false 
    sessionStorage.getItem("ourImpactSubDropdown") === 'true' ? this.ourImpactSubDropdown = true : this.ourImpactSubDropdown = false 
    sessionStorage.getItem("resourceSubDropdown") === 'true' ? this.resourceSubDropdown = true : this.resourceSubDropdown = false 
  }

  setFontSize(size: string){
    // console.log(size)
    // this.size= size;
    let elem = document.body;

    this.textSize.forEach(item => elem.classList.remove(item));
    elem.classList.add(size);
    this.currentTextSize = size;
    sessionStorage.setItem('nrlmTextSize', JSON.stringify({
      'currentTextSize': size,
    }));
  }

  hasRoute(route: string) {
    return this.router.url.includes(route);
  }

  getToResourceSubModule(data:any){
    if(data?.link){
      window.open(data.link, '_blank')?.focus();
    }else{
      this.router.navigate(
      ['/resource-center'],
      { queryParams: { 'subModule': data.subModuleName, '_id': data._id } }
    );
    console.log('submodules data----->', data);
    this.activeButton = data?.subModuleName;
      }
  }
  focusSearch(){
   let input = document.getElementById("searchFormInput");
   if(input){
     input?.focus()
   }
  }

  openAboutDropdown() {
    this.aboutUsDropdown = !this.aboutUsDropdown
    this.whatDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
    console.log('About Us Dropdown ', this.aboutUsDropdown)
  }

  openWhatDropdown() {
    this.whatDropdown = !this.whatDropdown
    this.aboutUsDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
  }

  openWorkStructureDropdown() {
    this.workStructureDropdown = !this.workStructureDropdown
    this.aboutUsDropdown = false
    this.whatDropdown = false
    this.impactDropdown = false
    this.resourceDropdown = false
  }

  openImpactDropdown() {
    this.impactDropdown = !this.impactDropdown
    this.resourceDropdown = false
    this.workStructureDropdown = false
    this.whatDropdown = false
    this.aboutUsDropdown = false
    this.whatSubDropdown = false
  }

  openResourceDropdown() {
    this.resourceDropdown = !this.resourceDropdown
    this.aboutUsDropdown = false
    this.whatDropdown = false
    this.workStructureDropdown = false
    this.impactDropdown = false
    this.whatSubDropdown = false
  }

  openWhatSubDropdown() {
    this.whatSubDropdown = true
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "true")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")

  }

  openWorkstructureSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = true
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "true")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openAboutusSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = true
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "true")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openOurImpactSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = true
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "true")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openResourceSubDropdown() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = true
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "true")
  }

  openHome() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openDashboard() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openCareers() {
    this.whatSubDropdown = false
    this.workStructureSubDropdown = false
    this.aboutUsSubDropdown = false
    this.ourImpactSubDropdown = false
    this.resourceSubDropdown = false
    sessionStorage.setItem("whatSubDropdown", "false")
    sessionStorage.setItem("workStructureSubDropdown", "false")
    sessionStorage.setItem("aboutUsSubDropdown", "false")
    sessionStorage.setItem("ourImpactSubDropdown", "false")
    sessionStorage.setItem("resourceSubDropdown", "false")
  }

  openGrievances() {
    
  }

  openCareersPage() {
    sessionStorage.removeItem('openFormStatus')
    sessionStorage.removeItem('educationQualification')
    sessionStorage.removeItem('languageData')
    sessionStorage.removeItem('experienceData')
    sessionStorage.removeItem('refrenceData')
    sessionStorage.removeItem('jobForApply')
  }

  showFakePdf(){
    let link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', this.fakePdfUrl);
      link.setAttribute('target', "_blank");
      link.setAttribute('download', '');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }
}

isHrModule(){
  let isHrMod = sessionStorage.getItem('userLevelName');
  console.log("Is Hr Mod ", isHrMod)
  let userLevelName =  isHrMod == 'HR Level' ? true : false
  console.log("User Level Name ", userLevelName)
  this.userLevelName = userLevelName
  return this.userLevelName

}

openAdminContent() {
  this.isHrModule()
  sessionStorage.removeItem('jobPostingForm')
  sessionStorage.removeItem('numberOfJob')
  sessionStorage.removeItem('previousNumberOfJob')
  sessionStorage.removeItem('positionMasterListData')
  sessionStorage.removeItem('saveJobListing')
  sessionStorage.removeItem('editjobListing')
  sessionStorage.removeItem('originalPositionNameList')
  sessionStorage.removeItem('instructionEditor')
  sessionStorage.removeItem('positionTableDate')
  sessionStorage.removeItem('positionMaster');
}
}

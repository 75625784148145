<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Sitemap</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow site">
          <h1>Sitemap</h1>
          <ul>
            <li><a class="nav-link" [routerLink]="['/terms-conditions']">Terms & Conditions</a></li>
            <li><a class="nav-link" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
            <li><a class="nav-link" [routerLink]="['/copyright-policy']">Copyright Policy</a></li>
            <li><a class="nav-link" [routerLink]="['/hyperlink-policy']">Hyperlink Policy</a></li>
            <li class="mb-2"><a class="nav-link" [routerLink]="['/Disclaimer']">Disclaimer</a></li>
            <li><a class="nav-link" [routerLink]="['/accessibility-statement']">Accessibility Statement</a></li>
            <li><a class="nav-link" [routerLink]="['/contact-ceo']">Contact CEO</a></li>
          </ul>
          <ul>
            <li><a class="nav-link fw-bold">About Us</a></li>
            <ul>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/introduction']">Introduction</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/founding-philosophy']">Founding Philosophy</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/genesis']">Genesis</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/goal']">Goal</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/guiding-principles']">Guiding Principles </a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/values']">Values</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/leadership']">Leadership</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/about/team']">Team</a></li>
            </ul>
            <li><a class="nav-link fw-bold">What We Do</a></li>
            <ul>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/institutional-capacity-building']">Institutional Capacity Building</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/social-inclusion-and-social-development']">Social Inclusion And Social Development</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/universal-financial-inclusion']">Universal Financial Inclusion</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/livelihoods']">livelihoods</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/monitoring-system-and-mis']">Monitoring System and MIS</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/what-we-do/national-rural-economic-transformation-project']">National Rural Economic Transformation Project</a></li>
            </ul>
            <li><a class="nav-link fw-bold">Work Structure</a></li>
            <ul>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/approach']">Approach</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/support-units']">Support Units</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/resource-blocks']">Resource Blocks</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/intensive-blocks']">Intensive Blocks</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/non-intensive-blocks']">Non-Intensive Blocks</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/exit-strategy']">Exit Strategy</a></li>
            </ul>
            <li><a class="nav-link fw-bold">Our Impact</a></li>
            <ul>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/our-impact/overview']">Overview</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/our-impact/success-stories']">Success Stories</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/our-impact/fact-sheets']">Fact Sheets</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/our-impact/milestones']">milestones</a></li>
            </ul>
            <li><a class="nav-link fw-bold">Resource Center</a></li>
            <ul>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/case-studies']">Case Studies</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/manuals']">Manuals</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/guidelines']">Guidelines</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/press-releases']">Press Releases</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/publications']">Publications</a></li>
                <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/annual-reports']">Annual Reports</a></li>
            </ul>
            <li><a class="nav-link" [routerLink]="['/join-us']">Careers</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/rti-act']">RTI Act</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/parliament-qa']">Parliament Q&A</a></li>
            <li class="nav-item "><a class="nav-link" [routerLink]="['/multimedia/forums']">Forums</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/faq']">FAQ</a></li>
            <li class="nav-item"><a class="nav-link fw-bold">Multimedia</a></li>
                <ul>
                    <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/photo-gallery']">Photo Gallery</a></li>
                    <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/video-gallery']">Video Gallery</a></li>
                </ul>

            <li class="nav-item"><a class="nav-link" [routerLink]="['/multimedia/resources']">Resources</a></li>
            <li><a class="nav-link" [routerLink]="['/knowledge-products/national-repository']">NRLM Repository</a></li>
            <li><a class="nav-link" [routerLink]="['/knowledge-products/nrlm-documents']">NRLM Documents</a></li>
            <li><a class="nav-link" [routerLink]="['/our-impact/newsletters']">Newsletters</a></li>
            <li class="mb-1"><a class="nav-link" [routerLink]="['/our-impact/success-stories']">Success Stories</a></li>
            <li class="nav-item"><a class="nav-link" [routerLink]="['/terms-conditions']">Terms & Conditions</a></li>
            <li><a class="nav-link" [routerLink]="['/copyright-policy']">Copyright Policy</a></li>
            <li><a class="nav-link" [routerLink]="['/hyperlink-policy']">Hyperlink Policy</a></li>
            <li><a class="nav-link" [routerLink]="['/disclaimer']">Disclaimer</a></li>
            <li><a class="nav-link" [routerLink]="['/accessibility-statement']">Accessibility Statement</a></li>
            <li class="mb-1"><a class="nav-link" [routerLink]="['/contact-ceo']">Contact CEO</a></li>
            <li><a class="nav-link" [routerLink]="['/home']">Home</a></li>
            <li><a class="nav-link" [routerLink]="['/feedback']">feedback</a></li>
            <li><a class="nav-link" [routerLink]="['/sitemap']">Sitemap</a></li>
          </ul>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>

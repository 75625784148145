import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { DisplayablePipe } from './shared/Pipe/displayable.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';

import { LoginComponent } from './pages/login/login.component';
import { ContentComponent } from './pages/admin/content/content.component';

import { JoinUsComponent } from './pages/join-us/join-us.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FaqComponent } from './pages/faq/faq.component';
import { FooterSubMenuComponent } from './pages/footer-sub-menu/footer-sub-menu.component';
import { PhotoGalleryComponent } from './pages/multimedia/photo-gallery/photo-gallery.component';
import { VideoGalleryComponent } from './pages/multimedia/video-gallery/video-gallery.component';
import { NationalRepositoryComponent } from './pages/knowledge-products/national-repository/national-repository.component';
import { KnowledgeProductsMenuComponent } from './pages/knowledge-products/knowledge-products-menu/knowledge-products-menu.component';
import { NrlmDocumentsComponent } from './pages/knowledge-products/nrlm-documents/nrlm-documents.component';
import { NewslettersComponent } from './pages/knowledge-products/newsletters/newsletters.component';
import { RtiActComponent } from './pages/multimedia/rti-act/rti-act.component';
import { ParliamentQaComponent } from './pages/multimedia/parliament-qa/parliament-qa.component';
import { ForumsComponent } from './pages/multimedia/forums/forums.component';
import { ResourcesComponent } from './pages/multimedia/resources/resources.component';
import { UpdatesComponent } from './pages/whats-new-section/updates/updates.component';
import { LettersCircularsComponent } from './pages/whats-new-section/letters-circulars/letters-circulars.component';
import { EventsComponent } from './pages/whats-new-section/events/events.component';
import { TendersComponent } from './pages/whats-new-section/tenders/tenders.component';
import { MediaNewsComponent } from './pages/whats-new-section/media-news/media-news.component';
import { WhatsNewMenuComponent } from './pages/whats-new-section/whats-new-menu/whats-new-menu.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { SharedChartComponent } from './shared/chart/sharedChart.component';
import { WorkStructureModule } from './pages/work-structure/work-structure.module';
import { SitemapComponent } from './pages/sitemap/sitemap.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CopyrightPolicyComponent } from './pages/copyright-policy/copyright-policy.component';
import { HyperlinkPolicyComponent } from './pages/hyperlink-policy/hyperlink-policy.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { AccessibilityStatementComponent } from './pages/accessibility-statement/accessibility-statement.component';
import { ContactCeoComponent } from './pages/contact-ceo/contact-ceo.component';
import { ScreenReaderAccessComponent } from './pages/screen-reader-access/screen-reader-access.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CardComponent } from './shared/card/card.component';
import { OtherUsefulLinksComponent } from './pages/other-useful-links/other-useful-links.component';
import { CommonFiltersComponent } from './shared/common-filters/common-filters.component';
import { CoreDocumentAddComponent } from './pages/admin/core-document-add/core-document-add.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth.interceptor';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button'
import { NgImageSliderModule } from 'ng-image-slider';
import { CreateFaqComponent } from './pages/admin/create-faq/create-faq.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from './shared/shared.module';
import { ResourcesModule } from './pages/resources/resources.module';
import { HighlightsComponent } from './pages/admin/highlights/highlights.component';
import { ContentSearchComponent } from './pages/content-search/content-search.component';
import { ContentDetailComponent } from './pages/content-detail/content-detail.component';
import { SingletonSecureStorageService } from './shared/services/storage-services';
import { RtiComplianceComponent } from './pages/multimedia/rti-compliance/rti-compliance.component';
import { KmsComponent } from './pages/kms/kms.component';
import { KmsCoreAddComponent } from './pages/admin/kms-core-add/kms-core-add.component';
import { CalendarComponent } from './shared/calendar/calendar.component';
import { AppointmentDetailComponent } from './shared/appointment-detail/appointment-detail.component';
import { UserPolicyComponent } from './pages/user-policy/user-policy.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { UserComponent } from './pages/user/user.component';
import { UserPolicyCreateComponent } from './pages/user-policy-create/user-policy-create.component';
import {MatTabsModule} from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { GrievancesComponent } from './pages/admin/grievances/grievances.component';
import { HrModuleComponent } from './pages/admin/hr-module/hr-module.component';
import { PositionMasterComponent } from './pages/admin/position-master/position-master.component';
import { InstructionEditorComponent } from './pages/admin/instruction-editor/instruction-editor.component';
import { JobPortalComponent } from './pages/job-portal/job-portal.component';
import { JobPortalFormComponent } from './pages/job-portal-form/job-portal-form.component';
import { DatePipe } from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    // DisplayablePipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    ContentComponent,
    JoinUsComponent,
    FaqComponent,
    FooterSubMenuComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    NationalRepositoryComponent,
    KnowledgeProductsMenuComponent,
    NrlmDocumentsComponent,
    NewslettersComponent,
    RtiActComponent,
    ParliamentQaComponent,
    ForumsComponent,
    ResourcesComponent,
    UpdatesComponent,
    LettersCircularsComponent,
    EventsComponent,
    TendersComponent,
    MediaNewsComponent,
    WhatsNewMenuComponent,
    FeedbackComponent,
    SharedChartComponent,
    SitemapComponent,
    TermsConditionsComponent,
    GrievancesComponent,
    PrivacyPolicyComponent,
    CopyrightPolicyComponent,
    HyperlinkPolicyComponent,
    DisclaimerComponent,
    AccessibilityStatementComponent,
    ContactCeoComponent,
    ScreenReaderAccessComponent,
    DashboardComponent,
    CardComponent,
    OtherUsefulLinksComponent,
    // CommonFiltersComponent,
    CoreDocumentAddComponent,
    // CreateFaqComponent,
    HighlightsComponent,
    ContentSearchComponent,
    ContentDetailComponent,
    RtiComplianceComponent,
    KmsComponent,
    KmsCoreAddComponent,
    AppointmentDetailComponent,
    CalendarComponent,
    UserComponent,
    UserCreateComponent,
    UserPolicyComponent,
    UserPolicyCreateComponent,
    HrModuleComponent,
    PositionMasterComponent,
    InstructionEditorComponent,
    JobPortalComponent,
    JobPortalFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    WorkStructureModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    NgImageSliderModule,
    MatPaginatorModule,
    MatTooltipModule,
    SharedModule,
    ResourcesModule,
    MatTabsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    AngularEditorModule,
  ],
  entryComponents:[
  ],
  providers: [
    DatePipe,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  SingletonSecureStorageService,
  
  {

  provide: APP_INITIALIZER,

  useFactory: (ds: SingletonSecureStorageService) => () => ds.init(),

  deps: [SingletonSecureStorageService],

  multi: true

  }],
  bootstrap: [AppComponent],
  exports:[CoreDocumentAddComponent,MatDialogModule,MatButtonModule,NgImageSliderModule,MatPaginatorModule,MatTooltipModule,ContentSearchComponent,ContentDetailComponent,
    AppointmentDetailComponent,
    CalendarComponent]
})
export class AppModule { }

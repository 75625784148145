<div class="container">
  <div class="row my-3">
    <div class="col px-0">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->

            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/multimedia" class="nav-link ">Multimedia</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3"> RTI ACT</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9 mb-3 bg-white shadow p-3">
      <div class="row">
        <div class=" mb-3 bg-white">
          <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId" (searchParams)="searchParams($event)">
          </app-common-filters>
          <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
          <div class="d-flex justify-content-between">
            <!-- <p class="my-2">Showing {{totalCount}} Results</p> -->
            <div class="d-flex">
              <div class="dropdown me-2">
                <!-- <button class="btn btn-outline-primary dropdown-toggle d-flex justify-content-between " type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span>Most Recent First</span><span><img src="/assets/images/dropdown.svg" alt=""></span>
                </button> -->
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div *ngIf="totalCount!==0;else noData; else noData">
            <div class="card rounded shadow-sm border-0 mt-3"  *ngFor="let item of cardData">
              <div class="card-body">
                <div class="row">
                  <div class="col" style="cursor: pointer;" (click)="navigateTo(item)">
                    <h6 class="">{{item?.title}}</h6>
                    <div class="text-muted small mb-3">{{item?.publishedDate | date:'mediumDate'}}</div>
                    <!-- {{item?.description}} -->
                  </div>
                  <div class="col-auto">
                    {{getTotalDownload(item.fileList)}} Downloads
                    <button class="btn btn-link" (click)="openDialog(item.fileList)"><img src="/assets/images/download.svg" alt=""></button>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <ng-template #noData>
            <div class="row mb-4 mt-4">
              <div style="text-align: center;margin-top: 50px;">
                <h1>
                  No Data Found
                </h1>
              </div>

            </div>
          </ng-template>



        </div>

      </div>
    </div>
    <div class="col-sm-3 mb-3 position-relative pe-0">
      <app-footer-sub-menu></app-footer-sub-menu>
    </div>
  </div>
</div>

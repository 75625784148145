import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CommonFiltersComponent } from 'src/app/shared/common-filters/common-filters.component';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-national-repository',
  templateUrl: './national-repository.component.html',
  styleUrls: ['./national-repository.component.scss'],
})
export class NationalRepositoryComponent implements OnInit {
  // @ViewChild('Paginator') paginator: MatPaginator | undefined;

  selectedType = 'card';
  subModules: any;
  totalCount = 0;
  subModuleName = '';
  selectedSubModule: any = {};
  subModuleData: any;
  cardData: any;
  page = 1;
  subModuleId: any;
  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }
  moduleId=8;
  isSubModuleOpen = false;
  // repoSubModules = [
  //   {
  //     subModuleName:'Videos',
  //     // moduleId: 8,
  //     subModuleId: 11
  //   },
    
  //   {
  //     subModuleName:'Document',
  //     // moduleId: 8,
  //     subModuleId: 10
  //   }
  // ]
  constructor(private router: Router, private contentService: ContentService,private dialog:MatDialog) {}
  // @ViewChild() paginator: MatPaginator;
  // ...
  // this.paginator.pageIndex = 0;
  @ViewChild(CommonFiltersComponent) filters:CommonFiltersComponent | undefined;
  ngOnInit(): void {
    this.getSubModules();
  }

  onSelectionChange(event: any) {
    this.router.navigate(['/admin/' + event.target.value]);
  }
  resetToNrmlRepo() {
    this.isSubModuleOpen = false;
    this.subModuleName = '';
  }
  changeModule(item:any){
    this.filters?.clearSearchFilters(true);
    this.getSubModuleData(item)
  }
  getSubModules() {
    this.contentService.getContent('submodulelist', 'moduleId=8').subscribe(
      (res: any) => {
        if (res) {
          this.subModules =
            res && res['wrapperList'].length && res['wrapperList'];
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getSubModuleData(subModule: any, page?: any,params:string='') {
    if (this.selectedSubModule.id == subModule.id && !page) {
      this.filters?.clearSearchFilters(true);
      // return;
    }
    this.selectedSubModule = subModule;
    if (!page) {
      this.page = 1;
    }
    this.contentService
      .getContent(
        'list',
        `moduleId=8&subModuleId=${subModule.id}&${params}&page=${this.page}`
      )
      .subscribe(
        (res: any) => {
          if (res) {
            this.subModuleName = subModule.subModuleName;
            this.isSubModuleOpen = true;
            this.totalCount = res.totalcount;
            this.cardData = res && res['wrapperList'].length ? res['wrapperList'] : [];
              for(let item of this.cardData){
                item['fileSource'] = this.getFileTypeImage(item.fileList);
              }
            }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  getLink(fileList: any) {
    let link = fileList[0] ? fileList[0].fileUrl : '';
    return link;
  }
  getFileTypeImage(fileList: any) {
    let type = fileList[0]?.fileType;
    if (type == 'csv') {
      return 'assets/images/pdfDefaullt.png';
    }
    if (type == 'image') {
      return '/assets/images/imageDefault.png';
    }
    if (type == 'video') {
      return '/assets/images/videoDefault.png';
    }
    if (type == 'pdf') {
      return '/assets/images/pdfDefaullt.png';
    }
    return '/assets/images/imageDefault.png';
  }

  pageEvent(event: any) {
    this.page = event;
    this.getSubModuleData(this.selectedSubModule, this.page);
  }

  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.getSubModuleData(this.selectedSubModule, this.page,searchParams)
  }

openDialog(fileList:any) {
  let data = {}
  if(this.subModuleName=='Videos'){
    data = {
      link:fileList,
      type:'video'
    }
  }else{
    data = {
      fileList:fileList,
    }
  }
const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
  data:data,
  width: '800px',
  height: 'auto',
});
dialogRef.afterClosed().subscribe((result:any) => {
  console.log(`Dialog result: ${result}`);
});
}
getTotalDownload(fileList:any){
  let total = 0;
  for (var i in fileList) {
    total += fileList[i].downloadCount;
  }
  return total

}

// checkFileType(data:any){
//   console.log(data)
//   let iframe=''
//   iframe =  this.contentService.getThumbnail(data[0].fileUrl)
//   return iframe
// }
checkFileType(data:any,videos:boolean){
  let iframe:any
   if(videos){
     iframe =  this.contentService.getThumbnail(data)
   }else{
   this.contentService.getThumbnail(data[0].fileUrl)
    iframe =  this.contentService.getImageThumbnail(data)
   }
   return iframe
 }

 getYouTubeFrame(link: any =''){
  let frameImg = '/assets/images/videDefault.png';
  if(link){
  let videoId = link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/) ? link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop() : '';
  if(videoId)
  frameImg = '//img.youtube.com/vi/'+videoId+'/0.jpg';
  }
  // console.log("frameImg", frameImg)
  return frameImg;
}
geturl(item:any){
  if(item.fileList[0].thumbnail){
    if(item.fileList[0].thumbnail.includes('/null')) return  '/assets/images/pdfDefaullt.png'
    return item.fileList[0].thumbnail;
  }
  return '/assets/images/pdfDefaullt.png'
}

}

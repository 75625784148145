import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");


@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  currentPage = 0;
  pageSize = 20;
  tableHeader: any = [{
    key: 'title',
    label: 'Title'
  },
  {
    key: 'publishedDate',
    label: 'Published Date'
  },
  {
    key: 'publishedBy',
    label: 'Published By'
  },
  {
    key: 'author',
    label: 'Author'
  },
  {
    key: 'fileList',
    label: 'File'
  },
  {
    key: 'source',
    label: 'Document Source'
  },
  {
    key: 'keyword',
    label: 'Keyword'
  }
  ];
  docExtensions = [
    'doc',
    'docm',
    'docx',
    'pdf',
    'txt',
    'csv',
    'xls',
    'xlsb',
    'xlsx',
    'pptm',
    'pptx',
    'application/pdf',
    'application/vnd.ms-excel'
  ];
  imgExtensions = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/raw', 'image/image'];
  videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    // 'html5',
    'mpeg-2',
  ];
  page = 1;
  entryTitle: string = 'NRLM Document';
  selectedModule: any;
  selectedModuleOptions: any = { moduleId: '1', subModuleId: '1' };
  rowData: any = [];
  isChecked: boolean = false;
  isViewMode: boolean = false;
  submitButton: string = 'Submit';
  dynamicForm: any = {
    title: [''],
    publishedDate: [''],
    publishedBy: [''],
    author: [''],
    fileList: [''],
    source: [''],
    keyword: ['']
  };
  totalCount: any = 0;
  fileAcceptType: any;
  labelKeys: any;
  permissionObject: any;
  contentPermission: any;
  constructor(
    private router: Router,
    private contentService: ContentService,
    private dialog: MatDialog

  ) { }
  isEntry: boolean = false;
  //   masterData:any = [
  //     {
  //         "id": 4,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-01-12T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": null,
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 3,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-01-12T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": "https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fview&psig=AOvVaw04_4SRLFzb_xYsq8gPbxXd&ust=1650958461405000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCPjA0dbZrvcCFQAAAAAdAAAAABAD",
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 2,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": null,
  //         "publishedDate": "2015-06-17T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": null,
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     },
  //     {
  //         "id": 1,
  //         "moduleId": 1,
  //         "subModuleId": 1,
  //         "title": "testtitle",
  //         "documentSource": "NRLM",
  //         "author": "NRLM",
  //         "uploadedOn": null,
  //         "publishedBy": "JS-RL",
  //         "publishedDate": "2020-08-17T18:30:00.000+00:00",
  //         "issuedNo": null,
  //         "issuedDate": null,
  //         "issuedBy": null,
  //         "corrigendum": null,
  //         "dueDateExtended": null,
  //         "autoExpiry": null,
  //         "lastDateSubmission": null,
  //         "location": null,
  //         "link": "https://www.google.com/url?sa=i&url=https%3A%2F%2Funsplash.com%2Fs%2Fphotos%2Fview&psig=AOvVaw04_4SRLFzb_xYsq8gPbxXd&ust=1650958461405000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCPjA0dbZrvcCFQAAAAAdAAAAABAD",
  //         "fileNumber": null,
  //         "source": null,
  //         "vacancyUploadedDate": null,
  //         "rtiQuery": null,
  //         "description": null,
  //         "isUpdate": null,
  //         "isHighlight": null,
  //         "approvedRejectedStatus": null,
  //         "approvedRejectedBy": null,
  //         "fileList": []
  //     }
  // ]
  masterData: any = [];
  isKms = false;
  catageoryOption: any = [{
    value: 'NRLM Document',
    label: 'NRLM Document',
    fileTypeAccept: this.docExtensions,
    options: [{
      value: 'core-document',
      label: 'NRLM Core Document',
      moduleId: '1',
      subModuleId: '1',
      dynamicForm: {
        title: [''],
        publishedDate: [''],
        publishedBy: [''],
        author: [''],
        fileList: [''],
        source: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'publishedDate',
        label: 'Published Date'
      },
      {
        key: 'publishedBy',
        label: 'Published By'
      },
      {
        key: 'author',
        label: 'Author'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    }, {
      value: 'national-report-plans',
      label: 'National Reports & Plans',
      moduleId: '1',
      subModuleId: '2',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'guidelines',
      label: 'Guidelines',
      moduleId: '1',
      subModuleId: '3',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'intensive-coverage',
      label: 'Intensive Coverage',
      moduleId: '1',
      subModuleId: '4',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'monthly-status-resblock',
      label: 'Monthly Status of Resource Blocks',
      moduleId: '1',
      subModuleId: '5',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'crp-calender',
      label: 'CRP Calendar',
      moduleId: '1',
      subModuleId: '6',
      dynamicForm: {
        title: [''],
        publishedDate: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'publishedDate',
        label: 'Published Date'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'fund-release',
      label: 'Fund Release',
      moduleId: '1',
      subModuleId: '7',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    },
    {
      value: 'ec-minutes',
      label: 'Approved Ec Minutes',
      moduleId: '1',
      subModuleId: '8',
      dynamicForm: {
        title: [''],
        fileList: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    }],
  }, {
    value: 'National Repository',
    label: 'National Repository',
    options: [{
      value: 'nrlmnationalrepo-document',
      label: 'Document',
      moduleId: '8',
      subModuleId: '10',
      dynamicForm: {
        title: [''],
        author: [''],
        fileList: [''],
        source: [''],
        publishedDate: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'author',
        label: 'Author'
      },
      {
        key: 'fileList',
        label: 'File'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'publishedDate',
        label: 'Published Date'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    }, {
      value: 'nrlmnationalrepo-videos',
      label: 'Videos',
      moduleId: '8',
      subModuleId: '11',
      dynamicForm: {
        title: [''],
        source: [''],
        link: [''],
        keyword: ['']
      },
      tableHeader: [{
        key: 'title',
        label: 'Title'
      },
      {
        key: 'source',
        label: 'Source'
      },
      {
        key: 'link',
        label: 'Link'
      },
      {
        key: 'keyword',
        label: 'Keyword'
      }
      ]
    }]
  },
  {
    value: 'Resources',
    label: 'Resources',
    options: [
      {
        value: 'Case Study',
        label: 'Case Study',
        moduleId: '27',
        subModuleId: '31',
        dynamicForm: {
          title: [''],
          author: [''],
          fileList: [''],
          source: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'publishedDate',
          label: 'Published Date'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Manuals',
        label: 'Manuals',
        moduleId: '27',
        subModuleId: '32',
        dynamicForm: {
          title: [''],
          author: [''],
          fileList: [''],
          source: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Guidelines',
        label: 'Guidelines',
        moduleId: '27',
        subModuleId: '36',
        dynamicForm: {
          title: [''],
          fileList: [''],
          source: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Press Release',
        label: 'Press Release',
        moduleId: '27',
        subModuleId: '33',
        dynamicForm: {
          title: [''],
          link: [''],
          source: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Publications',
        label: 'Publications',
        moduleId: '27',
        subModuleId: '34',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }

        ]
      },
      {
        value: 'Annual Reports',
        label: 'Annual Reports',
        moduleId: '27',
        subModuleId: '35',
        dynamicForm: {
          title: [''],
          fileList: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Evaluation and Study Reports',
        label: 'Evaluation and Study Reports',
        moduleId: '27',
        subModuleId: '37',
        dynamicForm: {
          title: [''],
          fileList: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },

    ]
  },

  {
    value: 'RTI ACT',
    label: 'RTI ACT',
    options: [
      {
        value: 'rti-received',
        label: 'RTI received and responses given',
        moduleId: '9',
        subModuleId: '12',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          fileList: [''],
          fileNumber: [''],
          rtiQuery: [''],
          description: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'fileNumber',
          label: 'File Number'
        }, {
          key: 'rtiQuery',
          label: 'RTI Query'
        },
        // {
        //   key: 'link',
        //   label: 'Link'
        // },
        {
          key: 'description',
          label: 'Rti Response'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'rti-compliance',
        label: 'Compliance under Section 4, RTI Act',
        moduleId: '9',
        subModuleId: '13',
        dynamicForm: {
          title: [''],
          fileList: [''],
          description: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }
    ]
  }, {
    value: 'Multimedia',
    label: 'Multimedia',
    options: [
      {
        value: 'multimedia-videos',
        label: 'Videos',
        moduleId: '11',
        subModuleId: '14',
        dynamicForm: {
          title: [''],
          source: [''],
          link: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'multimedia-photo',
        label: 'Photo',
        moduleId: '11',
        subModuleId: '15',
        dynamicForm: {
          title: [''],
          fileList: [''],
          source: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }
    ]
  },
  {
    value: 'misc',
    label: 'Other Modules',
    options: [
      {
        value: 'grievances',
        label: 'Grievances',
        moduleId: '10',
        subModuleId: '16',
        dynamicForm: {
          title: [''],
          author: [''],
          fileList: [''],
          source: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'publishedDate',
          label: 'Published Date'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'parliament-ques',
        label: 'Parliament Q & A',
        moduleId: '10',
        dynamicForm: {
          title: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'News Letters',
        label: 'News Letters',
        moduleId: '2',
        dynamicForm: {
          title: [''],
          issuedNo: [''],
          issuedDate: [''],
          publishedBy: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'issuedNo',
          label: 'Issued No'
        },
        {
          key: 'issuedDate',
          label: 'Issued Date'
        },
        {
          key: 'publishedBy',
          label: 'Published By'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'letters',
        label: 'Letters/Circular',
        moduleId: '4',
        dynamicForm: {
          title: [''],
          issuedDate: [''],
          fileList: [''],
          issuedBy: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'issuedDate',
          label: 'Date'
        },
        {
          key: 'issuedBy',
          label: 'Issued By'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'Tenders',
        label: 'Procurement',
        moduleId: '5',
        dynamicForm: {
          title: [''],
          corrigendum: [''],
          dueDateExtended: [''],
          autoExpiry: [''],
          lastDateSubmission: [''],
          issuedDate: [''],
          fileList: [''],
          referenceNo: [''],
          description: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'corrigendum',
          label: 'Corrigendum'
        },
        {
          key: 'dueDateExtended',
          label: 'Due Date Extended'
        },
        {
          key: 'autoExpiry',
          label: 'Auto Expiry'
        },
        {
          key: 'lastDateSubmission',
          label: 'Last Date of Submission'
        },
        {
          key: 'issuedDate',
          label: 'Date Of Issue'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'referenceNo',
          label: 'Reference No'

        },
        {
          key: 'description',
          label: 'Number of Days Extended'

        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'Events',
        label: 'Events',
        moduleId: '6',
        dynamicForm: {
          title: [''],
          // publishedDate: [''],
          location: [''],
          fileList: [''],
          startDate: [''],
          endDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        // {
        //   key: 'publishedDate',
        //   label: 'Date'
        // },
        {
          key: 'location',
          label: 'Location'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'startDate',
          label: 'Start Date',
        },
        {
          key: 'endDate',
          label: 'End Date',
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'Media/News',
        label: 'Media/News',
        moduleId: '7',
        dynamicForm: {
          title: [''],
          publishedDate: [''],
          link: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'publishedDate',
          label: 'Date'
        },
        {
          key: 'link',
          label: 'Link'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      }, {
        value: 'Careers',
        label: 'Careers',
        moduleId: '12',
        dynamicForm: {
          title: [''],
          vacancyUploadedDate: [''],
          lastDateSubmission: [''],
          fileList: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'vacancyUploadedDate',
          label: 'Vacancy Uploaded Date'
        },
        {
          key: 'lastDateSubmission',
          label: 'Last Date Of Submission'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }
        ]
      },
      {
        value: 'Highlights',
        label: 'Highlights',
        moduleId: '28',
        dynamicForm: {
          title: [''],
          autoExpiry: [''],
          link: [''],
          // ranking:[''],
          fileList: [],
          keyword: ['']
        },
        tableHeader: [
          {
            key: 'title',
            label: 'Title'
          },
          {
            key: 'autoExpiry',
            label: 'Auto Expiry'
          },
          // {
          //   key:'ranking',
          //   label: 'Ranking'
          // },
          {
            key: 'link',
            label: 'Link'
          },
          {
            key: 'fileList',
            label: 'File'
          },
          {
            key: 'keyword',
            label: 'Keyword'
          }
        ]
      },
      {
        value: 'Success Stories',
        label: 'Success Stories',
        moduleId: '78',
        dynamicForm: {
          title: [''],
          author: [''],
          fileList: [''],
          source: [''],
          publishedDate: [''],
          keyword: ['']
        },
        tableHeader: [{
          key: 'title',
          label: 'Title'
        },
        {
          key: 'author',
          label: 'Author'
        },
        {
          key: 'publishedDate',
          label: 'Published Date'
        },
        {
          key: 'fileList',
          label: 'File'
        },
        {
          key: 'source',
          label: 'Source'
        },
        {
          key: 'keyword',
          label: 'Keyword'
        }]
      }
    ]
  }];
  moduleData: any;
  buttonDisable: boolean = false;
  flag:any=0;

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
    })
    let selectData: any = { target: { value: 'core-document' } }
    this.onSelectionChange(selectData)
    // this.getMasterData()
    let userLevelId:any = sessionStorage.getItem('levelId')
    if(Number(userLevelId) === 1) {
      this.getkmsCount();
    }
    if (sessionStorage.getItem("permissionModules")) {
      this.permissionObject = sessionStorage.getItem("permissionModules");
      this.permissionObject = JSON.parse(this.permissionObject);
      console.log("permissionObject", this.permissionObject);
    }
    else {
      this.permissionObject = [
        {
          "title": "Content",
          "permission": {
            "/aajeevika/admincontent/list": true,
            "/aajeevika/admincontent/save": true,
            "/aajeevika/admincontent/update": true,
            "/aajeevika/admincontent/delete": true,
            "/aajeevika/content/changeApprovedStatus": true,
            "reject": true
          },
          "key": "content"
        },
        {
          "title": "FAQ",
          "permission": {
            "/aajeevika/faq/save": true,
            "/aajeevika/faq/faqlist": true,
            "/aajeevika/faq/update": true,
            "/aajeevika/faq/delete": true
          },
          "key": "faq"
        },
        {
          "title": "Website Editor",
          "permission": {
            "/aajeevika/extracontent/save": true,
            "/aajeevika/content/extralist": true,
            "/aajeevika/extracontent/update": true,
            "/aajeevika/extracontent/delete": true
          },
          "key": "editor"
        }
      ]
    }
  }

  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }
  


  onSelectionChange(event: any) {
    this.page = 1;

    //     moduleId: "11"
    // subModuleId: "15"


    this.catageoryOption.forEach((item: any) => {
      let tableHead: any = item.options.find((option: any) => option.value == event.target.value);

      if (tableHead && tableHead.tableHeader) {
        console.log("events", tableHead, event)
        this.moduleData = tableHead;
        this.dynamicForm = tableHead.dynamicForm;
        this.tableHeader = tableHead.tableHeader;
        this.entryTitle = tableHead.label;
        this.selectedModuleOptions = tableHead;
        if (tableHead.moduleId == '11' && tableHead?.subModuleId == '15') {
          this.fileAcceptType = this.imgExtensions.toString();
        } else {
          this.fileAcceptType = ''
        }

      }
    })
    this.getMasterData();

    console.log("gggg",event.target.value, event, this.catageoryOption, this.tableHeader);
    // this.router.navigate(['/admin/'+event.target.value]);

  }
  submitData(event: any) {
    console.log("event", event)
    let type = 'save';
    let fileInput = event.file;
    let id: number = 0;
    const formdata: any = new FormData();
    if (fileInput)
      for (let i = 0; i < fileInput.length; i++) {
        formdata.append("files", fileInput[i], fileInput[i].name);
      }

    for (let key in event.form) {
      console.log("keys", key)
      if (event.form[key] && key != 'fileList')
        formdata.append(key, event.form[key])
    }

    if (event.contentId) {
      // formdata.append("contentId", event.contentId)
      id = event.contentId;
      type = 'update';
    }
    formdata.append("moduleId", this.selectedModuleOptions.moduleId)
    // if(!this.selectedModuleOptions.subModuleId)
    // this.selectedModuleOptions.subModuleId = '1';
    if (this.selectedModuleOptions.subModuleId)
      formdata.append("subModuleId", this.selectedModuleOptions.subModuleId)
    console.log("keys", event, formdata)
    this.buttonDisable = true;
    this.contentService.saveContent(formdata, type, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        this.buttonDisable = false;
      swal({
        title: "Success",
        text: "Data submitted successfully",
        icon: "success",
        // dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getMasterData();
          }
        }
        )
    },
      (error) => {
        this.buttonDisable = false;
        console.log("edit cutoff error", error);
        if(error.status !== 403) {
          swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
       }
        // swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
      });

  }

  getMasterData() {
    
    console.log(this.selectedModule);
    let params = `moduleId=${this.selectedModuleOptions.moduleId}&page=${this.page}`;
    if (this.selectedModuleOptions.subModuleId)
      params += `&subModuleId=${this.selectedModuleOptions.subModuleId}`;
    this.contentService.getAdminContent('list', params).subscribe((result: any) => {
      console.log("results", result);
      this.masterData = result.wrapperList;
      this.totalCount = result?.totalcount

      console.log('masterData', this.masterData)
    },
      (err) => {
        console.log("Routing Error ", err)
      }
    )
  }

  editData(rowData: any, isEdit: any = true) {
    console.log("record", rowData, isEdit)
    this.labelKeys = {}
    this.tableHeader.forEach((item: any) => this.labelKeys[item.key] = item.label)
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    this.isEntry = true;
    this.submitButton = 'Update';
    this.dynamicForm = this.selectedModuleOptions.dynamicForm;
    this.isChecked = false;
  }

  setApprove(event: any, rowData: any) {
    rowData.checked = !rowData.checked;
    console.log("rowdata", rowData, event)
    if (this.masterData.find((item: any) => item.checked)) {
      this.isChecked = true;
    } else {
      this.isChecked = false;
    }

  }

  approveReject(isApproved: any = 1) {
    let id = {};
    let approvedText = isApproved == 1 ? 'Approved' : 'Rejected';
    let filterId = this.masterData.filter((item: any) => item.checked);
    id = filterId ? { id: filterId.map((item: any) => item.id) } : {};
    this.contentService.changeAprrove(isApproved, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data ${approvedText} successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.masterData = this.masterData.map((item: any) => ({ ...item, checked: false }));
              this.isChecked = false;
              this.getMasterData();
            }
          })

      console.log("result")
    },
      (error) => {
        console.log("edit cutoff error", error);

        swal("Error", `Some error occured`, "error");
      })
  }
  
  pageEvent(event: any) {
    this.page = event;
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getMasterData();
  }

  deleteContent(record: any) {
    this.contentService.deleteAdminContent('admincontent', `id=${record.id}`).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data Deleted successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.getMasterData();
            }
          })
    })
  }
  openDeleteDialog(data: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteContent(data)
      }
    });
  }
  resetFileType() {
    this.fileAcceptType = ''
    this.isEntry = false;
    console.log(this.selectedModuleOptions)
    console.log('masterData', this.masterData)
  }
  openDialog(fileList: any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent, {
      data: {
        fileList: fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  addCore() {
    this.labelKeys = {}
    this.tableHeader.forEach((item: any) => this.labelKeys[item.key] = item.label)
    this.isEntry = true;
    this.isViewMode = false;
    this.submitButton = 'Submit';
    this.rowData = {};
    this.isChecked = false;
  }

  checkAvailablitiy(endPoint: string) {
    let isAvail = false;
    this.permissionObject.find((item: any) => {
      if (item.endPoint == endPoint) {
        isAvail = true;
      }
    })
    return isAvail;
  }
}

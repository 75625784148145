<!-- <div class="container">
  <div class="row my-3 bg-white shadow" *ngFor="let item of dashBoardCards">
    <h5 class="text-center mt-2">{{item.cardTitle}}</h5>
    <div class="col-md-4 my-2" *ngFor="let data of item.cards" >
      <div class="card border border-2 h-100">
          <div class="card-body align-items-center" >
            <h3 class="card-title text-center">{{data.value}}</h3>
            <p class="card-text text-center">{{data.detail}}</p>
        </div>
    </div>
    </div>  
  </div>
</div> -->
<div class="container">
  <iframe src="https://nrlm.gov.in/dashboardForOuter.do?methodName=dashboard" scrolling="no" style="height: 1900px; border: 0px none; width: 100%; margin-top: -0px; "></iframe>
</div>
import { ChangeDetectionStrategy, SimpleChanges, OnChanges, Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import * as $ from 'jquery';
import {Moment} from 'moment';
import 'fullcalendar';
import { MatDialogRef } from '@angular/material/dialog';

export type Appointment = {
  id?: string;
  start?: Date;
  end?: Date;
  title?: string;
  allDay?: boolean;
  contentId?: number;
}

@Component({
  selector: 'app-calendar',
  styleUrls: ['./calendar.component.scss'],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() viewModes = ['month'];
  @Input() navButtons = ['prev', 'next', 'today'];
  @Input() appointments: Appointment[] = [];
  @Output() requestNewAppointment = new EventEmitter<Appointment>();
  @Output() requestUpdateAppointment = new EventEmitter<any>();
  @Output() appointmentUpdated = new EventEmitter<Appointment>();
  @ViewChild('calendar') calendar: ElementRef | any;
  constructor(public dialogRef: MatDialogRef<CalendarComponent>) { }

  get $Instance(): any {
    if(this.calendar)
    return $(this.calendar.nativeElement);
  }

  ngOnDestroy(): void {
    this.$Instance.fullCalendar('destroy');
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.appointments && simpleChanges.appointments.currentValue) {
      if(this.$Instance)
      this.updateAppointments();
    }
  }

  ngAfterViewInit(): void {
    if(this.$Instance)
    this.$Instance.fullCalendar({
      selectable: true,
      editable: true,
      eventSources: [{
        events: this.appointments || [],
      }],
      header: {
        left: this.navButtons.join(','),
        center: 'title',
        right: this.viewModes.join(',')
      },
      navLinks: true,
      eventLimit: true,
      select: (start: Moment, end: Moment) => {
        this.requestNewAppointment.emit(this.neutralize({ start: start.toDate(), end: end.toDate() }));
      },
      eventClick: (event: Appointment, e:any) => {
        console.log("events",e)
        let eventDetails = {...this.neutralize(event),...e}
        this.requestUpdateAppointment.emit(eventDetails);
      },
      eventDrop: (event: Appointment, delta:any, revert:any) => {
        this.appointmentUpdated.emit(this.neutralize(event));
      }
    });
  }
  
  closeIcon(){
    this.dialogRef.close();
  }

  private updateAppointments(): void {
    // we have to do it this way, because other wise the plugin is dependent on the 
    // reference of the event source. So we have to remove all event sources and add a new one
    this.$Instance.fullCalendar('removeEventSources', this.$Instance.fullCalendar('getEventSources'));
    this.$Instance.fullCalendar('addEventSource', { events: this.appointments });
  }

  private neutralize(event: Appointment): Appointment {
    // the widget mutates the appointment in many ways. We can keep it consistent with this function
    const { start, end, allDay, title, id, contentId } = event;
    return { start, end, allDay, title, id, contentId};
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
const swal: SweetAlert = require("sweetalert");

interface dynamicFormInterface {
  userLevel: string[],
  name: string[]
}

interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,

}

@Component({
  selector: 'app-user-policy',
  templateUrl: './user-policy.component.html',
  styleUrls: ['./user-policy.component.scss']
})
export class UserPolicyComponent implements OnInit {
  page = 1;
  entryTitle: string = 'User Level';
  totalCount: Number = 0;
  buttonDisable: boolean = false;
  isEntry: boolean = false;
  masterData: any = []
  isViewMode: boolean = false;
  rowData: userListInterface = {};
  flag:any=0;

  dynamicForm: dynamicFormInterface = {
    userLevel: [''],
    name: ['']
  }
  submitButton: string = 'Submit'
  tableHeader: { key: string, label: string }[] = [
    {
      key: 'userLevel',
      label: 'User Level',
    },

  ];
  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
      this.entryTitle = 'User Level'
    })
    this.getUserlevel()
    let userLevelId:any = sessionStorage.getItem('levelId')
    if(Number(userLevelId) === 1) {
      this.getkmsCount();
    }
  }
  pageEvent(event: any) {
    this.page = event;
    this.getUserlevel();
  }

  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }

  getUserlevel() {
    this.contentService
      .getUserLevel(`page=${this.page}`)
      .subscribe((result: any) => {
        this.masterData = result.wrapperList
      })
  }

  goBack() {
    this.entryTitle = "User levels"

    this.isEntry = false;
  }
  addCore(data = '') {
    this.entryTitle = "Create User level"
    this.isEntry = true;
    this.isViewMode = false;
    this.submitButton = 'Submit';
    this.rowData = {};
  }
  submitData(event: any) {
    console.log("Submit Data", event)
    let type = 'save';
    let perMod = event.permissionModules;
    let obj: any = {}
    let permit:any= [];
    perMod.forEach((item:any) => {
   
    for(let i =0; i<item.permission.length; i++){
        let title = item.permission[i].endpoint.split('/');
        title = item.title + title[title.length-1]
     let entryPermission = {title: title, shortKey: title, key: title, permissions: {[item.permission[i].endpoint] : item.permission[i].value}};
        permit.push(entryPermission)
    }
})
    // var perMods = perMod.map(function (obj: any) {
    //   const permissionObj:any = obj.permission
    //   console.log('Permission object ', permissionObj)
    //   let permissionArray:any = []
    //   for (let i = 0; i < permissionObj.length; i++) {  
    //     const curr = permissionObj[i]
    //     console.log('current permission is ', curr)
    //     const currentPermission = {
    //       [curr.endpoint]: curr.value
    //     }
    //     Object.assign(permissionArray, currentPermission)
    //     // permissionArray.push(currentPermission)
    //   }
    //   permissionArray = permissionObj.map((item:any) => ({[item.endpoint]: item.value}))
    //   console.log('PermissionArray', permissionArray)
    //   return {
    //     title: obj.title,
    //     shortKey: obj.key,
    //     permissions: {
    //       ...permissionArray
    //     }
    //   };
    // });
    console.log("submitPermods", permit);
    obj["name"] = event.form.name
    obj["permissionModules"] = permit;
    let values = obj
    console.log("Values ", values)
    let id = event.id ? event.id : 0;
    const formdata: any = new FormData();
    this.buttonDisable = true;
    console.log(this.submitButton)

    //remove short key
    // if(this.submitButton != 'Submit'){
    //   values?.permissionModules.forEach((item:any)=>{
    //     delete item.shortKey
    //   })
    // }
    this.contentService.saveUserLevel(values, id).subscribe((data: any) => {
      console.log("Submitted value", values);
     
      if (data.responseCode == '200')
        this.buttonDisable = false;
      swal({
        title: "Success",
        text: "Data submitted successfully",
        icon: "success",
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getUserlevel();
          }
        }
        )
    },
      (error) => {
        this.buttonDisable = false;
        console.log("edit cutoff error", error);
        if(error.status !== 403) {
          swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
       }
        // swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
      });

  }
  editData(rowData: any, isEdit: any = true) {
    this.entryTitle = "Edit User Level"
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    console.log(this.rowData);
    this.isEntry = true;
    this.submitButton = 'Update';
  }

  deleteFaq(record: any) {
    this.contentService.deleteUserLevelContent(`id=${record.id}`).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data Deleted successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.getUserlevel();
            }
          })
    })
  }

  openDeleteDialog(data: any) {
    console.log("Delete", data);
    if (data.id == 1) {
      console.log("Don't Delete");
      swal({
        title: "Alert",
        text: "You can't delete this entry!",
        icon: "error",
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getUserlevel();
          }
        }
        )
    }
    else {
      const dialogRef = this.dialog.open(DeleteDialogComponent);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.deleteFaq(data)
        }
      });
    }

  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ContentService } from '../services/content.service';

@Component({
  selector: 'app-common-filters',
  templateUrl: './common-filters.component.html',
  styleUrls: ['./common-filters.component.scss']
})
export class CommonFiltersComponent implements OnInit {



  @Output() searchParams:EventEmitter<any> = new EventEmitter();
  @Input() moduleId:any
  @Input() filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:false,
    to:false,
    fileType:false
  }
  fileTypes: any;
  sourceList: any;
  authorList: any;
  constructor(private fb: FormBuilder,private contentService:ContentService) { }
  filters = this.fb.group({
    globalSearch: '',
    author: '',
    source: '',
    publishedDateFrom:'',
    publishedDateTo:'',
    fileType: ''
  });
  ngOnInit(): void {
    // this.getAuthors();
    // this.getSources();
    // this.getFileType();
  }
  clearSearchFilters(stopEmit:boolean = false){
    this.filters.patchValue({
      globalSearch: '',
      author: '',
      source: '',
      publishedDateFrom:'',
      publishedDateTo:'',
      fileType: ''
    });
    if(stopEmit){
      return ;
    }
    this.searchParams.emit(this.filters.value)
  }

  emitSearchParams(){
    console.log(this.filters.value);
    this.searchParams.emit(this.filters.value)
  }
  // http://_BASEURL_/aajeevika/content/getDropDown
  // keyName=author&moduleId=1
  // getAuthors(){
  //   this.contentService.getContent('getDropDown',`keyName=author&moduleId=${this.moduleId}`).subscribe((res:any)=>{
  //     this.authorList = res && res['wrapperList'];
  //   },error=>{
  //     console.log(error)
  //   })
  // }
  // getSources(){
  //   this.contentService.getContent('getDropDown',`keyName=source&moduleId=${this.moduleId}`).subscribe((res:any)=>{
  //     this.sourceList = res && res['wrapperList'];

  //     },error=>{
  //       console.log(error)
  //     })
  // }
  // getFileType(){
  //   this.contentService.getContent('getfiletype').subscribe((res:any)=>{
  //     this.fileTypes = res && res['wrapperList'];
  //     },error=>{
  //       console.log(error)
  //     })
  // }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
import { CommonFiltersComponent } from 'src/app/shared/common-filters/common-filters.component';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  @ViewChild(CommonFiltersComponent) filters:CommonFiltersComponent | undefined;

  dataList:any=[];
  page = 1;
  subModules:any;
  totalCount = 0;
  subModuleName = '';
  subModuleData:any;
  currentSubModuleId = '';
  currentModule = '';
  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }
  moduleId=27;
  params:any;
  resourceSubModules = [
    {
      subModuleName:'Case-studies',
      _id:31
    },
    {
      subModuleName:'Manuals',
      _id:32
    },
    {
      subModuleName:'Guidelines',
      _id:36
    },
    // {
    //   subModuleName:'Press Releases',
    //   _id:33
    // },
    // {
    //   subModuleName:'Publications',
    //   _id:34
    // },
    {
      subModuleName:'Annual Reports',
      _id:35
    },
    {
      subModuleName:'Evaluation and Study Reports',
      _id:37
    }
  ]
  constructor(private contentService :ContentService,private router:ActivatedRoute,private route:Router,private dialog:MatDialog) {
    this.router.queryParams.subscribe(res=>{
      this.currentModule = res.subModule;
      // if(this.currentSubModuleId==res._id){
      // }
      this.getSubModuleData(res._id);

    })
  }

  ngOnInit(): void {

  }

  getSubModuleData(id:any){
    if(this.currentSubModuleId!=id){
      this.page=1;
    }
    this.currentSubModuleId = id;
    this.contentService.getContent("list", `moduleId=${this.moduleId}&subModuleId=${id}&page=${this.page}&${this.params}`,).subscribe((res:any)=>{
      if(res){
        this.totalCount = res.totalcount;
        this.dataList = res && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }
  getToResourceSubModule(data:any){
    this.filters?.clearSearchFilters(true)
    this.params = ""
    this.route.navigate(
      ['/resource-center'],
      { queryParams: { 'subModule': data.subModuleName, '_id': data._id } }
    );
  }
  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getSubModuleData(this.currentSubModuleId)
  }
  getTotalDownload(fileList:any){
    let total = 0;
    for (var i in fileList) {
      total += fileList[i].downloadCount;
    }
    return total

  }
  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  pageEvent(event:any){
    this.page = event;
    // this.getNewsLetter();
    this.getSubModuleData(this.currentSubModuleId)
  }

}

<div class="container">
    <div class="row my-3">
        <div class="col-sm-9">
          <div class="bg-white rounded-3 shadow">
            <ul class="nav align-items-center">
              <li class="nav-item">
                <a href="/" class="nav-link ">Home</a>
              </li>
              <li class="">/</li>
              <li class="nav-item">
                <a href="/grievances" class="nav-link ">Grievances</a>
              </li>
            </ul>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9 mb-3">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <div class="row ">
                        <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId"
                            (searchParams)="searchParams($event)">
                        </app-common-filters>
                    </div>
                    <div>
                        <ng-container>
                            <div class="card rounded shadow-sm border-0 mt-3" *ngFor="let item of dataList">
                                <div class="card-body ">
                                <div class="row align-items-center">
                                    <div class="col">
                                    <h6 class=" mb-2">{{item?.title}}</h6>
                                    <div class="text-muted small pipes">
                                        <span class="" *ngIf="item?.issuedDate">
                                        {{item?.issuedDate | date: 'mediumDate'}}
                                        </span>
                                        <span class="" *ngIf="item?.source">
                                        Source: {{item?.source}}
                                        </span>
                                        <span class="" *ngIf="item?.author">
                                        Author: {{item?.author}}
                                        </span>
                                    </div>
                                    </div>
                                    <div class="col-lg-auto" *ngIf="item?.fileList">
                                    {{getTotalDownload(item.fileList)}} Downloads
                                    <button (click)="openDialog(item.fileList)" class="btn btn-link"><img
                                        src="/assets/images/download.svg"></button>
                                    </div>
                                </div>
                
                                </div>
                            </div>
                        </ng-container>
                        <div class="row">
                        <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input, SimpleChange, OnChanges, SimpleChanges, ElementRef, Renderer2, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators,  } from '@angular/forms';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { DatePipe} from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Swal from 'sweetalert2';
import { ViewportScroller } from '@angular/common';
const swal: SweetAlert = require("sweetalert");
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
// import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-job-portal-form',
  templateUrl: './job-portal-form.component.html',
  styleUrls: ['./job-portal-form.component.scss']
})
export class JobPortalFormComponent implements OnInit, OnChanges {
  basicInformationFormData: any = ''
  computerProficiencyFormData: any;
  candidatureFormData:any;
  languageDataValidation: boolean = false
  computerProficiencyFormValidation: boolean = false
  userCvValidation: boolean = false
  experienceFormDataValidation: boolean = false
  commonTableDataFormValidation: boolean = false
  basicInformationFormValidation: boolean = false
  presentJobStatus: boolean = false
  experienceStartDate: string = ''
  experienceEndDate: string = ''
  currentExpDate: any;
  permanentAddressField: boolean = false
  othersLanguageStatus: boolean = false
  selectedLanguage: any = ''
  experienceEndDateJob: string = ''
  experienceStartDateJob: string = ''
  experienceFormValidation: boolean = false
  currentSalaryValidation: boolean = false
  lastJobStartDate: string = ''
  currentJobStartDate: string = ''
  editCurrentExperienceStartDate: any = ''
  editCurrentExperienceEndDate: string = ''
  currentJobEndDate: string = ''
  editJobStartDate: string = ''
  editJobEndDate: string = ''
  editLastJobStartDate: any = ''
  lastJobEditStartDate: any =''
  candidatureFormValidation: boolean = false
  @HostListener('window:beforeunload', ['$event'])
  handleBeforeUnload(event: any): void {
    // This function will be called when the user tries to refresh the page
    // You can perform any necessary actions here
    // For example, showing a confirmation message or saving data
    const confirmationMessage = 'You have unsaved changes. Do you really want to leave?';
    event.returnValue = confirmationMessage;
  }
  // @HostListener('window:beforeunload', ['$event'])
  // handleBeforeUnload(event: any) {
  //   // Perform any actions you need before the page reloads
  //   // For example, saving user data or notifying the user
  //   // This message won't be displayed in modern browsers, but you can use it for legacy ones
  //   event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  // }
  educationQualificationFormData: any = []
  commonTableData: any = []
  getListOfLanguageData: any = []
  languageData: any = []
  getRefrencesListOfData: any = []
  refrenceData: any = []
  fileBlob: any;
  b64Blob: any;
  educationQualificationFormViewMode: boolean = false
  languageFormViewMode: boolean = false
  getParticularEducationRowData: any;
  educationQualificationButtonName: any = 'save'
  getParticularLanguageRowData: any;
  languageFormButtonName: any = 'save';
  experienceFormButtonName: any = 'save'
  getParticularRefrenceRowData: any;
  refrenceFormButtonName: any = 'save'
  refrenceFormViewMode: any = false;
  finalJobPortalObject: any = {};
  userCv: any = '';
  @ViewChild('educationCertificateInput') educationCertificateInput: any;
  @Input() jobPostingRowDetails:any = [];
  @Input() applicationActive:boolean = false;
  @Output() jobPortalFormSuccessfully:any = new EventEmitter();
  @Output() backInstructionPage: any = new EventEmitter()
  @Output() backToPrevious: any = new EventEmitter()
  @Input() viewApplicationForm: boolean = false;
  jobNotificationDetails: any;
  getTheFilterOutPositionDetails: any;
  eligibleValidation: boolean = false
  applicantYears: any;
  imageSrc: any = ''
  certificateUrl: any = ''
  certificateFiles: any = ''
  getListOfExperienceData: any = []
  experienceFormData: any = []
  currentDate: string = ''
  eligibleForPositionBasedOnExpeirnce: any;
  eligiblePositionBasedOnExp: boolean = false
  fileNameStatus: any;
  applicantFormData: any;
  resume: any;
  applicableExpOfJob: any;
  applicableExpOfJobInMonth: any;
  checkEligibleBasedOnExp: boolean = false
  getParticularExperienceData: any;
  viewResume: any;
  appliedGrade: any[] = []
  selectPosition: boolean = false
  particularPositionDetails: any = []
  viewModeForm: boolean = false;
  genderDropdown:any = [
    {name: 'Male', value: 'male'},
    {name: 'Female', value: 'female'},
    {name: 'Transgender', value: 'transgender'}
  ]

  proficiencyDropdown:any = [
    {name: 'Beginner', value: 'beginner'},
    {name: 'Intermediate', value: 'intermediate'},
    {name: 'Professional', value: 'professional'}
  ]

  computerProficiencyDropdown:any = [
    {name: 'Beginner', value: 'beginner'},
    {name: 'Moderate', value: 'moderate'},
    {name: 'Expert', value: 'expert'}
  ]

  relationDropdown:any = [
    {name: 'Personal', value: 'personal'},
    {name: 'Professional', value: 'professional'}
  ]

  constructor(private formBuilder: FormBuilder,private scroll: ViewportScroller,
    private contentService: ContentService, private datePipe: DatePipe, private sanitizer: DomSanitizer, private el: ElementRef, private renderer: Renderer2, private router: Router) { 
    console.log("jobPostingRowDetails",this.jobPostingRowDetails);    
    
  }

  isViewMode: any = false;
  fileInput: any;
  fileAcceptType:any=[]

  basicInformationForm: FormGroup = this.formBuilder.group({
    appliedPosition: ['', Validators.required],
    name: ['', Validators.required],
    fatherName: ['', Validators.required],
    gender: ['', Validators.required],
    dob: ['', Validators.required],
    permanentAddress: ['', Validators.required],
    correspondenceAddress: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email, Validators.pattern("^[A-Za-z0-9+_.-]+@(.+.com)$")])],
    mobileNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
  })

  educationQualificationForm: FormGroup = this.formBuilder.group({
    degree: ['', Validators.required],
    passingYear: ['', Validators.required],
    institute: ['', Validators.required],
    boardUniversity: ['', Validators.required],
    specialization: ['', Validators.required],
    percentageGrading: ['', Validators.required],
    certificateFile: ['', Validators.required],
    certificateUrl: ['', Validators.required]
  })

  experienceForm: FormGroup = this.formBuilder.group({
    organizationName: ['', Validators.required],
    designation: ['', Validators.required],
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    experienceInMonth: ['', Validators.required],
    responsibilityAssignment: ['', Validators.required],
    achievements: ['']
  })

  experienceFormSalary: FormGroup = this.formBuilder.group({
    last_salary: ['', Validators.required]
  })

  languageForm: FormGroup = this.formBuilder.group({
    language: ['', Validators.required],
    read: ['', Validators.required],
    write: ['', Validators.required],
    understand: ['', Validators.required]
  })

  computerProficiencyForm: FormGroup = this.formBuilder.group({
    computerProficiency: ['', Validators.required],
  })

  candidatureForm: FormGroup = this.formBuilder.group({
    justificationCandidature: ['', Validators.required]
  })
  refrenceForm: FormGroup = this.formBuilder.group({
    referenceName: ['', Validators.required],
    referenceRelation: ['', Validators.required],
    referenceAddress: ['', Validators.required],
    referenceMobile: ['',[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"), Validators.required]],
    referenceEmail: ['', Validators.compose([Validators.email, Validators.pattern("^[A-Za-z0-9+_.-]+@(.+.com)$"), Validators.required])]
  })

  languageOptions = [
    {
      value: 'English',
      name: 'English'
    },
    {
      value: 'Hindi',
      name: 'Hindi'
    },
    {
      value: '',
      name: 'Others'
    }
  ]

  educationOptions = [
    {
      value: '10',
      name: '10th'
    },
    {
      value: '12',
      name: '12th'
    },
    {
      value: 'Diploma',
      name: 'Diploma'
    },
    {
      value: 'Graduation',
      name: 'Graduation'
    },
    {
      value: 'Post Graduation',
      name: 'Post Graduation'
    },
    {
      value: 'PHD',
      name: 'PHD'
    }
  ]

  copyEducationOptions = [...this.educationOptions]

  copyLanguageOptions = [...this.languageOptions]


  educationQualificationTableHeader:any = [
    {
      key: 'degree',
      label: 'Degree'
    },
    {
      key: 'passingYear',
      label: 'Year of Passing'
    },
    {
      key: 'institute',
      label: 'Institute'
    },
    {
      key: 'boardUniversity',
      label: 'Board/University'
    },
    {
      key: 'specialization',
      label: 'Specialization'
    },
    {
      key: 'percentageGrading',
      label: 'Percentage/Grades'
    },
    {
      key: 'certificateUrl',
      label: 'Certificate'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]


  languageFormHeader:any = [
    {
      key: 'language',
      label: 'Language'
    },
    {
      key: 'read',
      label: 'Read'
    },
    {
      key: 'write',
      label: 'Write'
    },
    {
      key: 'understand',
      label: 'Understand'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]


  refrenceFormHeader:any = [
    {
      key: 'referenceName',
      label: 'Name'
    },
    {
      key: 'referenceRelation',
      label: 'Relation'
    },
    {
      key: 'referenceAddress',
      label: 'Address'
    },
    {
      key: 'referenceMobile',
      label: 'Mobile Number'
    },
    {
      key: 'referenceEmail',
      label: 'Mail'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]

  experienceFormHeader:any = [
    {
      key: 'organizationName',
      label: 'Organisation Name'
    },
    {
      key: 'designation',
      label: 'Designation'
    },
    {
      key: 'startDate',
      label: 'Start Date'
    },
    {
      key: 'endDate',
      label: 'End Date / As on'
    },
    // {
    //   key: 'experienceInMonth',
    //   label: 'In Months'
    // },
    {
      key: 'responsibilityAssignment',
      label: 'Responsibility'
    },
    {
      key: 'achievements',
      label: 'Achievements'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ]

  copyeducationQualificationTableHeader:any= [...this.educationQualificationTableHeader]
  copylanguageFormHeader:any = [...this.languageFormHeader]
  copyrefrenceFormHeader:any = [...this.refrenceFormHeader]
  copyexperienceFormHeader:any = [...this.experienceFormHeader]
  
  ngOnInit(): void {
    
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // if(this.applicationActive){
    //   this.prefieldValue(this.jobPostingRowDetails);
    // }
    // this.browserRefresh = browserRefresh;
    // console.log('refreshed?:', browserRefresh);
    let educationQualificationData:any = sessionStorage.getItem('educationQualification') ? sessionStorage.getItem('educationQualification') : []
    let languageData:any = sessionStorage.getItem('languageData') ? sessionStorage.getItem('languageData') : []
    let refrenceData:any = sessionStorage.getItem('refrenceData') ? sessionStorage.getItem('refrenceData') : []
    let experienceData:any = sessionStorage.getItem('experienceData') ? sessionStorage.getItem('experienceData') : []
    // this.experienceFormData = experienceData.length > 0 && JSON.parse(experienceData)
    this.jobNotificationDetails = sessionStorage.getItem('particularPositionDetails') ? sessionStorage.getItem('particularPositionDetails') : []
    this.jobNotificationDetails = this.jobNotificationDetails.length > 0 && JSON.parse(this.jobNotificationDetails)
    // this.commonTableData = sessionStorage.getItem('educationQualification') ? sessionStorage.getItem('educationQualification') : []
    // this.commonTableData  = this.commonTableData.length > 0 ? JSON.parse(this.commonTableData) : []
    // console.log("Job Notification Details ", this.jobNotificationDetails)
    this.getTheFilterOutPositionDetails = this.filterPositionDetails(this.jobNotificationDetails.criteria)
    // console.log("after filter position details ", this.getTheFilterOutPositionDetails)
    // console.log("language Data ", languageData)
    // this.languageData = JSON.parse(languageData)
    // this.refrenceData = JSON.parse(refrenceData)
    // console.log("Language Data ", this.languageData)
    // this.commonTableData = JSON.parse(educationQualificationData)
    // console.log("On Init ", this.commonTableData)
    this.educationQualificationForm.valueChanges.subscribe((value:any) => {
      console.log("Edu Data", value);
    })
    this.getCurrentDate();
  }

  ngOnChanges(changes:SimpleChanges){
    console.log("Changes ", changes)
    // Use this when getting data from Live API unless, call the function in ngOninit

    if(changes?.jobPostingRowDetails?.currentValue){
      this.prefieldValue(changes?.jobPostingRowDetails?.currentValue)
      this.filterAction()
    }

    if(changes.viewApplicationForm && changes.viewApplicationForm.currentValue) {
       console.log("Changes ", changes.viewApplicationForm, changes.viewApplicationForm.currentValue)
       this.viewApplicationForm = this.isViewMode = changes.viewApplicationForm.currentValue
      //  if(changes.viewApplicationForm.currentValue){
      //     this.filterAction()
      //  }
    }

    // if(changes.)
  }

  getPermanentAddress(event:any) {
    // console.log("Event Permanent Address ", event)
    if(event.target.checked) {
       this.permanentAddressField = true
       this.basicInformationForm?.get('correspondenceAddress')?.patchValue(this.basicInformationForm.value.permanentAddress)
    } else {
      this.permanentAddressField = false
      this.basicInformationForm?.get('correspondenceAddress')?.patchValue('')
    }
  }

  filterAction() {
    const actionsTobeRemovedFromHeader = ['educationQualificationTableHeader','languageFormHeader', 'refrenceFormHeader', 'experienceFormHeader', 'refrenceData', 'languageData', 'experienceFormData', 'commonTableData']
    const keyToRemove:any = 'action';
    actionsTobeRemovedFromHeader.forEach((item: string) => {
      (this as any)[item] = this.removeActionFromtheData((this as any)[item], keyToRemove)
    })
  }

  removeActionFromtheData(dataArray: any[], keyToRemove: string) {
    return dataArray.filter((data: any) => data.key !== keyToRemove);
  }

  addAction() {
    this.copyTablesHeader()
    const tableDateList = ['languageData', 'refrenceData', 'experienceFormData', 'commonTableData']

    tableDateList.forEach((item:any) => {
      (this as any)[item] = this.addActionToData((this as any)[item])
    })
  }

  copyTablesHeader() {
    const headersToCopy = [
      'educationQualificationTableHeader',
      'languageFormHeader',
      'refrenceFormHeader',
      'experienceFormHeader',
    ];

    headersToCopy.forEach(header => {
      (this as any)[header] = (this as any)['copy' + header];
    });
  }

  addActionToData = (data:any) => {
    data.forEach((item:any) => {
      item['action'] = { view: false, edit: true, delete: true };
    });
    return [...data];
  };

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  getCurrentDate() {
    var currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    var day = currentDate.getDate().toString().padStart(2, '0');
    var formattedDate = year + '-' + month + '-' + day;
    console.log("Formatted Date ", formattedDate)
    this.currentDate = formattedDate
    this.lastJobStartDate = this.currentDate
    this.experienceStartDate = this.currentDate
    console.log("Experience Form ", this.experienceForm)
  }

  filterPositionDetails(criteriaDetails:any) {
    let positionDetails:any = []
    criteriaDetails.forEach((item:any) => {
      let returnObj = this.createDynamicObject({'id': 'positionMasterId', 'name': 'positionName', 'maxAge': 'maxAge', 'minExp': 'minExp'}, item)
      positionDetails.push(returnObj)
    })

    return positionDetails
  }

  onFileChange(event:any) {

  }

  getEducationQualification() {
    console.log("Education Qualification Form ", this.educationQualificationForm, this.educationQualificationForm)
    // this.educationOptions = this.filterEducationOptions(this.educationQualificationForm.value)
    console.log("Edu Data ", this.educationQualificationFormData)
    this.educationQualificationFormData = [...this.educationQualificationFormData]
    this.educationQualificationFormData.push(this.educationQualificationForm.value)
    this.appliedGrade = [...this.appliedGrade]
    if(this.educationQualificationForm.value.degree === '10' || this.educationQualificationForm.value.degree === '12') {
      this.appliedGrade.push(this.educationQualificationForm.value.degree)
    }
    console.log("Edu Data ", this.educationQualificationFormData)
    let copyQualificationData:any = []
    this.educationQualificationFormData.forEach((item:any) => {
      copyQualificationData.push(item)
    })
    // this.setFileName(copyQualificationData)
    // this.datePipe.transform(new Date(item['passingYear']), 'MMMM, d'),
    this.educationQualificationFormData.forEach((item:any, index:any) => {
      return (
        item['passingYear'] = item['passingYear'],
        item['action'] = {view: false, edit: true, delete: true},
        item['id'] = index + 1
      )
    })

    console.log("Edu Data ", this.educationQualificationFormData)
    this.commonTableData = this.educationQualificationFormData
    this.commonTableDataFormValidation = false
    // this.resetEducationCertificate()
    // let degreeFileName:any = document.getElementById('degeeFileName')
    // degreeFileName.value = ''

    

    // this.educationOptions = this.updateEducationOption(this.educationQualificationFormData, this.educationOptions)
    this.educationQualificationForm.reset({
      'degree': '',
    })
    sessionStorage.setItem('educationQualification', JSON.stringify(this.educationQualificationFormData))
    // this.educationQualificationForm.reset()
  }

  resetEducationCertificate() {
    let degeeFileName:any = document.getElementById('degeeFileName')
    degeeFileName.value = ''
  }

  updateEducationOption(educationData:any, educationOptions:any) {
    console.log("options data ", educationData, educationOptions)
    for(let i = 0; i < educationData.length; i++) {
      for(let j = 0; j < educationOptions.length; j++) {
          console.log("language options ", educationOptions[j].value, educationData[i].degree)
          if(educationData[i].degree === educationOptions[j].value) {
            // debugger
            educationOptions.splice(j, 1)
          }
      }
    }

    return educationOptions
  }

  appOptionsInEducation(particularEducationData:any, copyEducationOptions:any) {
    console.log("Particular Language ", particularEducationData,copyEducationOptions)
    for(let i = 0; i < copyEducationOptions.length; i++) {
        if(copyEducationOptions[i].value == particularEducationData.degree) {
          let obj:any = {}
          obj['value'] = copyEducationOptions[i].value
          obj['name'] = copyEducationOptions[i].name
          console.log("lang Obj ", obj)
          this.languageOptions.push(obj)
          break
        }
    }

    console.log("Copy Education options ", copyEducationOptions)

    return copyEducationOptions

    // return this.languageOptions
  }

  setFileName(eduData:any) {
    console.log("Edu Data ", eduData)
    let fileNameStatus:any = []
    eduData.forEach((item:any) => {
      let obj:any = {}
      // obj[`${item.degree}.pdf`] = item.certificateFile
      obj['fileName'] = `${item.degree}`
      obj['file'] = item.certificateFile
      fileNameStatus.push(obj)
    })

    console.log("Edu Data ", eduData)
    console.log("File Name Status ", fileNameStatus)
    this.fileNameStatus = fileNameStatus

    // setTimeout(()=>{
    //   console.log('fileNameStatus', this.fileNameStatus)
    // }, 2000)
    sessionStorage.setItem('eduData', JSON.stringify(fileNameStatus))
  }

  getDocumentUrl(url:any) {
    let downloadUrl = URL.createObjectURL(url)
    return downloadUrl
  }

  getLanguageData() {
    this.getListOfLanguageData = [...this.getListOfLanguageData] 
    console.log("Updated Language Options ", this.languageOptions)
    this.getListOfLanguageData.push(this.languageForm.value)
    this.languageOptions = this.updateLanguageOption(this.getListOfLanguageData, this.languageOptions)
    this.getListOfLanguageData.forEach((item:any, index:any) => {
      return (
        item['action'] = {view: false, edit: true, delete: true},
        item['id'] = index + 1
      )
     })
     this.languageDataValidation = false
     sessionStorage.setItem('languageData', JSON.stringify(this.getListOfLanguageData))
     this.languageData = this.getListOfLanguageData
     this.languageForm.patchValue({
        'language': '',
        'read': '',
        'write': '',
        'understand': '',
     }) 

     this.selectedLanguage == '' ? this.othersLanguageStatus = false : ''
  }

  updateLanguageOption(languageData:any, languageOptions:any) {
    for(let i = 0; i < languageData.length; i++) {
        for(let j = 0; j < languageOptions.length; j++) {
            console.log("language options ", languageOptions[j].value, languageData[i].language)
            if(languageOptions[j].value !== 'Others') {
              if(languageData[i].language === languageOptions[j].value) {
                languageOptions.splice(j, 1)
              }
            }
        }
    }

    return languageOptions
  }

  // getExperienceData
  getExperienceData() {
    console.log("get Min Exp Data ", this.getParticularExperienceData)
    this.experienceEndDateJob = this.experienceStartDateJob
    this.experienceStartDateJob = ''
    this.experienceEndDate = this.experienceStartDate
    this.getListOfExperienceData = [...this.getListOfExperienceData]
    this.getListOfExperienceData.push(this.experienceForm.value)
    this.checkEligibleBasedOnExp = this.checkEligibleBasedOnExperience(this.getListOfExperienceData)
    this.getListOfExperienceData.forEach((item:any, index:any) => {
      item['action'] = {view: false, edit: true, delete: true},
      item['id'] = index + 1
    })
    sessionStorage.setItem('experienceData', JSON.stringify(this.getListOfExperienceData))
    this.experienceFormData = this.getListOfExperienceData;
    this.experienceForm.patchValue({
      'organizationName': '',
      'designation': '',
      'startDate': '',
      'endDate': '',
      'experienceInMonth': '',
      'responsibilityAssignment': '',
      'achievements': '',
    })

    this.lastJobStartDate = this.decreaseDateByOne(this.experienceStartDate)
    console.log("Experence Start Date ", this.experienceStartDate)

    let presentJobLabel = document.getElementById('presentJobLabel')
    let presentJobCheckbox = document.getElementById('presentJob')

    if(this.presentJobStatus && presentJobLabel && presentJobCheckbox) {
      presentJobLabel.style.display = 'none'
      presentJobCheckbox.style.display = 'none'
      this.presentJobStatus = false
    } 

    if(this.getListOfExperienceData.length === 1) {
       let experienceDetailsEndDate = this.getListOfExperienceData[0]
       if(experienceDetailsEndDate !== 'Continuing') {
          if(presentJobLabel && presentJobCheckbox) {
            presentJobLabel.style.display = 'none'
            presentJobCheckbox.style.display = 'none'
            this.presentJobStatus = false
          } 
       }
    }
    this.experienceFormDataValidation = false
    console.log("Exp form data ", this.experienceFormData)
  }

  decreaseDateByOne(date:any) {
    // Given date: 2021-01-01
    var givenDate = new Date(date);

    // Decrease the date by 1 day
    givenDate.setDate(givenDate.getDate() - 1);

    // Convert the date to a string
    var decreasedDate = givenDate.toISOString().split('T')[0];

    console.log(decreasedDate); // Output: 2020-12-31
    return decreasedDate

  }

  checkEligibleBasedOnExperience(expData:any) {
    console.log("Exp Data ", expData)
    let eligibleForPositionBasedOnExpeirnce = false
    let totalUserExperienceInMonth = 0
    expData.forEach((item:any) => {
      totalUserExperienceInMonth = totalUserExperienceInMonth + Number(item['experienceInMonth'])
    })

    console.log("Total User Exp ", totalUserExperienceInMonth, this.applicableExpOfJobInMonth)

    if(totalUserExperienceInMonth >= this.applicableExpOfJobInMonth) {
      eligibleForPositionBasedOnExpeirnce = true
    } else {
      eligibleForPositionBasedOnExpeirnce = false
    }

    return eligibleForPositionBasedOnExpeirnce
  }

  convertMonthsIntoYears(monthNumber:any) {
    var months = monthNumber;
    var years = Math.floor(months / 12);
    return years
  }

  // Get Experinece Form Data
  getExperienceFormData(event:any) {
    console.log("Event ", event)
    this.getParticularExperienceData = event.rowData
    if(event.status === 'delete') {
       this.deleteExperienceData()
    } else if(event.status == 'edit') {
      this.editJobStartDate = this.getParticularExperienceData.startDate
      this.editJobEndDate = this.getParticularExperienceData.endDate
      this.lastJobStartDate = ''
      this.currentJobStartDate = ''
      this.currentJobEndDate = ''
      this.experienceFormButtonName =  'edit'
      this.updateExperienceStartDate(event.rowData)
      let testObj = Object.assign({},event.rowData)
      delete testObj.action
      delete testObj.id
      this.experienceForm.setValue(testObj)
      this.checkCurrentJobStatus(event.rowData)
    }
  }


  checkCurrentJobStatus(rowData:any) {
    if(rowData.endDate == 'Continuing') {
      this.presentJobStatus = true
      let presentJobLabel = document.getElementById('presentJobLabel')
      let presentJobCheckbox = document.getElementById('presentJob')

      if(this.presentJobStatus && presentJobLabel && presentJobCheckbox) {
        presentJobLabel.style.display = 'block'
        presentJobCheckbox.style.display = 'block'
      } 
    }
  }

  updateExperienceStartDate(rowData:any) {
    console.log("Experience Form Data ", this.experienceFormData, rowData)
    this.currentExpDate = rowData.startDate
    let presentJobLabel = document.getElementById('presentJobLabel')
    let presentJobCheckbox = document.getElementById('presentJob')
    let previousExpData;
    let currentExpData

    if(rowData.id === 1) {
      this.editCurrentExperienceStartDate = rowData.startDate
      if(rowData.endDate === 'Continuing') {
        this.editCurrentExperienceEndDate = this.currentDate
        this.lastJobStartDate = ''
        this.editJobEndDate = this.currentDate
        this.presentJobStatus = true
        if(this.presentJobStatus && presentJobLabel && presentJobCheckbox) {
          presentJobLabel.style.display = 'block'
          presentJobCheckbox.style.display = 'block'
        } 
      } if(rowData.id === 1 && this.experienceFormData.length === 1) {
        this.editJobStartDate = ''
        this.lastJobEditStartDate = rowData.startDate
      } else {
        this.editCurrentExperienceEndDate = rowData.endDate
        this.lastJobStartDate = '' 
      }
    } else if(this.experienceFormData[this.experienceFormData.length - 1].id === rowData.id) {
      // console.log("Last Experience Form Data ", rowData)
      this.editJobStartDate = ''
      this.lastJobEditStartDate = rowData.startDate
    }


    // if(rowData.endDate === "Continuing") {
    //   this.lastJobStartDate = ''
    // }


    // if(rowData.id > 1 && this.experienceFormData.length >= 3 && rowData.id !== this.experienceFormData[this.experienceFormData.length - 1].id) {
    //    previousExpData = this.experienceFormData.filter((data:any) => data.id === rowData.id-1)
    //    currentExpData = this.experienceFormData.filter((data:any) => data.id === rowData.id)
    // }

    // if(previousExpData) {
    //   this.experienceStartDate = previousExpData[0].startDate
    // } if (currentExpData) {
    //   this.experienceEndDate = currentExpData[0].endDate
    // }

    // console.log("Experience Start Date ", this.experienceStartDate, this.experienceEndDate)
  }



  deleteExperienceData() {
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this Experience ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
          // let experienceData:any = sessionStorage.getItem('experienceData') ? sessionStorage.getItem('experienceData') : []
        let experienceData:any = [...this.experienceFormData]
        let particularExperienceData = {...this.getParticularExperienceData}
        console.log("Check checkEligibleBasedOnExp ", this.checkEligibleBasedOnExp)
        console.log("Experience Data ", experienceData, particularExperienceData)

        let indexValue = experienceData.findIndex((el:any) => {
          return el.id === particularExperienceData.id
        })

        experienceData.splice(indexValue, 1)

        swal({
          title: "Success",
          text: "Data Delete successfully",
          icon: "success",
        })
        this.experienceFormData = experienceData
        this.getListOfExperienceData = experienceData
        this.checkEligibleBasedOnExp = this.checkEligibleBasedOnExperience(this.experienceFormData)
        sessionStorage.setItem('experienceData', JSON.stringify(this.experienceFormData))
        this.experienceForm.patchValue({
          'organizationName': '',
          'designation': '',
          'startDate': '',
          'endDate': '',
          'experienceInMonth': '',
          'responsibilityAssignment': '',
          'achievements': '',
        })
        this.experienceFormButtonName  = 'save'
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })

    this.experienceFormData.length > 0 ? this.experienceFormDataValidation = false : this.experienceFormDataValidation = true
  }

  updateExpeienceData() {

    // Remove present Job Check box
    let presentJobLabel = document.getElementById('presentJobLabel')
    let presentJobCheckbox = document.getElementById('presentJob')
    if(this.presentJobStatus && presentJobLabel && presentJobCheckbox) {
      presentJobLabel.style.display = 'none'
      presentJobCheckbox.style.display = 'none'
      this.presentJobStatus = false
    } 
    
    let experienceData:any = sessionStorage.getItem('experienceData') ? sessionStorage.getItem('experienceData') : []
    experienceData = JSON.parse(experienceData)
    let particularExperienceData = {...this.getParticularExperienceData, ...this.experienceForm.value}

    console.log("Experience Data ", experienceData, particularExperienceData)

    let indexValue = experienceData.findIndex((el:any) => {
      return el.id === particularExperienceData.id
    })

    experienceData.splice(indexValue, 1, particularExperienceData)
    this.experienceFormData = experienceData
    this.checkEligibleBasedOnExp = this.checkEligibleBasedOnExperience(this.experienceFormData)
    sessionStorage.setItem('experienceData', JSON.stringify(experienceData))
    this.experienceFormButtonName = 'save'
    this.experienceForm.patchValue({
      'organizationName': '',
      'designation': '',
      'startDate': '',
      'endDate': '',
      'experienceInMonth': '',
      'responsibilityAssignment': '',
      'achievements': '',
    })

    this.updateExperienceDateDetails()
  }

  updateExperienceDateDetails() {
    let lastExperienceData = this.experienceFormData[this.experienceFormData.length - 1]
    this.editJobEndDate = ''
    this.editJobStartDate = ''
    this.lastJobEditStartDate = ''
    this.lastJobStartDate = this.decreaseDateByOne(lastExperienceData.startDate)
    this.currentJobEndDate = lastExperienceData.endDate;

    console.log("Exp Data ", this.experienceFormData)

    // this.experienceEndDateJob = this.experienceStartDateJob
    // this.experienceStartDateJob = ''
    // let lastExperienceData = this.experienceFormData[this.experienceFormData.length - 1]
    // this.experienceEndDateJob = lastExperienceData.startDate
    // this.experienceStartDateJob = ''
  }

  // Get Present Job Status
  getPresentJobStatus(event:any) {
    // console.log("Present Job Status ", event)
    if(event.target.checked) {
       this.experienceStartDateJob = this.experienceStartDate
       this.experienceEndDateJob = this.currentDate
       this.presentJobStatus = true
       this.experienceForm.patchValue({
        endDate: 'Continuing'
       })
       this.experienceForm.get('endDate')?.clearValidators()
       this.experienceForm.get('endDate')?.updateValueAndValidity()
       let numberOfMonth = this.findNumberOfMonth(this.experienceForm.value.startDate, this.reformatDate(this.currentDate))
       let applicableMonthForJob = this.findApplicableYears(this.basicInformationForm.controls.appliedPosition.value)[1] * 12
       if(this.presentJobStatus && numberOfMonth) {
          this.experienceForm.get('experienceInMonth')?.patchValue(numberOfMonth + 1)
       }
       console.log("Number of Month ", numberOfMonth)
      //  this.lastJobStartDate = this.experienceStartDate
    } else {
      this.presentJobStatus = false
      this.experienceForm.get('endDate')?.setValidators([Validators.required])
      this.experienceForm.get('endDate')?.updateValueAndValidity()
    }
  }

  getRefrencesData() {
    console.log("Refrence Form Value ", this.refrenceForm)
    this.getRefrencesListOfData = [...this.getRefrencesListOfData]
    this.getRefrencesListOfData.push(this.refrenceForm.value)
    this.getRefrencesListOfData.forEach((item:any, index:any) => {
      return (
        item['action'] = {view: false, edit: true, delete: true},
        item['id'] = index + 1
      )
    })
    this.refrenceData = this.getRefrencesListOfData
    sessionStorage.setItem('refrenceData', JSON.stringify(this.refrenceData))
    this.refrenceForm.reset()
    this.refrenceForm.patchValue({
      'referenceRelation': ''
    })
  }

  saveApplicantFormData() {
    console.log("Validation of basicInformation Form ", this.basicInformationForm)
  }

  getCertificateDegree(event:any) {
    console.log("Certificate degree event ", event)
    if(event.target.files[0].size > 2000000) {
      swal("Error", `File size exceeds the limit (2MB)`, "error");
      let certificateDegree:any = document.getElementById('degeeFileName')
      certificateDegree.value = null
      this.educationQualificationForm.patchValue({
        certificateFile: '',
        certificateUrl: ''
      })
      return
    } else if(event.target.files[0].type !== 'application/pdf') {
      swal("Error", `Only PDF File is accepted`, "error");
      let certificateDegree:any = document.getElementById('degeeFileName')
      certificateDegree.value = null
      this.educationQualificationForm.patchValue({
        certificateFile: '',
        certificateUrl: ''
      })
      return
    } else {
      this.certificateFiles = event.target.files
      let documentUrl:any = this.fileHandler(event.target.files[0])
      this.educationQualificationForm.patchValue({
        certificateFile: event.target.files,
        certificateUrl: documentUrl.changingThisBreaksApplicationSecurity
      })
      return
    }
  }

  fileHandler(file:any) {
      const objectUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      return objectUrl

  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;

        reader.readAsDataURL(file);
    }
    
    console.log("Event ", event, this.imageSrc)
}

  getParticularEducationData(event:any) {
    // debugger
    console.log("Particular Education Data ", event, event.status)
    let testObj = Object.assign({}, event.rowData)
    delete testObj.action
    delete testObj.id
    console.log("Test Obj ", testObj)
    // debugger
    if(event.status == 'view') {
       this.educationQualificationButtonName = 'view'
       this.educationQualificationFormViewMode = true
    } else if(event.status == 'edit') {
      // debugger
      this.getParticularEducationRowData = event.rowData
      console.log("Get Particular Education row data ", this.getParticularEducationRowData)
      if(this.appliedGrade.includes(event.rowData.degree)) {
        let indexValue = this.appliedGrade.indexOf(event.rowData.degree)
        this.appliedGrade.splice(indexValue, 1)
      }
      console.log("Test Obj ", testObj)
      this.educationQualificationForm.setValue(testObj)
      this.educationQualificationButtonName = 'edit'
      this.educationQualificationFormViewMode = false
    } else {
      this.getParticularEducationRowData = event.rowData
      this.educationQualificationButtonName = 'delete'
      this.deleteParticularEducationQualification()
    }
  }

  filterEducationOptions(rowData:any) {
    this.educationOptions.forEach((item:any, index:any) => {
      if(item.value === rowData.degree) {
         this.educationOptions.splice(index, 1)
      }
    })

    console.log("Education Options ", this.educationOptions)

    return this.educationOptions
  }

  getTimeStampBasedOnMonth(month:any) {
    const dateString = month;
    const year = 2023; // You need to provide a valid year
    
    const dateComponents = dateString.split(', ');
    const monthName = dateComponents[0];
    const day = parseInt(dateComponents[1]);
    
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const monthIndex = months.indexOf(monthName);
    const dateObject = new Date(year, monthIndex, day);
      const timestamp = dateObject.getTime();
      return timestamp; // Output: Timestamp in milliseconds
  }

  updateEducationQualificationData() {
    let existingEducationQualificationFormData:any = sessionStorage.getItem('educationQualification') ? sessionStorage.getItem('educationQualification') : []
    existingEducationQualificationFormData = JSON.parse(existingEducationQualificationFormData)
    let editEducationQualificationData = {...this.getParticularEducationRowData, ...this.educationQualificationForm.value}
    if(editEducationQualificationData.degree == '10' || editEducationQualificationData.degree == '12') {
      this.appliedGrade.push(editEducationQualificationData.degree)
    }
    let indexValue = existingEducationQualificationFormData.findIndex((el:any) => {
      return el.id === editEducationQualificationData.id
    })

    existingEducationQualificationFormData.splice(indexValue, 1, editEducationQualificationData)
    this.commonTableData = existingEducationQualificationFormData
    sessionStorage.setItem('educationQualification', JSON.stringify(existingEducationQualificationFormData))
    this.educationQualificationButtonName = 'save'
    this.educationQualificationForm.reset()
  }

  // Delete Particular Education QualificationData
  deleteParticularEducationQualification() {
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this Qualification ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        let existingEducationQualificationFormData = [...this.educationQualificationFormData]
        let editEducationQualificationData = {...this.getParticularEducationRowData}
        let indexValue = existingEducationQualificationFormData.findIndex((el:any) => {
          return el.id === editEducationQualificationData.id
        })
    
        if(this.appliedGrade.includes(editEducationQualificationData.degree)) {
           let indexValue = this.appliedGrade.indexOf(editEducationQualificationData.degree)
           this.appliedGrade.splice(indexValue, 1)
        }
    
        existingEducationQualificationFormData.splice(indexValue, 1)

        swal({
          title: "Success",
          text: "Data Delete successfully",
          icon: "success",
        })
        // this.educationOptions = this.addOptionsEducation(existingEducationQualificationFormData)
        this.commonTableData = existingEducationQualificationFormData
        this.educationQualificationFormData = existingEducationQualificationFormData
        this.educationQualificationButtonName = 'save'
        console.log('Education Qualification form data ', this.educationQualificationFormData)
        // this.educationQualificationForm.reset()
        sessionStorage.setItem('educationQualification', JSON.stringify(existingEducationQualificationFormData))
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })
    this.commonTableData.length > 0 ? this.commonTableDataFormValidation = false : this.commonTableDataFormValidation = true
  }

  addOptionsEducation(formData:any) {
    // console.log("Form Data ", formData)
    console.log("Education options ", this.educationOptions, this.copyEducationOptions)
    // let remainingData:any = []
    // this.educationOptions.forEach((item:any) => {
    //   remainingData.push(item.degree)
    // })

    // this.copyEducationOptions.forEach((item:any) => {
    //   if(!remainingData.includes(item.value)) {
    //      this.educationOptions.push(item)
    //   }
    // })
    return this.educationOptions
  }

  getParticularLanguageData(event:any) {
    console.log("Particular Language ", event, this.languageForm)
    if(event.status == 'view' || event.status == 'edit') {
      if(event.rowData.language == 'English' || event.rowData.language == 'Hindi') {
        this.othersLanguageStatus = false
      } else {
        this.othersLanguageStatus = true
      }
      this.languageFormViewMode = event.status == 'view' ? true : false
    }
    if(event.status == 'view') {
       this.languageFormViewMode = true;
       this.languageFormButtonName = 'view'
    } else if(event.status == 'edit') {
      this.getParticularLanguageRowData = event.rowData
      let testObj = Object.assign({},event.rowData)
      delete testObj.action
      delete testObj.id
      this.languageForm.setValue(testObj)
      this.languageOptions = this.appOptionsInLanguage(this.getParticularLanguageRowData, this.copyLanguageOptions)
      this.languageFormViewMode = false;
      this.languageFormButtonName = 'edit'
    } else {
      this.getParticularLanguageRowData = event.rowData
      this.languageFormButtonName = 'delete'
      this.deleteParticularLanguageData()
    }
  }

  deleteParticularLanguageData() {
    // let languageData:any = sessionStorage.getItem('languageData') ? sessionStorage.getItem('languageData') : []
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this language ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        let languageData = [...this.languageData]

        this.languageOptions = this.appOptionsInLanguage(this.getParticularLanguageRowData, this.copyLanguageOptions)
        console.log("Get Particular language ", this.getParticularLanguageRowData, this.languageOptions)
    
        let indexValue = languageData.findIndex((el:any) => {
          return el.id === this.getParticularLanguageRowData.id
        })

        swal({
          title: "Success",
          text: "Data Delete successfully",
          icon: "success",
        })
    
        // // this.languageOptions = this.appOptionsInLanguage(this.languageData[indexValue], this.copyLanguageOptions)
    
        languageData.splice(indexValue, 1)
        this.languageData = languageData
        this.getListOfLanguageData = languageData
        sessionStorage.setItem('languageData', JSON.stringify(languageData))
        this.languageForm.patchValue({
          'language': '',
          'read': '',
          'write': '',
          'understand': '',
        })
        this.languageFormButtonName  = 'save'
        this.othersLanguageStatus = false
      } if(res?.isDenied) {
        this.languageFormButtonName  = 'save'
        Swal.close()
      } if(res?.isDismissed) {
        this.languageFormButtonName  = 'save'
        Swal.close()
      }
    })

    if(this.languageData.length === 0) {
       this.languageDataValidation = true
    } else {
      this.languageDataValidation = false
    }
  }

  appOptionsInLanguage(particularLanguage:any, copyLanguageOptions:any) {
    console.log("Particular Language ", particularLanguage, copyLanguageOptions)
    for(let i = 0; i < copyLanguageOptions.length; i++) {
        if(copyLanguageOptions[i].value == particularLanguage.language) {
          let obj:any = {}
          obj['value'] = copyLanguageOptions[i].value
          obj['name'] = copyLanguageOptions[i].name
          console.log("lang Obj ", obj)
          this.languageOptions.push(obj)
          break
        }
    }

    return this.languageOptions
  }

  updateLanguageData() {
    let languageData:any = [...this.languageData]
    let particularLanguageData = {...this.getParticularLanguageRowData, ...this.languageForm.value}
    console.log("Particular Language Data ", particularLanguageData, languageData)

    let indexValue = languageData.findIndex((el:any) => {
      return el.id === particularLanguageData.id
    })

    languageData.splice(indexValue, 1, particularLanguageData)
    this.languageOptions = this.updateLanguageOption(languageData, this.languageOptions)
    console.log("Language Data ", this.languageData)
    this.languageData = languageData
    sessionStorage.setItem('languageData', JSON.stringify(languageData))
    this.languageFormButtonName = 'save'
    this.languageForm.patchValue({
      'language': '',
      'read': '',
      'write': '',
      'understand': '',
    })

    this.selectedLanguage == '' ? this.othersLanguageStatus = false : '' 
  }

  // Get Particular Refrence data
  getParticularRefrenceData(event:any) {
    let testObj = Object.assign({}, event.rowData)
    delete testObj.action
    delete testObj.id
    this.getParticularRefrenceRowData = event.rowData
    this.refrenceForm.setValue(testObj)
    if(event.status == 'view') {
       this.refrenceFormButtonName = 'view'
       this.refrenceFormViewMode = true
    } else if(event.status == 'edit') {
      this.refrenceFormButtonName = 'edit'
      this.refrenceFormViewMode = false
    } else {
      this.refrenceFormButtonName = 'delete'
      this.deleteParticularRefrenceData()
    }
  }

  updateRefrenceData() {
    let existingRefrenceData:any = sessionStorage.getItem('refrenceData') ? sessionStorage.getItem('refrenceData') : []
    existingRefrenceData = JSON.parse(existingRefrenceData)
    let editRefrenceData = {...this.getParticularRefrenceRowData, ...this.refrenceForm.value}

    let indexValue = existingRefrenceData.findIndex((el:any) => {
      return el.id === editRefrenceData.id
    })

    existingRefrenceData.splice(indexValue, 1, editRefrenceData)
    this.refrenceData = existingRefrenceData
    sessionStorage.setItem('refrenceData', JSON.stringify(this.refrenceData))
    this.refrenceFormButtonName = 'save'
    this.refrenceForm.reset()
    this.refrenceForm.patchValue({
      'referenceRelation': ''
    })
  }

  deleteParticularRefrenceData() {
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this Refrence ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        let existingRefrenceData:any = sessionStorage.getItem('refrenceData') ? sessionStorage.getItem('refrenceData') : []
        existingRefrenceData = JSON.parse(existingRefrenceData)
        let editRefrenceData = {...this.getParticularRefrenceRowData, ...this.refrenceForm.value}
    
        let indexValue = existingRefrenceData.findIndex((el:any) => {
          return el.id === editRefrenceData.id
        })

        swal({
          title: "Success",
          text: "Data Delete successfully",
          icon: "success",
        })

        console.log("List of refrenceData ", this.getRefrencesListOfData)
    
        existingRefrenceData.splice(indexValue, 1)
        this.refrenceData = existingRefrenceData
        this.getRefrencesListOfData = this.refrenceData
        sessionStorage.setItem('refrenceData', JSON.stringify(this.refrenceData))
        this.refrenceFormButtonName = 'save'
        this.refrenceForm.reset()
        this.refrenceForm.patchValue({
          'referenceRelation': ''
        })
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })
  }

  // finalJobPotal() {
  //   this.finalJobPortalObject = {...this.basicInformationForm.value, ...this.computerProficiencyForm.value}
  //   this.finalJobPortalObject['experience'] = this.experienceFormData
  //   this.finalJobPortalObject['educationQualification'] = this.commonTableData
  //   this.finalJobPortalObject['languageData'] = this.languageData
  //   this.finalJobPortalObject['refrenceData'] = this.refrenceData
  //   this.finalJobPortalObject['usersCV'] = this.userCv
  //   this.finalJobPortalObject['fileName'] = this.fileNameStatus

  //   let formDataObject = this.convertDataIntoFormDataObject(this.finalJobPortalObject)

  //   // When API is ready
  //   this.contentService.saveJobPortalUserForm(formDataObject).subscribe((res:any) => {
  //     if(res.responseCode == '200') {
  //       swal({
  //         title: "Success",
  //         text: "Data submitted successfully",
  //         icon: "success",
  //       })
  //       this.jobPortalFormSuccessfully.emit('false')
  //     }
  //   },(err) => {
  //       swal("", `${err?.error?.errorMsg}`, "error");
  //   })

  //   swal({
  //     title: "Success",
  //     text: "Data submitted successfully",
  //     icon: "success",
  //   })
  //   // this.jobPortalFormSuccessfully.emit('false')

  // }

  updateApplicantFormData(applicantFormData:any) {
    for(let key in applicantFormData) {
        if(key === 'experience') {
           for(let key1 in applicantFormData[key][0]) {
               if(key1 === 'endDate' && applicantFormData[key][0][key1] === "Continuing") {
                applicantFormData[key][0][key1] = this.reformatDate(this.currentDate)
               }
           }
        }
    }

    console.log("Applicant Form Data ", applicantFormData)
  }

  finalJobPortal() {
    this.basicInformationForm.value['positionId'] = this.basicInformationForm.value['appliedPosition']
    delete this.basicInformationForm.value['appliedPosition']
    this.applicantFormData = {...this.basicInformationForm.value, resume: this.userCv, ...this.computerProficiencyForm.value, ...this.candidatureForm.value, ...this.experienceFormSalary.value}
    this.applicantFormData['reference'] = this.reformattedValue(this.refrenceData)
    this.applicantFormData['language'] = this.reformattedValue(this.languageData)
    this.applicantFormData['experience'] = this.reformattedValue(this.experienceFormData)
    console.log("Applicant Form Data ", this.applicantFormData)
    this.applicantFormData['education'] = this.educationReformatValue(this.commonTableData)
    // console.log("Applicant Form Data ", this.applicantFormData)
    this.updateApplicantFormData(this.applicantFormData)
    // this.applicantFormData['fileName'] = this.fileNameStatus
    let formDataObject = this.convertDataIntoFormDataObject(this.applicantFormData)
console.log("file", formDataObject);

    setTimeout(() => {
      this.contentService.saveJobPortalUserForm(formDataObject).subscribe((res:any) => {
        if(res.responseCode == '200 OK') {
          swal({
            title: "Success",
            text: "Application Submit Successfully",
            icon: "success",
          })
          this.jobPortalFormSuccessfully.emit('false')
        }
      },(err) => {
          swal("", `${err?.error?.errorMsg}`, "error");
      })
    },500)
    

      // swal({
      //   title: "Success",
      //   text: "Data submitted successfully",
      //   icon: "success",
      // })
    console.log("Applicant Form Data ", this.applicantFormData, formDataObject)
  }

  reformattedValue(data:any) {
    console.log('data', data);
    if(data !== undefined){
      data.forEach((item:any) => {
        delete item?.id
        delete item?.action
        delete item?.certificateFile
        delete item?.certificateUrl
      })
    }
    return data
  }

  blobToFile(blob:Blob, fileName: string){       
    return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type })
}

  educationReformatValue(data:any) {
    data.forEach((item:any) => {
      // this.applicantFormData['certificateFile'] = this.blobToFile(item?.certificateUrl, item?.degree)
      delete item?.id
      delete item?.action
      // delete item?.certificateFile
      // delete item?.certificateUrl
    })

    return data
  }

  convertDataIntoFormDataObject(obj:any) {
    // debugger
    console.log("userCv ", obj, this.fileNameStatus)
    const formData:any = new FormData()
    let userCv = obj.resume
    if(userCv) {
       for(let i = 0; i < userCv.length; i++) {
           formData.append('resume', userCv[i], userCv[i].name)
       }
    }
    formData.append('jobPostId', this.jobNotificationDetails.id)
    // if(this.fileNameStatus) {
    //   this.fileNameStatus.map((item:any) => {
    //     //  console.log("File Name ", item)
    //     formData.append('certificateFile', item.file[0]);
    //     // for(let i = 0; i < item.length; i++) {
    //         // formData.append(`${item.fileName}`, item.file[i], item.file[i].name)
    //       console.log('filess', item.file[0])
    //     // }
    //   })
    // }
    // let educationQualification = JSON.stringify(obj['educationQualification'])
    // let educationQualificationBlob = new Blob([educationQualification], {type: 'application/json'})
    // let experience = JSON.stringify(obj['experience'])
    // let experienceBlob = formData.append('json', new Blob([experience], {type: 'application/json'}))
    // let languageData = JSON.stringify(obj['languageData'])
    // let languageBlob = new Blob([languageData], {type: 'application/json'})
    // let refrenceData = JSON.stringify(obj['refrenceData'])
    // let refrenceBlob = new Blob([refrenceData], {type: 'application/json'})
    for(let key in obj) {
      if(key && key !== 'education' && key !== 'experience' && key !== 'language' && key !== 'reference' && key !== 'resume') {
         formData.append(key, obj[key])
      } if(key == 'education') {
        for(let edu of obj.education){
          let file = edu.certificateFile;
          // let blob = file.slice(0, file.size, file.type); 
          // let newFile = new File([blob], `${edu.degree}.pdf`, {type: file.type});
          for(let i = 0; i < file.length; i++) {
              formData.append('certificateFile', file[i], `${edu.degree}.pdf`);
          }
          // formData.append('certificateFile', file, `${edu.degree}.pdf`);
          edu.certificate = `${edu.degree}.pdf`;
          delete edu.certificateFile;
          delete edu.certificateUrl;
        }
        let objectForm = JSON.stringify(obj[key])
        formData.append(key, new Blob([objectForm], {type: 'application/json'}))
        // formData.append(key, JSON.stringify(obj[key]), {type: 'application/json'})
      } if(['experience', 'language','reference'].includes(key)) {
        let objectForm = JSON.stringify(obj[key])
        formData.append(key, new Blob([objectForm], {type: 'application/json'}))
        // formData.append(key, JSON.stringify(obj[key]), {type: 'application/json'})
      } 
      // if(key == 'language') {
      //   let objectForm = JSON.stringify(obj[key])
      //   formData.append(key, new Blob([objectForm], {type: 'application/json'}))
      //   // formData.append(key, JSON.stringify(obj[key]))
      // } 
      // if(key == 'reference') {
      //   formData.append(key, JSON.stringify(obj[key]))
      // } 
    }
    
  //   for (var pair of formData.entries()) {
  //     console.log('formdata',pair[0]+ ', ' + pair[1]); 
  // }
    // setTimeout(()=>{
    // }, 2000)
    return formData
  }

  getUserCV(event:any) {
    // console.log("Getting a user CV ", event)
    console.log("Type Of file ", event.target.files[0].type)
    let userCVFile = document.getElementById("fileInput") as HTMLInputElement
    if(event.target.files[0].size > 2000000) {
      swal("Error", `File size exceeds the limit (2MB)`, "error");
      userCVFile.value = ''
      this.userCv = ''
      return
    } else if(event.target.files[0].type !== 'application/pdf') {
      swal("Error", `Only PDF File is accepted`, "error");
      userCVFile.value = ''
      this.userCv = ''
      return
    } else {
      this.userCv = event.target.files
      this.userCvValidation = false
    }
  }

  backToInstruction() {
    this.backInstructionPage.emit('false')
  }

  backToApplication(){
    this.backToPrevious.emit('false');
  }

  // get Particular position details 
  getParticularPositionDetails(positionDetail:any) {
    console.log("get position details ", positionDetail, this.getTheFilterOutPositionDetails);
    this.selectPosition = true
    let applicableYearForJob = this.findApplicableYears(this.basicInformationForm.controls.appliedPosition.value)
    this.applicableExpOfJobInMonth = this.convertYearsToMonth(applicableYearForJob[1])
    this.getThePositionDetails(this.getTheFilterOutPositionDetails, positionDetail.target.value)
    if(this.applicantYears) {
       this.checkEligible(this.applicantYears)
    }
  }

  getThePositionDetails(getTheFilterOutPositionDetails:any, positonDetailsValue:any) {
    this.particularPositionDetails = getTheFilterOutPositionDetails.filter((item:any) => item.id == Number(positonDetailsValue))
    console.log("Position Details ", this.particularPositionDetails)
  }

  convertYearsToMonth(years:any) {
    return years * 12
  }

  // Get applicant date of birth
  getDateOfBirth(event:any) {
    console.log("event ", event, this.basicInformationForm)
    this.applicantYears = this.reformatDate(event.target.value)
    this.basicInformationForm.get('dob')?.patchValue(this.applicantYears)
    this.checkEligible(this.applicantYears)
  }

  getExperienceStartDate(event:any) {
    console.log("Experience form data ", this.experienceForm)
    let experienceStartDate = this.reformatDate(event.target.value)
    this.experienceStartDate = experienceStartDate
    this.currentJobStartDate = experienceStartDate
    this.currentJobEndDate = this.lastJobStartDate
    this.experienceForm.get('startDate')?.patchValue(experienceStartDate)
    this.presentJobStatus = false
    this.experienceForm.get('endDate')?.reset()
    this.experienceForm.get('endDate')?.setValidators([Validators.required])
      this.experienceForm.get('endDate')?.updateValueAndValidity()
  }

  getEndDateOfJob(event:any) {
    console.log("End Date of Job ", this.reformatDate(event.target.value), event.target.min, event)
    this.experienceStartDateJob = this.experienceStartDate
    this.experienceEndDateJob = this.reformatDate(event.target.value)
    let experienceEndDate = this.reformatDate(event.target.value)
    // this.experienceForm.get('endDate')?.patchValue(experienceEndDate)
    this.experienceForm.patchValue({
      endDate: this.experienceEndDateJob
    })
    let numberOfMonth = this.findNumberOfMonth(event.target.min, this.reformatDate(event.target.value))
    let applicableMonthForJob = this.findApplicableYears(this.basicInformationForm.controls.appliedPosition.value)[1] * 12
    console.log("Number Of Month ", numberOfMonth, applicableMonthForJob)
    console.log("Experience Form ", this.experienceForm)
    if(this.experienceForm.value.endDate && this.experienceForm.value.startDate) {
      this.experienceForm.get('experienceInMonth')?.patchValue(numberOfMonth + 1)
    }
  }
  

  // Reformat Date
  reformatDate(originalDate:any) {
    const dateObject = new Date(originalDate);
    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');

    let convertedDate = `${year}-${month}-${day}`;
    return convertedDate
  }

  // Check Eligible for the position
  checkEligible(year:any) {
    let applicantYears:any = this.calculateAge(year)
    console.log("ap year ", applicantYears)
    let applicableYearForJob = this.findApplicableYears(this.basicInformationForm.controls.appliedPosition.value)
    console.log("Years ", applicantYears, applicableYearForJob, applicableYearForJob[0])
    if(applicableYearForJob[0]) {
      if(applicantYears > applicableYearForJob[0]) {
        this.eligibleValidation = true
      } else {
        this.eligibleValidation = false
      }
    }
  }


  findApplicableYears(selectedPosition:any) {
    let getApplicableYears;
    let experienceForPosition;
    for(let i = 0; i < this.getTheFilterOutPositionDetails.length; i++) {
        if(this.getTheFilterOutPositionDetails[i].id === Number(selectedPosition)) {
           getApplicableYears = this.getTheFilterOutPositionDetails[i].maxAge
           experienceForPosition = this.getTheFilterOutPositionDetails[i].minExp
           break;
        }
    }

    console.log("Get Applicable years ", getApplicableYears)

    return [getApplicableYears, experienceForPosition]
  }

  // calculate age 
  calculateAge(dob:any) {
    let applicantYear = dob.split('-')[0]
    console.log("Applicant Year ", applicantYear)

    const currentYear = new Date().getFullYear();

    // Calculate the age
    const age = currentYear - applicantYear;

    return age;
  }

  findNumberOfMonth(min:any, max:any) {
    console.log("Min date ", min, max)
    const startDate = new Date(min);
  const endDate = new Date(max);
  const monthsBetween = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();
  return monthsBetween
  }

  prefieldValue(data:any){
    console.log("tytyty",data);
    
    let basicInformationForm ={
      appliedPosition: data?.position_id,
      positionName: data?.position_name,
      name: data?.name,
      fatherName: data?.father_name,
      gender: data?.gender,
      dob: data?.dob,
      permanentAddress: data?.permanent_address,
      correspondenceAddress: data.correspondence_address,
      email: data?.email,
      mobileNumber: data?.mobile_name
    };
    this.basicInformationForm.patchValue(basicInformationForm);
    this.basicInformationFormData = basicInformationForm
    console.log("Basic Information Data ", this.basicInformationFormData)

    // let educationQualificationForm = {
    //   degree: data.education[0].degree ,
    //   passingYear: data.education[0].passing_year,
    //   institute: data.education[0].institute,
    //   boardUniversity: data.education[0].board_university,
    //   specialization: data.education[0].specialization,
    //   percentageGrading: data.education[0].percentage_grading,
    //   certificateFile: data.education[0].certificate,
    //   certificateUrl:  ""
    // };

    // this.commonTableData = data.education;
    // this.refrenceData = data.reference;
    // this.languageData = data.language;
    // this.experienceFormData = data.experience;
    // this.educationQualificationForm.patchValue(educationQualificationForm);

    // let experienceForm = {
    //   organizationName: data.experience[0].organization_name,
    //   designation: data.experience[0].designation,
    //   startDate: data.experience[0].start_date,
    //   endDate: data.experience[0].end_date,
    //   experienceInMonth: data.experience[0].experience_in_month,
    //   responsibilityAssignment: data.experience[0].responsibility_assignment,
    //   achievements: data.experience[0].achievements
    // };
    // this.experienceForm.patchValue(experienceForm);

      // if(data.hasOwnProperty('experience')) {
      //    let experienceData:any = []
      //    data.experience.forEach((item:any) => {
      //      let obj:any = {}
      //      obj['organizationName'] = 
      //    })
      // }

      if(data.hasOwnProperty('education')) {
        let education:any = []
        data.education.forEach((item:any) => {
          let obj:any = {}
          obj['degree'] = item.degree;
          obj['passingYear'] = item.passing_year;
          obj['institute'] = item.institute
          obj['boardUniversity'] = item.board_university;
          obj['specialization'] = item.specialization;
          obj['percentageGrading'] = item.percentage_grading;
          obj['certificateUrl'] = 'https://aajeevikaimg.lokos.in/'+item.certificate;
          education.push(obj)
        })

        console.log("Education Data ", education)

        this.commonTableData = education
     }

     if(data.hasOwnProperty('experience')) {
      let experience:any = []
      data.experience.forEach((item:any) => {
        let obj:any = {}
        obj['organizationName'] = item.organization_name;
        obj['designation'] = item.designation;
        obj['startDate'] = new Date(item.start_date).toLocaleDateString()
        obj['endDate'] = new Date(item.end_date).toLocaleDateString();
        obj['experienceInMonth'] = item.experience_in_month;
        obj['responsibilityAssignment'] = item.responsibility_assignment;
        obj['achievements'] = item.achievements;
        experience.push(obj)
      })

      this.experienceFormSalary.patchValue({
        last_salary: data.last_salary
      })

      this.experienceFormData = experience
   }

      if(data.hasOwnProperty('language')) {
         let languageData:any = []
         data.language.forEach((item:any) => {
           let obj:any = {}
           obj['language'] = item.language;
           obj['read'] = item.read;
           obj['write'] = item.write;
           obj['understand'] = item.understand
           languageData.push(obj)
         })

         this.languageData = languageData
      }

      let computerProficiencyForm = {
        computerProficiency: data.computer_proficiency,
      };

      this.computerProficiencyFormData = computerProficiencyForm

      let candidatureForm = {
        justificationCandidature: data.justification_candidature
      }

      this.candidatureFormData = candidatureForm

      this.computerProficiencyForm.patchValue(computerProficiencyForm);
      this.candidatureForm.patchValue(candidatureForm)

      if(data.hasOwnProperty('reference')) {
        let refrenceData:any = []
        data.reference.forEach((item:any) => {
        //  console.log('Refrence Item ', item)
          if(item !== null) {
            let obj:any = {}
            obj['referenceName'] = item?.name
            obj['referenceRelation'] = item?.relation
            obj['referenceAddress'] = item?.address
            obj['referenceMobile'] = item?.mobile
            obj['referenceEmail'] = item?.email
            refrenceData.push(obj)
          }
        })
        console.log("Refrence Data ", refrenceData)

        this.refrenceData = refrenceData
      }
  
      this.viewResume = data.resume
  }

  downloadResume(link:any) {
    console.log("link", link)
    window.open(link)
  }

  saveReview() {
    console.log("Experience Form ", this.experienceForm, this.experienceFormSalary)
    console.log("Basic Information form ", this.basicInformationForm, this.userCv, this.computerProficiencyForm)
    console.log("Validation ", this.languageDataValidation, this.computerProficiencyFormValidation, this.userCvValidation, )
    if(this.languageData.length === 0) {
       this.languageDataValidation = true
    } if(!this.computerProficiencyForm.valid) {
      this.computerProficiencyFormValidation = true
    } if(!this.candidatureForm.valid) {
      this.candidatureFormValidation = true
    } if(!this.userCv) {
      this.userCvValidation = true
    } if(this.experienceFormData.length === 0) {
      this.experienceFormDataValidation = true
    } if(this.commonTableData.length === 0) {
      this.commonTableDataFormValidation = true
    } if(!this.basicInformationForm.valid){
      this.basicInformationFormValidation = true
    } if(!this.experienceFormSalary.valid) {
      this.experienceFormValidation = true
    } if(this.computerProficiencyFormValidation || this.candidatureFormValidation || !this.checkEligibleBasedOnExp || this.eligibleValidation || this.languageDataValidation || this.userCvValidation || this.experienceFormDataValidation || this.commonTableDataFormValidation || this.basicInformationFormValidation || this.experienceFormValidation) {
      this.scrollToTop()
    } if(this.languageData.length !== 0 && this.candidatureForm.valid  && this.computerProficiencyForm.valid  && this.userCv && this.experienceFormData.length !== 0 && this.commonTableData.length !== 0 && this.basicInformationForm.valid && this.checkEligibleBasedOnExp && !this.eligibleValidation && this.experienceFormSalary.valid) {
      this.viewModeForm = true;
      this.isViewMode = true;
      this.viewApplicationForm = true;
      this.basicInformationFormData = this.getPositionName(this.basicInformationForm.value)
      this.computerProficiencyFormData = this.computerProficiencyForm.value
      this.candidatureFormData = this.candidatureForm.value
      console.log("Form Data ", this.basicInformationFormData, this.computerProficiencyFormData, this.candidatureFormData)
      this.experienceFormValidation = false
      this.filterAction()
    }
  }

  // get Current Salary or last Salary
  getCurrentSalary(event:any) {
    console.log("Event Salary ", event, event.target.valueAsNumber)
    if(event.target.valueAsNumber > 100000000) {
       this.currentSalaryValidation = true
    } else {
      this.currentSalaryValidation = false
    }
  }

  // Format Salary
  formatSalary(salary: number): string {
    return salary.toLocaleString(); // This will add commas as thousands separators
  }

  getPositionName(value:any) {
    // console.log("Position Details ", this.getTheFilterOutPositionDetails, value)
    for(let i = 0; i < this.getTheFilterOutPositionDetails.length; i++) {
        if(this.getTheFilterOutPositionDetails[i].id === Number(value.appliedPosition)) {
           value['positionName'] = this.getTheFilterOutPositionDetails[i].name
           break
        }
    }

    return value
  }

  editJobPortalForm() {
    this.scrollToTop()
    this.viewModeForm = false;
    this.isViewMode = false
    this.viewApplicationForm = false;
    this.addAction()
  }

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }

  printPreview() {
   window.print()
  }

  createDynamicObject(data:any, details:any) {
    let obj:any = {}
    for(let key in data) {
      obj[key] = details[data[key]]
    }

    return obj
  }

  getParticularLanguage(event:any) {
    // console.log("Particular language ", event)
    let selectedLanguage = event.target.value
    this.selectedLanguage = event.target.value
    if(selectedLanguage === '') {
       this.othersLanguageStatus = true;
    } else {
      this.othersLanguageStatus = false
    }
  }
}

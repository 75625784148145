<div class="d-flex justify-content-between align-items-center">
    <div class="records-count d-sm-block text-secondary">
        <!-- Showing {{ pager.startIndex + 1 }} to {{ pager.endIndex + 1 }} of -->
        {{ totalCount }} Items
    </div>

    <nav class="pages">
        <ul class="pagination">
            <!-- <li [ngClass]="{ disabled: currentPageNumber === 1 }" class="page-item">
                <a href="javascript:;" class="page-link" (click)="prev()">Previous</a>
            </li>

            <li [ngClass]="{ 'custom-disabled active': currentPageNumber === page }" class="page-item"
                *ngFor="let page of pager.pages; trackBy: trackByFn">
                <a class="page-link" href="javascript:;" (click)="getData(page)">{{
                    page
                    }}</a>
            </li>

            <li [ngClass]="{ disabled: currentPageNumber + 1 > totalPages }" class="page-item">
                <a class="page-link" href="javascript:;" (click)="next()">Next</a>
            </li> -->
        </ul>
    </nav>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(array: any[], keysToExclude: string[]): any[] {
    return array.filter(item => !keysToExclude.includes(item.key));
  }

}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {}

  transform(value: string): string {
    const dateParts:any = value.split('-');
    if (dateParts.length === 2) {
      const year = dateParts[0];
      const month = dateParts[1];
      const formattedDate = new Date(`${year}-${month}-01`); // Assuming day is always 01
      let formatedDate:any = this.datePipe.transform(formattedDate, 'MMMM yyyy');
      return formatedDate
    }
    return '-';
  }


}

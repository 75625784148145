<div class="bg-light rounded-3 shadow sticky-top index">
  <nav class="nav flex-column  justify-content-between px-2 menu-cust">
      <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/approach']" [routerLinkActive]="['active']">Approach</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/support-units']" [routerLinkActive]="['active']">Support Units</a></li>
      <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/resource-blocks']" [routerLinkActive]="['active']">Resource Blocks</a></li> -->
      <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/community-institutional']" [routerLinkActive]="['active']">Community Institutional Architecture</a></li>
      <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/intensive-blocks']" [routerLinkActive]="['active']">Intensive Blocks</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/non-intensive-blocks']" [routerLinkActive]="['active']">Non-Intensive Blocks</a></li> -->
      <li class="nav-item"><a class="nav-link" [routerLink]="['/work-structure/exit-strategy']" [routerLinkActive]="['active']">Exit Strategy</a></li>
  </nav>
</div>
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import * as $ from 'jquery';
import {Moment} from 'moment';
import 'fullcalendar';

export type Appointment = {
  id?: string;
  start?: Date;
  end?: Date;
  title?: string;
  allDay?: boolean;
  contentId?: number;
}
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor(private contentService:ContentService,private dialog:MatDialog,private router:Router) { }

  isNew:any = null;
  appointmentDetail: Appointment |any= null;
  isEvents: boolean = false;
  appointments: Appointment[] = [
  ];

  

   viewModes = ['month', 'agendaWeek', 'agendaDay'];
   navButtons = ['prev', 'next', 'today'];
   requestNewAppointment = new EventEmitter<Appointment>();
   requestUpdateAppointment = new EventEmitter<Appointment>();
   appointmentUpdated = new EventEmitter<Appointment>();
  page=1;
  totalCount=0;
  ngOnInit(): void {
    console.log("events", this.appointments)
    this.getEvents();
    
  }
  events:any

  onRequestNewAppointment(e: any): void {
    this.isNew = true;
    console.log("events", e)
    let appointment: Appointment = e;
    this.appointmentDetail = appointment;
  }

  onRequestUpdateAppointment(e: any): void {
    this.isNew = null;
    console.log("eventsUpdate", e)
    let mouseEvent:any = {clientX: e.originalEvent.clientX, clientY: e.originalEvent.clientY}
    let appointment: Appointment = e;
    this.appointmentDetail = appointment;
    if(document.getElementById("appointment-detail")){
      let element: any =document.getElementById("appointment-detail");
    element.style.top=mouseEvent.clientY+'px';
        element.style.left=mouseEvent.clientX+'px';
        console.log("eventsUpdateInside", element, mouseEvent.clientX, mouseEvent.clientY)
    }
  }

  onCloseAppointmentDetail(): void {
    this.appointmentDetail = null;
    this.isNew = null;
    this.onRequestNewAppointment(null)
  }

  onAdd(appointment: Appointment): void {
    this.appointments = [...this.appointments, { id: new Date().getTime().toString(), ...appointment }];
    this.onCloseAppointmentDetail();
  }

  onUpdate(appointment: Appointment): void {
    this.appointments = this.appointments.map(
      a => a.id === appointment.id ? { ...a, ...appointment } : a
    );
    this.onCloseAppointmentDetail();
  }

  onAppointmentUpdated(appointment: Appointment): void {
    this.onUpdate(appointment);
  }
  getEvents(){
    this.contentService.getContent("list", `moduleId=6&page=${this.page}`).subscribe((res:any)=>{
      if(res){
        this.events = res && res['wrapperList'].length && res['wrapperList'];
        this.totalCount = res.totalcount;
        this.appointments = res['wrapperList'].map((item: any) => ({contentId: item.id, id: item.id, title: item.title,start:new Date(item.startDate).toUTCString(), end: new Date(item.endDate).toUTCString()}))
      }
    },error=>{
      console.log(error)
    })
  }
  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getEvents();
  }

  toggleEvents(event:any){
    console.log("event", event)
    this.appointmentDetail = null;
  }
}

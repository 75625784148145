<div class="container">
  <div class="row my-3">
    <div class="col px-0">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
          <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->

            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/multimedia" class="nav-link ">Multimedia</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3"> Parliament Q&A</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3 bg-white shadow p-3">
      <div class="row">
        <div class=" mb-3 bg-white">
          <div class="row bg-light p-2 mt-3 align-items-center">
        <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId" (searchParams)="searchParams($event)"></app-common-filters>
            <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)" ></app-paginator>
          </div>
          <div class="d-flex justify-content-between">
            <!-- <p class="my-2">Showing {{totalCount}} Results</p> -->
            <div class="d-flex">
              <div class="dropdown me-2">
                <!-- <button class="btn btn-outline-primary dropdown-toggle d-flex justify-content-between " type="button"
                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <span>Most Recent First</span><span><img src="/assets/images/dropdown.svg" alt=""></span>
                </button> -->
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" href="#">Action</a></li>
                  <li><a class="dropdown-item" href="#">Another action</a></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div  *ngIf="totalCount!==0;else noData; else noData">
            <div >
              <div class="row mb-4 mt-4"  >
                <div class="col-sm-4 " *ngFor="let item of cardData" >

                  <div class="card mx-2 mb-2 p-1">
                    <div style="width: 100%; ">
                    <img [src]="geturl(item)" style="cursor: pointer;"  (click)="openDialog(item?.fileList)" class="card-img-top" alt="...">
                  </div>
                    <div class="card-body" >
                      <div class="mb-1 text-truncate" matTooltip="{{item?.title}}">
                        <img src="/assets/images/pdf.svg" alt="" style="width: 20px;">
                        <a style="cursor: pointer;" (click)="openDialog(item.fileList)" target="_blank"
                            >{{item?.title}}</a>
                      </div>
                      <div>
                        <p class="mb-1">{{item?.description}}</p>
                        <small>Published Date: {{item?.publishedDate | date:'mediumDate'}}</small>
                      </div>
                      <div><img src="/assets/images/download.svg" alt="">
                        <small>Total Downloads: <strong>{{getTotalDownload(item.fileList)}}</strong></small>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="card mx-2 mb-2">
                    <div style="width: 100%; text-align: center;">
                    <img [src]="item.link?item.link:'/assets/images/pdf.svg'" style="width: 100px;" class="card-img-top" alt="...">
                  </div>
                    <div class="card-body" style="font-size:12px">
                      <div class="mb-1">
                        <img src="/assets/images/pdf.svg" alt="" style="width: 20px;"><a [href]="getLink(item.fileList)" target="_blank"
                          style="font:16px/21px;">{{item?.title}}</a>
                      </div>
                      <div>
                        <p class="mb-1">{{item?.description}}</p>
                        <small>Published Date: {{item?.publishedDate | date:'mediumDate'}}</small>
                      </div>
                      <div><img src="/assets/images/download.svg" alt="">
                        <small>Total Downloads: <strong>{{item?.fileList?.length ? item?.fileList[0]. downloadCount : '0'}}</strong></small>
                      </div>
                    </div>
                  </div> -->


                </div>
              </div>

            </div>
          </div>


          <ng-template #noData >
            <div class="row mb-4 mt-4">
              <div style="text-align: center;margin-top: 50px;">
                <h1>
                  No Data Found
                </h1>
              </div>

            </div>
          </ng-template>



        </div>

      </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative pe-0">
      <app-footer-sub-menu></app-footer-sub-menu>
    </div>
  </div>
</div>

<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Screen Reader Access</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
      <div class="col-sm-8 mb-3">
        <div class="card border-0 rounded-2 shadow mb-3">
          <div class="card-body">
            <h4>Screen Reader Access</h4>
            <p>NRLM website complies with World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.0 level AA. This will enable people with visual impairments access the website using assistive technologies, such as screen readers. The information of the website is accessible with different screen readers.</p>
            <p class="fw-bold">Various Screen Readers to choose from</p>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Screen Reader</th>
                  <th scope="col">Website</th>
                  <th scope="col">Free / Commercial</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Screen Access For All (SAFA)</td>
                  <td><a href="http://safa-reader.software.informer.com/download/" target="_blank">http://safa-reader.software.informer.com/download/ (External website that opens in a new window)</a></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>Non Visual Desktop Access (NVDA)</td>
                  <td><a href="http://www.nvda-project.org/" target="_blank">http://www.nvda-project.org/ (External website that opens in a new window)</a></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>System Access To Go</td>
                  <td><a href="http://www.satogo.com/" target="_blank">http://www.satogo.com/ (External website that opens in a new window)</a></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>Thunder</td>
                  <td><a href="http://www.screenreader.net/index.php?pageid=2" target="_blank">http://www.screenreader.net/index.php?pageid=2 (External website that opens in a new window)</a></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>WebAnywhere</td>
                  <td><a href="http://webanywhere.cs.washington.edu/wa.php" target="_blank">http://webanywhere.cs.washington.edu/wa.php (External website that opens in a new window)</a></td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>Hal</td>
                  <td><a href="http://www.yourdolphin.co.uk/productdetail.asp?id=5" target="_blank">http://www.yourdolphin.co.uk/productdetail.asp?id=5 (External website that opens in a new window)</a></td>
                  <td>Commercial</td>
                </tr>
                <tr>
                  <td>JAWS</td>
                  <td><a href="http://www.freedomscientific.com/jaws-hq.asp" target="_blank">http://www.freedomscientific.com/jaws-hq.asp (External website that opens in a new window)</a></td>
                  <td>Commercial</td>
                </tr>
                <tr>
                  <td>Supernova</td>
                  <td><a href="http://www.yourdolphin.co.uk/productdetail.asp?id=1" target="_blank">http://www.yourdolphin.co.uk/productdetail.asp?id=1 (External website that opens in a new window)</a></td>
                  <td>Commercial</td>
                </tr>
                <tr>
                  <td>Window-Eyes</td>
                  <td><a href="http://www.gwmicro.com/Window-Eyes/" target="_blank">http://www.gwmicro.com/Window-Eyes/ (External website that opens in a new window)</a></td>
                  <td>Commercial</td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
      </div>
      <div class="col-sm-4 mb-3 position-relative">
          <app-footer-sub-menu></app-footer-sub-menu>
      </div>
  </div>
 
</div>
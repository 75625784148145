import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exit-strategy',
  templateUrl: './exit-strategy.component.html',
  styleUrls: ['./exit-strategy.component.scss']
})
export class ExitStrategyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = require('sweetalert');

@Component({
  selector: 'app-kms',
  templateUrl: './kms.component.html',
  styleUrls: ['./kms.component.scss'],
})
export class KmsComponent implements OnInit {
  currentPage = 0;
  pageSize = 20;
  masterData: any = [];
  isKms = true;
  kmsId= ''
  excludeArray = [
    'id',
    'moduleId',
    'subModuleId',
    'path',
    'approvedRejectedBy',
    'approvedRejectedStatus',
    'isHighlight',
    'isUpdate',
    'submodulename',
  ];
  tableHeader: any = [
    {
      key: 'fileName',
      label: 'File Name',
    },
    {
      key: 'file',
      label: 'File',
    },
    {
      key: 'knowledgeMaterialVertical',
      label: 'KM Vertical',
    },
    // {
    //   key: 'crossStatePublication',
    //   label: 'Cross State Publication',
    // },
    {
      key: 'source',
      label: 'Source',
    },
    {
      key: 'state',
      label: 'State',
    }
  ];
  docExtensions = [
    'doc',
    'docm',
    'docx',
    'pdf',
    'txt',
    'csv',
    'xls',
    'xlsb',
    'xlsx',
    'pptm',
    'pptx',
    'application/pdf',
    'application/vnd.ms-excel',
  ];
  imgExtensions = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/raw',
    'image/image',
  ];
  videoExtensions = [
    'mp4',
    'mov',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'f4v',
    'swf',
    'mkv',
    'webm',
    // 'html5',
    'mpeg-2',
  ];
  page = 1;
  entryTitle: string = 'NRLM Document';
  selectedModule: any;
  selectedModuleOptions: any = { moduleId: '1', subModuleId: '1' };
  rowData: any = [];
  isChecked: boolean = false;
  isViewMode: boolean = false;
  submitButton: string = 'Submit';
  dynamicForm: any = {
    title: [''],
    publishedDate: [''],
    publishedBy: [''],
    author: [''],
    fileList: [''],
    source: [''],
  };
  totalCount: any = 0;
  fileAcceptType: any;
  labelKeys: any;
  isEntry: boolean = false;
  moduleData: any;
  buttonDisable: boolean = false;
  flag:any=0;
  constructor(
    private router: Router,
    private contentService: ContentService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
    })
    let selectData: any = { target: { value: 'core-document' } };
    this.getMasterData();
    this.getkmsCount();
  }

  submitData(event: any) {
    console.log('event', event);
    let type = 'save';
    let fileInput = event.file;
    let id: number = 0;
    const formdata: any = new FormData();
    if (fileInput)
      for (let i = 0; i < fileInput.length; i++) {
        formdata.append('files', fileInput[i], fileInput[i].name);
      }

    for (let key in event.form) {
      console.log('keys', key);
      if (event.form[key] && key != 'fileList')
        formdata.append(key, event.form[key]);
    }

    if (event.contentId) {
      id = event.contentId;
      type = 'update';
    }
    formdata.append('moduleId', this.selectedModuleOptions.moduleId);
    // if(!this.selectedModuleOptions.subModuleId)
    // this.selectedModuleOptions.subModuleId = '1';
    if (this.selectedModuleOptions.subModuleId)
      formdata.append('subModuleId', this.selectedModuleOptions.subModuleId);
    console.log('keys', event, formdata);
    this.buttonDisable = true;
    this.contentService.saveContent(formdata, type, id).subscribe(
      (data: any) => {
        if (data.responseCode == '200') this.buttonDisable = false;
        swal({
          title: 'Success',
          text: 'Data submitted successfully',
          icon: 'success',
          // dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getMasterData();
          }
        });
      },
      (error) => {
        this.buttonDisable = false;
        console.log('edit cutoff error', error);
        if(error.status !== 403) {
          swal("", `${error?.error?.errorMsg ? error?.error?.errorMsg : 'Internal server error'}`, "error");
       }
        // swal(
        //   '',
        //   `${
        //     error?.error?.errorMsg
        //       ? error?.error?.errorMsg
        //       : 'Internal server error'
        //   }`,
        //   'error'
        // );
      }
    );
  }
  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }
  getMasterData() {
    this.contentService
      .getKmsContent('list', `page=${this.page}`)
      .subscribe((result: any) => {
        let obj: any = {};
        this.masterData = result.wrapperList.map((kms:any) => ({...kms, file: [{
          "id": (Math.random()*10).toFixed(),
          "contentId": (Math.random()*10).toFixed(),
          "fileUrl": kms.file,
          "fileSize": (Math.random()*10).toFixed(),
          "fileType": kms.file.split('.').pop(),
          "downloadCount": 0,
          "viewCount": 0,
      }]}));
      console.log('masterdata--->', this.masterData);

     let totalCount = this.masterData?.filter((element:any) => element?.approverName == element?.approverName)?.length
     console.log(totalCount);
    //  this.contentService.kmsCounter.next(totalCount)
      //   this.masterData.push({
      //     "id": 3,
      //     "fileName": "Dummy object",
      //     "fileType": "ppt",
      //     "file": "https://stgaajeevikaimg.dhwaniris.in/samplepptx_(1)_1654418755040.pptx",
      //     "crossStatePublication": "prashant",
      //     "language": "eng",
      //     "category": 232323,
      //     "source": "uuuu",
      //     "approverName": "prashant",
      //     "approverDesignation": "testing",
      //     "keyword": "usjs",
      //     "createdAt": "2022-06-05T08:32:41.958+00:00",
      //     "updatedAt": "2022-06-05T08:45:55.042+00:00",
      //     "deletedAt": null,
      //     "createdBy": 5,
      //     "updatedBy": 5,
      //     "deletedBy": null,
      //     "isDeleted": 1,
      //     "knowledgeMaterialVertical": "uuu"
      // })
        this.totalCount = result?.totalcount;

      });
  }
  checkDataTypes(data: any) {
    if (typeof data == 'string') {
      return data[0].toUpperCase() + data.slice(1);
    }
    return data;
  }
  typeOf(data: any) {
    return typeof data;
  }
  editData(rowData: any, isEdit: any = true) {
    console.log('record', rowData, isEdit);
    this.labelKeys = {};
    this.labelKeys = this.tableHeader.map(
      (item: any) => (this.labelKeys[item.key] = item.label)
    );
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    this.isEntry = true;
    this.submitButton = 'Update';
    this.dynamicForm = this.selectedModuleOptions.dynamicForm;
    this.isChecked = false;
  }

  pageEvent(event: any) {
    this.page = event;
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getMasterData();
  }

  resetFileType() {
    this.fileAcceptType = '';
    this.isEntry = false;
    console.log(this.selectedModuleOptions);
    console.log('masterData', this.masterData);
  }
  openDialog(fileList: any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent, {
      data: {
        fileList: fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  addCore(data:any) {
    console.log('viewdata---->', data)
    this.labelKeys = {};
    this.kmsId = data.id
    // if(!this.kmsId) this.rowData = {}
    this.tableHeader.forEach(
      (item: any) => (this.labelKeys[item.key] = item.label)
    );
    this.isEntry = true;
    this.isViewMode = false;
    this.submitButton = 'Submit';
  }
}

<div class="bg-light rounded-3 shadow h-100">
  <nav class="nav flex-column h-100 justify-content-between px-2">
      <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/case-studies']">Case Studies</a></li>
      <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/manuals']">Manuals</a></li> -->
      <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/guidelines']">Guidelines</a></li>
      <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/press-releases']">Press Releases</a></li>
      <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/publications']">Publications</a></li> -->
      <li class="nav-item"><a class="nav-link" [routerLink]="['/resource-center/annual-reports']">Annual Reports</a></li>
  </nav>
</div>

import { Directive, ElementRef, HostListener,OnInit } from '@angular/core';

@Directive({
  selector: '[appReadMore]',
})
export class ReadmoreDirective implements OnInit {
  button:any;
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add('less-text');
    console.log("readMore", this.el.nativeElement)
  }

  ngOnInit(){
    if(this.el.nativeElement.textContent.length > 305)
    this.el.nativeElement.innerHTML =  this.el.nativeElement.innerHTML.substring(0,300)+'<span id="dots" style="color: #d14c2b; cursor:pointer;">...Read more</span><span id="more" >'+this.el.nativeElement.innerHTML.substring(300)+'</span>';
  }

  @HostListener('click', ['$event']) onClick($event:any){
    console.log("readMoreClick", this.el.nativeElement)
    if(this.el.nativeElement.textContent.length > 305){
    if(this.el.nativeElement.classList.value.includes('less-text') )
    {
      this.el.nativeElement.classList.remove('less-text');
      this.el.nativeElement.classList.add('more-text');
    }else {
      this.el.nativeElement.classList.remove('more-text');
      this.el.nativeElement.classList.add('less-text');
    }
  }

  }
}

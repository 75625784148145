<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a class="nav-link">Knowledge Products</a>
          </li>
          <li>/</li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/knowledge-products/nrlm-documents']"
              routerLinkActive="router-link-active" (click)="getNrlmDocs()">NRLM Documents</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-2"> {{subModuleName}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    
    <div class="col-sm-8 mb-3 ">
      <div class="bg-white p-3 rounded-3 shadow">
        <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId" (searchParams)="searchParams($event)">
        </app-common-filters>
        <div class="row  align-items-center">
          <div class="d-flex justify-content-between">
            <p class="m-2">Showing {{totalCount}} Results</p>
            <div>
              <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
            </div>
            <div class="d-flex">
              <!-- <div class="dropdown m-2">
              <button class="btn btn-outline-primary dropdown-toggle d-flex justify-content-between " type="button"
                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <span>Most Recent First</span><span><img src="/assets/images/dropdown.svg" alt=""></span>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </div> -->
              <!-- <div class="mt-3">
                <button (click)="viewChange('card')" [ngClass]="{'active-btn': selectedType == 'card' }"
                  style="border-radius:3px; border: 0; width: 40px;">
                  <img *ngIf="selectedType == 'card'" src="/assets/images/grid_1.svg" alt="">
                  <img *ngIf="selectedType != 'card'" src="/assets/images/grid_2.svg" alt="">
                </button>
                <button (click)="viewChange('list')" [ngClass]="{'active-btn': selectedType == 'list' }"
                  style="border-radius:3px; border: 0;">
                  <img *ngIf="selectedType == 'list'" src="/assets/images/list_2.svg" alt="">
                  <img *ngIf="selectedType != 'list'" src="/assets/images/list_1.svg" alt="">
                </button>
              </div> -->
            </div>
          </div>
          <div *ngIf="totalCount!==0;else noData; else noData">
            <div class="" *ngIf="selectedType == 'card'">
              <div class="row mb-4 mt-4 cardStyle g-0">
                <div class="col-sm-4 " *ngFor="let item of cardData">
                  <div class="card mx-2 mb-2 p-1">
                    <div style="width: 100%; ">
                      <img [src]="geturl(item)" style="cursor: pointer;" height='190'
                      (click)="openDialog(item.fileList)" class="card-img-top" alt="...">
                    </div>
                    <div class="card-body">
                      <div class="mb-1 text-truncate" style="cursor: pointer;" matTooltip="{{item?.title}}">
                        <!-- <img src="/assets/images/pdf.svg" alt="" style="width: 20px;"> -->
                        <a (click)="openDialog(item.fileList)">{{item?.title}}</a>
                      </div>
                      <div>
                        <!-- <ng-container *ngIf="" > -->
                          <ng-container *ngIf="item.publishedDate"><small > Published Date: {{item?.publishedDate | date:'mediumDate'}}<br></small></ng-container>
                          <ng-container *ngIf="item.author"><small > Author: {{item?.author }}</small><br></ng-container>
                          <ng-container *ngIf="item.source"><small *ngIf="item.source"> Source: {{item?.source }}</small></ng-container>

                        <!-- </ng-container> -->

                      </div>
                      <div><img src="/assets/images/download.svg" alt="">
                        <small>Total Downloads: <strong>{{getTotalDownload(item.fileList)}}</strong></small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedType == 'list'">
              <div class="row mb-4 mt-2">
                <div class="card mb-2" *ngFor="let item of cardData">
                  <div class="row g-0">
                    <div class="col-sm-5 d-flex align-items-center">
                      <img [src]="geturl(item)" class=" h-75" alt="..." style="cursor: pointer;"
                      (click)="openDialog(item.fileList)">
                    </div>
                    <div class="col-sm-7 d-flex align-items-center">
                      <div class="card-body">
                        <!-- <img src="/assets/images/pdf.svg" alt="" style="width: 20px;"> -->
                        <a style="cursor: pointer;"
                          (click)="openDialog(item.fileList)">{{item?.title}}</a>
                        <p class="mb-1">{{item?.description}}</p>
                        <small>Published Date: {{item?.publishedDate | date:'mediumDate'}}</small>
                        <div><img src="/assets/images/download.svg" alt="">
                          <small>Total Downloads: <strong>{{getTotalDownload(item.fileList)}}</strong></small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noData>
            <div class="row mb-4 mt-4">
              <div style="text-align: center;margin-top: 50px;">
                <h1>
                  No Data Found
                </h1>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

    </div>
    <div class="col-sm-4 mb-3 position-relative" style="z-index: 0;">
      <div class="bg-white p-3 rounded-3 shadow sticky-top">
        <nav class="nav flex-column justify-content-between px-2 menu-cust" *ngFor="let item of subModules">
          <li class="nav-item"  style="cursor: pointer;"><a class="nav-link"
              (click)="changeModule(item)" [ngClass]="{'linkColor':currentSubModuleId == item.id }">{{item?.subModuleName}}</a></li>
        </nav>
      </div>
    </div>


  </div>

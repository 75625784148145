import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  photoGallery: any = [];
  totalItem = 0;
  page = 1;
  params:any = "";
  filterConfig = {
    globalSearch:true,
    search:true,
    author:false,
    source:false,
    from:true,
    to:true,
    fileType:false
  }
  moduleId=8
  constructor(
    private contentService: ContentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getPhotoGallery();
  }
  getPhotoGallery(){
    this.contentService.getContent("list", `moduleId=11&subModuleId=15&page=${this.page}&${this.params}`).subscribe(
      (data: any) => {
        this.photoGallery = data.wrapperList;
        this.totalItem = data.totalcount;
        console.log(this.photoGallery);

      }
    );
  }
  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
        type:'image'
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  pageEvent(event: any) {
    this.page = event;
    this.getPhotoGallery();
  }
  searchParams(event: any){
    let searchParams  = new URLSearchParams(event).toString();
    this.params = searchParams;
    this.page = 1;
    this.getPhotoGallery()
  }

  getImageThumbnail(item:any){
    let imageUrl = item.fileList && item.fileList.length && item.fileList[0].fileUrl ? item.fileList[0]?.fileUrl : '/assets/images/women.png'; 
    return imageUrl;
  }

}

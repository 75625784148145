<div class="header-image">
    <img src="/assets/images/ajjevika-banner-Partnership-Blocks.jpg" alt="Partnership Blocks" title="Partnership Blocks" class="img-fluid" />
    <div class="page-title">Partnership Blocks</div>
</div>
<div class="container">
    <div class="row my-3">
        <div class="col">
            <div class="bg-white rounded-3 shadow">
                <ul class="nav align-items-center">
                    <li class="nav-item">
                        <a href="/" class="nav-link ">Home</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <a href="/work-structure" class="nav-link ">Work Structure</a>
                    </li>
                    <li class="">/</li>
                    <li class="nav-item">
                        <div class="px-3">Partnership Blocks</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9">
            <div class="card border-0 rounded-2 shadow mb-3">
                <div class="card-body">
                    <p>SRLMs take up implementation in blocks where there is pre-existing significant mobilization of poor women
                        into SHGs as partnership blocks in partnership with Community Federations and the NGOs working in these
                        blocks. NRLM has developed Partnership Guidelines to identify and work with NGOs and CBOs. NGOs that
                        have significant presence (done 80% mobilization or promoted a SHG federation) in a block are identified
                        as partner NGOs. These NGOs are expected to follow NRLM’s implementation protocols and processes in the
                        blocks.</p>
                </div>
            </div>
        </div>
        <div class="col-sm-3 mb-3">
            <app-work-structure-menu></app-work-structure-menu>
        </div>
    </div>
</div>
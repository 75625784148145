<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
  <ng-container *ngIf="carouselImages?.length; else noData">
    <div class="carousel-indicators">
      <ng-container *ngFor="let item of carouselImages;let i = index">
        <button type="button" class="carousel-indicators-icon" data-bs-target="#carouselExampleCaptions"
          [attr.data-bs-slide-to]="i" [ngClass]="{'active': i==0}" aria-current="true" aria-label="Slide"></button>
      </ng-container>
    </div>

    <div class="carousel-inner">
      <ng-container *ngFor="let item of carouselImages;let i = index">
        <div class="carousel-item " [ngClass]="{'active': i==0}">
          <img [src]="item" class="d-block w-100 " style="height: auto; max-height: 470px;" alt="banner_modiji">
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noData>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="/assets/home/banner_modiji@1x.png" class="d-block w-100" alt="banner_modiji">
      </div>
    </div>
  </ng-template>
  <button *ngIf="carouselImages?.length" class="carousel-control-prev" type="button"
    data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button *ngIf="carouselImages?.length" class="carousel-control-next" type="button"
    data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
<div class="bg-pattern text-center wrapper">
  <div class="green-text-cont p-2 lines">
      <div class="ticker">
        <span class="text-decorate">
          {{tickerString}}
        </span>
      </div>
  </div>
</div>
<div>
  <!-- Starting carousel... -->

  <!-- Welcome Section... -->
  <div class="welcomeBg">
    <div class="container gradientPadding">
      <div class=" bg-pattern">
        <div class="wel-cont white-card rounded-3 p-3" id="main-content">
          <div class="row align-items-center">
            <div class="col-md-9 col-lg-9 col-sm-9 mb-3 mb-lg-0">
              <div class="info-over">
                <div class="image">
                  <p class="wel-para1 ms-2 mt-4">
                    Aajeevika - Deendayal Antyodaya Yojna-National Rural Livelihoods Mission (DAY-NRLM) was launched by the
                    Ministry
                    of Rural Development (MoRD), Government of India in June 2011.
                  </p>
                  <p class="wel-para2 ms-2 mt-4">
                    Aided in part through investment support by the World Bank, the Mission aims at creating
                    efficient and effective institutional platforms of the rural poor, enabling them to
                    increase household income through sustainable livelihoods enhancements and improved
                    access to financial services.
                  </p>
                </div>
                <!-- <img src="http://lorempixel.com/300/200" alt="Avatar" class="image">  -->
                <div class="infoText">
                 <span class="vl-custom"></span>

                  <h2 class="ms-2 titleHead">
                    
                    &nbsp;Welcome to
                    <br>
                    &nbsp;Deendayal Antyodaya Yojana 
                    <br>
                    &nbsp;National Rural Livelihoods Mission
                  </h2>
                </div>
              </div>
              
              <!-- <h4 class="wel-title">
                <span class="vl"></span>
                Welcome to Deendayal Antyodaya Yojana - National Rural Livelihoods Mission
              </h4> -->
              
              <div>
                <a class="btn btn-readmoreWelcome" [routerLink]="['/about/introduction']">
                  Read More<span class="ms-2">&#62;</span>
                </a>
              </div>
            </div>
            <div class="col-md-3 col-sm-3 col-lg-3 my-2">
              <img src="/assets/home/womens.jpg" class="img-fluid" alt="womens">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Highlights and What's New?? Section... -->
  <div class="whatsnewBg">
     <div class="container">
      <div class="">
        <div class="row">
          <div class="col-md-8 col-lg-8 col-sm-8 mb-3">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-lg-12">
                <h3 class="p-3 m-0">
                  What's New?
                </h3>
                <div class="px-3 mb-3">
                  <button class="btn me-3" [ngClass]="(selectedTab == 'update') ? 'btn-active' : 'btn-pending'"
                    (click)="changeTab('update')">Updates</button>
                  <button class="btn me-3" [ngClass]="(selectedTab == 'letters') ? 'btn-active' : 'btn-pending'"
                    (click)="changeTab('letters')">Letters/Circulars</button>
                  <button class="btn me-3" [ngClass]="(selectedTab == 'events') ? 'btn-active' : 'btn-pending'"
                    (click)="changeTab('events')">Events</button>
                  <button class="btn me-3" [ngClass]="(selectedTab == 'tenders') ? 'btn-active' : 'btn-pending'"
                    (click)="changeTab('tenders')">Procurement</button>
                  <button class="btn me-3" [ngClass]="(selectedTab == 'media') ? 'btn-active' : 'btn-pending'"
                    (click)="changeTab('media')">Media/News</button>
                </div>
              </div>
            </div>
            <div class="bg-white shadow rounded-3 gradientBottom">
              
              <div class="hl-contentsContainer px-3">
                <!-- Update Tab Content -->
                <ng-container *ngIf="selectedTab == 'update' && selectedTabData.length">
                  <ng-container *ngFor="let item of selectedTabData">
                    <a style="cursor: pointer;" (click)="navigateTo(item)" class="hoverLink">
                      {{item.title}}
                    </a>
    
                    <hr class="">
                  </ng-container>
                </ng-container>
                <!-- Letters and Circulars Tab Content -->
                <ng-container *ngIf="selectedTab == 'letters' && selectedTabData.length">
                  <!-- <ng-container *ngIf="selectedTabData; else noContent" > -->
                  <ng-container *ngFor="let values of selectedTabData">
                    <a style="cursor: pointer;" (click)="navigateTo(values)" class="hoverLink">
                      {{values.title}}
                      <p class="small text-muted">
                        {{values.publishedDate | date :'mediumDate'}}
                      </p>
                      <hr class="">
                    </a>
                  </ng-container>
                  <!-- </ng-container> -->
                </ng-container>
                <!-- Tenders Tab Content -->
                <ng-container *ngIf="selectedTab == 'tenders'&& selectedTabData.length">
                  <!-- <ng-container *ngIf="selectedTabData; else noContent" > -->
                  <ng-container *ngFor="let obj of selectedTabData">
                    <a style="cursor: pointer;" (click)="navigateTo(obj)" class="hoverLink">
                      {{obj.title}}
                    </a>
                    <p class="small text-muted">
                      {{obj?.issuedDate | date :'mediumDate'}}
                    </p>
                    <hr class="">
                  </ng-container>
                  <!-- </ng-container> -->
                </ng-container>
                <!-- Events Tab Content -->
                <ng-container *ngIf="selectedTab == 'events' && selectedTabData.length">
                  <!-- <ng-container *ngIf="selectedTabData; else noContent" > -->
                  <ng-container *ngFor="let event of selectedTabData">
                    <a style="cursor: pointer;" (click)="navigateTo(event)" class="hoverLink">
                      {{event.title}}
                    </a>
                    <p class="small text-muted">
                      {{event?.publishedDate | date :'mediumDate'}}
                    </p>
                    <hr class="">
                  </ng-container>
                  <!-- </ng-container> -->
                </ng-container>
                <ng-container *ngIf="selectedTab == 'media'">
                  <!-- <ng-container *ngIf="selectedTabData; else noContent" > -->
                  <ng-container *ngFor="let event of selectedTabData">
                    <a href="{{event.link}}" target="_blank" style="cursor: pointer;" class="hoverLink text-decoration-none">
                      {{event.title}}
                      <p class="small text-muted">
                        {{event.publishedDate | date :'mediumDate'}}
                      </p>
                    </a>
                    <hr class="">
                  </ng-container>
                  <!-- </ng-container> -->
                </ng-container>
                <ng-template #noContent>Coming soon</ng-template>
              </div>
              <div class="border-top-0 text-center">
                <button class="btn btn-viewmore" (click)="navigatiToPage(selectedTab)" [disabled]="!selectedTabData">View
                  All<span class="ms-2">&#62;</span></button>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4 mb-3">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 mb-3">
                <div class="bg-white shadow rounded-3 marginNew">
                  <div class="card-title m-0 highlights">
                    <h3 class="p-3 m-0">
                      Highlights
                    </h3>
                  </div>
                  
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12 col-lg-12 col-sm-12 mb-3">
                <div class="bg-white shadow rounded-3 mt-4 highlightBorder">
                  <div class="card-body highlights-card-height py-2" style="overflow-y: auto;max-height: 40px;">
                    <ng-container *ngIf="Highlights.length;else noHighlights">
                      <ng-container *ngFor="let item of Highlights">
                        <div class="text-start">
                          <a style="cursor: pointer;" (click)="navigateTo(item)" class="text-decoration-none highlightText">
                            {{item.title}}
                          </a>
                        </div>
                        <hr class="">
                      </ng-container>
                    </ng-container>
                    <ng-template #noHighlights>
                      <div class="row mb-4 mt-4">
                        <div style="text-align: center;margin-top: 50px;">
                          <h6>
                            No Highlights Available
                          </h6>
                        </div>
        
                      </div>
                    </ng-template>
                  </div>
                  <div class="card-footer border-top-0 bg-white customHl" [routerLink]="['/highlights']">
                    <button class="btn btn-readmoreHighlights">Read More<span class="ms-2">&#62;</span></button>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
     </div>
  </div>

  <!-- Media Section -->
  <!-- <div class="mediaBgHome mt-5">
    <div class="container">
      <div class="rounded">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <h3 class="k-title p-3 m-0">
              Social Media
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12">
            <mat-tab-group mat-align-tabs="start" color="white" (selectedTabChange)="tabChanged($event)">
              <mat-tab label="Youtube" [ngClass]="{'mediaActive' : mediaActive}">
                <div id="gallery" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                      <div class="carousel-item active">
                          <div class="row">
                               
                                <div class="col-4">
                                  <div class="mediacard">
                                    <iframe class="rounded w-100" src="https://www.youtube.com/embed/Ifb1N-RXXM4"
                                  title="YouTube video player" frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowfullscreen>
                                  </iframe>
                               
                                  <h6 class="mt-4">
                                    National Discourse on Food, Nutrition, Health & WASH
                                  </h6>
                                  <p>Video Published date: </p><small>25th March 2022</small>
                                  </div>
                                </div>
              
                          </div>
                      </div>
              
                      <div class="carousel-item">
                          <div class="row">
                            <div class="col-4">
                              <div class="mediacard">
                                <iframe class="rounded w-100" src="https://www.youtube.com/embed/Ifb1N-RXXM4"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                                </iframe>
                             
                                <h6 class="mt-4">
                                  National Discourse on Food, Nutrition, Health & WASH
                                </h6>
                                <p>Video Published date: </p><small>25th March 2022</small>
                              </div>                
                            </div>      
                          </div>
                      </div>
                  </div>
              
                  <a class="carousel-control-prev" href="#gallery" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                  </a>
              
                  <a class="carousel-control-next" href="#gallery" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                  </a>
                </div>
    
              </mat-tab>
              <mat-tab label="Facebook">
                <div id="gallery" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                      <div class="carousel-item active">
                          <div class="row">
                              <div class="col-4 media">
                                <iframe
                          src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAAjeevika%2F&tabs=timeline&width=380&height=480&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                          class="rounded w-100 h-100 iframeCustom" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                          allowfullscreen="true"
                          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                             
                               
                              </div>
                          </div>
                      </div>
              
                    
                  </div>
              
                  <a class="carousel-control-prev" href="#gallery" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                  </a>
              
                  <a class="carousel-control-next" href="#gallery" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                  </a>
                </div>
          
              </mat-tab>
              <mat-tab label="Twitter">
                <div id="gallery" class="carousel slide" data-ride="carousel">
                  <div class="carousel-inner">
                      <div class="carousel-item active">
                          <div class="row">
                              <div class="col-4 media">
                                <iframe style="border:none;overflow:hidden" class="rounded w-100 h-100 iframeCustom"
                          src="data-tweet-url=https://twitter.com/DAY_NRLM"
                          src="data:text/html;charset=utf-8,%3Ca%20class%3D%22twitter-timeline%22%20href%3D%22https%3A//twitter.com/DAY_NRLM%3Fref_src%3Dtwsrc%255Etfw%22%3ETweets%20by%20DAY_NRLM%3C/a%3E%0A%3Cscript%20async%20src%3D%22https%3A//platform.twitter.com/widgets.js%22%20charset%3D%22utf-8%22%3E%3C/script%3E%0A"
                          allowfullscreen="true"></iframe>
                             
                                
                              </div>
              
    
                          </div>
                      </div>
              
                      
                  </div>
              
                  <a class="carousel-control-prev" href="#gallery" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                  </a>
              
                  <a class="carousel-control-next" href="#gallery" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                  </a>
                </div>
              </mat-tab>
            </mat-tab-group>
            
            <div class="card-footer border-top-0" style="text-align:center;background: none;">
              <a class="btn btn-readmoreWelcome mediaViewAll mb-3" target="_blank" [href]="index">
                View All<span class="ms-2">&#62;</span>
              </a>
            </div>
          </div>
        </div>
        
      </div>
      
    </div>
  </div>   -->
  

  <!-- Knowledge Products Section -->
  <!-- <div class="kpProductBg">
    <div class="container">
      <div class="rounded knowledgeProducts">
        <h3 class="p-3 m-0 k-title">
          
          Knowledge Products
        </h3>
        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="row customCard">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="card card1">
                  
                  <div class="info">
                    
                </div>
              </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="kp-title mt-3">
                  <h5>
                    National Repository
                  </h5>
                  </div>
                  <div>
                    <p class="desc">
                      NRLM National Repository is a one-stop-shop knowledge source on the programme.
                    </p>
                  </div>
                <button class="btn btn-readmoreWelcome mt-3" [routerLink]="['/knowledge-products/national-repository']">View
                  All<span class="ms-2">&#62;</span></button>
              </div>
            </div>

          </div>
    
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="row customCard">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="card card2">
                  <div class="info">
                   
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="kp-title mt-3">
                  <h5>
                    DAY-NRLM Documents
                  </h5>
                  </div>
                  <div>
                    <p class="desc">
                      NRLM National Repository is a one-stop-shop knowledge source on the programme.
                    </p>
                  </div>
                <button class="btn btn-readmoreWelcome mt-3" [routerLink]="['/knowledge-products/nrlm-documents']">View All<span class="ms-2">&#62;</span></button>
              </div>
            </div>
            
          </div>
          
        </div>
        <div class="row mt-3">
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="row customCard">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="card card3">
                  <div class="info">
                    
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="kp-title mt-3">
                  <h5>
                    Newsletters
                  </h5>
                  </div>
                  <div>
                    <p class="desc">
                      Inspiring stories of rural women who have risen out of abject poverty with the help of their institutions.
                    </p>
                  </div>
                <button class="btn btn-readmoreWelcome mt-3" [routerLink]="['/knowledge-products/newsletters']">View All<span class="ms-2">&#62;</span></button>
              </div>
            </div>
            
          </div>
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="row customCard">
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="card card4">
                  <div class="info">
                
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-6">
                <div class="kp-title mt-3">
                  <h5>
                    Success Stories
                  </h5>
                </div>
                <div>
                  <p class="desc">
                    Inspiring stories of rural women who have risen out of abject poverty with the help of their institutions.
                  </p>
                </div>
                <button class="btn btn-readmoreWelcome mt-3" [routerLink]="['/our-impact/success-stories']">View All<span class="ms-2">&#62;</span></button>
              </div>
            </div>
            
          </div>
        </div>
        
       
          <div class="col-md-3 mb-2">
            <div class="kp-bgDark d-flex justify-content-center align-items-center flex-column ms-3">
              <img src="/assets/home/nrlmrepo.png" alt="graduation-cap">
              <a class="text-decoration-none">
                <h5 class="kp-title">
                  National Repository
                </h5>
              </a>
              <button class="btn b-outline kp-btn" [routerLink]="['/knowledge-products/national-repository']">View
                All</button>
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="kp-bgDark d-flex justify-content-center align-items-center flex-column">
              <img src="/assets/home/document.png" alt="documents-icon">
              <a class="text-decoration-none">
                <h5 class="kp-title">
                  DAY-NRLM Documents
                </h5>
              </a>
              <button class="btn b-outline kp-btn" [routerLink]="['/knowledge-products/nrlm-documents']">View All</button>
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="kp-bgDark d-flex justify-content-center align-items-center flex-column">
              <img src="/assets/home/newsletter.png" alt="email-icon">
              <a class="text-decoration-none">
                <h5 class="kp-title">
                  Newsletters
                </h5>
              </a>
              <button class="btn b-outline kp-btn" [routerLink]="['/knowledge-products/newsletters']">View All</button>
            </div>
          </div>
          <div class="col-md-3 mb-2">
            <div class="kp-bgDark d-flex justify-content-center align-items-center flex-column me-3">
              <img src="/assets/home/success.png" alt="book-flip-icon">
              <a class="text-decoration-none">
                <h5 class="kp-title">
                  Success Stories
                </h5>
              </a>
              <button class="btn b-outline kp-btn" [routerLink]="['/our-impact/success-stories']">View All</button>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div> -->   
  

  <!-- Quick Links Section ... -->
  <div class="white-card rounded">
    <h3 class="text-center k-title">
      
      Quick Links
    </h3>
    <!-- <div class="row px-3">
        <div class="col-md-2 mb-2 mb-md-0">
          <a href="https://nrlm.gov.in/outerReportAction.do?methodName=showIndex#gsc.tab=0">
          <div class="card">
            <img class="img-fluid" src="/assets/home/day-nrlm-mis.png">
          </div>
          </a>
        </div>
        <div class="col-md-2 mb-2 mb-md-0">
          <a href="http://mksp.gov.in/">
            <div class="card">
              <img class="img-fluid" src="/assets/home/mksp.png">
            </div>
          </a>
        </div>
        <div class="col-md-3 mb-2 mb-md-0">
          <a href="https://daynrlmbl.aajeevika.gov.in/">
            <div class="card">
              <img class="img-fluid" src="/assets/home/shg-bank-linkage.png">
            </div>
          </a>
        </div>
        <div class="col-md-2 mb-2 mb-md-0">
          <a href="https://daynrlmbl.aajeevika.gov.in/">
            <div class="card">
              <img class="img-fluid" src="/assets/home/shg-bank-linkage.png">
            </div>
          </a>
        </div>
        <div class="col-md-2">
          <a [routerLink]="['/other-useful-links']">
            <div class="card">
              <img class="img-fluid" src="/assets/home/useful.png">
            </div>
          </a>
        </div>
      </div> -->
    
    <div class="container d-flex flex-column flex-md-row justify-content-center align-items-center mt-4">
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard">
        <a href="https://nrlm.gov.in/outerReportAction.do?methodName=showIndex#gsc.tab=0" target="_blank">
          <div class="card">
            <img class="img-fluid" src="/assets/home/day-nrlm-mis.png">
          </div>
        </a>
      </div>
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard">
        <a href="http://mksp.gov.in/" target="_blank">
          <div class="card">
            <img class="img-fluid" src="/assets/home/mksp.png">
          </div>
        </a>
      </div>
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard">
        <a href="https://daynrlmbl.aajeevika.gov.in/" target="_blank">
          <div class="card">
            <img class="img-fluid" src="/assets/home/shg-bank-linkage.png">
          </div>
        </a>
      </div>
      
    </div>
    <div class="container d-flex flex-column flex-md-row justify-content-center align-items-center mt-4">
      
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard">
        <a href="http://ddugky.gov.in/" target="_blank">
          <div class="card">
            <img class="img-fluid" src="/assets/home/ddu.png">
          </div>
        </a>
      </div>
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard">
        <a [routerLink]="['/other-useful-links']" target="_blank">
          <div class="card">
            <img class="img-fluid" src="/assets/home/useful.png">
          </div>
        </a>
      </div>
      <div class="box me-0 me-md-4 mb-2 mb-md-0 customCard" style="border: 1px solid #dfbbbb;">
        <a href="http://97.74.87.6/NRLMApp/#/login" target="_blank" style="text-decoration: none;color:#155889">
          <div class="card">
            <h3 class="addedCard img-fluid">Knowledge Management System</h3>
          </div>
        </a>
      </div>
    </div>
    <!-- <div id="dataInfo">
        <div id="myCarousel" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">

            </div>
          </div>
          <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
          <div class="carousel-indicators">
            <button type="button" class="carousel-indicators-icon" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" class="carousel-indicators-icon" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" class="carousel-indicators-icon" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
        </div>
      </div> -->
      
  </div>

</div>

<div class="modal fade" id="homeModal" tabindex="-1" aria-labelledby="homeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" style="width: 570px;">
    <div class="modal-content">
      <div class="modal-header" style="border:none">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <!-- <div class="col-sm-8">
            <h1 class="title text-center">Welcome to DAY - NRLM</h1>
            <h4 class="wel-para p-4">
              <ng-container>Deendayal Antyodaya Yojana - National Rural Livelihoods Mission (DAY-NRLM) was launched by the Ministry of Rural Development (MoRD), Government of India in June 2011. Aided in part through investment support by the World Bank, the Mission aims at creating efficient and effective institutional platforms of the rural poor, enabling them to increase household income through sustainable livelihood enhancements and improved access to financial services.</ng-container>
            </h4>

          </div>
          <div class="col-sm-4">
            <ng-container *ngIf="popupDoc.length; else defaultImage"><img class="img-fluid" style="height: 250px;"
                src="{{popupDoc[0]?.file_url}}" alt="welcome-popup img"></ng-container>
            <ng-template #defaultImage><img class="img-fluid" style="height: 250px;"
                src="/assets/images/welcome-popup.png" alt="welcome-popup img"></ng-template>
          </div> -->
         <div class="col-12">
                <img src="../../../assets/images/G20theme.png" style="width: 100%;
        height: 262px;" alt="">
         </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row mt-3">
      <div class="mb-3">
          <div class="bg-white p-4">
              <h3>Highlights</h3>
              <ng-container *ngFor="let item of highlights">
              <h6>{{item.title}} </h6>
              <p class="text-truncate">
                Click here for more :  <a style="cursor:pointer;color: #1D3A7C;" (click)="navigateTo(item)">{{item.title}}</a>
              </p>
              <p>
               <!-- Last Date to Apply: {{item.lastDateSubmission | date : 'mediumDate'}} -->
              </p>
              <hr>
            </ng-container>
          </div>
      </div>

  </div>
</div>

import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  SimpleChange,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  // Current page number
  currentPageNumber: number = 1;
  // Total records count
  totalRecordsCount: number = 0;
  // Total pages
  totalPages: number = 0;
  
  constructor() { }

  @Input() totalCount: string = '';
  // @Input() 

  ngOnInit(): void {
    // this.totalRecordsCount = this.totalCount;
  }

}

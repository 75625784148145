<div class="header-image" *ngIf="!isSubModuleOpen">
  <img
    src="/assets/images/repository.jpg"
    alt="Introduction"
    title="Introduction"
    class="img-fluid"
  />
  <div class="page-title">NRLM National Repository</div>
</div>
<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/about" class="nav-link">Knowledge Products</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: Pointer;" (click)="resetToNrmlRepo()"
              >NRLM National Repository</a
            >
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a class="nav-link" style="cursor: Pointer;">
              {{subModuleName}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-9 mb-3">
      <ng-container *ngIf="isSubModuleOpen">
        <div class="mb-3 bg-white">

          <app-common-filters [filterConfig]="filterConfig" [moduleId]="moduleId" (searchParams)="searchParams($event)"></app-common-filters>

          <div class="d-flex justify-content-between">
            <p class="m-2" style="padding: 10px;">Showing {{totalCount}} Results</p>
            <div>
              <!-- <mat-paginator #Paginator
                  (page)="pageEvent($event)"
                  [length]="totalCount"
                  [pageSize]="20"
                  aria-label="Select page">
            </mat-paginator> -->
            <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)" ></app-paginator>
            </div>
          </div>
          <div *ngIf="totalCount !== 0; else noData">
            <div class="row mb-4 mt-4 cardStyle"  >
              <div class="col-sm-4 " *ngFor="let item of cardData" >
                <div class="card mx-2 mb-2 p-3" style="font-size: 13px;">
                  <div style="width: 100%; ">
                  <img  class="card-img-top" style="cursor: pointer;" [src]="subModuleName == 'Videos'?getYouTubeFrame(item.link):geturl(item)" height="190"  (click)="openDialog(subModuleName == 'Videos'?item.link:item.fileList)"  alt="no image">
                  <div [ngClass]="{'youtube-play-btn': subModuleName == 'Videos'}"></div>
                  <div class="card-img-top img-class" ></div>
                  </div>
                  <div class="card-body" >
                    <div class="mb-1 text-truncate" matTooltip="{{item?.title}}">
                      <!-- <img src="/assets/images/pdf.svg" alt="" style="width: 20px;"> -->
                      <ng-container *ngIf="subModuleName == 'Videos'; else defaultTarget"><a [href]="item.link" target="_blank"
                        >{{item?.title}}</a></ng-container>
                      <ng-template #defaultTarget><a  style="cursor: pointer;" (click)="openDialog(item.fileList)"
                        >{{item?.title}}</a></ng-template>
                    </div>
                    <div *ngIf="subModuleName !== 'Videos'" ><img src="/assets/images/download.svg" alt="">
                      <small>Total Downloads: <strong>{{getTotalDownload(item.fileList)}}</strong></small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noData>
            <div class="row">
              <div class="img-style text-center">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <h1>No Data Found!</h1>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-container>
      <div class="card border-0 rounded-2 shadow mb-3" *ngIf="!isSubModuleOpen">
        <div class="card-body">
          <p>
            NRLM National Repository is a one-stop-shop knowledge source on the
            programme. It covers the various sectors of thematic operations of NRLM
            viz., Institution Building, Social Inclusion and Social Development,
            Financial Inclusion and Livelihoods. The Repository aims at providing a
            detailed account of NRLM implementation. It is a compilation of a
            variety of knowledge products including case studies, concept briefs,
            guidance notes, training modules etc. in the form of videos, documents,
            brochures and booklets catalogued in the various themes. The Repository
            consists of knowledge resource materials developed by NRLPS and the
            SRLMs. The resource materials trace the evolution of NRLM from the SGSY
            programme, its launch thereafter and the approach and strategies of
            implementation. It consists of case studies that provide an
            understanding of how NRLM works at the micro level and also the various
            policies adopted by the national and state levels that shape the course
            of the programme. It is also a platform for cross-learning across SRLMs
            and partners of NRLM. This Repository would also be useful to
            development policymakers, students, practitioners, academicians in
            particular and the society in general. The Repository will be constantly
            updated by a dedicated team set up at NRLPS with resource materials that
            are developed within the NRLM system.
          </p>
          <!-- <div>
            <app-sharedChart></app-sharedChart>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-sm-3 mb-3"  style="z-index: 0;">
      <!-- <app-knowledge-products-menu></app-knowledge-products-menu> -->
      <div class="bg-light rounded-3 shadow sticky-top">
        <nav

          class="nav flex-column justify-content-between px-2 menu-cust"
          *ngFor="let item of subModules"
        >
          <li class="nav-item">
            <a
              class="nav-link"
              (click)="changeModule(item)"
              [ngClass]="{'linkColor':item.id==selectedSubModule.id }"
              >{{ item.subModuleName }}</a
            >
          </li>
        </nav>
      </div>
    </div>
  </div>


</div>

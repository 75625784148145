import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor() { }
  dashBoardCards=[
    {
      cardTitle:"GEOGRAPHICAL COVERAGE ",
      cards:[
        {
          value:"34",
          detail:"States/Union Territories transited to NRLM",
        },  
        {
          value:"682",
          detail:"Districts with intensive blocks",
        },  
        {
          value:"6290",
          detail:"Blocks where intensive implementation has started",
        },  
        {
          value:"232625",
          detail:"Gram Panchayats where intensive implementation has started",
        },  
        {
          value:"741448",
          detail:"Villages where intensive implementation has started",
        }  
      ]
    },
    {
      cardTitle:"PROGRESS IN INTENSIVE BLOCKS (INCLUDES NRLM-EAP/ STATE PROJECTS)",
      cards:[
        {
          value:"677.5L",
          detail:"households mobilized into SHGs",
        },  
        {
          value:"61.7L",
          detail:"SHGs promoted ",
        },  
        {
          value:"245572",
          detail:"Village Organizations promoted",
        },  
        {
          value:"1738337",
          detail:"SHGs provided Revolving Fund",
        },  
        {
          value:"235553.7L",
          detail:"Revolving Fund disbursed to SHGs",
        },  
        {
          value:"1035731",
          detail:"SHGs provided Community Investment Fund (CIF)",
        },  
        {
          value:"660861.4L",
          detail:"Community Investment Fund disbursed to SHGs ",
        },  
        {
          value:"307696",
          detail:" Community Resource Persons developed",
        }  
      ]
    },
    {
      cardTitle:"PROGRESS UNDER SHG BANK LINKAGE ",
      cards:[
        {
          value:"6488800",
          detail:"SHG have bank loan outstanding ",
        },  
        {
          value:"104814.17Cr",
          detail:"Outstanding",
        },  
        {
          value:"2008383",
          detail:"SHGs availed loan amount",
        },  
        {
          value:"27336.96Cr",
          detail:"Disbursed  ",
        }
      ]
    },
    {
      cardTitle:"COVID-19 RESPONSE",
      cards:[
        {
          value:"168927854",
          detail:"Masks manufactured by SHGs ",
        },  
        {
          value:"529741",
          detail:"Protective Equipment manufactured by SHGs ",
        },  
        {
          value:"513059 liter",
          detail:"Sanitizer manufactured by SHGs",
        },  
        {
          value:"122682",
          detail:"Community Kitchen operational by SHGs ",
        }
      ]
    },
    {
      cardTitle:"FARM LIVELIHOOD PROGRESS ",
      cards:[
        {
          value:"810156",
          detail:"Mahila Kisans covered under Agro Ecological Practice interventions",
        },  
        {
          value:"893450",
          detail:"Mahila Kisan households having Agri Nutri Garden",
        },  
        {
          value:"54312",
          detail:"Villages covered under Farm Livelihood interventions",
        },  
        {
          value:"78988",
          detail:"Mahila Kisans organized into Farmer Producer Organizations",
        }
      ]
    },
    {
      cardTitle:"SVEP PROGRESS ",
      cards:[
        {
          value:"0",
          detail:"Entrepreneurs",
        }
      ]
    }
    
  ]

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.scss']
})
export class UpdatesComponent implements OnInit {

page=1
totalCount=0
  constructor(private contentService:ContentService,private dialog:MatDialog,private router:Router) { }
  updates:any
  ngOnInit(): void {
    this.getUpdates();
  }
  getUpdates(){
    this.contentService.getContent('getupdatecontent',`page=${this.page}`).subscribe((res:any)=>{
      if(res){
        this.updates = res && res['wrapperList'].length && res['wrapperList'];
        this.totalCount = res.totalcount;
      }
    },error=>{
      console.log(error)
    })
  }

  openDialog(fileList:any) {
    const dialogRef = this.dialog.open(CommonGalleryDialogComponent,{
      data:{
        fileList:fileList,
      },
      width: '800px',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getUpdates();
  }
}

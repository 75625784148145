<div
  class="bg-white rounded-3 shadow p-4 my-3"
  *ngIf="!isEntry; else submitEntry"
>
  <div class="row">
    <div class="col">
      <h3 class="text-black">{{ entryTitle }}</h3>
    </div>
    <div class="col text-end" *ngIf="checkAvailablitiy('/aajeevika/admincontent/save')">
      <a
        class="btn btn-bg"
        (click)="
          addCore()
        "
      >
        <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
        Add
      </a>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-3">
      <select
        name="select category"
        id="category"
        (change)="onSelectionChange($event)"
        class="form-select"
      >
        <optgroup
          *ngFor="let optGroup of catageoryOption"
          [label]="optGroup?.label"
        >
          <option
            *ngFor="let option of optGroup.options"
            [selected]="
              option.moduleId == selectedModuleOptions.moduleId &&
              option.subModuleId == selectedModuleOptions.subModuleId
            "
            [value]="option?.value"
          >
            {{ option?.label }}
          </option>
        </optgroup>
      </select>
    </div>
    <div class="col-md-9"></div>
    <!-- <div class="col-auto">
            <select class="form-select" id="selectOptions">
                <option selected>Select Category</option>
                <option value="1">Success Stories</option>
                <option value="2">Fact Sheets</option>
                <option value="3">Milestones</option>
                <option value="4">Case Studies</option>
                <option value="5">Manuals</option>
                <option value="6">Letters/Circulars</option>
                <option value="7">Tenders</option>
                <option value="8">Events</option>
                <option value="9">Media/News</option>
                <option value="10">NRLM Documents</option>
                <option value="11">News Letters</option>
                <option value="12">NRLM National Repository	Document</option>
                <option value="13">RTI ACT</option>
                <option value="14">Parliament Q & A</option>
                <option value="15">Multimedia</option>
                <option value="16">Careers</option>
            </select>
        </div> -->
  </div>
  <div class="row align-items-center">
    <div class="col">
      <button
        class="btn btn-success btn-sm me-3"
        [ngClass]="{ disabled: isChecked == false }"
        (click)="approveReject(1)"
        *ngIf="checkAvailablitiy('/aajeevika/content/changeApprovedStatus')"
      >
        Approve
      </button>
      <button
        class="btn btn-danger btn-sm"
        [ngClass]="{ disabled: isChecked == false }"
        (click)="approveReject(0)"
        *ngIf="checkAvailablitiy('/aajeevika/content/changeApprovedStatus')"
      >
        Reject
      </button>
    </div>
    <div class="col-auto">
      <app-paginator
        [totalCount]="totalCount"
        [page]="page"
        (nextPage)="pageEvent($event)"
      ></app-paginator>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead class="bg-blue">
        <tr>
          <th class="border-0"></th>
          <th class="text-white border-0"><span class="ps-2">S.No.</span></th>
          <th class="text-white border-0"><span class="">Status</span></th>
          <th class="text-white border-0" *ngFor="let heading of tableHeader">
            {{ heading?.label }}
          </th>
          <!-- <th class=" text-white border-0">Published Date</th>
                    <th class=" text-white border-0">Published by</th>
                    <th class=" text-white border-0">Author</th>
                    <th class=" text-white border-0">Document Source</th>
                    <th class=" text-white border-0">File</th> -->
          <th class="text-white border-0">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of masterData; let sno = index">
          <td class="">
            <input
              type="checkbox"
              id="{{ 'exampleCheck' + sno }}"
              (click)="setApprove($event, record)"
            />
          </td>
          <td class="" width="10">{{ (sno + 1) + (currentPage * pageSize )}}</td>
          <td class="">
            <ng-container *ngIf="record?.approvedRejectedStatus == 1"
              >Approved</ng-container
            >
            <ng-container *ngIf="record?.approvedRejectedStatus == 2"
              >Rejected</ng-container
            >
            <ng-container
              *ngIf="
                record.approvedRejectedStatus == 0 ||
                !record.approvedRejectedStatus
              "
            >
              Approval Pending</ng-container
            >
          </td>
          <td *ngFor="let headKey of tableHeader" class="text-truncate">
            <ng-container
              *ngIf="
                headKey.key.includes('dueDateExtended') ||
                  headKey.key.includes('corrigendum');
                else noCorrigendum
              "
            >
              {{ record[headKey.key] == 1 ? "Yes" : "No" }}</ng-container
            >
            <ng-template #noCorrigendum>
              <ng-container
                *ngIf="
                  (!headKey.key.includes('dueDateExtended') &&
                    headKey.key.includes('Date')) ||
                    headKey.key.includes('Expiry');
                  else default
                "
              >
                {{ record[headKey.key] | date : 'mediumDate' :'UTC' }}
                </ng-container
              >
              <ng-template #default>
                <ng-container
                  *ngIf="headKey.key.includes('fileList'); else subDefault"
                >
                  <a
                  style="cursor: pointer;"
                    (click)="openDialog(record[headKey.key])"
                    ><i class="fa fas fa-paperclip orange"></i></a 
                ></ng-container>
                <ng-template #subDefault>{{ record[headKey.key] }}</ng-template>
              </ng-template>
            </ng-template>
          </td>
          <!-- {{record[headKey.key][0]?.fileType}} -->
          <!-- href="{{record[headKey.key][0]?.fileUrl}}" -->
          <!-- <td class=""> Pending for approval</td>
                    <td class="">Success Stories</td>
                    <td class="">Gregre</td>
                    <td class="">Lorem Ipsum Dol...</td>
                    <td class="">NRLM</td>
                    <td class="">
                        PDF
                    </td> -->
          <td class="text-nowrap" width="90" style="cursor: pointer">
            <img
              src="../../../../assets/images/view-icon.png"
              alt="view icon"
              (click)="editData(record, false)"
              *ngIf="checkAvailablitiy('/aajeevika/admincontent/list')"
            />
            <img
              src="..//assets/icons/edit_black_24dp.svg"
              alt="edit icon"
              (click)="editData(record)"
              *ngIf="checkAvailablitiy('/aajeevika/admincontent/update')"
            />
            <img
              src="..//assets/icons/delete_black_24dp.svg"
              (click)="openDeleteDialog(record)"
              alt="delete icon"
              *ngIf="checkAvailablitiy('/aajeevika/admincontent/delete')"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div class="row text ">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#">Previous</a></li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item"><a class="page-link" href="#">Next</a></li>
            </ul>
          </nav>
    </div> -->
</div>
<ng-template #submitEntry>
  <app-core-document-add
    [title]="entryTitle"
    [fileAcceptType]="fileAcceptType"
    [dynamicForm]="dynamicForm"
    [submitButton]="submitButton"
    [buttonDisable]="buttonDisable"
    (previousPath)="resetFileType()"
    [isViewMode]="isViewMode"
    [rowData]="rowData"
    (submitEntry)="submitData($event)"
    [moduleData]="moduleData"
    [labelKeys]="labelKeys"
    [isKms]="isKms"
  ></app-core-document-add>
</ng-template>

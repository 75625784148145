import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { sha256 } from 'js-sha256';
const swal: SweetAlert = require("sweetalert");

interface dynamicFormInterface {
  question: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  answer: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  name: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  mobile: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  username: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  email: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  password: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  confirmPassword: (string | ((control: AbstractControl) => ValidationErrors | null))[];
  levelId: (string | ((control: AbstractControl) => ValidationErrors | null))[];
}

interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
  levelId?: string
}

interface submitEntryInterface {

  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
  id?: string,
  levelId?: string
}

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  userForm: FormGroup| any;
  @Input() rowData:userListInterface | any = [];
  @Input() submitButton:string = 'Submit';
  @Output() submitEntry: submitEntryInterface | any = new EventEmitter<submitEntryInterface>();
  @Output() previousPath: any = new EventEmitter();
  @Input() isViewMode: boolean = false;
  @Input() buttonDisable: boolean = false;
  @Input() title: string = 'Create User';
  @Input() dynamicForm: dynamicFormInterface = {question: ['',Validators.required],answer: ['',Validators.required], name: ['',Validators.required], mobile: ['',Validators.required], username: ['',Validators.required], email: ['',Validators.required], password: ['',Validators.required], levelId: ['',Validators.required],confirmPassword: ['',Validators.required]};
  // fileInput: any;
  // userPolicy:any= [];
  userLevel:any;
  salt:any;
  passwordMatch: boolean = false
  passwordValidator: boolean = false

  constructor(private formBuilder: FormBuilder,private contentService:ContentService,private dialog :MatDialog) {

    this.createUserForm()

    this.salt= sessionStorage.getItem('saltId')
    console.log("SaltId",this.salt);
    
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // get f() { return this.userForm.controls; }


  ngOnInit(): void {
    this.contentService.getUserLevel().subscribe((result: any) => {
      console.log("Level", result.wrapperList);
       this.userLevel = result.wrapperList
    })

  }

  createUserForm() {
    this.userForm = this.formBuilder.group({
      name: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      mobile: ['',[Validators.required,Validators.pattern('[6-9]\\d{9}')]],
      password: ['',[Validators.required,Validators.minLength(3)]],
      confirmPassword: ['',[Validators.minLength(3)]],
      username: ['',[Validators.required,Validators.minLength(3)]],
      levelId: ['',Validators.required],  
    });

    // this.getConfirmPasswordValue()
  }

  getConfirmPasswordValue(event:any) {
    console.log("confirm password ", event.target.value, this.userForm.get('password').value)

    if(event.target.value && event.target.value === this.userForm.get('password').value) {
       this.passwordMatch = true
       return
    } else if(event.target.value && event.target.value !== this.userForm.get('password').value) {
      this.passwordMatch = false
    } else {
      this.passwordMatch = false
    }
    // this.userForm.get('confirmPassword').valueChanges.subscribe((res:any) => {
    //   console.log("Res ", res)
    // })
  }

  getPasswordValue(event:any) {
    this.validatePassword(event.target.value)
    if(this.userForm.get('confirmPassword').value && event.target.value === this.userForm.get('confirmPassword').value) {
       this.passwordMatch = true
       return
    } else if(this.userForm.get('confirmPassword').value && event.target.value !== this.userForm.get('confirmPassword').value) {
      this.passwordMatch = false
    } else {
      this.passwordMatch = false
    }
  }

  validatePassword(value:any): void {
    if(value) {
      const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!regexPattern.test(value)) {
        this.passwordValidator = true
      } else {
        this.passwordValidator = false
      }
    } else {
      this.passwordValidator = false
    }
  }

  // getConfirmPasswordValue(event:any) {
  //   console.log("Event ", event)
  // }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log("chanes", changes, this.userForm);
    if(changes.rowData && changes.rowData.currentValue){
      this.rowData = changes.rowData.currentValue;
      for(let key in this.userForm.value){
        this.userForm.patchValue({
          [key] : this.rowData[key]
        })
      }
      this.passwordMatch = true
      this.prefilledForm();

    }
    if(changes.isViewMode && changes.isViewMode.hasOwnProperty('currentValue')){
      this.isViewMode = changes.isViewMode.currentValue;
    }

    if(changes.dynamicForm && changes.dynamicForm.currentValue){
      this.dynamicForm = changes.dynamicForm.currentValue
      this.userForm = this.formBuilder.group(changes.dynamicForm.currentValue);
      if(this.rowData.hasOwnProperty('id'))
      this.prefilledForm();
    }

    console.log('View Mode ', this.isViewMode)
  }

  prefilledForm(){
      for(let key in this.userForm.value){
        this.userForm.patchValue({
          [key] : this.rowData[key]
        })
      }
  }

  onSubmit(){
    console.log('User Form ', this.userForm)
    let pass;
    let valueObj = this.userForm.value
    if(this.salt && !this.rowData.hasOwnProperty('id')){
      pass = sha256(this.userForm.get('password')?.value);
      valueObj['password'] = pass
    }
    console.log("Submit", this.userForm.value, this.rowData, pass);
    delete valueObj.question;
    delete valueObj.answer
    delete valueObj.confirmPassword
    if(this.rowData.hasOwnProperty('id')) {
      let updatedObj = this.userForm.value
      valueObj = this.verifyPassword(updatedObj)
    }
    this.submitEntry.emit({form: {
      ...valueObj,
    }, id: this.rowData?.id});    
  }

  verifyPassword(obj:any) {
    let checkPasswordChange:boolean = false
    if(obj.password != this.rowData.password) {
       checkPasswordChange = true
       let pass;
       if(checkPasswordChange) {
        pass = sha256(obj.password);
        obj['password'] = pass
       }
    }

    return obj
  }

  goBack() {
    this.previousPath.emit();
  }

  onSelectionChange(event:any){

  }
  onPolicyChange(event:any){

  }
}

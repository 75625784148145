import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-letters-circulars',
  templateUrl: './letters-circulars.component.html',
  styleUrls: ['./letters-circulars.component.scss']
})
export class LettersCircularsComponent implements OnInit {
letters:any;
totalCount = 0;
page=1;
  constructor(private contentService:ContentService,private router:Router) { }

  ngOnInit(): void {
    this.getLetter()
  }
  getLetter(){
    this.contentService.getContent("list", `moduleId=4&page=${this.page}`).subscribe((res:any)=>{
      if(res){
        this.letters = res && res['wrapperList'].length && res['wrapperList'];
        this.totalCount = res.totalcount;
        this.letters = res['wrapperList'].length && this.letters.reverse();
        // this.subModules = this.subModules.filter((item:any)=>item.moduleId==1);
        // this.subModules = this.subModules.reverse()
      }
    },error=>{
      console.log(error)
    })
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  pageEvent(event:any){
    this.page = event;
    this.getLetter();
  }
}

import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-case-studies',
  templateUrl: './case-studies.component.html',
  styleUrls: ['./case-studies.component.scss']
})
export class CaseStudiesComponent implements OnInit {
  caseStudies: any = [];
  totalcount: string = ''
  totalItem: any;

  constructor(
    private contentService: ContentService

  ) { }

  ngOnInit(): void {
    this.contentService.getContent("list", "moduleId=3&page=1").subscribe(
      (data: any) => {
        this.caseStudies = data.wrapperList;
        this.totalItem = data.totalcount;
        console.log(this.caseStudies);

      }
    );

  }

}

<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Hyperlink Policy</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow">
        <h3 class="mb-2">Hyperlink Policy</h3>
        <p class="fw-bold">Links to external websites/portals</p>
        <p>At many places in this website, you shall find links to other websites/portals. This links have been placed for your convenience. NRLM/NIC is not responsible for the contents and reliability of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. We can not guarantee that these links will work all the time and we have no control over availability of linked pages.</p>
        <p class="fw-bold">Links to NRLM by other websites</p>
        <p>We do not object to you linking directly to the information that is hosted on this website and no prior permission is required for the same. However, we would like you to inform us about any links provided to this website so that you can be informed of any changes or updations therein. Also, we do not permit our pages to be loaded into frames on your site. The pages belonging to this website must load into a newly opened browser window of the User.</p>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>
  <div>
    <table class="table table-bordered" *ngIf="tableData?.length > 0">
        <thead class="bg-blue">
          <tr>
            <th class="text-white border-0"><span class="ps-2">S.No.</span></th>
            <ng-container *ngFor="let heading of tableHeader">
              <th class="text-white border-0" *ngIf="!['id', 'educationQualificationCertificate', 'certificateFile'].includes(heading.key)">
                {{ heading?.label }}
              </th>
            </ng-container>
          </tr>
        </thead>
        
        <tbody>
          <tr *ngFor="let data of tableData; let sno = index">
            <td>{{ currentPage ? (sno + 1) + (currentPage * pageSize) : sno + 1}}</td>
            <ng-container  *ngFor="let heading of tableHeader">
              <td *ngIf="!['id', 'educationQualificationCertificate', 'certificateFile'].includes(heading.key)">
                <div>
                  <!-- Data -->
                  <div *ngIf="heading.key !== 'file' && heading.key !== 'action' && heading.key !== 'certificateUrl' && heading.key !== 'id'">
                    <p
                      [ngClass]="{'truncate': data[heading.key] && data[heading.key].length >= 25}"
                      *ngIf="heading.key | displayable"
                      style="text-align: center;"
                    >
                      <ng-container *ngIf="heading.key === 'passingYear'; else dashTemplate">
                        {{ data[heading.key] | customDate }}
                      </ng-container>
                      <ng-template #dashTemplate>
                        {{ data[heading.key] || '-' }}
                      </ng-template>
                    </p>
                  </div>
                  <!-- File -->
                  <div *ngIf="heading.key === 'file' || heading.key === 'certificateUrl'">
                    <p class="view-click center-text">
                      <i  class="fa fa-paperclip orange" (click)="openFile(data[heading.key])"></i>
                    </p>
                  </div>
                  <!-- Action button -->
                  <ng-container *ngIf="data.action">
                    <div *ngIf="heading.key === 'action'">
                      <ng-container *ngFor="let actionType of ['view', 'edit', 'delete']">
                        <img
                          *ngIf="data.action[actionType]"
                          (click)="actionType === 'view' ? viewData(data, actionType, sno) : (actionType === 'edit' ? editData(data, actionType, sno) : deleteData(data, actionType, sno))"
                          [src]="'..//assets/icons/' + actionType + '_black_24dp.svg'"
                          [alt]="actionType + ' icon'"
                          class="view-click"
                        />
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <!-- <ng-container *ngFor="let subData of  data | keyvalue | filterArray : keysToExclude">
              <td>
                  <p *ngIf="subData.key === 'file' || subData.key == 'certificateUrl'" class="view-click center-text">
                    <i  class="fa fa-paperclip orange" (click)="openFile(subData.value)"></i>
                  </p>
                  <p
                    [ngClass]="{'truncate': subData.value && subData.value.length >= 25}"
                    *ngIf="subData | displayable"
                    style="text-align: center;"
                  >
                    <ng-container *ngIf="subData.key === 'passingYear'; else dashTemplate">
                      {{ subData.value | customDate }}
                    </ng-container>
                    <ng-template #dashTemplate>
                      {{ subData.value || '-' }}
                    </ng-template>
                  </p>
                  <div *ngIf="subData.key == 'action'" class="center-text">
                    <ng-container *ngFor="let actionType of ['view', 'edit', 'delete']">
                      <img
                        *ngIf="data.action[actionType]"
                        (click)="actionType === 'view' ? viewData(data, actionType, sno) : (actionType === 'edit' ? editData(data, actionType, sno) : deleteData(data, actionType, sno))"
                        [src]="'..//assets/icons/' + actionType + '_black_24dp.svg'"
                        [alt]="actionType + ' icon'"
                        class="view-click"
                      />
                    </ng-container>
                    <i class="fa fa-download download-icon-style" aria-hidden="true" *ngIf="data.action.downloadIcon" (click)="downloadUrl(data.action.downloadIcon)"></i>
                  </div>
              </td>
            </ng-container> -->
          </tr>
        </tbody>
      </table>
  </div>

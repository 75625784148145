<div class="header-image">
    <img src="/assets/images/ajjevika-banner-Support-Units.jpg" alt="Introduction" title="Introduction" class="img-fluid" />
    <div class="page-title">Community Institutional Architecture</div>
  </div>
  <div class="container">
    <div class="row my-3">
      <div class="col">
        <div class="bg-white rounded-3 shadow">
          <ul class="nav align-items-center">
            <li class="nav-item">
              <a href="/" class="nav-link ">Home</a>
            </li>
            <li class="">/</li>
            <li class="nav-item">
              <a href="/about" class="nav-link ">Work Structure</a>
            </li>
            <li class="">/</li>
            <li class="nav-item">
              <div class="px-3">Community Institutional Architecture</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-9">
        <div class="card border-0 rounded-2 shadow mb-3">
          <div class="card-body">
            ‘Communitization’ is an integral part of NRLM. Communitization strategy is a paradigm shift in program implementation, reiterating the Mission’s enormous faith in the capacity of the poor women. Community Institutions eventually will drive NRLM Program and the SRLMs would facilitate the process such that the community institutions will manage their own institutions, over a period of time. 

The community institutions, formed as part of NRLM implementation are the forums for community to address their poverty and vulnerability. These institutions need to sustain to meet the emerging needs of community. The role or activities of any institution when it is started / formed may be different but over a period of time the sustainability of those institutions depends on its service to its members and how the institution is changing according to the changing needs of the members. The community institutions will take services of their community cadre.

The household members under DAY-NRLM are mobilized into Universal Institutional Architecture – Self-help Groups (SHGs), and their federations at Village level (Village Organization, VO), at Cluster level (Cluster Level Federation, CLF), and/or at Block level (Block Level Federation, BLF) within the first 18 months of our initial mobilization drives in a Block. 
             <br>
             <br>
            <ul>
                <li>
                   <b>Self-help Group (SHG):</b>  SHG is an affinity-based homogenous group, with the members belonging to the same community and living in the vicinity of each other. Each SHG consists of 10-20 women. SHGs of vulnerable persons such as PwDs, elderly, etc. may consist of both women and men and their size may be smaller (5-20). Special SHGs may be formed for vulnerable like PwDs, Elderly, with duly customized norms. 
                    A Self-Help Group (SHG) is the primary building block of the NRLM institutional design. The SHG serves the purpose of providing women members space for self-help, mutual cooperation and collective action for social and economic development. It promotes savings, builds own funds and becomes the local financial institution to provide a range of financial services including providing credit for debt-swapping and livelihoods. 
                    
                </li>
                <li>
                    <b>Primary Level Federation (PrLF):</b>  Primary Level Federation (PrLF) of SHGs is at the village/ hamlet or Panchayat level (depending on the number of SHGs). This federation is also known by different names – Village Organisation (VO), Panchayat Level Federation (PLF), Hamlet Level Forum (HLF), etc. Usually, the PrLF consists of 5-20 SHGs limited to one habitation/village.
                    The federation enhances members’ solidarity, voice, bargaining power, and serves as a platform for greater collective action, increased access to markets and local institutions, quicker dissemination of information, easier convergence and improved significance for members in their local milieu.
                </li>
                <li>
                    <b>Cluster Level Federation (CLF):</b>  CLF is a federation of all PrLFs in the cluster of the villages. CLF brings together all PrLFs and/or all SHGs in the Cluster to achieve economies of scale and critical mass required for solidarity. It serves as a platform for greater collective action, increased access to markets and local institutions, more organized Capacity Building, easier financial intermediation and inclusion, convergence and improved significance for members in their local milieu. Usually, the CLF consists of 5-30 PrLFs.
                </li>
                
              </ul>
              <br>
              <br>
              <h5>Role of Community Cadre/ Community Resource Persons </h5>
              <p>Community Resource Persons (CRPs) are members of the community who have graduated out of poverty with the help of their SHGs. CRP rounds are the cornerstone of the Resource Block strategy. This strategy essentially rests on the premise that the community learns from the community better. CRPs are employed by the Community Based Organizations (CBOs) at various levels and in various verticals. There are some CRPs who specialize in certain areas, such as training, gender, social action, farm livelihoods, non-farm livelihoods, financial inclusion, FNHW, convergence etc. These CRPs provide services within the state and different states in teams to inspire women from other communities to form and strengthen SHGs and their federations, producer groups and producer companies and various thematic interventions.</p>
          </div>
        </div>
      </div>
      <div class="col-sm-3 mb-3">
        <app-work-structure-menu></app-work-structure-menu>
      </div>
    </div>
  </div>
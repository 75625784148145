<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Privacy Policy</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow">
          <h3>Privacy Policy</h3>
        <p>NRLM website and apps based on this website do not automatically capture any specific personal information from you, (like name, phone number or e-mail address), that allows us to identify you individually.
        </p>
        <p>
          If the website requests you to provide personal information, you will be informed for the particular purposes for which the information is gathered and adequate security measures will be taken to protect your personal information.
          </p>
          <p>We do not sell or share any personally identifiable information volunteered on the website to any third party (public/private). Any information provided to this Portal will be protected from loss, misuse, unauthorized access or disclosure, alteration, or destruction.</p>
          <p>
          We gather certain information about the User, such as Internet protocol (IP) addresses, domain name, browser type, operating system, the date and time of the visit and the pages visited. We make no attempt to link these addresses with the identity of individuals visiting our site unless an attempt to damage the site has been detected.</p>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>
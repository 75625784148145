<div class="search-container" *ngIf="!contentId">
  <div class="banner-centered">
    <h2 [matTooltip]="searchString">
      {{searchString}}
    </h2>
  </div>

</div>


<div class="container">
  <div class="row my-3">
    <div [ngClass]="{'col-sm-9': !contentId, 'col-sm-12': contentId}">
      <div class="bg-white me-2 rounded-3 shadow" *ngIf="!contentId">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Content</div>
          </li>
          <li class="">/</li>
          <li class="nav-item text-truncate">
            <div class="px-3" [matTooltip]="searchString">{{searchString | slice : 0:80 }}</div>
          </li>
        </ul>
      </div>
      <div class="row mt-3">
        <div class="mb-3 col-sm-12">
          <div class="bg-white p-4">
            <!-- <h3>Content here:</h3> -->
            <div>
              <div class="row mt-3"> 
                <i class="fa fa-arrow-left" (click)="goBack()"></i>
                <h4 class="">
                  {{item.value.Title }}
                </h4>
                <hr />
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr *ngFor="let key of item$ | async | keyvalue">
                      <ng-container *ngIf="key.key != 'FileList' && key.key != 'Link' && key.key!='ModuleId' && key.key!='SubModuleId' ">
                        <ng-container *ngIf=" item$.ModuleId == 5 && key.key == 'Description' || item$.ModuleId == 9 && item$.SubModuleId==12 && key.key == 'Description'; else defaultCell">
                          <td class="bg-light fw-bold" camelCaseToSpace>{{ item$.ModuleId == 9 ? 'RTI Response' : 'No. of days'}}</td>
                        </ng-container>
                        <ng-template #defaultCell>
                          <td class="bg-light fw-bold" camelCaseToSpace>{{key.key}}</td>
                        </ng-template>
                        <td>{{getDate(key.value,key.key)}}</td>
                      </ng-container>
                      <!-- && key.key != 'SubModuleName' && key.key!='Description' && key.key!='ModuleId' && key.key!='SubModuleId' -->
                      <!-- <ng-container *ngIf="key.key=='AutoExpiry'">
                        <td class="bg-light fw-bold" camelCaseToSpace>{{key.key}}</td>
                        <td></td>
                      </ng-container> -->
                      <!-- <ng-container *ngIf="key.key=='Description'; else description">
                        <td class="bg-light fw-bold" camelCaseToSpace >RTI Response</td>
                        <td>{{key.value}}</td>
                      </ng-container>
                      <ng-template #description>
                        <td class="bg-light fw-bold" camelCaseToSpace>{{key.key}}</td>
                        <td>{{getDate(key.value,key.key)}}</td>
                      </ng-template> -->
                      <ng-container *ngIf="key.key == 'Link';">
                        <td class="bg-light fw-bold" camelCaseToSpace>{{key.key}}</td>
                        <td> <a [href]="key.value" target="_blank" >{{key.value}}</a></td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
              <ng-container *ngIf="item.value.FileList?.length ;">
                <div class="mt-2">File list</div>
                <!-- <div *ngFor="let el of item?.FileList; ">
                  <a [href]="el.fileUrl" (click)="downloadCount(el);" target="_blank" style="text-decoration:none"
                    class="m-2">
                    <i class="fa fa-file"></i> file | Downloads : {{el.downloadCount}}
                  </a>
                </div> -->
                <div >
                  <a (click)="openDialog(item.value.FileList)" style="text-decoration:none;cursor: pointer;"
                    class="m-2">
                    <i class="fa fa-file"></i> file | Downloads : {{getTotalDownload(item.value.FileList)}}
                  </a>
                </div>
              </ng-container>
              <ng-template #noData>
                <div class="row mb-4 mt-4">
                  <div style="text-align: center;margin-top: 50px;">
                    <h1>
                      No Data Found
                    </h1>
                  </div>
                </div>
              </ng-template>
            </div>

          </div>
        </div>

      </div>
    </div>
    <div class="col-sm-3 " *ngIf="!contentId">
      <app-footer-sub-menu ></app-footer-sub-menu>
    </div>
  </div>

</div>

<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Feedback</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>

  <div class="row">

    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow">
          <h1>Feedback</h1>
          <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" class="form-group" autocomplete="new-feedback-form">
          <div class="d-flex">
            <div class="mb-3 me-3">
              <label  class="form-label">Name<span class="text-danger">*</span></label>
              <input type="text" appAlphabetOnly class="form-control"  formControlName="name" aria-describedby="emailHelp" placeholder="Enter Name" required autocomplete="new-name-form">
            </div>
            <div class="mb-3">
              <label  class="form-label">Email<span class="text-danger">*</span></label>
              <input type="email" class="form-control" placeholder="Enter Email" formControlName="email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="new-email-form">
            </div>
          </div>
          <div class="mb-3">
            <label  class="form-label">Feedback<span class="text-danger">*</span></label>
            <textarea class="form-control" rows="3" placeholder="Give your feedback" formControlName="feedback" required></textarea>
          </div>
          <div class="d-flex align-items-end">
            <div class="mb-3 me-3">
              <label  class="form-label">Enter Captcha:<span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter captcha" formControlName="captcha" required autocomplete="new-label-form">
            </div>
            <div class="mb-3 d-flex">
              <div  id="captchaIdFeedback" height="80"></div>
              <div > <i class="fa fa-refresh m-2" aria-hidden="true" (click)="refreshLogin()"></i></div>

              <!-- <i class="fa fa-refresh m-2" aria-hidden="true" (click)="initialMechanism()"></i> -->
              <!-- <img class="img-fluid" src="/assets/login/captcha.png" alt="captcha image"> -->
            </div>
          </div>
          <button type="submit" class="btn btn-bg" [disabled]="!feedbackForm.valid" >Submit</button>
        </form>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>

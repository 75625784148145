<div class="container-fluid careerImage" *ngIf="openFormStatus === 'false'"> 
    <div class="centered">Instructions</div>
</div>
<div class="instruction-text-box" *ngIf="openFormStatus === 'false'" id="getInstuctionList">
    <div class="d-flex" style="margin-left: 8px;" (click)="backButton()">
        <img _ngcontent-ojv-c182="" src="assets/icons/arrow_back_black_24dp.svg" alt="back icon" style="cursor: pointer; ">
        <p class="moduleHeading m-2">Instructions</p>
    </div>
    <!-- Instructions Components -->
    <div [innerHtml]="instructionList"></div>

</div>
<div class="continue-button"  *ngIf="openFormStatus === 'false'" (click)="openForm()">Continue</div>

<div *ngIf="openFormStatus === 'true'">
    <app-job-portal-form (jobPortalFormSuccessfully)="jobPortalFormSuccessfully($event)" (backInstructionPage)="comingToInstructionPage($event)"></app-job-portal-form>
</div>
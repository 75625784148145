import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkStructureRoutingModule } from './work-structure.routing.module';
import { ApproachComponent } from './approach/approach.component';
import { WorkStructureMenuComponent } from './work-structure-menu/work-structure-menu.component';
import { SupportUnitsComponent } from './support-units/support-units.component';
import { ResourceBlocksComponent } from './resource-blocks/resource-blocks.component';
import { IntensiveBlocksComponent } from './intensive-blocks/intensive-blocks.component';
import { NonIntensiveBlocksComponent } from './non-intensive-blocks/non-intensive-blocks.component';
import { PartnershipBlocksComponent } from './partnership-blocks/partnership-blocks.component';
import { ExitStrategyComponent } from './exit-strategy/exit-strategy.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommunityInstitutionalComponent } from './community-institutional/community-institutional.component';

@NgModule({
    declarations: [
        ApproachComponent,
        WorkStructureMenuComponent,
        SupportUnitsComponent,
        ResourceBlocksComponent,
        IntensiveBlocksComponent,
        NonIntensiveBlocksComponent,
        PartnershipBlocksComponent,
        ExitStrategyComponent,
        CommunityInstitutionalComponent,
    ],
    imports: [
        CommonModule,
        WorkStructureRoutingModule,
        SharedModule
    ],
})
export class WorkStructureModule { }
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-reader-access',
  templateUrl: './screen-reader-access.component.html',
  styleUrls: ['./screen-reader-access.component.scss']
})
export class ScreenReaderAccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

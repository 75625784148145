import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copyright-policy',
  templateUrl: './copyright-policy.component.html',
  styleUrls: ['./copyright-policy.component.scss']
})
export class CopyrightPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

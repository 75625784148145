import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonGalleryDialogComponent } from 'src/app/shared/common-gallery-dialog/common-gallery-dialog.component';
import { ContentService } from 'src/app/shared/services/content.service';
import { CalendarComponent } from 'src/app/shared/calendar/calendar.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
declare var window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  extraList:any=[];
  tickerString = '';
  carouselImages:any=[];
  popupDoc:any=[];
  mediaActive: boolean = true
  index:any='https://www.youtube.com/user/aajeevikaNRLM';

  constructor(
    private router:Router,
    private contentService:ContentService,
    private dialog:MatDialog
  ) { }
  updateTabContent = [
    // {
    //   title: "Vacancies in Deen Dayal Upadhyaya Grameen Kaushalya Yojana(DDU-GKY) (National Mission Manager – Skills / Mission Manager – Legal Affairs ). Last Date: 27th October 2021, 1700 hrs (IST).",
    //   desc: "Vacancies in Deen Dayal Upadhyaya Grameen Kaushalya Yojana(DDU-GKY) (National Mission Manager – Skills / Mission Manager – Legal Affairs ). Last Date: 27th October 2021, 1700 hrs (IST).",
    //   link:"https://aajeevika.gov.in/en/content/vacancies-deen-dayal-upadhyaya-grameen-kaushalya-yojanaddu-gky-national-mission-manager-%E2%80%93"
    // },

  ]
  lettersTabContent = [
  ]
  tendersTabContent = [
  ]
  eventsTabContent = [
  ]

  getLetter(){
    this.contentService.getContent("list", "moduleId=4").subscribe((res:any)=>{
      console.log("Get Content Res ", res)
      if(res){
        this.selectedTabData = res && res['wrapperList'].length && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }
  getTenders(){
    this.contentService.getContent("list", "moduleId=5").subscribe((res:any)=>{
      if(res){
        this.selectedTabData = res && res['wrapperList'].length && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }
  getEvents(){
    this.contentService.getContent("list", "moduleId=6").subscribe((res:any)=>{
      if(res){
        this.selectedTabData = res && res['wrapperList'].length && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }

  getMedia(){
    this.contentService.getContent("list", "moduleId=7").subscribe((res:any)=>{
      if(res){
        this.selectedTabData = res && res['wrapperList'].length && res['wrapperList']
        console.log(this.selectedTab,res)
      }
    },error=>{
      console.log(error)
    })
  }

  getUpdates(){
    this.contentService.getContent("getupdatecontent").subscribe((res:any)=>{
      console.log('Update from content ', res)
      if(res){
        this.selectedTabData = res && res['wrapperList'].length && res['wrapperList']
        console.log("Selected Tab Data ", this.selectedTab,res)
      }
    },error=>{
      console.log(error)
    })
  }
  selectedTab = 'update';
  selectedTabData:any[] = [];
  Highlights:any[] = []
  homeModal: any;

  ngOnInit(): void {
    this.getUpdates();
    this.getHighlights();
    const visitorJson = sessionStorage.getItem('nrlmVisitor');
    const getvisitor = visitorJson !== null ? JSON.parse(visitorJson) : "";
    if(!getvisitor){
      this.openWelcomeModal();
    }
   this.getExtraList()

  }

  ngAfterViewInit(){
    // this.modal.open();
  }

  changeTab(tab:any){
    let self:any = this;
    this.selectedTab = tab;
    this.selectedTabData = self[tab+"TabContent"];
    if(tab=='tenders'){
      this.getTenders();
    }
    if(tab=='events'){
        this.getEvents();
    }
    if(tab=='letters'){
      this.getLetter();
    }
    if(tab=='media'){
      this.getMedia();
    }
    if(tab=='update'){
      this.getUpdates();
    }

  }

  navigatiToPage(selectedTab:string){
    switch(selectedTab){
      case 'update':
        this.router.navigate(['/whats-new-section/updates']);
        break;
      case 'letters':
        this.router.navigate(['/whats-new-section/letters-circulars']);
        break;
      case 'events':
        // this.router.navigate(['/whats-new-section/events']);
        this.openDialog()
        break;
      case 'tenders':
        this.router.navigate(['/whats-new-section/tenders']);
        break;
      case 'media':
      this.router.navigate(['whats-new-section/media-news']);
      break;
    }
  }

  openWelcomeModal(){
    this.homeModal = new window.bootstrap.Modal(
      document.getElementById('homeModal')
    );
    this.homeModal.show();

    sessionStorage.setItem('nrlmVisitor', JSON.stringify({
      'visited': true,
    }));
  }

  getExtraList(){
    this.contentService.getContent("extralist").subscribe((res:any)=>{
      if(res){
        this.extraList = res && res['wrapperList'].length && res['wrapperList'];
        console.log('Extra List ', this.extraList)
       this.getTickerString(this.extraList);
       this.getCarouselList(this.extraList);
       this.getPopup(this.extraList)
;      }
    },error=>{
      console.log(error)
    })
  }
  getTickerString(data:any){
    let tickerArray = data.filter((item:any)=>item.contentType=='ticker').sort((a:any,b:any)=>a.ranking-b.ranking).map((item:any)=>item.tickerName)
    if(!tickerArray.length) return;
    tickerArray.forEach((element:any,index:number) => {
      if(tickerArray.length==1){
        this.tickerString+=element;
      }else if(tickerArray.length>1 && tickerArray.length-1 != index ){
        this.tickerString+=element + ' | '
      }
      else {
        this.tickerString+=element
      }
    });
  }
  getCarouselList(data:any){
    this.carouselImages = data.filter((item:any)=>item.contentType=='image').sort((a:any,b:any)=>a.ranking-b.ranking).map((item:any)=>item.file_url)
    console.log(this.carouselImages,'dhagshd')

  }
  getPopup(data:any){
    console.log("Get Popup function call")
    this.popupDoc = [];
    this.popupDoc = data.filter((item:any)=>item.contentType=='popup');
    console.log("Popup doc ", this.popupDoc)
  }
  getHighlights(){
    this.contentService.getContent("list", "moduleId=28").subscribe((res:any)=>{
      if(res){
        this.Highlights = res && res['wrapperList'];
        this.Highlights = this.Highlights.sort((a:any,b:any)=>b.ranking-a.ranking);
      }
    },error=>{
      console.log(error)
    })

  }
  openDialog() {
    const dialogRef = this.dialog.open(CalendarComponent,{
      data:{
        fileList:'',
      },
      width: '800px',
      height: '600px',
      panelClass: "foo"
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.index = tabChangeEvent.index
    this.navigateMedia()
  }
 
  navigateMedia(){
    if(this.index == 1){
      this.index = 'https://www.facebook.com/aajeevika' 
    }else if(this.index == 2){
      this.index = 'https://twitter.com/DAY_NRLM'
    } else{
      this.index = 'https://www.youtube.com/user/aajeevikaNRLM'
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import Swal from 'sweetalert2';
const swal: SweetAlert = require("sweetalert");
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { event } from 'jquery';

interface jobPostingTableData {
  position: string;
  title: string;
  vacancyUploadedDate: string;
  lastDateSubmission: string;
  documetLink: string;
  keyWord: string;
  action : {view: boolean, edit: boolean, delete: boolean, downloadIcon: boolean};
  // actionButton: boolean;
}

interface tableHeaders {
  key: string;
  label: string
}

interface jobPostingDynamicForm {
  title: string[];
  uploadDate: string[];
  openingDate: string[];
  lastDate: string[];
  jobNotificationCode: string[];
  fileList: string[];
}

interface applicationRecievedData {
  responseCode: string;
  wrapperList: object[]
}

interface jobPostingObject {
  responseCode: string;
  wrapperList: Object[]
}

interface postDataInterface {
  responseCode: string;
}

interface particularRowData {
  status: string;
  rowData: Object[]
}

interface deleteData {
  contentId: string
}

@Component({
  selector: 'app-hr-module',
  templateUrl: './hr-module.component.html',
  styleUrls: ['./hr-module.component.scss']
})
export class HrModuleComponent implements OnInit {

  jobPosting: boolean = true;
  totalCount: number = 0
  applicationRecieved: boolean = false;
  jobPostingHeaders: tableHeaders[] = [
    {
      key: 'title',
      label: 'Title'
    },
    {
      key: 'uploadDate',
      label: 'Vacancy Uploaded Date'
    },
    {
      key: 'openingDate',
      label : 'Application Opening Date'
    },
    {
      key: 'lastDate',
      label: 'Application Closing Date'
    },
    {
      key: 'jobNotificationCode',
      label: 'Code'
    },
    {
      key: 'file',
      label: 'File'
    },
    {
      key: 'action',
      label: 'Action'
    },
    {
      key: 'id',
      label: 'Id'
    }
  ];
  aaplicationRecivedTableHeaders:tableHeaders[] = [
    {
      key: 'jobNotification',
      label: 'Notification Name'
    },
    {
      key: 'position_name',
      label: 'Job Position'
    },
    {
      key: 'name',
      label : 'Name of Candidate'
    },
    {
      key: 'created_at',
      label: 'Date of Applied'
    },
    {
      key: 'action',
      label: 'Action'
    }
  ];

  notificationPositingHeaders:tableHeaders[] = [
    {
      key: 'positionName',
      label: 'Position Name'
    },
    {
      key: 'underPosition',
      label: 'Under Position'
    },
    {
      key: 'maxAge',
      label: 'Max Age'
    },
    {
      key: 'minYearsExperience',
      label: 'Min years of Experience'
    }
  ]
  applicationRecivedTableData: Object[] = [
    {
      jobPostion: 'Job Position A',
      candidateName: 'Ayushi Kumar',
      dateOfApplied: '26 july 2023',
      action: {view: true, edit: false, delete: false},
    }
  ]
  // jobPostingData: any = []

  commonTableHeader: Object[] = [];
  commonTableData: Object[] = []
  positionMaster: string= 'false';
  jobPostingTitle: string = 'Job Posting';
  jobPostingSubmitButton: string = 'Save & Submit';
  jobPostingDynamicForm:jobPostingDynamicForm = {
    title: [''],
    uploadDate: [''],
    openingDate: [''],
    lastDate: [''],
    jobNotificationCode: [''],
    fileList:[''],
  }
  jobPostingAddFormStatus: string = "false"
  docExtensions = [
    'pdf',
    'docx'
  ];
  jobPostingRowDetails: any = []
  jobPostingFormViewMode: boolean = false
  instructionEditor: string = 'false';
  page: number = 1;
  currentPage: number = 0;
  pageSize: number = 20
  jobPostingViewFormStatus: any;
  applicationActive: boolean = false;
  positionListData: any;
  particularPositionValue: any = null
  currentPath: string = ''
  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  // Should Show Div
  shouldShowDiv(): boolean {
    return (
      this.positionMaster === 'false' &&
      this.jobPostingAddFormStatus === 'false' &&
      !this.applicationActive &&
      this.instructionEditor === 'false'
    );
  }

  ngOnInit(): void {
    // console.log('Current Route ', this.route)
    this.getTheCurrentRouteUrl()
    let statusLevel = this.currentPath === 'hrModule' ? 'jobPosting' : 'applicationRecieved'
    this.openStatusFunction(statusLevel)
    this.positionMaster = sessionStorage.getItem('positionMaster') || 'false'
    this.jobPostingAddFormStatus = sessionStorage.getItem('jobPostingForm') || 'false'
    this.instructionEditor = sessionStorage.getItem('instructionEditor') || 'false'
    this.removeDataFromSessionStorage()
  }

  // getDataFromSessionStorage() {
  //   const keysWithDefaultValue:any = 
  //     {'positionMaster' : 'false','jobPostingForm' : 'false','instructionEditor': 'false'}

  //   for(const key in keysWithDefaultValue) {
  //      this['key'] : any = sessionStorage.getItem(key) || keysWithDefaultValue[key]
  //   }
  // }

  removeDataFromSessionStorage() {
    const itemsToRemove = [
      'jobPostingForm',
      'numberOfJob',
      'previousNumberOfJob',
      'positionMasterListData',
      'saveJobListing',
      'editjobListing',
      'originalPositionNameList'
    ];

    itemsToRemove.forEach((item:any) => {
      sessionStorage.removeItem(item)
    })
  }

  getTheCurrentRouteUrl() {
    this.route.url.subscribe((event) => {
      // console.log("Route Event ", event, event[0].path)
      this.currentPath = event[0].path
    })
  }

  getPositionMasterData() {
    // When Apis are ready
    this.contentService.getPositionMasterData(this.page).subscribe((res:any) => {
        // console.log("Response ", res)
        if(res?.responseCode == '200 ok') {
          this.totalCount = res?.totalcount
          this.positionListData =  res?.wrapperList
          sessionStorage.setItem('positionTableData', JSON.stringify(res?.wrapperList))
          console.log("Common Table Data ", this.commonTableData)
        }
    },(err) => {
       swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  openStatusFunction(status:string) {
    console.log("Status ", status)
    this.page = 1
    this.currentPage = 0
    if(status == 'jobPosting') {
       this.jobPosting = true;
       this.applicationRecieved = false;
       this.commonTableHeader = this.jobPostingHeaders;
       this.getJobPostData()
    } else {
      this.resetFilterApplicationRecievedData()
      this.applicationRecieved = true;
      this.jobPosting  = false;
      this.getApplicationRecievedData()
      this.getPositionMasterData()
    }
  }

  getApplicationRecievedData() {
    // When Apis are ready
    this.contentService.getApplicationRecievedData(this.page, Number(this.particularPositionValue)).subscribe((res:applicationRecievedData | any) => {
      console.log("Application Response ", res)
      if(res?.responseCode === '200') {
         this.totalCount = res?.totalcount
         this.commonTableData = this.reformatApplicationRecieveData(res?.wrapperList)
         this.commonTableHeader = this.aaplicationRecivedTableHeaders;
      }
    },(err) => {
       if(err.status !== 403) {
          swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
       }
    })
  }

  reformatApplicationRecieveData(data:any) {
    console.log("Application Recieved Data ", data)
    let reformatApplicationRecievedData:any = []
    data.forEach((item:any, index:any) => {
      const obj = {
        ...item,
        jobNotification: item?.job_post_name || '-',
        created_at : this.datePipe.transform(item?.created_at, 'd MMMM, y'),
        action: {view: true, edit: false, delete: false, downloadIcon: item?.resume}
      }
      reformatApplicationRecievedData.push(obj)
    })

    return reformatApplicationRecievedData

  }

  openPositionMaster() {
    this.positionMaster = "true"
    sessionStorage.setItem("positionMaster", this.positionMaster)
  }

  jonPostingAddForm() {
    this.jobPostingAddFormStatus = "true"
    this.jobPostingFormViewMode = false
    this.jobPostingRowDetails = [];
    this.jobPostingSubmitButton = 'Save & Submit'
    sessionStorage.setItem('jobPostingForm', this.jobPostingAddFormStatus)
    sessionStorage.removeItem('saveJobListing')
    sessionStorage.removeItem('editjobListing')
  }

  backJobPostingForm() {
    this.positionMaster = 'false';
    this.jobPostingAddFormStatus = 'false';
    this.removeDataFromSessionStorage()
    // console.log("Page ", this.page, this.currentPage)
  }

  resetPage() {
    this.page = 1
    this.currentPage = 0
    this.jobPosting ? this.getJobPostData() : this.getApplicationRecievedData()
  }

  backToHrModule(){
    this.positionMaster = 'false';
    this.applicationActive = false;
    this.jobPostingAddFormStatus = "false";
  }

  submitJobPostingData(event:any) {
    console.log("Updated job Row Data ", event)
    event.submitButtonText === 'Save & Submit' ? this.finalSubmitJobPostingData(event) : this.updateJobPostingData(event)
    
  }

  finalSubmitJobPostingData(event:any) {
    console.log("Event job Posting ", event)
    // update savejob posting data
    let updatedSaveJobListingData = this.getUpdatedSaveJobListingData(event.saveJobListing)

    event.saveJobListing = updatedSaveJobListingData
    // When Apis is ready
    const {file: fileList, saveJobListing: criteria, form, instructions } = event;
    delete form['jobListing'];

    const jobPostingDataObject = {
      ...form,
      fileList,
      criteria: criteria,
      instructions: instructions
    };

    console.log("Job Posting Data Object ", jobPostingDataObject, form)
    let formDataObject = this.convertDataIntoFormDataObject(jobPostingDataObject)
    this.contentService.saveJobPostingData(formDataObject).subscribe((res:postDataInterface | any) => {
      if(res.responseCode == '200') {
        swal({
          title: "Success",
          text: "Data submitted successfully",
          icon: "success",
        })
        this.jobPostingAddFormStatus = 'false'
        this.removeDataFromSessionStorage()
        this.getJobPostData()
      }
    },(err) => {
      swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  getUpdatedSaveJobListingData(saveJobListing:any) {
    // console.log("Save Job Listing ", saveJobListing)
    saveJobListing.forEach((item:any) => {
      delete item['action']
      delete item['particularPositionId']
    })

    return saveJobListing
  }

  // convertDataIntoFormDataObject(data:any) {
  //   console.log("Data ", data)
  //   const formData:any = new FormData();
  //   let fileInput = data.fileList
  //   let criteria = JSON.stringify(data['criteria'])
  //   let blob = new Blob([criteria], { type: 'application/json' })

  //   if(fileInput) {
  //     if(typeof(fileInput) !== 'string') {
  //       console.log("File Input ", fileInput)
  //       for(let i = 0; i < fileInput.length; i++) {
  //         formData.append("file", fileInput[i], fileInput[i]?.name)
  //       }
  //     }
  //   }

  //   for(let key in data) {
  //     console.log("Data ", data[key], key)
  //     if(key && key !== 'fileList' && key !== 'criteria') {
  //         formData.append(key, data[key])
  //     } if(key == 'criteria') {
  //       formData.append(key, blob);
  //     }
  //   }
  //   return formData
  // }

  convertDataIntoFormDataObject(data:any) {
    console.log("Data ", data)
    const formData:any = new FormData();
    let fileInput = data.fileList
    let criteria = JSON.stringify(data['criteria'])
    let blob = new Blob([criteria], {type: 'application/json'})

    if(fileInput) {
      if(typeof(fileInput) !== 'string') {
      console.log("File Input ", fileInput)
        for(let i = 0; i < fileInput.length; i++) {
          formData.append("file", fileInput[i], fileInput[i]?.name)
        }
      } else {
        formData.append("file", fileInput)
      }
    }

    for(let key in data) {
      console.log("Data ", data[key], key)
      if(key && key !== 'fileList' && key !== 'criteria') {
          formData.append(key, data[key])
      } if(key == 'criteria') {
        formData.append(key, blob)
      }
    }
    return formData
  }

  updateJobPostingData(event:any) {
    console.log("Update Job Post Data ", event)
    let updatedSaveJobListingData = this.getUpdatedSaveJobListingData(event.editjobListing)
    event.editjobListing = updatedSaveJobListingData
    const {form, file: fileList, editjobListing: criteria,instructions} = event
    delete form['jobListing']
    const jobPostingDataObject = {
      ...form,
      fileList,
      criteria: criteria,
      instructions: instructions
    }
    console.log("Job Posting Data Object ", jobPostingDataObject)
    let formDataObject = this.convertDataIntoFormDataObject(jobPostingDataObject)
    console.log("Form Data Object ", formDataObject)
    this.contentService.updateJobPostingData(formDataObject, event.contentId).subscribe((res:postDataInterface | any) => {
      if(res.responseCode == '200') {
        swal({
          title: "Success",
          text: "Data Updated successfully",
          icon: "success",
        })
        this.jobPostingAddFormStatus = 'false'
        this.removeDataFromSessionStorage()
        this.getJobPostData()
      }
    },(err) => {
      if(err.status !== 403) {
        swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
      }
      // swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
    })
  }


  getJobPostData() {
    // When Apis ready
    // debugger
    this.contentService.getJobPostingData(this.page).subscribe((res:jobPostingObject | any) => {
      if(res.responseCode == '200') {
        this.totalCount = res?.totalcount
        this.commonTableHeader = this.jobPostingHeaders;
        this.commonTableData = this.filterJobPostData(res?.wrapperList)
        console.log("Common Table Data ", this.commonTableData)
      }
    },(err) => {
      swal("", `${err?.error?.errorMsg}`, "error");
    })
  }

  filterJobPostData(data: any) {
    let filterData = data.map((item: any) => ({
      ...item,
      uploadDate: this.datePipe.transform(item.uploadDate, 'd MMMM, y'),
      openingDate: this.datePipe.transform(item.openingDate, 'd MMMM, y'),
      lastDate: this.datePipe.transform(item.lastDate, 'd MMMM, y'),
      jobNotificationCode: item.jobNotificationCode,
      action: { view: true, edit: true, delete: true },
    }));

    console.log("Filter Data ", filterData)
    return filterData
  }

  jobPostingRowData(event:particularRowData) {
    console.log("Event ", event)
    if(event?.status == 'delete') {
       this.deleteJobPostingData(event.rowData)
    } else {
      this.applicationActive = this.jobPosting ? false : true
      this.viewJobPostingRowData(event.rowData)
      this.jobPostingAddFormStatus = 'true'
      this.jobPostingFormViewMode = event.status === 'view';
      this.jobPostingSubmitButton = event.status === 'view' ? 'View' : 'Update'

    }
  }

  viewJobPostingRowData(rowData:any) {
    const apiEndPoints = !this.applicationActive ? 'getParticularJobPostingRowData' : 'getParticularApplicationRowData'
    this.contentService[apiEndPoints](rowData.id).subscribe((res: any) => {
      console.log("Particular row Data ", res);
      if (res?.responseCode === '200') {
        this.jobPostingViewFormStatus = 'true';
        this.jobPostingRowDetails = res?.wrapperList[0];
      }
    });
  }


  deleteJobPostingData(rowData:any) {
    console.log("Row Data ", rowData)
    Swal.fire({
      icon:'warning',
      text:'Are you sure you want to delete this Job Notification ?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
    }).then((res:any) => {
      if(res?.isConfirmed) {
        this.contentService.deleteJobPostingData(rowData.id).subscribe((res:any) => {
          if(res.responseCode == '200') {
            swal({
              title: "Success",
              text: "Data Delete successfully",
              icon: "success",
            })
            this.getJobPostData()
          }
        },(err) => {
          if(err.status !== 403) {
            swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
          }
          // swal("", `${err?.error?.errorMsg ? err?.error?.errorMsg : 'Internal server error'}`, "error");
        })
      } if(res?.isDenied) {
        Swal.close()
      } if(res?.isDismissed) {
        Swal.close()
      }
    })
  }

  openInstructionsEditor() {
    this.instructionEditor = 'true'
    sessionStorage.setItem('instructionEditor', this.instructionEditor)
  }

  comeToHrModuleFromInstruction(event:any) {
    console.log("Event Hr ", event)
    this.instructionEditor = event
    sessionStorage.removeItem('instructionEditor')
  }

  pageEvent(event:any) {
    console.log("Event page ", event)
    this.page = event
    this.currentPage = event == 1 ? 0 : (event-1)
    console.log("current Page ", this.currentPage)
    this.jobPosting ? this.getJobPostData() : this.getApplicationRecievedData()
  }

  getPositionDetails(event:any) {
    this.particularPositionValue = event.target.value
  }

  filterApplicationRecievedData() {
    this.getApplicationRecievedData()
  }

  resetFilterApplicationRecievedData() {
    this.particularPositionValue = ''
    this.getApplicationRecievedData()
  }

  downloadData() {
    this.contentService.getDownloadData(Number(this.particularPositionValue)).subscribe((res:any) => {
       this.downloadCsvData(res)
    })
  }

  downloadCsvData(csvData:any) {
    const blob = new Blob([csvData], { type: 'text/csv' });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'application_recieved_data.csv';
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  }
}

<div class="container">
  <div class="row my-3">
      <div class="col">
          <div class="bg-white rounded-3 shadow">
              <ul class="nav align-items-center">
                  <li class="nav-item">
                      <a href="/" class="nav-link ">Home</a>
                  </li>
                  <li class="">/</li>
                  <li class="nav-item">
                      <div class="px-3">Accessibility Statement</div>
                  </li>
              </ul>
          </div>
      </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
        <div class="bg-white p-3 shadow">
        <h3>Accessibility Statement</h3>
        <p>We are committed to ensure that the DAY-NRLM website is accessible to all users irrespective of device in use, technology or ability. It has been built, with an aim, to provide maximum accessibility and usability to its visitors.</p>
        <p>We have put in our best efforts to ensure that all information on this website is accessible to people with disabilities. For example, a user with visual disability can access this website using assistive technologies, such as screen readers and magnifiers.</p>
        <p>We also aim to be standards compliant and follow principles of usability and universal design, which should help all visitors of this website.</p>
        <p>This  website is designed using XHTML 1.0 Transitional and meets priority 1 (level A) of the Web Content Accessibility Guidelines (WCAG) 2.0  laid down by the World Wide Web Consortium (W3C). Part of the information in the website is also made available through links to external Web sites. External Web sites are maintained by the respective departments who are responsible for making these sites accessible.</p>
        <p>If you have any problem or suggestion regarding the accessibility of this website, please send a feedback to us.</p>
        </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
        <app-footer-sub-menu></app-footer-sub-menu>
    </div>
</div>
</div>
<div class="shadow-lg mt-4 mb-4" *ngIf="positionMasterFormStatus == 'false'">
    <div class="d-flex justify-content-between align-items-center" style="margin: 8px;">
        <div class="d-flex" (click)="backButton()">
            <p class="moduleHeading m-2">Position Master</p>
        </div>
        <div class="div-border add-button d-flex justify-content-center align-items-center" (click)="openPositionMasterForm()">
            <img src="assets/icons/add_black_24dp.svg" alt="add icon" />
            <p style="margin: 0;">Add</p>
        </div>
    </div>
    <!-- <hr class="reset-margin" /> -->
    <div class="mt-3" *ngIf="commonTableData.length > 0" style="margin: 10px;">
        <app-common-table [tableHeader]="commonTableHeader" [tableData]="commonTableData" (particularRowData)="positionMasterRowData($event)" [currentPage]="currentPage"></app-common-table>
    </div>
    <!-- Pagination -->
    <div class="row">
        <app-paginator [totalCount]="totalCount" [page]="page" (nextPage)="pageEvent($event)"></app-paginator>
    </div>
    <div *ngIf="commonTableData.length === 0" class="d-flex justify-content-center align-items-center mt-3">
        <img src="../../../../assets/images/No data-amico (1).jpg" alt="non data image" class="no-data-image" />
    </div>
</div>

<div *ngIf="positionMasterFormStatus === 'true'">
    <app-core-document-add
        [positionMaster]="true"
        [addMoreButton]="false"
        [title]="positionMasterTitle"
        [dynamicForm]="positionMasterDynamicForm"
        (previousPath)="backPositionMasterForm()"
        (submitEntry)="submitPositionData($event)"
        [isViewMode]="positionMasterFormViewMode"
        [rowData]="positionMasterRowDetails"
        [submitButton]="positionMasterSubmitButton"
    ></app-core-document-add>
</div>
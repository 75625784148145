<div class="header-image">
  <img
    src="/assets/images/case.png"
    alt="Introduction"
    title="Introduction"
    class="img-fluid"
  />
  <div class="page-title">Newsletters</div>
</div>
<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/about" class="nav-link">Knowledge Products</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Newsletters</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
      <div class="card border-0 rounded-2 shadow mb-3">
        <div class="card-body">
          <p>
            Inspiring stories of rural women who have risen out of abject poverty
            with the help of their institutions. These inspirational stories of
            unvanquished spirit and of powerful transformation will inspire poor
            women all over to join such networks and become change agents in
            ushering an inclusive society that recognizes and values the poor in
            their quest for empowerment.
          </p>
          <div class="row mx-0">
            <app-common-filters
              [filterConfig]="filterConfig"
              [moduleId]="moduleId"
              (searchParams)="searchParams($event)"
            ></app-common-filters>
          </div>
          <ng-container>
            <div
              class="card rounded shadow-sm border-0 mt-3"
              *ngFor="let item of cardData"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12 mb-3 mb-lg-0 col-lg-auto">
                    <h6>{{ item?.issuedNo }}</h6>
                    <div class="small text-muted">Issued No.</div>
                  </div>
                  <div class="col-sm-12 mb-3 mb-lg-0 col-lg-auto">
                    <h6>{{ item?.issuedDate | date: "mediumDate" }}</h6>
                    <div class="small text-muted">Issue Date</div>
                  </div>
                  <div class="col-sm-12 mb-3 mb-lg-0 col-lg text-truncate" [matTooltip]="item.publishedBy">
                    <h6>{{ item?.publishedBy }}</h6>
                    <div class="small text-muted">Published By</div>
                  </div>
                  <div class="col-sm-12 mb-3 mb-lg-0 col-lg text-truncate">
                    <h6>{{ item.title }}</h6>
                    <div class="small text-muted">Uploaded Newsletter</div>
                  </div>
                  <div class="col-sm-12 mb-3 mb-lg-0 col-lg-auto d-grid ">
                    <button class="btn btn-sm btn-link" (click)="openDialog(item.fileList)"
                      ><i class="fa fas fa-paperclip me-3"></i><img
                        src="/assets/images/download.svg"
                         />
                      </button>
                    <div class="small text-muted">Total Downloads : {{ getTotalDownload(item.fileList) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <app-paginator
              [totalCount]="totalCount"
              [page]="page"
              (nextPage)="pageEvent($event)"
            ></app-paginator>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
      <app-knowledge-products-menu></app-knowledge-products-menu>
    </div>
  </div>
  
</div>

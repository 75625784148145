<div class="container">
  <div class="row my-3">
    <div class="col">
      <div class="bg-white rounded-3 shadow">
        <ul class="nav align-items-center">
          <li class="nav-item">
            <a href="/" class="nav-link ">Home</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <a href="/about" class="nav-link ">Resource Center</a>
          </li>
          <li class="">/</li>
          <li class="nav-item">
            <div class="px-3">Case Studies</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-8 mb-3">
      <div class="header-image">
        <img src="/assets/images/case.png" alt="Introduction" title="Introduction" class="img-fluid" />
        <div class="page-title">Case Studies</div>
      </div>
    </div>
    <div class="col-sm-4 mb-3 position-relative">
      <app-resource-menu></app-resource-menu>
    </div>
  </div>
  <div class="card border-0 rounded-2 shadow mb-3">
      <div class="card-body">
          <p>Inspiring stories of rural women who have risen out of abject poverty with the help of their institutions. These inspirational stories of unvanquished spirit and of powerful transformation will inspire poor women all over to join such networks and become change agents in ushering an inclusive society that recognizes and values the poor in their quest for empowerment.</p>
                <div class=" mx-auto bg-light px-3 py-2">
                    <div class="input-group">
                        <input class="form-control border-end-0 border" type="search" placeholder="Looking for" id="example-search-input">
                        <span class="input-group-append">
                            <button class="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button">
                                <img src="/assets/images/search.svg" alt="">
                            </button>
                        </span>
                    </div>
                </div>
                <app-common-filters></app-common-filters>

                <!-- <div class="row mx-0">
                    <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <select name="" id="" class="form-select">
                        <option value="">Author</option>
                      </select>
                    </div>
                    <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <select name="" id="" class="form-select">
                        <option value="">Source</option>
                      </select>
                    </div>
                    <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <select name="" id="" class="form-select">
                        <option value="">From</option>
                      </select>
                    </div>
                    <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <select name="" id="" class="form-select">
                        <option value="">To</option>
                      </select>
                    </div>
                    <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <select name="" id="" class="form-select">
                        <option value="">File Type</option>
                      </select>
                    </div>
                  <div class="col-6 col-md-2 mb-1 mb-md-0">
                      <button type="button" class="btn btn-primary">Apply</button>
                      <button type="button" class="btn btn-outline-primary">Reset</button>
                    </div>
                  </div>
                </div> -->
                <ng-container>
                  <div class="card rounded shadow border-0 mt-3" *ngFor="let item of caseStudies">
                    <div class="card-body ">
                      <div class="row justify-content-evenly">
                        <div class="col-6 col-lg">
                          <h5>{{item.publishedDate | date:'mediumDate'}}</h5>
                          <p>Issue Date</p>
                        </div>
                        <div class="col-6 col-lg">
                          <h5>{{ item.publishedBy || 'NA'}}</h5>
                          <p>Published By</p>
                        </div>
                        <div class="col-6 col-lg">
                          <h5>{{ item.title || 'NA'}}</h5>
                          <p>Name</p>
                        </div>
                        <div class="col-6 col-lg">
                          <h5></h5>
                          <p>File</p>
                        </div>
                        <div class="col-6 col-lg">
                          <span class="d-flex"><img src="/assets/images/download.svg" alt=""><h5>{{item?.fileNumber || '0'}}</h5></span>
                          <p>Total Downloads</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
  </div>
</div>

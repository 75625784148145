import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/shared/services/content.service';
import { SweetAlert } from "sweetalert/typings/core";
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { sha256 } from 'js-sha256';
const swal: SweetAlert = require("sweetalert");

interface dynamicFormInterface {
  name: string[],
  email: string[],
  username: string[],
  mobile: string[],
  password: string[],
  confirmPassword: string[],
  answer: string[],
  question: string[],
  levelId: string[]
}

interface tableHeaderInterface {
  [key: string]: { key: string; label: string; }[]
}


interface userListInterface {
  name?: string,
  username?: string,
  email?: string,
  mobile?: string,
  password?: string,
level?: string
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  page: number = 1;
  currentPage: any = 0
  pageSize: any = 20
  entryTitle: string = 'Users';
  userList: userListInterface[] | any[] = []
  totalCount: number = 0;
  buttonDisable: boolean = false;
  isEntry: boolean = false;
  isViewMode: boolean = false;
  rowData: userListInterface = {}
  dynamicForm: dynamicFormInterface = {
    name: [''],
    email: [''],
    username: [''],
    mobile: [''],
    password: [''],
    question: [''],
    confirmPassword: [''],
    answer: [''],
    levelId: ['']
  }
  submitButton = 'Submit'
  tableHeader: { key: string, label: string }[] = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'username',
      label: 'Username',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'mobile',
      label: 'Mobile',
    },
    // {
    //   key: 'password',
    //   label: 'Password',
    // },

  ];
  salt ='rSgQjJC1jjMXVZE0Cb4KHA==';
  flag:any=0;

  constructor(private contentService: ContentService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.contentService.kmsRouter.subscribe((item)=>{
      console.log(item)
      this.isEntry = item
      this.entryTitle = 'Users'
    })
    this.getUsers()
    let userLevelId:any = sessionStorage.getItem('levelId')
    if(Number(userLevelId) === 1) {
      this.getkmsCount();
    }
  }
  pageEvent(event: any) {
    this.page = event;
    this.currentPage = event == 1 ? 0 : (event-1)
    this.getUsers();
  }
  
  getkmsCount(){
    this.contentService.getKmsCount('isSyncedCount',`flag=${this.flag}`)
                       .subscribe((res:any)=>{
                        console.log('kms count--->>>', res)
                        let kmsCount = res
                        this.contentService.kmsCounter.next(kmsCount)
                       })
  }

  getUsers() {
    this.contentService
      .getUser('list', `page=${this.page}`)
      .subscribe((result: any) => {

        let obj: any = {};
        this.userList = result.wrapperList;
        this.totalCount = result?.totalcount;

      });
  }
  goBack() {
    this.entryTitle = "Users"

    this.isEntry = false;
  }
  addCore() {
    this.entryTitle = "Create User"
    this.isEntry = true;
    this.isViewMode = false;
    this.submitButton = 'Submit';
    this.rowData = {};
  }
  submitData(event: any) {
    // let pass = sha256(event.form.get('password')?.value);
    // pass = sha256(pass + this.salt);
    console.log("event", event)
    let values = event.form
    let id: number = event.id ? event.id : 0;
    this.buttonDisable = true;
    this.contentService.saveUser(values, id).subscribe((data: any) => {
      if (data.responseCode == '200')
        this.buttonDisable = false;
      swal({
        title: "Success",
        text: "Data submitted successfully",
        icon: "success",
      })
        .then((willDelete) => {
          if (willDelete) {
            this.rowData = {};
            this.isEntry = false;
            this.getUsers();
          }
        }
        )
    }
      ,
      (error) => {
        this.buttonDisable = false;
        console.log("edit cutoff error", error);
        let errorMap = error?.error?.errorMap;
        let errorMessage = errorMap
          ? Object.values(errorMap).map((msg: any) => msg.charAt(0).toUpperCase() + msg.substring(1)).join('. ')
          : 'Internal server error';
        // if(error.status !== 403) {
          if(errorMap) {
            swal("", `${errorMessage}`, "error");
          } else {
            swal("", `${error.error.errorMsg}`, "error");
          }
        // }
        // swal("", `${error?.error?.errorMsg?error?.error?.errorMsg : 'Internal server error' }`, "error");
      });
  }
  editData(rowData: any, isEdit: any = true) {
    this.entryTitle = "Edit User"
    this.isViewMode = !isEdit;
    this.rowData = JSON.parse(JSON.stringify(rowData));
    this.isEntry = true;
    this.submitButton = 'Update';
    // this.dynamicForm = this.selectedModuleOptions.dynamicForm;
  }

  deleteFaq(record: any) {
    this.contentService.deleteUserContent(`id=${record.id}`).subscribe((data: any) => {
      if (data.responseCode == '200')
        swal({
          title: "Success",
          text: `Data Deleted successfully`,
          icon: "success",
          // dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
              this.getUsers();
            }
          })
    })
  }

  openDeleteDialog(data: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteFaq(data)
      }
    });
  }
}

<div class="bg-white rounded-3 shadow p-5 my-3">
  <div class="col-auto d-flex">
    <a class="btn btn-link p-1" (click)="goBack()">
      <img src="assets/icons/arrow_back_black_24dp.svg" alt="back icon">
    </a>
    <h4 class="m-1">Content</h4>
  </div>
  <hr>
  <div class="col-auto ">
    <div class="card-body">
      <div class="table-responsive">
        <table class="table" >
          <thead>
            <tr>
              <ng-container *ngFor="let key of filteredHeader | keyvalue | slice:0:6">
                <ng-container>
                  <th class="bg-light fw-bold text-truncate" camelCaseToSpace >{{key.key}}</th>
                </ng-container>
              </ng-container>
            </tr>
          </thead>
        <tr >
          <ng-container *ngFor="let key of filteredHeader | keyvalue | slice:0:6">
            <ng-container *ngIf="key.key != 'File' && key.key != 'Link' ">
              <td>{{getDate(key.value,key.key)}}</td>
            </ng-container>
            <ng-container *ngIf="key.key == 'Link';">
              <td> <a [href]="key.value" target="_blank">{{key.value}}</a></td>
            </ng-container>
            <ng-container *ngIf="key.key == 'File'" >
              <td>
                <a (click)="openDialog(item.File)" style="text-decoration:none;cursor: pointer;"class="m-2">
                  <i class="fa fa-file"></i>
                </a>
              </td>
            </ng-container>
          </ng-container>
        </tr>
        <br>
        <thead>
          <tr>
            <ng-container *ngFor="let key of filteredHeader | keyvalue | slice:6">
              <ng-container>
                <th class="bg-light fw-bold text-truncate" camelCaseToSpace >{{key.key}}</th>
              </ng-container>
            </ng-container>
          </tr>
        </thead>
        <tr >
          <ng-container *ngFor="let key of filteredHeader | keyvalue | slice:6">
            <ng-container *ngIf="key.key != 'File' && key.key != 'Link' ">
              <td>{{getDate(key.value,key.key)}}</td>
            </ng-container>
            <ng-container *ngIf="key.key == 'Link';">
              <td> <a [href]="key.value" target="_blank">{{key.value}}</a></td>
            </ng-container>
            <ng-container *ngIf="key.key == 'File'" >
              <a (click)="openDialog(item.File)" style="text-decoration:none;cursor: pointer;"class="m-2">
                <i class="fa fa-file"></i>
              </a>
            </ng-container>
          </ng-container>
        </tr>
      </table>
      </div>
    </div>
  </div>

</div>
<div class="col-auto shadow card-body bg-white" style="height: 500px;">
  <div class="row m-3">
    <div class="col-sm-3 d-flex m-2">
      <select name="select category" id="category" (change)="onSelectionChange($event)" class="form-select">
        <option *ngFor="let item of catageoryOption" [value]="item.id"
         [selected]="item?.id == rowData?.moduleId" [disabled]=" rowData?.moduleId" >{{item.label}}</option>
      </select>
    </div>
<!-- {{subModules | json}} {{rowData | json}} -->
    <div class="col-sm-3 d-flex m-2">
      <select name="select category" id="category" (change)="onSubModuleChange($event)" class="form-select">
        <option *ngFor="let item of subModules" [value]="item.value"
        [selected]="item?.subModuleId == rowData?.subModuleId || rowData?.moduleId == 'misc' && item?.moduleId == rowData?.subModuleId" [disabled]=" rowData?.subModuleId" >{{item.label}}</option>
      </select>
    </div>
    <div class="col-2 ml-1 mt-2" *ngIf="!rowData?.kmsId">
      <a class="btn" [ngClass]="{' btn-danger ml-2': isEntry, ' btn-success ml-2':!isEntry}" (click)="
          addCore();
          isEntry=!isEntry
        ">
        {{isEntry? 'Cancel':'Generate'}}
      </a>
    </div>
  </div>

  <ng-container *ngIf="isEntry">
    <app-core-document-add [title]="entryTitle" [fileAcceptType]="fileAcceptType" [dynamicForm]="dynamicForm"
      [submitButton]="submitButton" [buttonDisable]="buttonDisable" (previousPath)="resetFileType()"
      [isViewMode]="isViewMode" [rowData]="rowData" [isKms]="isKms" (submitEntry)="submitData($event)"
      [labelKeys]="labelKeys"></app-core-document-add>
  </ng-container>
</div>


import { Component, HostListener, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  pageYoffset = 0;
  @HostListener('window:scroll', ['$event']) onScroll(){
    this.pageYoffset = window.pageYOffset;
  }
  totalVisitor = 0;
  constructor(
    private scroll: ViewportScroller,
    private contentService :ContentService
  ) { }

  ngOnInit(): void {
    this.addVisitorsCount();
    this.getVisitorCount();
  }

  scrollToTop(){
    this.scroll.scrollToPosition([0,0]);
  }
  getVisitorCount(){
    this.contentService.getContent('getvisitorscount').subscribe((res:any)=>{
      this.totalVisitor = res && res['wrapperList'][0]?.visitorsCount;
    })
  }

  addVisitorsCount(){
    this.contentService.addVisitor().subscribe(res=>{
      // console.log(res)
    })

  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContentService } from './content.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

    logout() {
      // Perform logout logic (clear tokens, user data, etc.)
      this.contentService.logout().subscribe((res: any) => {
        console.log('logout')
        sessionStorage.removeItem('token');
      })
    }
    constructor(private _router:Router, private contentService : ContentService) {
    }
    public isLogIn:any = new BehaviorSubject('false')

    isLoggedIn() {
      const token = sessionStorage.getItem('token');
      token? this.isLogIn.next(true): this.isLogIn.next(false)
     return token? true: false
    }



}

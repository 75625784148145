import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/shared/services/content.service';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit {

  constructor(private contentService:ContentService,private router:Router) { }
  highlights:any;
  ngOnInit(): void {
    this.getHighlights();
  }
  getHighlights(){
    this.contentService.getContent("list", "moduleId=28").subscribe((res:any)=>{
      if(res){
        this.highlights = res && res['wrapperList']
      }
    },error=>{
      console.log(error)
    })
  }
  navigateTo(item:any){
    this.router.navigate(['content-details/'+ item.id]);
  }
}

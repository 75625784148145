import { Directive, HostListener, ElementRef, Input } from "@angular/core";
// import { blockChar } from '../../models/restrict-char/restrict-char';
import { blockChar } from './restrict-char';
@Directive({
  selector: "[appAlphabetOnly]",
})
export class AlphabetOnlyDirective {
  key:any;
  @Input() appAlphabetOnly: any;
  blockChar = blockChar;
  // @HostListener("keydown", ["$event"]) onKeydown(event: KeyboardEvent) {
  @HostListener("keydown", ["$event"]) onKeydown(event: any) {
    this.key = event.keyCode;
    let arr;
    let isString = false;
    // console.log(event.target['value'])
    let getVal: any =  event.target['value']

    // let str;
    // let isPatternMatch = event.key.match(/^[A-Za-z0-9\'\"\(\)\-\ \.-@,]+/g);

    // if (this.appAlphabetOnly && this.appAlphabetOnly.length) {
    //   arr = this.appAlphabetOnly;
    //   str = arr.join("").toString();
    //   isString = str.includes(event.key);
    //   if (isString) {
    //     event.preventDefault();
    //   }
    // }
    // if (!isPatternMatch && !isString) {
    //   event.preventDefault();
    // }
    // if(this.field == 'text'){ }
    // console.log('blockChar', event.key, blockChar)
      if(this.appAlphabetOnly && this.appAlphabetOnly.length){
        let block = this.blockChar
        // console.log("before",block)
        block = block.filter(el=>this.appAlphabetOnly.indexOf( el ) < 0);
        // console.log("final block",block)
       let str = block.join("").toString();
        let isText = str.includes(event.key);
        // console.log("isTextisTextisText",isText)
        if (isText) {
          // console.log("pass")
          event.preventDefault();
        }
        // block = block.filter( function( el ) {
        //   return this.appAlphabetOnly.indexOf( el ) < 0;
        // });

      }else{
        let block= this.blockChar;
        // let arrText = block;
        let str = block.join("").toString();
        let isText = str.includes(event.key);
        if (isText) {
          event.preventDefault();
        }
      }


  }
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent){
    e.preventDefault()
  //     if(this.appAlphabetOnly) {
  //     // const isRestrictPasteExist = this.appAlphabetOnly.find((item:any) => item == '####')
  //     // if (isRestrictPasteExist) {
  //     //   e.preventDefault()}
  //     // }
  // }
}
}
